<template lang="pug">
    #static-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4           
            //- list
            .card.card--shadow.bg-white.border-gray-200.p-4.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                    h2.font-bold List FAQ
                    .header-cta.flex.items-center
                        router-link(to="/faq/create").btn-primary.btn-small.ml-2
                            span.mr-1
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                            span Add FAQ
                .card__body
                    .filter-content.mb-4
                        SearchInput(:inputData.sync="searchInput" @search="load()")

                    .box-table
                        table.table.table-set.w-full
                            thead
                                tr
                                    th  No.
                                    th  Question
                                    th  Status
                                    th  created at
                                    th  
                            tbody
                                tr(v-for="(list, index) in pagesList" :key="list.index")
                                    td.w-8 {{ index + 1 }}.
                                    td
                                        router-link.link.font-bold.block(:to="{ name: 'FAQDetail', params: { id: list.id }}" style="max-width:450px")
                                            span {{ list.title }}

                                    td
                                        template(v-if="list.status === 1")
                                            span.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Publish
                                        template(v-if="list.status === 2")
                                            span.px-2.py-1.bg-orange-300.text-orange-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Draft

                                    td
                                        small {{ list.created_at | moment('DD MMMM YYYY') }}

                                    td
                                        router-link(:to="{name:'FAQEdit', params: {id: list.id}}").btn-default.btn-icon
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                        button.btn-default.btn-icon(@click="destroy(list.id)")
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                    Pagination( :pagination="pagination" @paginate="load()" )
</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import SearchInput from './../../components/content/SearchInput';
import Pagination from './../../components/content/Pagination';
import Vue from "vue";

export default {
    name: 'FAQPage',
    components: {
        Breadcrumb,
        SearchInput,
        Pagination,

    },
    methods: {
        load() {
            let params = '?q='+this.searchInput+'&page='+this.pagination.current_page;

            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/faq'+params)
                .then(response => {
                    this.pagesList = response.data.data
                    this.pagination = response.data.meta
                })
        },
        destroy (id) {
            if (confirm('Are you sure?')) {
                Vue.prototype.$http
                    .delete(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/faq/'+ id)
                    .then(() => {
                        this.load()

                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success Deleting FAQ!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }
        },
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'FAQ',
                    url: '/faq',
                },
            ],
            pagesList: null,
            searchInput: '',
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    mounted() {
        this.load()
    }
    

}
</script>

<style lang="sass" scoped>
small
    line-height: 0
    position: relative
    top: -4px

.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>