<template lang="pug">
    #poin-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            //- list
            .card.card--shadow.bg-white.border-gray-200.p-4.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                    .flex.items-center
                        h2.font-bold Request Adjustment Poin
                        .bubble.w-5.h-5.rounded-full.text-red-100.bg-red-600.flex.items-center.justify-center.text-xs.font-bold.ml-2(v-if="total_request != 0") {{ total_request }}
                .card__body
                    .block
                        .box-table
                            table.table.table-set.w-full
                                thead
                                    tr
                                        th Date
                                        th Requested By
                                        th.border-r Reff
                                        th Transaction Amount Before
                                        th.border-r Point Before
                                        th Transaction Amount After Adjustment
                                        th.border-r  Point After Adjustment
                                        th
                                tbody
                                    tr(v-for="item in dataPoin" :key="item.id")
                                        td {{ item.created_at | moment('DD MMMM YYYY') }}
                                        td
                                            .block {{ item.merchant.company_name }}
                                            .text-xs {{ item.staff.name }}
                                        td.border-r
                                            button.btn-primary-o.mr-2.btn-small(@click="showAttachment(item.transaction)")
                                                icon.w-6.h-6
                                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Document Text</title><path d='M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/><path d='M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/></svg>
                                                span &nbsp;{{ item.transaction.transaction_reference }}
                                        td {{ item.transaction.transaction_amount | moneyFilter }}
                                        td.border-r {{ item.transaction.point | moneyFilter }}
                                        td {{ item.transaction_amount | moneyFilter }}
                                        td.border-r {{ item.point_after_correction | moneyFilter }}
                                        td.action-2
                                            button.btn.btn-danger-o.border-red-500.text-red-500.btn-small.mb-2(type='button' @click="rejectReasonAdjustment(item)") Reject&nbsp;
                                            button.btn-primary.btn-small(type='button' @click="acceptAdjustment(item.id)") Accept

        .overlay-popup.bg-gray-200.bg-opacity-25(v-if="showModal")
            .overlay-popup__container
                .popup.rounded.shadow
                    header.popup__header.border-b.border-gray-200.p-6.flex.justify-between
                        h2.font-bold Detail Transaction Reff - {{ reffID }}
                        button.btn-icon.btn-icon--40(type='button' @click="showModal = false")
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    .popup__body.p-8
                        span.mb-3 Reff : {{ reffID }}
                        template(v-if="attachmentReff")
                            .block.border-b.border-gray-200.pb-4.mt-4
                                img(:src="attachmentReff" )
                        template(v-else)
                            .block
                                .block No Image

                    .popup__footer.border-t.border-gray-200.p-6
                        .flex.items-center.justify-end
                            button.btn-default(type='button' @click="showModal = false") Back

        .overlay-popup.bg-gray-200(v-if="showReject")
            .overlay-popup__container
                .popup.rounded.shadow
                    header.popup__header.border-b.border-gray-200.p-6.flex.justify-between
                        h2.font-bold Detail Request Adjustment Poin
                        button.btn-icon.btn-icon--40(type='button' @click="showReject = false")
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    .popup__body.p-8
                        .block.border-b.border-gray-200.pb-4.mb-4
                            strong.text-xs.text-gray-600.block.pb-2 Requested time
                            p {{ rejectItem.created_at | moment('DD MMMM YYYY') }}

                        .block.border-b.border-gray-200.pb-4.mb-4
                            strong.text-xs.text-gray-600.block.pb-2 Requested by
                            p.mb-1 {{ rejectItem.merchant.company_name || '' }}
                            p.text-xs {{ rejectItem.staff.name || ''}}

                        .block.border-b.border-gray-200.pb-4.mb-4
                            strong.text-xs.text-gray-600.block.pb-2 Transaction Amount Before
                            p.mb-1 {{ rejectItem.transaction.transaction_amount || '' }}

                        .block.border-b.border-gray-200.pb-4.mb-4
                            strong.text-xs.text-gray-600.block.pb-2 Transaction Amount After
                            p.mb-1 {{ rejectItem.transaction_amount || '' }}

                        //- .block.border-b.border-gray-200.pb-4.mb-4
                            strong.text-xs.text-gray-600.block.pb-2 Point After Correction
                            p.mb-1 {{ rejectItem.type == 1 ? '+':'-' }} {{ rejectItem.point_after_correction }}

                        .block
                            strong.text-xs.text-gray-600.block.pb-2 Reason
                            input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                type="text"
                                placeholder="Reason reject"
                                v-model="reason"
                            )

                    .popup__footer.border-t.border-gray-200.p-6
                        .flex.items-center.justify-end
                            button.btn-default(type='button' @click="showReject = false") Back
                            button.btn-primary.ml-4(type='button' @click="rejectAdjustment(rejectItem.id)") Reject Request

</template>

<script>
    import Breadcrumb from './../../components/content/breadcrumb';
    import Vue from "vue";

    export default {
        name: 'NotificationPage',
        components: {
            Breadcrumb,

        },
        data() {
            return {
                breadcrumb: [
                    {
                        name: 'Request Adjustment Poin',
                        url: '/notification',
                    },
                ],
                dataPoin: [],
                total_request: 0,
                categoryFilter: '',
                rejectItem: null,
                showReject: false,
                reason: '',

                checkAttachment: null,
                attachmentReff: '',
                reffID: '',
                showModal: false,
            }
        },
        methods: {
            load() {
                let params = '';
                if (this.categoryFilter != '') params += '&category='+this.categoryFilter;
                Vue.prototype.$http
                    .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/adjustment/request'+params)
                    .then((response) => {
                        this.dataPoin = response.data.data
                    })
            },
            showAttachment(item){
                this.checkAttachment = null
                this.reffID = item.transaction_reference
                if (item.attachment !== null) {
                    this.checkAttachment = 'reff'
                    this.attachmentReff = item.attachment[0]
                } else {
                    this.attachmentReff = null
                    this.checkAttachment = null
                }
                this.showModal = !this.showModal;
            },
            acceptAdjustment(id) {
                if(confirm("Are you sure?")) {
                    Vue.prototype.$http
                        .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/adjustment/request/' + id + '/accept')
                        .then((response) => {
                            this.dataPoin = response.data.data
                            this.load()

                            this.$store
                                .commit('ADD_NOTIFICATION', {
                                    title: 'Success!',
                                    text: 'Success Accepting Adjustment!',
                                    type: 'success',
                                    timeout: false,
                                    delay: 0
                                });
                        })
                }
            },
            rejectReasonAdjustment(item) {
                this.rejectItem = item;
                this.showReject = true;
                // Vue.prototype.$http
                //     .delete(process.env.VUE_APP_API_HOST + '/api/v1//management/memberships/master/adjustment/request/'+id)
                //     .then((response) => {
                //         this.dataPoin = response.data.data
                //     })
            },
            rejectAdjustment(id) {
                if(confirm("Are you sure?")) {
                    Vue.prototype.$http
                        .delete(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/adjustment/request/'+id+'?reason='+this.reason)
                        .then((response) => {
                            this.dataPoin = response.data.data
                            this.showReject = false
                            this.load()

                            this.$store
                                .commit('ADD_NOTIFICATION', {
                                    title: 'Success!',
                                    text: 'Success Reject Adjustment!',
                                    type: 'success',
                                    timeout: false,
                                    delay: 0
                                });
                        })
                }
            },
        },
        mounted() {
            this.load();
        },

    }
</script>

<style lang="sass" scoped>
    .overlay-popup
        position: fixed
        left: 0
        top: 0
        bottom: 0
        right: 0
        width: 100%
        height: 100%
        overflow: auto
        &__container
            .popup
            background-color: #ffffff
            max-width: 600px
            margin: 2rem auto
            &__header
                display: flex
                align-items: center
                justify-content: space-between


    .table-set
        tr
            th,
            td
                padding: 12px 16px
                border-bottom: 1px solid #f1f7fb
                transition: all 0.3s ease-in-out
            &:hover
                td
                    background-color: rgba(0, 0, 0, 0.0250 )
            th
                text-transform: uppercase
                text-align: left
                font-size: 11px
                opacity: 0.7
</style>
