<template lang="pug">
    #broadcast-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                    h2.font-bold Group Broadcast A
                    .header-cta.flex.items-center
                        router-link(to="/broadcast").link.font-sx
                            span Back
                .card__body
                    .flex.items-center.justify-between.mb-8
                        .flex-grow.font-bold 150 Users
                        .flex.items-center
                        button.btn-primary-o.mr-2.btn-small
                            .icon.mr-2
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                            span Import Group
                        button.btn-primary-o.mr-2.btn-small
                            .icon.mr-2
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                            span Export Group
                        router-link.btn-primary-o.btn-small(to="/broadcast/new") Edit Group

                    .bg-orange-200.text-orange-900.p-3.rounded.mb-4.flex.items-center.justify-between
                        span 5 Users checked
                        button.btn-primary.btn-small(type='submit') Delete
                        

                    .box-table.border
                        table.table.table-set.w-full
                            thead
                                tr
                                    th
                                        .field-checkbox
                                            input( type="checkbox" name="user" checked)
                                            .ui-checkbox
                                                .bullet
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>

                                    th  No.
                                    th  Name
                                    th  Email
                                    th  Phone
                                    th  Poin
                                    th
                            tbody
                                tr(v-for="(item, index) in broadcastGroup" :key="item.id")
                                    td.w-12
                                        .field-checkbox
                                            input( type="checkbox" name="user" checked)
                                            .ui-checkbox
                                                .bullet
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>

                                    td.w-8 {{ index + 1}}
                                    td
                                        router-link.link.font-bold(:to="{ name: 'UserPage', params: { id:item.member.id }}")
                                            .avatar.flex.items-center
                                                figure.avatar.w-8.h-8.rounded-full.overflow-hidden.mr-4
                                                    img.w-full.h-full.object-cover(:src="item.member.base_user.avatar", v-bind:alt="item.name")
                                                span {{ item.member.customer_name }}
                                    td  {{ item.member.base_user.email }}
                                    td  {{ item.member.base_user.phone }}
                                    td  {{ item.member.point_active | moneyFilter }}
                                    td
                                        button.btn-icon.btn-icon--40(type='submit')
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>

                        Pagination( :pagination="pagination" @paginate="load()" )


</template>

<script>
import Breadcrumb from './../../../components/content/breadcrumb';
import Vue from "vue";
import Pagination from "../../../components/content/Pagination";

export default {
    name: 'BroadcastGroupDetail',
    components: {
        Breadcrumb,
        Pagination,
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Broadcast',
                    url: '/broadcast',
                },
                {
                    name: 'Group Broadcast A',
                    url: '/create',
                },
            ],

            broadcastGroup: [],
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    methods: {
        load() {
            let params = '?q='+this.searchInput+'&page='+this.pagination.current_page;
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/notification/group/'+this.$route.params.id+'/member'+params)
                .then((response) => {
                    this.broadcastGroup = response.data.data
                    this.pagination = response.data.meta
                })
        },
    },
    mounted() {
        this.load();
    },

}
</script>

<style lang="sass" scoped>
    .table-set
        tr
            th,
            td
                padding: 12px 16px
                border-bottom: 1px solid #f1f7fb
                transition: all 0.3s ease-in-out
            &:hover
                td
                    background-color: rgba(0, 0, 0, 0.0250 )
            th
                text-transform: uppercase
                text-align: left
                font-size: 11px
                opacity: 0.7
    .btn-icon
        display: flex
        align-items: center
        justify-content: center
        border-radius: 100%
        &--40
            width: 40px
            height: 40px
            svg
                width: 20px
                height: 20px

    .field-checkbox
        position: relative
        cursor: pointer
        input
            width: 100%
            height: 100%
            position: absolute
            left: 0
            top: 0
            opacity: 0
            z-index: 3
            &:checked
                +
                    .ui-checkbox
                        .bullet
                            border: 1px solid brown
                            svg
                                opacity: 1
                                stroke: brown
                        span
                            color: brown
        .ui-checkbox
            display: inline-flex
            align-items: center
            .bullet
                width: 24px
                height: 24px
                border-radius: 4px
                border: 1px solid #dddddd
                position: relative
                display: flex
                align-items: center
                justify-content: center
                transition: all 0.3s
                svg
                    opacity: 0
            span
</style>