<template lang="pug">
    #news-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                    h2.font-bold {{ editing ? 'Edit' : 'New' }} What's On
                    .header-cta.flex.items-center
                        router-link(to="/whats-on").link.font-sx
                            span Back
                .card__body
                    form(action="" method="method" @submit.prevent="onSubmit(0)")
                        section.border-b.border-blue-100.mb-8.pb-8
                            .container-small
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Title
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: Nikmati akrhir tahun di JHL Serpong"
                                                    v-model="form.title"
                                                )
                                                .flex.items-center.justify-between.mt-2(v-if="editing")
                                                    span.info.text-xs {{ form.name }}
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Category
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    v-model="form.category"
                                                )
                                                    option(value="0" selected) Select Category
                                                    option(v-for="(category, index) in categoryList" :key="category.index" :value="category.id") {{ category.name }}
                                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                    <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            //-.flex.items-center.justify-between.mt-2
                                                span.info.text-xs message
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Cover
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .w-full.bg-gray-200.mb-2(style="max-width: 600px")
                                                img.w-full.rounded(
                                                    v-if="coverImage"
                                                    :src="coverImage ? coverImage : 'https://i.imgur.com/dL0n397.png'", alt="alt"
                                                )
                                            span.info.text-xs Please upload image with 864 x 1080

                                            .block.mt-4
                                                UploadMedia(:inputMedia.sync="coverImage")

                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right
                                            label.block.h-16.py-2 News
                                                small.text-red-500(style="padding-left:4px") *
                                        .block(class="w-3/4").ml-8
                                            .relative.w-full.mr-4
                                                vueEditor(
                                                    v-model="form.content"
                                                )
                                                //-.flex.items-center.justify-between.mt-2
                                                    span.info.text-xs message
                        section
                            .flex.items-center.justify-end
                                //-button.link(type='button') Reset
                                button.btn-primary-o.ml-3(type='button' @click="add(0)" v-if="!editing") Draft
                                button.btn-primary.ml-3(type='button' @click="add(1)" v-if="!editing") Publish
                                button.btn-primary-o.ml-3(type='button' @click="update(0)" v-if="editing") Draft
                                button.btn-primary.ml-3(type='button' @click="update(1)" v-if="editing") Update


</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import UploadMedia from './../../components/content/UploadMedia';
import { VueEditor } from "vue2-editor";
import Vue from "vue";
import router from "../../router";

export default {
    name: 'NewsCreate',
    components: {
        Breadcrumb,
        UploadMedia,
        VueEditor
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Whats On',
                    url: '/whats-on',
                },
                {
                    name: 'Create',
                    url: '/create',
                },
            ],
            categoryList: null,
            form: {
                title: null,
                category: 0,
                image: 'https://i.imgur.com/dL0n397.png',
                content: "<h2>Some initial content</h2>",
                status: 0,
                comment_status: 1,
            },
            coverImage: 'https://i.imgur.com/dL0n397.png',
            editing: false,
        }
    },

    methods: {
        load(){
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/post/' + this.$route.params.id)
                .then((response) => {
                    // this.merchantData = response.data.data
                    var data = response.data.data;
                    this.form = data
                    this.form.category = data.category[0].id ? data.category[0].id:0;
                    this.coverImage = data.image
                    this.breadcrumb = [
                        {
                            name: 'Whats On',
                            url: '/whats-on',
                        },
                        {
                            name: data.title,
                            url: '/whats-on/edit/'+data.id,
                        },
                    ]
                })
        },

        add(status) {
            var data = {
                'title': this.form.title,
                'content': this.form.content,
                'category': this.form.category,
                'image': this.coverImage,
                'comment_status': this.form.comment_status,
                'publish_at': Vue.moment().format('YYYY-MM-DD'),
                'status': status
            }

            Vue.prototype.$http
                .post(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/post', data)
                .then(response => {
                    router.push({ name: 'NewsDetail', params: { id: response.data.data.id } })
                })
        },
        update(status) {
            var data = {
                'title': this.form.title,
                'content': this.form.content,
                'category': this.form.category,
                'image': this.coverImage,
                'comment_status': this.form.comment_status,
                'publish_at': Vue.moment().format('YYYY-MM-DD'),
                'status': status
            }

            Vue.prototype.$http
                .patch(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/post/'+this.$route.params.id, data)
                .then(response => {
                    router.push({ name: 'NewsDetail', params: { id: response.data.data.id } })
                })
        }
    },
    created() {
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/category')
            .then((response) => {
                this.categoryList = response.data.data

                if ("id" in this.$route.params) {
                    this.load();
                    this.editing = true;
                } else {
                    // this
                }
            })
    },
    

}
</script>

<style lang="sass" scoped>
    .input-file
        width: 100%
        height: 100%
        left: 0
        top: 0
        opacity: 0
        position: absolute
        z-index: 1
</style>