<template lang="pug">
    //- voucher activity
    .card
        header.card__header.flex.items-center.justify-between.pb-4
            h2.font-bold Voucher
        .card__body
            .filter-content.mb-4
                SearchInput(:inputData.sync="searchInput" @search="filter()")
                .inline-block.relative.w-64
                    select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(v-model="statusFilter" @change="filter()")
                        option(value="all") All Status
                        option(value="active") Active
                        option(value="claimed") Claimed
                        option(value="redeemed") Redeemed
                        option(value="expired") Expired
                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                        <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>

            .block.mb-6.pb-6.border-b.border-gray-200
                .list-item.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4(
                    v-for="list in voucherList" :key="list.index"
                )
                    .flex.items-center.flex-grow
                        router-link.block(:to="{ name: 'VoucherDetail', params: { id: list.catalogue.id }}")
                            figure.rounded.w-16.h-16.overflow-hidden
                                img.w-full.h-full.object-cover(:src="list.catalogue.thumbnail ? list.catalogue.thumbnail : 'https://i.imgur.com/9XwzHPA.png'", v-bind:alt="list.catalogue.catalogue_name")
                        router-link.pl-3.block(:to="{ name: 'VoucherDetail', params: { id: list.catalogue.id }}") {{ list.catalogue.catalogue_name }}
                            small.block Code : {{ list.voucher_code }}
                    template(v-if="list.voucher_exchanged_at")
                        .text-green-500.text-right.text-xs
                            .block ditukarkan pada
                            | {{ list.voucher_exchanged_at | moment('DD MMMM YYYY')}}
                    template(v-else-if="checkClosestExpired(list.last_allowed_exchange_date)")
                        .text-yellow-500.text-right.text-xs
                            .block kadaluarsa
                            | {{ list.last_allowed_exchange_date | moment('from', true) }} lagi
                    template(v-else-if="checkExpired(list.last_allowed_exchange_date)")
                        span.text-red-500.text-right.text-xs
                            .block kadaluarsa
                            | {{ list.last_allowed_exchange_date | moment('DD MMMM YYYY') }}
                    template(v-else)
                        span.text-right.text-xs
                            .block kadaluarsa pada
                            | {{ list.last_allowed_exchange_date | moment('DD MMMM YYYY') }}
            Pagination( :pagination="pagination" @paginate="load()" )
    //- end voucher activity
</template>

<script>
import Vue from "vue";
import Pagination from './../../components/content/Pagination';
import SearchInput from './../../components/content/SearchInput';

export default {
    name: 'VoucherActivity',
    components: {
        Pagination,
        SearchInput,
    },
    data() {
        return {
            voucherList: null,
            searchInput: '',
            statusFilter: 'all',
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    methods: {
        load() {
            let loader = this.$loading.show()
            let params = '?page='+this.pagination.current_page;
            if (this.statusFilter != '') params += '&status='+ this.statusFilter;
            if (this.searchInput != '') params += '&q='+ this.searchInput;
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/'+this.$route.params.id+'/voucher'+params)
                .then((response) => {
                    this.voucherList = response.data.data
                    this.pagination = response.data.meta
                    loader.hide()
                })
        },
        filter(){
            this.pagination.current_page = 1;
            this.load()
        },
        checkExpired(date) {
            return Vue.moment(date) < Vue.moment()
        },
        checkClosestExpired(date) {
            return Vue.moment(date).fromNow().includes('hari')
        }
    },
    mounted() {
        this.load()
    }
}
</script>