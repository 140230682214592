<template lang="pug">
    .alert.alert-info.flex.items-center.justify-between.bg-blue-500.text-blue-100.p-4.rounded.fixed.right-0.top-0.mr-5.mt-20.z-20(v-if="submitStatus === 'PENDING'")
        .flex-grow
            p Sending...
        //- button.btn-icon.btn-icon--40(type='button' @click="submitStatus = null")
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
    .alert.alert-success.flex.items-center.justify-between.bg-green-500.text-green-100.p-4.rounded.fixed.right-0.top-0.mr-5.mt-20.z-20(v-else-if="submitStatus === 'OK'")
        .flex-grow
            p Role Saved
        button.btn-icon.btn-icon--40(type='button' @click="submitStatus = null")
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
    .alert.alert-error.flex.items-center.justify-between.bg-red-500.text-red-100.p-4.rounded.fixed.right-0.top-0.mr-5.mt-20.z-20(v-else-if="submitStatus === 'ERROR'")
        .flex-grow
            p Something went wrong
        button.btn-icon.btn-icon--40(type='button' @click="submitStatus = null")
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
</template>

<script>
    export default{
        name: 'SubmitStatus',
        props: {
            submitStatus: {
                type: String,
            },
        },
    }
</script>