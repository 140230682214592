<template lang="pug">
    .box-on-boarding
        .flex.items-center.justify-between.mb-4.pb-4
            h2.font-bold Banner Call To Action
            .block
                router-link(:to="{ name: 'BannerCreate' }").btn-primary.btn-small.ml-2
                        span Add Banner
        .box-table
            table.table.table-set.w-full
                thead
                    tr
                        th  Image
                        th  Activity
                        th  Target
                        th  Clicked
                        th  Status
                        th
                tbody
                    tr(v-for="banner in bannerData")
                        td
                            .w-32
                                img.w-full(:src="banner.image ? banner.image : 'https://i.imgur.com/dL0n397.png'", :alt="banner.name")
                        td {{ banner.activity }}
                        td
                            p {{ banner.activity_target ? banner.activity_target : '-' }}
                        td
                            h2 -
                        td
                            template(v-if="banner.status == 0")
                                span.px-2.py-1.bg-gray-200.text-gray-700.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Deactived
                            template(v-if="banner.status == 1")
                                span.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Active
                        td.action-2
                            router-link(:to="{name:'BannerEdit', params: {id: banner.id}}").btn-default.btn-icon
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                            button.btn-default.btn-icon(@click="destroy(banner.id)")
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
</template>

<script>
import Vue from "vue";

export default {
    name: 'OnBoarding',
    data() {
        return {
            bannerData: [],
        }
    },
    methods: {
        load() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/banner?group=HOME')
                .then((response) => {
                    this.bannerData = response.data.data
                })
        },
        destroy (id) {
            if (confirm('Are you sure?')) {
                Vue.prototype.$http
                    .delete(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/banner/' + id)
                    .then(() => {
                        this.load()

                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success Deleting Banner!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }

        },
    },
    mounted() {
        this.load();
    },
}
</script>

<style lang="sass" scoped>
    .table-set
        tr
            th,
            td
                padding: 12px 16px
                border-bottom: 1px solid #f1f7fb
                transition: all 0.3s ease-in-out
            &:hover
                td
                    background-color: rgba(0, 0, 0, 0.0250 )
            th
                text-transform: uppercase
                text-align: left
                font-size: 11px
                opacity: 0.7
</style>