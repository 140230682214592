<template lang="pug">
    .auth-page
        .auth-page__header.mb-8
            h1 Forgot Your password?
            p We will send your link to reset your password to your email. Please write registered account email bellow
        .auth-page__body
            form(action="" method="method")
                .field.mb-4
                    label.text-sm.font-bold.mb-3.block(
                        for="account"
                    ) Email Registered
                    input.border-main.border.rounded.w-full.py-2.px-3(
                        type="text"
                        placeholder="Write your email"
                        id="account"
                    )
                .field.flex.items-center.justify-between
                    button.btn-primary(type='submit').font-bold.uppercase Send
                    router-link.link(to="Login").text-sm Back to login

</template>

<script>
export default {
    name:'LoginPage'
}
</script>

<style lang="sass" scoped>
    .border-main
        border-color: #FFEFD8
        background-color: darken(#231F20,5%)
        &::placeholder
            color: #FFEFD8
            opacity: 0.4

</style>