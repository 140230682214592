<template lang="pug">
    .report-page
        vue-tabs
            v-tab(title="Register")
                RegisterActivity

            v-tab(title="Membership")
                MembershipActivity

            v-tab(title="Poin")
                PoinActivity
            //- v-tab(title="Login")
                LoginActivity

</template>

<script>
import {VueTabs, VTab} from 'vue-nav-tabs'
import RegisterActivity from './RegisterActivity'
import MembershipActivity from './MembershipActivity'
import PoinActivity from './PoinActivity'
import LoginActivity from './LoginActivity'

export default {
    name: 'ReportsCustomer',
    components: {
        VueTabs,
        VTab,
        RegisterActivity,
        MembershipActivity,
        PoinActivity,
        LoginActivity,
    },
}
</script>