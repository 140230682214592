<template lang="pug">
    #broadcast-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            vue-tabs
                v-tab(title="Broadcast")
                    //- Broadcast
                    BroadcastDashboard
                    //- end Broadcast
                v-tab(title="Group")
                    //- Broadcast group
                    BroadcastGroup
                    //- end Broadcast group
            
</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import {VueTabs, VTab} from 'vue-nav-tabs'
import BroadcastDashboard from './dashboard/Index';
import BroadcastGroup from './group/Index';

export default {
    name: 'CustomersPage',
    components: {
        Breadcrumb,
        VueTabs,
        VTab,
        BroadcastDashboard,
        BroadcastGroup,
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Broadcast',
                    url: '/broadcast',
                },
            ],
        }
    }

}
</script>

<style lang="sass" scoped>
small
    line-height: 0
    position: relative
    top: -4px

.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>