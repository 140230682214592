<template lang="pug">
    #broadcast-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .card.card--shadow.bg-white.border-gray-200.p-12.rounded.mb-8
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                    h2.font-bold {{ broadcastData.title }}
                    .header-cta.flex.items-center
                        router-link(to="/broadcast").link.font-sx
                            span Back
                .card__body
                    .grid.grid-cols-3.gap-4.mb-8
                        .block
                            span.block.mb-3.text-xs.uppercase.text-gray-500.font-bold Type
                            .border.border-gray-300.bg-gray-200.p-1.rounded.text-gray-600.w-32.text-center.text-xs.font-bold.uppercase Notification
                        .block
                            span.block.mb-3.text-xs.uppercase.text-gray-500.font-bold Send to
                            .text-ms {{ broadcastData.group.name }}
                        .block
                            span.block.mb-3.text-xs.uppercase.text-gray-500.font-bold Created at
                            .text-ms {{ broadcastData.created_at | moment('DD MM YYYY') }}
                    .border.border-gray-300.p-4.rounded
                        span.block.mb-3.text-xs.uppercase.text-gray-500.font-bold redirect to
                        //- if link has voucher
                        .flex.items-center(v-if="broadcastData.activity == 'voucher'")
                            .w-24.mr-4
                                figure
                                    img.rounded(src="https://i.imgur.com/9XwzHPA.png", alt="alt")
                            .block
                                h3.text-ms.mb-2 Voucher Hotel 10% promo ramdahan Berkah 2020
                                .mb-2.px-2.py-1.bg-gray-300.text-gray-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase silver
                                .inline-block.border.border-gray-200.bg-gray-100.pl-2.px-4.rounded.w-auto
                                    .flex.items-center
                                        .icon.w-8.h-8.flex.items-center.justify-center
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                        | Hotel
                        .flex.items-center(v-else="broadcastData.activity == 'news'")
                            .w-24.mr-4r
                                figure
                                    img.rounded(src="https://i.imgur.com/9XwzHPA.png", alt="alt")
                            .block
                                h3.text-ms.mb-2 news Hotel 10% promo ramdahan Berkah 2020
                                .mb-2.px-2.py-1.bg-gray-300.text-gray-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase silver
                                .inline-block.border.border-gray-200.bg-gray-100.pl-2.px-4.rounded.w-auto
                                    .flex.items-center
                                        .icon.w-8.h-8.flex.items-center.justify-center
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                        | Hotel

            .grid.grid-cols-5.gap-x-1.mb-8
                .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
                    .block 
                        span.text-xs.opacity-75.uppercase Broadcast to
                        h2.text-3xl {{ broadcastData.group.member_count | moneyFilter }}
                .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
                    .block 
                        span.text-xs.opacity-75.uppercase Opened
                        h2.text-3xl {{ broadcastData.opened_count | moneyFilter }}
                    //-.block.text-right.text-xs
                        span.block.text-green-300 + 2%
                        small from Last day
                .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
                    .block 
                        span.text-xs.opacity-75.uppercase Percentation Opened
                        h2.text-3xl {{ percentationOpened }}%
                    //-.block.text-right.text-xs
                        span.block.text-green-200 + 20%
                        small from Last day
        
            .card.card--shadow.bg-white.border-gray-200.p-4.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                    h2.font-bold Group Broadcast {{ broadcastData.group.name }}'s users
                .card__body
                    .box-table
                        table.table.table-set.w-full
                            thead
                                tr
                                    th  No.
                                    th  Name
                                    th  Email
                                    th  Phone
                                    th  Poin
                                    th  Status
                            tbody
                                tr(v-for="(item, index) in broadcastGroup" :key="item.id")
                                    td.w-8 {{ index + 1}}
                                    td
                                        router-link.link.font-bold(:to="{ name: 'UserPage', params: { id:item.member.id }}")
                                            .avatar.flex.items-center
                                                figure.avatar.w-8.h-8.rounded-full.overflow-hidden.mr-4
                                                    img.w-full.h-full.object-cover(:src="item.member.base_user.avatar", v-bind:alt="item.name")
                                                span {{ item.member.customer_name }}
                                    td  {{ item.member.base_user.email }}
                                    td  {{ item.member.base_user.phone }}
                                    td  {{ item.member.point_active | moneyFilter }}
                                    td
                                        .w-24
                                            template(v-if="item.status === 'opened'")
                                                .bg-green-300.text-green-800.rounded.p-1.text-center.font-bold.uppercase.text-xs {{ item.status}}
                                            template(v-if="item.status === 'received'")
                                                .bg-orange-300.text-orange-800.rounded.p-1.text-center.font-bold.uppercase.text-xs {{ item.status}}

                        Pagination( :pagination="pagination" @paginate="load()" )


</template>

<script>
import Breadcrumb from './../../../components/content/breadcrumb';
import Vue from "vue";
import Pagination from "../../../components/content/Pagination";

export default {
    name: 'BroadcastDetail',
    components: {
        Breadcrumb,
        Pagination,
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Broadcast',
                    url: '/broadcast',
                },
                {
                    name: 'Ada Voucher 10% untuk Anda',
                    url: '/create',
                },
            ],
            broadcastData: null,
            broadcastGroup: [],
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    computed: {
        percentationOpened: function(){
            var percentage = this.broadcastData.opened_count / this.broadcastData.group.member_count * 100;
            if (isNaN(percentage)) percentage = 0;
            return percentage;
        }
    },
    methods: {
        load() {
            let params = '?q='+this.searchInput+'&page='+this.pagination.current_page;
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/notification/group/'+this.$route.params.id+'/member'+params)
                .then((response) => {
                    this.broadcastGroup = response.data.data
                    this.pagination = response.data.meta
                })

            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/notification/group/'+this.$route.params.groupId+'/message/'+this.$route.params.id)
                .then((response) => {
                    this.broadcastData = response.data.data

                    this.breadcrumb = [
                        {
                            name: 'Broadcast',
                            url: '/broadcast',
                        },
                        {
                            name: this.broadcastData.title,
                            url: '/broadcast/group/'+this.broadcastData.group.id+'/detail/'+this.broadcastData.id,
                        },
                    ]
                })
        },
    },
    mounted() {
        this.load();
    },

}
</script>

<style lang="sass" scoped>
    .table-set
        tr
            th,
            td
                padding: 12px 16px
                border-bottom: 1px solid #f1f7fb
                transition: all 0.3s ease-in-out
            &:hover
                td
                    background-color: rgba(0, 0, 0, 0.0250 )
            th
                text-transform: uppercase
                text-align: left
                font-size: 11px
                opacity: 0.7
</style>