import Vue from 'vue'
import Router from 'vue-router'

import auth from '../library/store'


// Auth
import LoginPage from './../pages/auth/Login.vue'
import ForgotPage from './../pages/auth/Forgot.vue'

// Dashboard
import DashboardPage from './../pages/dashboard/Index.vue'
import MerchantDashboardPage from './../pages/merchant/Detail.vue'

// customers
import CustomersPage from './../pages/customers/Index.vue'
import CustomerEdit from './../pages/customers/Edit.vue'

// broadcast
import BroadcastShell from './../pages/broadcast/Broadcast.vue'
import BroadcastPage from './../pages/broadcast/Index.vue'
import BroadcastDashboardDetail from './../pages/broadcast/dashboard/Detail.vue'
import BroadcastDashboardCreate from './../pages/broadcast/dashboard/Create.vue'
import BroadcastGroupDetail from './../pages/broadcast/group/Detail.vue'
import BroadcastGroupCreate from './../pages/broadcast/group/Create.vue'

// users
import UserPage from './../pages/users/Index.vue'

// profile
import ProfilePage from './../pages/profile/Index.vue'
import ProfileEdit from './../pages/profile/Edit.vue'

// Notification
import NotificationPage from './../pages/notification/Index.vue'

// Adj Poin
import TicketPoinPage from './../pages/poin/Request.vue'
import PoinPage from './../pages/poin/Index.vue'

// voucher
import VouchersShell from './../pages/vouchers/Vouchers.vue'
import VouchersPage from './../pages/vouchers/Index.vue'
import VoucherCreate from './../pages/vouchers/Create.vue'
import VoucherDetail from './../pages/vouchers/Detail.vue'
import PreviewVoucher from './../pages/vouchers/Preview.vue'
import SendVoucher from './../pages/vouchers/SendToCustomer.vue'

// tranfer point
import GiftCardShell from './../pages/giftcard/GiftCard.vue'
import GiftCardPage from './../pages/giftcard/Index.vue'
import GiftCardCreate from './../pages/giftcard/Create.vue'
import GiftCardDetail from './../pages/giftcard/Detail.vue'

// Merchant
import MerchantShell from './../pages/merchant/Merchant.vue'
import MerchantPage from './../pages/merchant/Index.vue'
import MerchantCreate from './../pages/merchant/Create.vue'
import MerchantDetail from './../pages/merchant/Detail.vue'

import MerchantEmployeeCreate from './../pages/merchant/employee/Create.vue'
import MerchantEmployeeDetail from './../pages/merchant/employee/Detail.vue'

// News
import NewsShell from './../pages/news/News.vue'
import NewsPage from './../pages/news/Index.vue'
import NewsCreate from './../pages/news/Create.vue'
import NewsDetail from './../pages/news/Detail.vue'

// Pages
import StaticShell from './../pages/static/Static.vue'
import StaticPage from './../pages/static/Index.vue'
import StaticCreate from './../pages/static/Create.vue'
import StaticDetail from './../pages/static/Detail.vue'

// FAQ
import FAQShell from './../pages/faq/Static.vue'
import FAQPage from './../pages/faq/Index.vue'
import FAQCreate from './../pages/faq/Create.vue'
import FAQDetail from './../pages/faq/Detail.vue'

// Membership
import MembershipShell from './../pages/membership/Membership.vue'
import MembershipPage from './../pages/membership/Index.vue'
import MembershipCreate from './../pages/membership/Create.vue'
import MembershipDetail from './../pages/membership/Detail.vue'
import MembershipCreateAccumulation from './../pages/membership/CreateAccumulation.vue'

// Services
import ServicesShell from './../pages/services/Services.vue'
import ServicesPage from './../pages/services/Index.vue'
import ServiceCreate from './../pages/services/Create.vue'
import ServiceDetail from './../pages/services/Detail.vue'
import ServiceMessage from './../pages/services/Message.vue'

// Settings
import SettingsShell from './../pages/settings/Settings.vue'
import SettingsApp from './../pages/settings/app/Index.vue'
import SettingsLanguage from './../pages/settings/language/Index.vue'

import SettingsAdmin from './../pages/settings/admin/Index.vue'
import SettingsAdminDetail from './../pages/settings/admin/Detail.vue'
import SettingsAdminCreate from './../pages/settings/admin/Create.vue'

import SettingsCategories from './../pages/settings/categories/Index.vue'
import SettingsCategoriesDetail from './../pages/settings/categories/Detail.vue'
import SettingsCategoriesCreate from './../pages/settings/categories/Create.vue'
import SettingsRole from './../pages/settings/role/Index.vue'

import SettingsNotification from './../pages/settings/notification/Index.vue'

import SettingsImages from './../pages/settings/images/Index.vue'
import SettingsImagesCreate from './../pages/settings/images/Create.vue'

// Reports
import ReportsShell from './../pages/reports/Reports.vue'
import ReportsCustomers from './../pages/reports/customers/Index.vue'
import ReportsVouchers from './../pages/reports/vouchers/Index.vue'
import ReportsMerchants from './../pages/reports/merchants/Index.vue'
import ReportsTransactions from './../pages/reports/transactions/Index.vue'
import ReportsServices from './../pages/reports/services/Index.vue'

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
    const token = localStorage.getItem('access_token')
    if (typeof token === 'undefined' || token === null || token === 'undefined') {
        next()
    } else {
        next('/dashboard')
    }

}

const ifAuthenticated = (to, from, next) => {
    if (to.name !== 'LoginPage') {
        const token = localStorage.getItem('access_token')
        if (typeof token === 'undefined' || token === null || token === 'undefined') {
            next('/login')
        } else {
            next()
        }
    } else {
        next()
    }


}

const router = new Router({
    mode: 'history',
    linkExactActiveClass: 'is-dashboard',
    routes: [
        {
            path: '/',
            redirect: {name: 'DashboardPage'}
        },
        {
            path: '/dashboard',
            name: 'DashboardPage',
            component: DashboardPage,
        },
        {
            path: '/partner-dashboard',
            name: 'MerchantDashboardPage',
            component: MerchantDashboardPage,
        },
        // login
        {
            path: '/login',
            name: 'LoginPage',
            component: LoginPage,
            beforeEnter: ifNotAuthenticated,
            meta: {
                layout: 'auth'
            }
        },

        // Forgot
        {
            path: '/forgot',
            name: 'ForgotPage',
            component: ForgotPage,
            meta: {
                layout: 'auth'
            }
        },

        // Preview Voucher
        {
            path: '/preview-voucher/:id',
            name: 'PreviewVoucher',
            component: PreviewVoucher,
            meta: {
                layout: 'blank'
            }
        },

        //  cutomers
        {
            path: '/customers',
            name: 'CustomersPage',
            component: CustomersPage,
        },
        {
            path: '/customer/:id/edit',
            name: 'CustomerEdit',
            component: CustomerEdit,
        },

        //  broadcast
        {
            path: '/broadcast',
            name: 'BroadcastShell',
            component: BroadcastShell,
            children: [
                {
                    path: '/broadcast',
                    name: 'BroadcastPage',
                    component: BroadcastPage,
                },
                {
                    path: 'group/:groupId/detail/:id',
                    name: 'BroadcastDashboardDetail',
                    component: BroadcastDashboardDetail,
                },
                {
                    path: 'create',
                    name: 'BroadcastDashboardCreate',
                    component: BroadcastDashboardCreate,
                },
                {
                    path: 'group/:groupId/edit/:id',
                    name: 'BroadcastDashboardEdit',
                    component: BroadcastDashboardCreate,
                },
                {
                    path: 'group/:id',
                    name: 'BroadcastGroupDetail',
                    component: BroadcastGroupDetail,
                },
                {
                    path: 'group/new',
                    name: 'BroadcastGroupCreate',
                    component: BroadcastGroupCreate,
                },
                {
                    path: 'group/edit/:id',
                    name: 'BroadcastGroupEdit',
                    component: BroadcastGroupCreate,
                },
            ]
        },

        //  Notification
        {
            path: '/notification',
            name: 'NotificationPage',
            component: NotificationPage,
        },

        //  Adjust Poin
        {
            path: '/ticket-poin',
            name: 'TicketPoinPage',
            component: TicketPoinPage,
        },
        {
            path: '/poin',
            name: 'PoinPage',
            component: PoinPage,
            props: route => ({ date: route.query.date })
        },


        // users
        {
            path: '/customers/user/:id',
            name: 'UserPage',
            component: UserPage,
        },

        // profile
        {
            path: '/profile',
            name: 'ProfilePage',
            component: ProfilePage,
        },
        {
            path: '/profile/edit',
            name: 'ProfileEdit',
            component: ProfileEdit,
        },

        // voucher
        {
            path: '/vouchers',
            name: 'VouchersShell',
            component: VouchersShell,
            children: [
                {
                    path: '/vouchers',
                    name: 'VouchersPage',
                    component: VouchersPage,
                },
                {
                    path: 'create',
                    name: 'VoucherCreate',
                    component: VoucherCreate,
                },
                {
                    path: 'edit/:id',
                    name: 'VoucherEdit',
                    component: VoucherCreate,
                },
                {
                    path: 'detail/:id',
                    name: 'VoucherDetail',
                    component: VoucherDetail,
                },
                {
                    path: 'send-to/:type',
                    name: 'SendVoucherCreate',
                    component: SendVoucher,
                },
            ]
        },

        // customer voucher
        {
            path: '/customer-vouchers',
            name: 'VouchersShell',
            component: VouchersShell,
            children: [
                {
                    path: 'edit/:id',
                    name: 'SendVoucherEdit',
                    component: SendVoucher,
                },
            ]
        },

        // gitfcard
        {
            path: '/transfer-point',
            name: 'GiftCardShell',
            component: GiftCardShell,
            children: [
                {
                    path: '/transfer-point',
                    name: 'GiftCardPage',
                    component: GiftCardPage,
                },
                {
                    path: 'create',
                    name: 'GiftCardCreate',
                    component: GiftCardCreate,
                },
                {
                    path: 'edit/:id',
                    name: 'GiftCardEdit',
                    component: GiftCardCreate,
                },
                {
                    path: 'detail/:id',
                    name: 'GiftCardDetail',
                    component: GiftCardDetail,
                }
            ]
        },

        // Merchant
        {
            path: '/partners',
            name: 'MerchantShell',
            component: MerchantShell,
            children: [
                {
                    path: '/partners',
                    name: 'MerchantPage',
                    component: MerchantPage,
                },
                {
                    path: 'create',
                    name: 'MerchantCreate',
                    component: MerchantCreate,
                },
                {
                    path: 'edit/:id',
                    name: 'MerchantEdit',
                    component: MerchantCreate,
                },
                {
                    path: 'edit-my-partner',
                    name: 'MerchantEditSelf',
                    component: MerchantCreate,
                },
                {
                    path: 'detail/:id',
                    name: 'MerchantDetail',
                    component: MerchantDetail,
                },
                {
                    path: 'employee/create',
                    name: 'MerchantSelfEmployeeCreate',
                    component: MerchantEmployeeCreate,
                },
                {
                    path: 'employee/detail/:id',
                    name: 'MerchantSelfEmployeeDetail',
                    component: MerchantEmployeeDetail,
                },
                {
                    path: 'employee/edit/:id',
                    name: 'MerchantSelfEmployeeEdit',
                    component: MerchantEmployeeCreate,
                },
                {
                    path: ':merchantId/employee/create',
                    name: 'MerchantEmployeeCreate',
                    component: MerchantEmployeeCreate,
                },
                {
                    path: ':merchantId/employee/detail/:id',
                    name: 'MerchantEmployeeDetail',
                    component: MerchantEmployeeDetail,
                },
                {
                    path: ':merchantId/employee/edit/:id',
                    name: 'MerchantEmployeeEdit',
                    component: MerchantEmployeeCreate,
                },
            ]
        },

        // news
        {
            path: '/whats-on',
            name: 'NewsShell',
            component: NewsShell,
            children: [
                {
                    path: '/whats-on',
                    name: 'NewsPage',
                    component: NewsPage,
                },
                {
                    path: 'create',
                    name: 'NewsCreate',
                    component: NewsCreate,
                },
                {
                    path: 'edit/:id',
                    name: 'NewsEdit',
                    component: NewsCreate,
                },
                {
                    path: 'detail/:id',
                    name: 'NewsDetail',
                    component: NewsDetail,
                }
            ]
        },

        // static
        {
            path: '/pages',
            name: 'staticShell',
            component: StaticShell,
            children: [
                {
                    path: '/pages',
                    name: 'StaticPage',
                    component: StaticPage,
                },
                {
                    path: 'create',
                    name: 'StaticCreate',
                    component: StaticCreate,
                },
                {
                    path: 'edit/:id',
                    name: 'StaticEdit',
                    component: StaticCreate,
                },
                {
                    path: 'detail/:id',
                    name: 'StaticDetail',
                    component: StaticDetail,
                }
            ]
        },

        // faq
        {
            path: '/faq',
            name: 'faqShell',
            component: FAQShell,
            children: [
                {
                    path: '/faq',
                    name: 'FAQPage',
                    component: FAQPage,
                },
                {
                    path: 'create',
                    name: 'FAQCreate',
                    component: FAQCreate,
                },
                {
                    path: 'edit/:id',
                    name: 'FAQEdit',
                    component: FAQCreate,
                },
                {
                    path: 'detail/:id',
                    name: 'FAQDetail',
                    component: FAQDetail,
                }
            ]
        },

        // Membership
        {
            path: '/membership',
            name: 'MembershipShell',
            component: MembershipShell,
            children: [
                {
                    path: '/membership',
                    name: 'MembershipPage',
                    component: MembershipPage,
                },
                // {
                //     path: 'create',
                //     name: 'MembershipCreate',
                //     component: MembershipCreate,
                // },
                {
                    path: 'edit/:id',
                    name: 'MembershipEdit',
                    component: MembershipCreate,
                },
                {
                    path: 'detail/:id',
                    name: 'MembershipDetail',
                    component: MembershipDetail,
                },
                {
                    path: 'accumulation',
                    name: 'MembershipCreateAccumulation',
                    component: MembershipCreateAccumulation,
                }
            ]
        },

        // Services
        {
            path: '/services',
            name: 'ServicesShell',
            component: ServicesShell,
            children: [
                {
                    path: '/services',
                    name: 'ServicesPage',
                    component: ServicesPage,
                },
                {
                    path: 'create',
                    name: 'ServiceCreate',
                    component: ServiceCreate,
                },
                {
                    path: 'edit/:id',
                    name: 'ServiceEdit',
                    component: ServiceCreate,
                },
                {
                    path: 'detail/:id',
                    name: 'ServiceDetail',
                    component: ServiceDetail,
                },
                {
                    path: 'message',
                    name: 'ServiceMessage',
                    component: ServiceMessage,
                },
            ]
        },

        // settings
        {
            path: '/settings',
            name: 'SettingsShell',
            component: SettingsShell,
            children: [
                {
                    path: '/settings/app',
                    name: 'SettingsApp',
                    component: SettingsApp,
                },
                {
                    path: '/settings/lang',
                    name: 'SettingsLanguage',
                    component: SettingsLanguage,
                },
                {
                    path: '/settings/admin',
                    name: 'SettingsAdmin',
                    component: SettingsAdmin,
                },
                {
                    path: '/settings/admin/detail/:id',
                    name: 'SettingsAdminDetail',
                    component: SettingsAdminDetail,
                },
                {
                    path: '/settings/admin/create',
                    name: 'SettingsAdminCreate',
                    component: SettingsAdminCreate,
                },
                {
                    path: '/settings/admin/edit/:id',
                    name: 'SettingsAdminEdit',
                    component: SettingsAdminCreate,
                },
                {
                    path: '/settings/categories',
                    name: 'SettingsCategories',
                    component: SettingsCategories,
                },
                {
                    path: '/settings/categories/:slug',
                    name: 'SettingsCategoriesDetail',
                    component: SettingsCategoriesDetail,
                },
                {
                    path: '/settings/categories/:slug/create',
                    name: 'SettingsCategoryCreate',
                    component: SettingsCategoriesCreate
                },
                {
                    path: '/settings/categories/:slug/edit/:id',
                    name: 'SettingsCategoryEdit',
                    component: SettingsCategoriesCreate
                },
                {
                    path: '/settings/role',
                    name: 'SettingsRole',
                    component: SettingsRole,
                },
                {
                    path: '/settings/notification',
                    name: 'SettingsNotification',
                    component: SettingsNotification,
                },
                {
                    path: '/settings/images',
                    name: 'SettingsImages',
                    component: SettingsImages,
                },
            ]
        },

        {
            path: '/banner/create',
            name: 'BannerCreate',
            component: SettingsImagesCreate,
        },
        {
            path: '/banner/edit/:id',
            name: 'BannerEdit',
            component: SettingsImagesCreate,
        },

        // reports
        {
            path:'/reports',
            name: 'ReportsShell',
            component: ReportsShell,
            children: [
                {
                    path: '/reports/customers',
                    name: 'ReportsCustomers',
                    component: ReportsCustomers,
                },
                {
                    path: '/reports/vouchers',
                    name: 'ReportsVouchers',
                    component: ReportsVouchers,
                },
                {
                    path: '/reports/partners',
                    name: 'ReportsMerchants',
                    component: ReportsMerchants,
                },
                {
                    path: '/reports/transactions',
                    name: 'ReportsTransactions',
                    component: ReportsTransactions,
                },
                {
                    path: '/reports/services',
                    name: 'ReportsServices',
                    component: ReportsServices,
                },
            ]
        }

    ],

    created() {
        auth.dispatch('AutoLogin')
    },
})

router.beforeEach(ifAuthenticated)

export default router