<template lang="pug">
    //- admin activity
    .card
        header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
            h2.font-bold Recent Activity

        .card__body.text-center.text-yellow-700.opacity-50
            span Feature on development
            .list-item.border-b.border-gray-200.mb-6.pb-6(
                v-for="item in activities" :key="item.id"
            )
                .flex.items-center.justify-between
                    .flex-grow
                        span.block {{ item.desc }}
                    span.block.opacity-50 {{ item.time }}
    //- end admin activity
</template>

<script>
export default {
    name: 'AdminActivity',
    data() {
        return {
            activities: [
            ]
        }
    }
}
</script>