<template lang="pug">
    #membership-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            //- membership info
            .card.card--shadow.bg-white.border-gray-200.p-4.rounded.mb-8
                .card__body
                    .flex
                        .flex.flex-grow
                            .block
                                .w-64
                                    .rounded.bg-orange-200.overflow-hidden
                                        img(:src="membershipData.logo ? membershipData.logo : 'https://i.imgur.com/dL0n397.png'")
                            .flex-grow.pl-4
                                span.inline-block.mb-4.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.w-40.block.text-center.uppercase {{ membershipData.name }}
                                .flex.items-center
                                    .block {{ membershipData.slug }}
                                .flex.items-center
                                    .block(v-if="membershipData.display !== 1") Price : Rp {{ membershipData.membership_fee | moneyFilter }}
                                    .block(v-if="membershipData.display === 1") Maintain Fee : Rp {{ membershipData.membership_maintain_fee | moneyFilter }}
                                    .block.px-2(v-if="membershipData.display === 1") •
                                    .block(v-if="membershipData.display === 1") Accumulation : Rp {{ membershipData.upgrade_when_reach | moneyFilter }}
                                    .block.px-2 •
                                    .block {{ membershipData.days_to_expire }} days expired
                        .block
                            router-link.btn-primary-o.btn-small(:to="{ name: 'MembershipEdit', params: { id: membershipData.id } }") Edit
            //- end membership info

            //- membership's voucher
            vue-tabs
                v-tab(title="General Vouhcer")
                    //- Broadcast
                    MembershipVouhcer
                    //- end Broadcast
                v-tab(title="Gifted Vouhcer")
                    //- Broadcast group
                    DefaultVouhcer
                    //- end Broadcast group
            
            
            //- end membership's voucher
</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb'
import {VueTabs, VTab} from 'vue-nav-tabs'
import MembershipVouhcer from './MembershipVouhcer.vue'
import DefaultVouhcer from './DefaultVouhcer.vue'
import Vue from "vue";

export default {
    name: 'MerchantDetail',
    components: {
        Breadcrumb,
        VueTabs,
        VTab,
        MembershipVouhcer,
        DefaultVouhcer,
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Membership',
                    url: '/membership',
                },
                {
                    name: 'Platinum',
                },
            ],
            membershipData: null,
        }
    },
    mounted() {
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/type/' + this.$route.params.id)
            .then((response) => {
                this.membershipData = response.data.data
                this.breadcrumb = [
                    {
                        name: 'Membership',
                        url: '/membership',
                    },
                    {
                        name: response.data.data.name,
                        url: '/membership/detail/'+response.data.data.id,
                    },
                ]
            })
    },
}
</script>

<style lang="sass" scoped>

</style>