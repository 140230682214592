<template lang="pug">
    #services-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4           
            //- list
            .card.card--shadow.bg-white.border-gray-200.p-4.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                    h2.font-bold List Services
                    .header-cta.flex.items-center
                        router-link(:to="{name:'ServiceCreate'}").btn-primary.btn-small.ml-2
                            span.mr-1
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                            span Add service
                .card__body
                    .filter-content.mb-4
                        SearchInput(:inputData.sync="searchInput" @search="load()")
                        //- .inline-block.relative.w-64.mr-4
                            select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                v-model="locationFilter"
                                @change="load()"
                            )
                                option(value="0") All Location
                                option Bali
                                option Tangerang
                                option Lombok
                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>

                    .box-table
                        table.table.table-set.w-full
                            thead
                                tr
                                    th  No.
                                    th  Name
                                    th  New Inbox
                                    th  Status
                                    th
                            tbody
                                tr(v-for="(list, index) in dataList" :key="list.index")
                                    td.w-8 {{ index + 1 }}.
                                    td
                                        router-link.link.block(:to="{name: 'ServiceDetail', params: { id: list.id }}" style="max-width:450px")
                                            .flex.items-center
                                                .block
                                                    figure.avatar.w-16.h-16.overflow-hidden.mr-4
                                                        img.w-full.h-full.object-cover(:src="list.cover_image ? list.cover_image : 'https://i.imgur.com/9XwzHPA.png'", v-bind:alt="list.name")
                                                .block 
                                                    .font-bold {{ list.name }}
                                                    small {{ list.address }}

                                    td
                                        router-link.w-6.h-6.flex.items-center.justify-center.rounded-full.bg-red-500.text-red-100(
                                            :to="list.url"
                                        ) {{ list.inbox||0 | moneyFilter }}

                                    td
                                        template(v-if="list.status === 1")
                                            span.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Publish
                                        template(v-if="list.status === 0")
                                            span.px-2.py-1.bg-orange-300.text-orange-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Draft


                                    td.action-2
                                        router-link(:to="{name:'ServiceEdit', params: {id: list.id}}").btn-default.btn-icon
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                        button.btn-default.btn-icon(@click="destroy(list.id)")
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>

                    Pagination( :pagination="pagination" @paginate="load()" )
</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import Pagination from './../../components/content/Pagination';
import SearchInput from './../../components/content/SearchInput';
import Vue from "vue";

export default {
    name: 'ServicesPage',
    components: {
        Breadcrumb,
        SearchInput,
        Pagination
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Services',
                    url: '/services',
                },
            ],
            searchInput: '',
            locationFilter: 0,
            pagination: {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
            dataList: null,
        }
    },

    methods: {
        load() {
            var params = '?q='+this.searchInput+'&page='+this.pagination.current_page;
            if (this.locationFilter != 0) params += '&location='+this.locationFilter;
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/service/partner'+params)
                .then((response) => {
                    this.dataList = response.data.data
                    this.pagination = response.data.meta
                })
        },

        destroy (id) {
            if (confirm('Are you sure?')) {
                Vue.prototype.$http
                    .delete(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/service/partner/'+ id)
                    .then(() => {
                        this.load()
                    })
            }

        },

    },
    mounted() {
        this.load()
    },
    

}
</script>

<style lang="sass" scoped>
small
    line-height: 0
    position: relative
    top: -4px

.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>