<template>
    <div class="notifications">
        <notification
                v-for="(notification, index) in notificationsList"
                :key="notification.text + index"
                :notification="notification"
                @close-notification="removeNotification"
        ></notification>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    import Notification from './TheNotification';
    export default {
        components: {
            Notification
        },
        computed: {
            ...mapGetters({
                notificationsList: 'notificationsList',
            }),
        },
        methods: {
            removeNotification: function (notification) {
                this.$store.commit('REMOVE_NOTIFICATION', notification);
            },
        },
    }
</script>