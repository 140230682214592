<template lang="pug">
    .btn-primary-o.relative
        input.input-file(
            type="file"
            @change="changeCover"
            )
        span.mr-2
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
        span Change Image
</template>

<script>
    import Vue from "vue";

    export default{
        name: 'UploadMedia',
        props: {
            inputMedia: {
                type: String,
            },
        },
        data() {
            return {
                coverImage: null,
            }
        },
        methods: {
            changeCover(e){
                let formData = new FormData();
                formData.append('file', e.target.files[0]);

                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/lite-media/api-manage/', formData, {headers: { 'Content-Type': 'multipart/form-data' }})
                    .then((response) => {
                        if (response.data.error === 422) { alert(response.data.errors.file[0]) }
                        else { this.inputMedia = response.data.data.url; this.$emit('update:inputMedia', this.inputMedia)  }
                    })
            },
        }
    }
</script>

<style lang="sass" scoped>
    .input-file
        width: 100%
        height: 100%
        left: 0
        top: 0
        opacity: 0
        position: absolute
        z-index: 1
</style>