<template lang="pug">
    //- admin activity
    .card
        header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
            h2.font-bold Recent Activity

        .card__body
            .list-item.border-b.border-gray-200.mb-6.pb-6(
                v-for="item in activities" :key="item.id"
            )
                .flex.items-center.justify-between
                    .flex-grow
                        span.block {{ item.desc }}
                    span.block.opacity-50 {{ item.time }}
    //- end admin activity
</template>

<script>
export default {
    name: 'AdminActivity',
    data() {
        return {
            activities: [],
            // activities: [
            //     {
            //         desc: 'Added new voucher - name voucher',
            //         time: '2 minutes ago'
            //     },
            //     {
            //         desc: 'Edit voucher - name voucher',
            //         time: '4 minutes ago'
            //     },
            //     {
            //         desc: 'Delete voucher - name voucher',
            //         time: '10 minutes ago'
            //     },
            //     {
            //         desc: 'Added new role admin',
            //         time: '12 minutes ago'
            //     },
            //     {
            //         desc: 'Edit role admin',
            //         time: '12 minutes ago'
            //     },
            // ]
        }
    }
}
</script>