import { render, staticRenderFns } from "./CreateAccumulation.vue?vue&type=template&id=396ea0bb&scoped=true&lang=pug&"
import script from "./CreateAccumulation.vue?vue&type=script&lang=js&"
export * from "./CreateAccumulation.vue?vue&type=script&lang=js&"
import style0 from "./CreateAccumulation.vue?vue&type=style&index=0&id=396ea0bb&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "396ea0bb",
  null
  
)

export default component.exports