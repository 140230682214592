<template lang="pug">
    #settings-box
        .card.card--shadow.p-4.rounded.border.border-gray-200.bg-white
            header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                h2.font-bold Applications
                .header-cta.flex.items-center
                    button.btn-primary.btn-small.ml-2(
                        v-on:click="config = !config"
                    )
                        template(v-if="config === true")
                            span Hide Configuration
                        template(v-else)
                            span Add Configuration
            .card__body
                .box-configuration(v-bind:class="{'box-configuration--show' : config}")
                    .box-configuration__inner.p-6.bg-gray-200.border.border-gray-400.rounded.mb-6
                        .flex.items-center
                            .field.mr-3.flex-grow
                                label.text-xs.uppercase.font-bold.text-gray-600 Name
                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                    type="text"
                                    placeholder="Create Name"
                                    v-model="optionName"
                                )
                            .field.mr-3.flex-grow
                                label.text-xs.uppercase.font-bold.text-gray-600 Value
                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                    type="text"
                                    placeholder="Create Value.."
                                    v-model="optionValue"
                                )
                            .block
                                label.block.opacity-0 Value
                                button.btn-primary(@click="create()") Add
                .box-table
                    table.table.table-set.w-full
                        thead
                            tr
                                th Name
                                th Value
                                th
                        tbody
                            tr(v-for="(list, index) in optionList")
                                td
                                    template(v-if="list.configEdit === true")
                                        .block
                                            input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                type="text"
                                                v-model="list.option_name"
                                            )
                                    template(v-else)
                                        .block.py-2.w-full {{ list.option_name }}
                                td
                                    template(v-if="list.configEdit === true")
                                        .flex.items-center
                                            .block.mr-3.flex-grow
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    v-model="list.option_value"
                                                )
                                            button.btn-primary-o.btn-small(@click="confirmEdit(list)") save
                                    template(v-else)
                                        .block.py-2.w-full {{ list.option_value }}
                                td.action-2
                                    button.btn-default.btn-icon(v-if="list.configEdit === true" v-on:click="cancelEdit(list)")
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>
                                    button.btn-default.btn-icon(v-else="" v-on:click="list.configEdit = !list.configEdit")
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                    button.btn-default.btn-icon(v-on:click="destroy(list)")
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>




</template>

<script>
import Vue from "vue";

export default {
    name: 'SettingsApp',
    data() {
        return {
            config: false,
            configEdit: true,
            optionList: null,
            optionName: '',
            optionValue: '',
        }
    },
    methods: {
        load(){
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/options')
                .then(response => {
                    this.optionList = response.data.data.map(v => {
                        this.$set(v, 'configEdit', false)
                        v.originalName = v.option_name //  will be used when user click the cancel botton
                        v.originalValue = v.option_value //  will be used when user click the cancel botton
                        return v
                    })
                })
        },
        create() {
            var data = {
                'option_name': this.optionName,
                'option_value': this.optionValue,
            }

            Vue.prototype.$http
                .patch(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/options', data)
                .then(() => {
                    this.optionName = ''
                    this.optionValue = ''
                    this.load()
                    this.config = false
                })
        },
        cancelEdit(row) {
            row.option_name = row.originalName
            row.option_value = row.originalValue
            row.configEdit = false
        },
        confirmEdit(row) {
            var data = {
                'option_name': row.option_name,
                'option_value': row.option_value,
            }

            Vue.prototype.$http
                .patch(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/options', data)
                .then(() => {
                    row.configEdit = false
                    row.originalName = row.option_name
                    row.originalValue = row.option_value
                })
        },
        destroy (row) {
            if (confirm('Are you sure?')) {
                Vue.prototype.$http
                    .delete(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/options/'+ row.id)
                    .then(() => {
                        this.load()
                    })
            }

        },
    },
    mounted() {
      this.load()
    },
}
</script>

<style lang="sass" scoped>

    .box-configuration
        display: none
        &--show
            display: block


    .table-set
        tr
            th,
            td
                padding: 12px 16px
                border-bottom: 1px solid #f1f7fb
                transition: all 0.3s ease-in-out
            &:hover
                td
                    background-color: rgba(0, 0, 0, 0.0250 )
            th
                text-transform: uppercase
                text-align: left
                font-size: 11px
                opacity: 0.7
</style>