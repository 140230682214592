<template lang="pug">
    #customers-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            //- highlight customers
            highlightCustomers
            .card.card--shadow.bg-white.border-gray-200.p-4.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                    h2.font-bold List Customers
                    ExportButton(export-id="customer-export-id" export-end-point="/api/v1/management/memberships/master/export/customer")
                .card__body
                    .filter-content.mb-4
                        SearchInput(:inputData.sync="searchInput" @search="load()")
                        .inline-block.relative.w-64
                            select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                v-model="typeFilter"
                                @change="load()"
                            )
                                option(value="0") All Type
                                template(v-for="(optionType, index) in listFilterOption" )
                                    option(:value="optionType.id") {{ optionType.name }}

                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        //- button.btn-primary-o.btn-small.ml-2(@click="load") Advance Filter
                        .inline-block.relative.w-64.ml-4
                            select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                v-model="statusFilter"
                                @change="load()"
                            )
                                option(value="99") All Status
                                option(value="1") Active
                                option(value="3") Inactive
                                option(value="0") Registration Pending
                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    .box-table
                        table.table.table-set.w-full
                            thead
                                tr
                                    th  No.
                                    th  Name
                                    th  Status
                                    th  Email
                                    th  Phone
                                    th  Type
                                    th  Poin
                                    th  Latest Activity
                            tbody
                                tr(v-for="(user, index) in customerList" v-bind:key="user.index")
                                    td.w-8 {{ index + pagination.from }}.
                                    td
                                        router-link.link.font-bold(:to="{ name: 'UserPage', params: { id: user.id }}")
                                            .avatar.flex.items-center
                                                figure.avatar.w-8.h-8.rounded-full.overflow-hidden.mr-4
                                                    img.w-full.h-full.object-cover(:src="user.avatar ? user.avatar : 'https://i.imgur.com/dL0n397.png'")
                                                span {{ user.customer_name }}
                                    td
                                        .px-2.py-1.rounded.font-bold.text-xs.text-center.uppercase.bg-green-500.text-green-200(v-if="user.status == 1") Active
                                    .px-2.py-1.rounded.font-bold.text-xs.text-center.uppercase.bg-blue-500.text-blue-200(v-if="user.status == 0") Registration Pending
                                    .px-2.py-1.rounded.font-bold.text-xs.text-center.uppercase.bg-red-500.text-red-200(v-if="user.status == 2") Suspended
                                    .px-2.py-1.rounded.font-bold.text-xs.text-center.uppercase.bg-red-500.text-red-200(v-if="user.status == 3") Inactive
                                    td {{ user.email }}
                                    td {{ user.phone }}
                                    td
                                        MembershipTypeLabel(:membershipType="user.membership_type.name")
                                    td
                                        span {{ user.point_active | moneyFilter }}
                                    td
                                        span.opacity-75 {{ user.updated_at | moment('DD MMMM YYYY HH:mm') }}
                    Pagination( :pagination="pagination" @paginate="load()" )
</template>

<script>
    import Breadcrumb from './../../components/content/breadcrumb';
    import Pagination from './../../components/content/Pagination';
    import SearchInput from './../../components/content/SearchInput';
    import MembershipTypeLabel from './../../components/content/MembershipTypeLabel';
    import highlightCustomers from './highlight';
    import Vue from "vue";
    import ExportButton from "@/components/content/ExportButton";

    export default {
        name: 'CustomersPage',
        components: {
            Breadcrumb,
            highlightCustomers,
            Pagination,
            SearchInput,
            MembershipTypeLabel,
            ExportButton
        },
        data() {
            return {
                breadcrumb: [
                    {
                        name: 'Customers',
                        url: '/customers',
                    },
                ],
                customerList: null,
                tableList: null,
                listFilterOption: null,
                statusFilter: 99,
                pagination : {
                    total: 0,
                    per_page: 10,
                    from: 1,
                    to: 0,
                    current_page: 1
                },
                searchInput: '',
                typeFilter: 0,
            }
        },

        methods: {
            load() {
                // let encoded= encodeURIComponent(this.searchInput);
                let params = '?page='+this.pagination.current_page;
                if (this.typeFilter != 0) params += '&type='+this.typeFilter;
                params += this.statusFilter != 99 ? '&status='+this.statusFilter : '';
                if (this.searchInput != '') {
                    let encoded = encodeURIComponent(this.searchInput)
                    params += '&q='+encoded
                }
                Vue.prototype.$http
                    .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/'+params)
                    .then((response) => {
                        this.customerList = response.data.data.map((v) => {
                            if (v.membership_type.active === false) {
                                // v.membership_type.name = "Non Active"
                            }
                            return v;
                        })
                        this.pagination = response.data.meta
                    })
            }

        },
        mounted() {
            this.load()
                Vue.prototype.$http
                    .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/type')
                    .then(response => (this.listFilterOption = response.data.data))
        },

    }
</script>

<style lang="sass" scoped>
    small
        line-height: 0
        position: relative
        top: -4px

    .table-set
        tr
            th,
            td
                padding: 12px 16px
                border-bottom: 1px solid #f1f7fb
                transition: all 0.3s ease-in-out
            &:hover
                td
                    background-color: rgba(0, 0, 0, 0.0250 )
            th
                text-transform: uppercase
                text-align: left
                font-size: 11px
                opacity: 0.7
</style>