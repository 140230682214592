<template lang="pug">
    #poin-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            //- list
            .card.card--shadow.bg-white.border-gray-200.p-4.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                    h2.font-bold All Poin Activity
                    ExportButton(export-id="all-point-export-id" :export-end-point="exportEndPoint")

                .card__body
                    section.filter-section.flex.items-center.mb-8
                        .inline-block.relative.ui-daterange
                            .block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight
                                date-range-picker(
                                    ref="picker"
                                    opens="center"
                                    :locale-data="locale"
                                    :maxDate="maxDate"
                                    v-model="dateRange"
                                    appendToBody=true,
                                    @update="updateFilter"
                                )
                                    template(v-slot:input="picker") {{ dateRange.startDate }} - {{ dateRange.endDate }}
                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-5 w-5"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                    //- end filter
                    .block
                        .box-table
                            table.table.table-set.w-full
                                thead
                                    tr
                                        th User
                                        th Type
                                        th Transaction
                                        th Amount
                                        th Total Point User
                                        th Activity at
                                        th Reff
                                tbody
                                    tr(v-for="item in dataPoin" :key="item.id")
                                        td
                                            router-link(:to="{name : 'UserPage', params: {id: item.member.id }}") {{ item.member.customer_name }}
                                        td
                                            .flex.flex-col
                                                template(v-if="(item.transaction_type == 1)")
                                                    span.px-2.py-1.bg-green-300.text-green-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Earn
                                                template(v-else-if="(item.transaction_type != 1)")
                                                    span.px-2.py-1.bg-red-300.text-red-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Spend
                                                span {{ item.created_at | moment('DD MMMM YYYY HH:mm') }}
                                        td
                                            strong {{ item.transaction_amount  | moneyFilter }}
                                        td
                                            strong {{ item.transaction_type == 1 ? '+':'-' }} {{ item.point }}
                                        td
                                            strong {{ item.member.point_active | moneyFilter }}
                                        td
                                            router-link( v-if="item.partner !== null" :to="{name: 'MerchantDetail', params: {id: item.partner.id }}") {{ item.partner.company_name }}
                                            router-link.block.text-xs(v-if="item.staff" :to="{name: 'MerchantEmployeeDetail', params:{id: item.staff.id}}") by {{ item.staff.officer_name }}
                                        td {{ item.transaction_type == 1 ? 'Shopping':'Voucher Purchase' }}
                                        td
                                            button.btn-primary-o.mr-2.btn-small(@click="showAttachment(item)")
                                                .icon.w-6.h-6
                                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Document Text</title><path d='M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/><path d='M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/></svg>
                                                span &nbsp;{{ item.transaction_reference }}
                        Pagination( :pagination="pagination" @paginate="load()" )

        .overlay-popup.bg-gray-200.bg-opacity-25(v-if="showModal")
            .overlay-popup__container
                .popup.rounded.shadow
                    header.popup__header.border-b.border-gray-200.p-6.flex.justify-between
                        h2.font-bold Detail Transaction Reff - {{ reffID }}
                        button.btn-icon.btn-icon--40(type='button' @click="showModal = false")
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    .popup__body.p-8
                        span.mb-3 Reff : {{ reffID }}
                        template(v-if="attachmentReff")
                            .block.border-b.border-gray-200.pb-4.mt-4
                                img(:src="attachmentReff" )
                        template(v-else)
                            .block
                                .block No Image

                    .popup__footer.border-t.border-gray-200.p-6
                        .flex.items-center.justify-end
                            button.btn-default(type='button' @click="showModal = false") Back
</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import Vue from "vue";
import Pagination from "../../components/content/Pagination";
import ExportButton from "../../components/content/ExportButton";
import moment from "moment";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    name: 'NotificationPage',
    components: {
        Breadcrumb,
        Pagination,
        DateRangePicker,
        ExportButton
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'All Point Activity',
                    url: '/poin',
                },
            ],
            dataPoin: [],
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
            checkAttachment: null,
            attachmentReff: '',
            reffID: '',
            showModal: false,
            maxDate: moment().format('MM/DD/YYYY'),
            dateRange: {
                startDate: moment().startOf('month').format('MM/DD/YYYY'),
                endDate: moment().endOf('month').format('MM/DD/YYYY'),
            },
            locale: {
                direction: 'ltr', //direction of text
                format: 'mm/dd/yyyy',
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
            },
            exportProgress: 1,
        }
    },
    filters: {
        date(val) {
            return val ? moment(val).format("DD/MM/YYYY") : "";
        },
        dateURI(val) {
            return val ? moment(val).format("YYYY-MM-DD") : "";
        }
    },
    computed: {
        thereIsAttachment:()=> {
            return this.checkattachment
        },
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
        urlPrefix(){
            return this.userData.principal_staff === 1 ? 'master':'merchant';
        },
        exportEndPoint(){
            let endPoint = '/api/v1/management/memberships/master/point/adjust/export'
            let params = '?start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate);
            if (this.urlPrefix == 'merchant') endPoint = '/api/v1/management/memberships/merchant/report/point/export';
            return endPoint +params;
        }
    },
    methods: {
        updateFilter(){
            this.dateRange.startDate = moment(this.dateRange.startDate).format('MM/DD/YYYY')
            this.dateRange.endDate = moment(this.dateRange.endDate).format('MM/DD/YYYY')
            this.pagination.current_page = 1;
            this.$route.query.date = null;
            this.load()
        },
        load() {
            if(this.$route.query.date) {
                this.dateRange.endDate = this.dateRange.startDate = moment(this.$route.query.date).format('MM/DD/YYYY')
            }

            let loader = this.$loading.show()
            let endpoint = 'master/point/adjust'
            if (this.urlPrefix == 'merchant'){
                endpoint = 'merchant/report/point'
            }
            let params = '?page='+this.pagination.current_page;
            params += '&start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate)+'&page='+this.pagination.current_page
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+endpoint+params)
                .then((response) => {
                    this.dataPoin = response.data.data
                    this.pagination = response.data.meta
                    loader.hide()
                })
        },
        checkExport(){
            let exportId = localStorage.getItem('all-point-export-id');
            if (exportId) {
                let loader = this.$loading.show({
                    // Optional parameters
                    container: null,
                    canCancel: false,
                });
                Vue.prototype.$http
                    .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/check?export_id='+exportId)
                    .then((response) => {
                        loader.hide()
                        if ( response.data.data.name ) {
                            this.exportProgress = 3;
                            localStorage.setItem('all-point-export-url', process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/'+response.data.data.name);
                        } else {
                            this.exportProgress = 2;
                            alert('Your file is still on progress... come back later to check your file!');
                        }
                    })
            } else {
                this.exportProgress = 1;
            }
        },
        exportReport(){
            if (this.exportProgress === 1) {
                let loader = this.$loading.show({
                    // Optional parameters
                    container: null,
                    canCancel: false,
                });
                let endPoint = '/api/v1/management/memberships/master/point/adjust/export'
                let params = '?start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate);
                if (this.urlPrefix == 'merchant') endPoint = '/api/v1/management/memberships/merchant/report/point/export';
                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + endPoint +params)
                    .then((response) => {
                        this.exportProgress = 2;
                        loader.hide()
                        localStorage.setItem('all-point-export-id', response.data.data.id);
                        alert('Exporting your file... come back later to check your file!');
                    })
            } else if (this.exportProgress === 2) {
                this.checkExport()
            } else if (this.exportProgress === 3) {
                this.downloadExport()
            }
        },
        downloadExport(){
            let exportURL = localStorage.getItem('all-point-export-url');
            window.open(exportURL, '_self')
            localStorage.removeItem('all-point-export-id');
            localStorage.removeItem('all-point-export-url');
            this.exportProgress = 1;
        },
        showAttachment(item){
            this.checkAttachment = null
            this.reffID = item.transaction_reference
            if (item.attachment !== null) {
                this.checkAttachment = 'reff'
                this.attachmentReff = item.attachment[0]
            } else {
                this.attachmentReff = null
                this.checkAttachment = null
            }
            this.showModal = !this.showModal;
        }
    },
    mounted() {
        this.load();
        this.checkExport()
    },
}
</script>

<style lang="sass" scoped>
    .overlay-popup
        position: fixed
        left: 0
        top: 0
        bottom: 0
        right: 0
        width: 100%
        height: 100%
        overflow: auto
        &__container
            .popup
            background-color: #ffffff
            max-width: 600px
            margin: 2rem auto
            &__header
                display: flex
                align-items: center
                justify-content: space-between


    .table-set
        tr
            th,
            td
                padding: 12px 16px
                border-bottom: 1px solid #f1f7fb
                transition: all 0.3s ease-in-out
            &:hover
                td
                    background-color: rgba(0, 0, 0, 0.0250 )
            th
                text-transform: uppercase
                text-align: left
                font-size: 11px
                opacity: 0.7
</style>
