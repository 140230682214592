<template lang="pug">
    #merchant-page
        Breadcrumb( :ListBreadcrumb="breadcrumb" v-if="this.userData.principal_staff === 1")
        .content-area.px-4
            //- merchant info
            .card.card--shadow.bg-white.border-gray-200.p-4.rounded.mb-8
                .card__body
                    .flex
                        .flex.flex-grow
                            .block
                                .w-32.h-32
                                    .rounded.bg-orange-200.overflow-hidden.w-full.h-full.object-cover
                                        img(:src="merchantData.company_logo ? merchantData.company_logo : 'https://i.imgur.com/9XwzHPA.png'")
                            .flex-grow.pl-4
                                .flex.items-center.mb-3
                                    span.inline-block.mr-1.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase(v-if="merchantData.status == 1") Active
                                    span.inline-block.mr-1.px-2.py-1.bg-yellow-300.text-yellow-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase(v-if="merchantData.status == 3") Inactive
                                    span.inline-block.mr-4.px-2.py-1.bg-yellow-200.text-yellow-700.rounded.font-bold.text-xs.w-24.block.text-center.uppercase(v-if="merchantData.display === 0") Unlisted
                                    h2.font-bold.mb-1 {{ merchantData.company_name }}
                                span.text-xs.block.mb-2 {{ merchantData.company_description }}
                                span.text-xs.block.mb-2 {{ merchantData.primary_outlet.outlet_address }}
                                span.text-xs.block.mb-2 {{ merchantData.primary_outlet.outlet_phone }} | {{ merchantData.primary_outlet.outlet_email || '-' }}
                                a.text-xs.block.mb-2(:href="merchantData.discover_link" v-if="merchantData.discover_link") {{ merchantData.discover_link }}
                                .flex.items-center.mb-6
                                    .border.bordr-gray-200.bg-gray-100.pr-2.rounded.mr-3(v-if="merchantData.category")
                                        template
                                            .flex.items-center
                                                .icon.w-8.h-8.flex.items-center.justify-center(v-if="merchantData.category.slug === 'hotel'")
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                                .icon.w-8.h-8.flex.items-center.justify-center(v-if="merchantData.category.slug === 'restaurant'")
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right-circle"><circle cx="12" cy="12" r="10"></circle><polyline points="12 16 16 12 12 8"></polyline><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                                                .icon.w-8.h-8.flex.items-center.justify-center(v-if="merchantData.category.slug === 'gym'")
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
                                                .icon.w-8.h-8.flex.items-center.justify-center(v-if="merchantData.category.slug === 'salon'")
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
                                                span {{ merchantData.category ? merchantData.category.name : '-' }}
                        .block
                            router-link.btn-primary-o.btn-small(:to="{ name: 'MerchantEdit', params: {id: merchantData.id}}" v-if="this.userData.principal_staff === 1") Edit
                            router-link.btn-primary-o.btn-small(:to="{ name: 'MerchantEditSelf'}" v-else) Edit
            //- end merchant info

            vue-tabs
                v-tab(title="Point")
                    MerchantPoint(v-bind:merchantData="merchantData")

                v-tab(title="Voucher")
                    MerchantVoucher(v-bind:merchantData="merchantData")

                v-tab(title="Employee")
                    MerchantEmployee(v-bind:merchantData="merchantData")

                v-tab(title="Connection")
                    IntegrationClient(v-bind:merchantData="merchantData")

                v-tab(title="Outlet")
                    Outlet(v-bind:merchantData="merchantData" v-bind:primaryOutletId="merchantData.primary_outlet.id")
</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import MerchantVoucher from './MerchantVoucher.vue'
import MerchantPoint from './MerchantPoint.vue'
import IntegrationClient from './IntegrationClient.vue'
import Outlet from './Outlet.vue'
import Vue from "vue";
import {VueTabs, VTab} from 'vue-nav-tabs';
import MerchantEmployee from './employee/Index.vue';

export default {
    name: 'MerchantDetail',
    components: {
        Breadcrumb,
        MerchantVoucher,
        MerchantEmployee,
        MerchantPoint,
        IntegrationClient,
        Outlet,
        VueTabs,
        VTab,
    },
    computed: {
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Partners',
                    url: '/partners',
                },
                {
                    name: 'Hotel JHL Serpong',
                    url: '/partners',
                },
            ],
            merchantData: {
                primary_outlet: {
                    id: null,
                }
            },
        }
    },
    created() {
        if (this.userData.principal_staff === 1) {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/merchant/' + this.$route.params.id)
                .then((response) => {
                    this.merchantData = response.data.data
                    this.breadcrumb = [
                        {
                            name: 'Partners',
                            url: '/partners',
                        },
                        {
                            name: this.merchantData.company_name,
                            url: '/partners/detail/'+this.merchantData.id,
                        },
                    ]
                })
        } else {
            this.merchantData = this.userData.merchant
        }


    },
}
</script>

<style lang="sass" scoped>

</style>
