<template lang="pug">
    #settings-box
        .content-area.px-4
            //- highlight customers
            .grid.grid-cols-5.gap-x-8.mb-4

                //- user info
                .col-span-3
                    vue-tabs
                        v-tab(title="Recent Activity")
                            //- Password activity
                            AdminActivity
                            //- end Password activity

                        //- v-tab(title="Login Activity")
                            //- Login activity
                            AdminLogin
                            //- end Login activity


                //- end user info

                //- user sidebar
                .col-span-2
                    .card.card--shadow.rounded.bg-white.p-8.relative
                        .absolute.top-0.right-0.p-4
                            router-link.btn-primary-o.btn-small(:to="{ name: 'SettingsAdminEdit', params: { id: adminData.id } }") Edit
                        .flex.flex-col
                            .info-user__avatar.w-32.h-32.rounded-full.overflow-hidden.m-auto
                                img.w-full.h-full.object-cover(:src="adminData.avatar ? adminData.avatar : 'https://i.imgur.com/9XwzHPA.png'", alt="alt")
                            .info-user__account.text-gray-600.text-center.my-4
                                strong.text-xl.block.mb-2 {{ adminData.name }}
                                span.inline-block.px-2.py-1.bg-gray-100.border.border-gray-300.rounded.font-bold.text-xs.block.text-center.uppercase(v-if="adminData.role") {{ adminData.role ? adminData.role.name : '' }}
                            //- intro account
                            .p-8.text-gray-700
                                .flex.items-center.mb-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 26 26" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><g xmlns="http://www.w3.org/2000/svg" transform="translate(0 0)"><g transform="translate(1 1)"><path class="a" d="M13.035,14.58A6.808,6.808,0,1,0,8.19,12.6,6.807,6.807,0,0,0,13.035,14.58Z" transform="translate(-1 -1)"/><path class="a" d="M25,24.93A10.365,10.365,0,0,0,14.62,14.58H11.38A10.365,10.365,0,0,0,1,24.93" transform="translate(-1 -0.93)"/></g></g></svg>
                                    .block {{ adminData.username }}
                                .flex.items-center.mb-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                    .block {{ adminData.email }}
                                .flex.items-center.mb-4(v-if="adminData.phone")
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                    .block {{ adminData.phone }}
                                .flex.items-start.border-t.border-dashed.pt-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                    .block
                                        p.block.mb-2 Logout all account, reset and send new password to {{adminData.name}}'s email
                                        button.btn.btn-danger-o.border-red-500.text-red-500.btn-small(@click="sendPassword") Send password

</template>

<script>
import {VueTabs, VTab} from 'vue-nav-tabs'
import AdminLogin from './AdminLogin'
import AdminActivity from './AdminActivity'
import Vue from "vue";


export default {
    name: 'AdminDetail',
    components: {
        VueTabs,
        VTab,
        AdminLogin,
        AdminActivity
    },
    data() {
        return {
            adminData:null,
            submitStatus: null,
        }
    },
    methods: {
        load(){
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/admin/user/' + this.$route.params.id)
                .then((response) => {
                    this.adminData = response.data.data
                })
        },
        sendPassword() {
            if (confirm('Are you sure?')) {
                this.submitStatus = 'PENDING'
                this.$store
                    .commit('ADD_NOTIFICATION', {
                        title: 'Sending...',
                        text: 'Sending...',
                        type: 'alert-info',
                        timeout: false,
                        delay: 0
                    });
                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/admin/user/' + this.$route.params.id + '/reset/password/')
                    .then(() => {
                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Sending Password!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }
        }
    },
    mounted() {
        this.load()
    },
}
</script>

<style lang="sass" scoped>
    .info-user
        transform: translateY(-24px)
</style>