<template lang="pug">
    #settings-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4    
            .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                    h2.font-bold Change Membership
                    .header-cta.flex.items-center
                        router-link(to="/settings/membership").link.font-sx
                            span Back
                .card__body
                    form(action="" method="method")
                        section.border-b.border-blue-100.mb-8.pb-8
                            .container-small
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Total Accumulation
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex 2.500.000"
                                                    value="2.500.000"
                                                    v-model="pointAccumulation"
                                                )
                                                .flex.items-center.justify-between.mt-2
                                                    span.info.text-xs message
                                                    span.info.text-xs 0/125
                        section
                            .flex.items-center.justify-end
                                button.link(type='submit') Reset
                                //- button.btn-primary-o.ml-3(type='submit') Deactive
                                button.btn-primary.ml-3(type='button' @click="update()") Publish


</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import Vue from "vue";
import router from "../../router";

export default {
    name: 'MembershipCreateAccumulation',
    components: {
        Breadcrumb,

    },
    data() {
        return {
            cover: 'https://i.imgur.com/dL0n397.png',
            breadcrumb: [
                {
                    name: 'Membership',
                    url: '/membership',
                },
                {
                    name: 'Accumulation',
                    url: '/createAccumulation',
                },
            ],
            membershipDefault: null,
            pointAccumulation: 0,
        }
    },

    methods: {
        load(){
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/type/default')
                .then(response => {
                    this.membershipDefault = response.data.data;
                    this.pointAccumulation = this.membershipDefault.upgrade_when_reach;
                })
        },
        update(){
            var data = {
                upgrade_when_reach: this.pointAccumulation,
            }

            Vue.prototype.$http
                .patch(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/type/' + this.membershipDefault.id, data)
                .then(() => {
                    router.push({ name: 'MembershipPage' })
                })
        }
    },

    created() {
      this.load();
    },
    

}
</script>

<style lang="sass" scoped>
    .input-file
        width: 100%
        height: 100%
        left: 0
        top: 0
        opacity: 0
        position: absolute
        z-index: 1
    
    .field-radio
        position: relative
        cursor: pointer
        input
            width: 100%
            height: 100%
            position: absolute
            left: 0
            top: 0
            opacity: 0
            z-index: 3
            &:checked
                +
                    .ui-radio
                        border: 1px solid brown
                        &::after
                            transform: translate(-50%, -50%) scale(1)
        .ui-radio
            width: 24px
            height: 24px
            border-radius: 100%
            border: 1px solid #dddddd
            position: relative
            transition: all 0.3s
            &::after
                content: ''
                width: 14px
                height: 14px
                position: absolute
                top: 50%
                left: 50%
                border-radius: 100%
                transform: translate(-50%, -50%) scale(0)
                transition: all 0.3s
                background-color: brown
                
</style>