<template lang="pug">
    #authLayout.auth-layout.flex.w-full.h-screen
        .auth-layout__banner
        .auth-layout__content.flex-grow.flex.items-center.justify-center.h-screen
            .auth-container.m-auto
                slot
</template>

<script>
export default {
    name: 'AuthLayout',
}
</script>

<style lang="sass">
.auth-layout
    background-color: #231F20
    &__banner
        flex: 0 0 40%
        background: url('https://images.pexels.com/photos/4476618/pexels-photo-4476618.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500')no-repeat center center
        background-size: cover
    &__content
        .auth-container
            max-width: 400px
            color: #FFEFD8
            h1
                font-weight: bold
                font-size: 2em
                padding-bottom: 8px
</style>