<template lang="pug">
    .grid.grid-cols-5.gap-x-1.mb-4
        .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
            .block 
                span.text-xs.opacity-75 ALL CUSTOMERS
                h2.text-3xl {{ customersSummary.total }}
            //- .block.text-right.text-xs
                span.block.text-green-200 + 20%
                small from Last Month
        .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
            .block 
                span.text-xs.opacity-75 BRONZE
                h2.text-3xl {{ customersSummary.total_bronze_member }}
            //- .block.text-right.text-xs
                span.block.text-green-200 + 20%
                small from Last Month
        .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
            .block 
                span.text-xs.opacity-75 SILVER
                h2.text-3xl {{ customersSummary.total_silver_member }}
            //- .block.text-right.text-xs
                span.block.text-red-300 - 0.2%
                small from Last Month
        .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
            .block 
                span.text-xs.opacity-75 GOLD
                h2.text-3xl {{ customersSummary.total_gold_member }}
            //- .block.text-right.text-xs
                span.block.text-green-200 + 20%
                small from Last Month
        .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
            .block 
                span.text-xs.opacity-75 PLATINUM
                h2.text-3xl {{ customersSummary.total_platinum_member }}
            //- .block.text-right.text-xs
                span.block.text-green-200 + 20%
                small from Last Month
</template>

<script>
    import Vue from "vue";

    export default {
        name: 'highlightCustomers',
        data() {
            return {
                customersSummary: null,
            }
        },
        mounted() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/summary')
                .then((response) => {
                    this.customersSummary = response.data.data
                })
        }
    }
</script>