<template lang="pug">
    #settings-page
        .card.card--shadow.bg-white.border-gray-200.p-12.rounded
            header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                h2.font-bold New Admin
                .header-cta.flex.items-center
                    router-link(to="/settings/admin").link.font-sx
                        span Back
            .card__body
                form(action="" method="method")
                    section.border-b.border-blue-100.mb-8.pb-8
                        .container-small
                            .field
                                .flex.mb-6
                                    .block(class="w-1/4").text-right.mr-8
                                        label.block.h-16.py-2 Username
                                            small.text-red-500(style="padding-left:4px") *
                                    .flex-grow
                                        .inline-block.relative.w-full.mr-4
                                            input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                type="text"
                                                placeholder="Write a username here..."
                                                v-model="$v.form.username.$model"
                                                :class="{ 'border-red-500': $v.form.username.$error }"
                                            )
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.username.$error")
                                                span.info.text-xs This field is required
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="validationErrorsData.username != null")
                                                span.info.text-xs {{ validationErrorsData.username[0] }}
                            .flex.mb-6
                                .block(class="w-1/4").text-right.mr-8
                                    label.block.h-16.py-2 Name
                                        small.text-red-500(style="padding-left:4px") *
                                .flex-grow
                                    .inline-block.relative.w-full.mr-4
                                        input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                            type="text"
                                            placeholder="Write a name here..."
                                            v-model="$v.form.name.$model"
                                            :class="{ 'border-red-500': $v.form.name.$error }"
                                        )
                                        .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.name.$error")
                                            span.info.text-xs This field is required
                                        .flex.items-center.justify-between.mt-2.text-red-500(v-if="validationErrorsData.name != null")
                                            span.info.text-xs {{ validationErrorsData.name[0] }}
                            .field
                                .flex.mb-6
                                    .block(class="w-1/4").text-right.mr-8
                                        label.block.h-16.py-2 Email
                                            small.text-red-500(style="padding-left:4px") *
                                    .flex-grow
                                        .inline-block.relative.w-full.mr-4
                                            input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                type="email"
                                                placeholder="Write registerd email here..."
                                                v-model="$v.form.email.$model"
                                                :class="{ 'border-red-500': $v.form.email.$error }"
                                            )
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.email.$error")
                                                span.info.text-xs This field is required
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="validationErrorsData.email != null")
                                                span.info.text-xs {{ validationErrorsData.email[0] }}
                            //- .field
                                .flex.mb-6
                                    .block(class="w-1/4").text-right.mr-8
                                        label.block.h-16.py-2 Phone Number
                                            small.text-red-500(style="padding-left:4px") *
                                    .flex-grow
                                        .inline-block.relative.w-full.mr-4
                                            input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                type="text"
                                                placeholder="Write registerd phone number here..."
                                                v-model="form.phone"
                                            )
                                            //-.flex.items-center.justify-between.mt-2
                                                span.info.text-xs message
                                                span.info.text-xs 0/125
                            .field
                                .flex.mb-6
                                    .block(class="w-1/4").text-right.mr-8
                                        label.block.h-16.py-2 Role
                                            small.text-red-500(style="padding-left:4px") *
                                    .flex-grow
                                        .inline-block.relative.w-full
                                            select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                v-model="$v.form.role.$model"
                                                :class="{ 'border-red-500': $v.form.role.$error }"
                                            )
                                                option(value="0") Choose Role
                                                option(v-for="role in roleList" :value="role.id") {{ role.name }}
                                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.role.$error")
                                            span.info.text-xs This field is required
                                        .flex.items-center.justify-between.mt-2.text-red-500(v-if="validationErrorsData.role != null")
                                            span.info.text-xs {{ validationErrorsData.role[0] }}
                            .field
                                .flex.mb-6
                                    .block(class="w-1/4").text-right.mr-8
                                        label.block.h-16.py-2 Photo
                                            small.text-red-500(style="padding-left:4px") *
                                    .flex-grow
                                        .cover-voucher.w-64.h-64.bg-gray-200.rounded-full.mb-2
                                            img.w-full.h-full.object-cover.rounded-full(
                                                v-if="avatar"
                                                :src="avatar ? avatar : 'https://i.imgur.com/9XwzHPA.png'", alt=""
                                            )
                                        .pt-4.text-center.w-64
                                            span.info.text-xs Please upload image with 400 x 400
                                            .block.mt-4
                                                UploadMedia(:inputMedia.sync="avatar")
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.avatar.$error")
                                                span.info.text-xs This field is required
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="validationErrorsData.avatar != null")
                                                span.info.text-xs {{ validationErrorsData.avatar[0] }}

                    section
                        .flex.items-center.justify-end
                            //-button.link(type='submit') Reset
                            //-button.btn-primary-o.ml-3(type='button' @click="add()" v-if="!editing") Deactive
                            button.btn-primary.ml-3(type='button' @click="add()" v-if="!editing" :disabled="submitStatus === 'PENDING'") Save
                            //-button.btn-primary-o.ml-3(type='button' @click="update(0)" v-if="editing") Deactive
                            button.btn-primary.ml-3(type='button' @click="update(1)" v-if="editing" :disabled="submitStatus === 'PENDING'") Save
                        p.typo__p.text-right.mt-3.text-green-500(v-if="submitStatus === 'OK'") Thanks for your submission!
                        p.typo__p.text-right.mt-3.text-red-500(v-if="submitStatus === 'ERROR'") Please fill the form correctly.
                        p.typo__p.text-right.mt-3.text-blue-500(v-if="submitStatus === 'PENDING'") Sending...

</template>

<script>

import UploadMedia from "../../../components/content/UploadMedia";
import Vue from "vue";
import router from "../../../router";
import {required} from "vuelidate/lib/validators";

export default {
    name: 'SettingsAdminCreate',
    components: {
        UploadMedia,
    },
    data() {
        return {
            editing: false,
            submitStatus: null,
            content: "<h2>Some initial content</h2>",
            avatar: 'https://i.imgur.com/9XwzHPA.png',
            roleList: [],
            form: {
                username: '',
                name: '',
                email: '',
                role: '',
            },
            validationErrorsCheck: false,
            validationErrorsData: {
                name: null,
                username: null,
                email: null,
                role: null,
                avatar: null,
            },
        }
    },
    validations: {
        form: {
            username: { required },
            name: { required },
            email: { required },
            role: { required },
        },
        avatar: { },
    },

    methods: {
        load(){
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/admin/user/' + this.$route.params.id)
                .then((response) => {
                    this.form = response.data.data
                    this.form.role = response.data.data.role_id
                    this.avatar = response.data.data.avatar
                })
        },
        add () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                this.submitStatus = 'PENDING'
                var data = {
                    'username': this.form.username,
                    'name': this.form.name,
                    'email': this.form.email,
                    // 'phone': this.form.phone,
                    'role': this.form.role,
                    'avatar': this.avatar,
                }

                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/admin/user', data)
                    .then(response => {
                        if (response.data.error != 0) this.submitStatus = 'ERROR';
                        if (response.data.error === 422) {
                            this.validationErrorsCheck = true
                            this.validationErrorsData = response.data.errors
                        } else {
                            router.push({ name: 'SettingsAdminDetail', params: { id: response.data.data.id } })
                            this.$store
                                .commit('ADD_NOTIFICATION', {
                                    title: 'Success!',
                                    text: 'Success Add Admin!',
                                    type: 'success',
                                    timeout: false,
                                    delay: 0
                                });
                        }
                    })
            }


        },
        update (status) {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                this.submitStatus = 'PENDING'
                var data = {
                    'username': this.form.username,
                    'name': this.form.name,
                    'email': this.form.email,
                    // 'phone': this.form.phone,
                    'role': this.form.role,
                    'avatar': this.avatar,
                    'status': status
                }

                Vue.prototype.$http
                    .patch(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/admin/user/'+this.$route.params.id, data)
                    .then(response => {
                        if (response.data.error != 0) this.submitStatus = 'ERROR';
                        if (response.data.error === 422) {
                            this.validationErrorsCheck = true
                            this.validationErrorsData = response.data.errors
                        } else {
                            router.push({name: 'SettingsAdminDetail', params: {id: response.data.data.id}})
                            this.$store
                                .commit('ADD_NOTIFICATION', {
                                    title: 'Success!',
                                    text: 'Success Updating Admin!',
                                    type: 'success',
                                    timeout: false,
                                    delay: 0
                                });
                        }
                    })
            }
        },
    },
    created() {
        if ("id" in this.$route.params) {
            this.load();
            this.editing = true;
        } else {
            // this
        }

        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/admin/role')
            .then(response => {
                this.roleList = response.data.data
            })
    },


}
</script>

<style lang="sass" scoped>
    .input-file
        width: 100%
        height: 100%
        left: 0
        top: 0
        opacity: 0
        position: absolute
        z-index: 1
</style>
