<template lang="pug">
    #settings-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4    
            .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                    h2.font-bold {{ editing ? 'Edit': 'New' }} Membership
                    .header-cta.flex.items-center
                        router-link(:to="{ name: 'MembershipPage'}").link.font-sx
                            span Back
                .card__body
                    form(action="" method="method")
                        section.border-b.border-blue-100.mb-8.pb-8
                            .container-small
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Name
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="Write a name here..."
                                                    v-model="form.name"
                                                )
                                                //-.flex.items-center.justify-between.mt-2
                                                    span.info.text-xs message
                                                    span.info.text-xs 0/125
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Slug
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    readonly="readonly"
                                                    type="text"
                                                    placeholder=""
                                                    v-model="form.slug"
                                                )
                                                //-.flex.items-center.justify-between.mt-2
                                                    span.info.text-xs message
                                                    span.info.text-xs 0/125
                                .field(v-if="form.display !== 1")
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Price
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                money.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex 5.000.000"
                                                    v-model="form.membership_fee"
                                                    v-bind="money"
                                                )
                                                //-.flex.items-center.justify-between.mt-2
                                                    span.info.text-xs message
                                                    span.info.text-xs 0/125
                                .field(v-if="form.display === 1")
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Maintain Fee
                                                //- small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                money.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex 5.000.000"
                                                    v-model="form.membership_maintain_fee"
                                                    v-bind="money"
                                                )
                                                //-.flex.items-center.justify-between.mt-2
                                                    span.info.text-xs message
                                                    span.info.text-xs 0/125
                                .field(v-if="form.display === 1")
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Accumulation
                                                //- small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                money.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex 5.000.000"
                                                    v-model="form.upgrade_when_reach"
                                                    v-bind="money"
                                                )
                                                //-.flex.items-center.justify-between.mt-2
                                                    span.info.text-xs message
                                                    span.info.text-xs 0/125

                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Expired's Periode
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(v-model="form.days_to_expire")
                                                    option(value="365") 1 year
                                                    option(value="180") 6 months
                                                    option(value="93") 3 monts
                                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                    <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            //-.flex.items-center.justify-between.mt-2
                                                span.info.text-xs message
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Cover
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .w-full.bg-gray-200.mb-2(style="max-width: 600px")
                                                img.w-full.rounded(
                                                    v-if="coverImage"
                                                    :src="coverImage ? coverImage : 'https://i.imgur.com/9XwzHPA.png'", alt="alt"
                                                )
                                            .pt-4.w-64
                                                span.info.text-xs Please upload image with 1.600 x 900
                                                .block.mt-4
                                                    UploadMedia(:inputMedia.sync="coverImage")

                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right
                                            label.block.h-16.py-2 Benefit
                                                small.text-red-500(style="padding-left:4px") *
                                        .block(class="w-3/4").ml-8
                                            .relative.w-full.mr-4
                                                vueEditor(
                                                    v-model="form.description"
                                                )
                                                //-.flex.items-center.justify-between.mt-2
                                                    span.info.text-xs message

                                //-.field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right
                                            label.block.h-16.py-2 Terms & Conditions
                                                small.text-red-500(style="padding-left:4px") *
                                        .block(class="w-3/4").ml-8
                                            .relative.w-full.mr-4
                                                vueEditor(
                                                    v-model="form.term"
                                                )
                                                //-.flex.items-center.justify-between.mt-2
                                                    span.info.text-xs message


                        section
                            .flex.items-center.justify-end
                                //-button.link(type='submit') Reset
                                //- button.btn-primary-o.ml-3(type='button' @click="update(0)")) Deactive
                                button.btn-primary.ml-3(type='button' @click="update()") Update


</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import Vue from "vue";
import router from "../../router";
import {Money} from "v-money";
import UploadMedia from "../../components/content/UploadMedia";
import {VueEditor} from "vue2-editor";

export default {
    name: 'MembershipCreate',
    components: {
        Breadcrumb,
        Money,
        UploadMedia,
        VueEditor,
    },
    data() {
        return {
            coverImage: 'https://i.imgur.com/dL0n397.png',
            breadcrumb: [
                {
                    name: 'Membership',
                    url: '/membership',
                },
                {
                    name: 'Edit Membership',
                    url: '/edit',
                },
            ],
            form: {
                name: null,
                slug: null,
                membership_fee: null,
                membership_maintain_fee: null,
                days_to_expire: 365,
                design: 'https://i.imgur.com/dL0n397.png',
            },
        }
    },

    methods: {
        load() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/type/' + this.$route.params.id)
                .then((response) => {
                    this.form = response.data.data
                    this.coverImage = response.data.data.logo
                    this.breadcrumb = [
                        {
                            name: 'Membership',
                            url: '/membership',
                        },
                        {
                            name: response.data.data.name,
                            url: '/membership/detail/'+response.data.data.id,
                        },
                    ]
                })
        },
        // add() {
        //
        // },
        update(status) {
            var data = {
                name: this.form.name,
                membership_fee: this.form.membership_fee,
                membership_maintain_fee: this.form.membership_maintain_fee,
                days_to_expire: this.form.days_to_expire,
                term: this.form.term,
                description: this.form.description,
                design: this.coverImage,
                logo: this.coverImage,
                status: status
            }

            if (this.form.upgrade_when_reach)
                data.upgrade_when_reach = this.form.upgrade_when_reach

            Vue.prototype.$http
                .patch(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/type/' + this.$route.params.id, data)
                .then(response => {
                    router.push({ name: 'MembershipDetail', params: { id: response.data.data.id } })
                })
        },
    },

    created() {
        if ("id" in this.$route.params) {
            this.load();
            this.editing = true;
        } else {
            // this
        }


    },
    

}
</script>

<style lang="sass" scoped>
    .input-file
        width: 100%
        height: 100%
        left: 0
        top: 0
        opacity: 0
        position: absolute
        z-index: 1
    
    .field-radio
        position: relative
        cursor: pointer
        input
            width: 100%
            height: 100%
            position: absolute
            left: 0
            top: 0
            opacity: 0
            z-index: 3
            &:checked
                +
                    .ui-radio
                        border: 1px solid brown
                        &::after
                            transform: translate(-50%, -50%) scale(1)
        .ui-radio
            width: 24px
            height: 24px
            border-radius: 100%
            border: 1px solid #dddddd
            position: relative
            transition: all 0.3s
            &::after
                content: ''
                width: 14px
                height: 14px
                position: absolute
                top: 50%
                left: 50%
                border-radius: 100%
                transform: translate(-50%, -50%) scale(0)
                transition: all 0.3s
                background-color: brown
                
</style>