<template lang="pug">
    .col-span-3.card.card--shadow.bg-white.border-gray-200.p-4.rounded
        header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
            h2.font-bold Inbox by services
        .card__body
            .box-services
                router-link.list-service(v-for="list in dataInbox" :key="list.id" :to="{name: 'ServiceDetail', params: { id: list.id }}")
                    .flex-grow
                        p.mb-1 {{ list.name }}
                        .text-xs {{ list.address }}
                    .ml-3
                        .w-6.h-6.flex.items-center.justify-center.rounded-full.bg-red-500.text-red-100.font-bold.text-xs {{ list.inbox||0 | moneyFilter }}


</template>

<script>
import Vue from "vue";

export default {
    name: 'HighlightServices',
    components: {

    },

    data() {
        return {
            dataList: []
        }
    },

    methods: {
        load() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/service/partner')
                .then((response) => {
                    this.dataList = response.data.data
                })
        },
    },
    created() {
        this.load()
    },
}
</script>

<style lang="sass" scoped>
    .list-service
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        border-bottom: 1px solid #edf2f7
        padding: 1rem
        &:hover
            background: rgba(0,0,0, 0.025)
</style>