import Vue from 'vue'
import router from './router/index';
import store from './library/store'
import MainLayout from './shell/Layout.vue';
import Http from './library/HttpDefault';

import AppLayout from './layouts/AppLayout.vue';
import AuthLayout from './layouts/AuthLayout.vue';

import VueTabs from 'vue-nav-tabs';
import money from 'v-money';
import Vuelidate from 'vuelidate';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";

Vue.component('app-layout', AppLayout)
Vue.component('auth-layout', AuthLayout)

Vue.config.productionTip = false
Vue.prototype.$http = Http;

const moment = require('moment');
require('moment/locale/id')
Vue.use(require('vue-moment'), {moment})
Vue.use(VueTabs)
Vue.use(Vuelidate)
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: 'Rp ',
  suffix: '',
  precision: 0,
  masked: false
})
Vue.use(Loading, {
  container: null,
  canCancel: false,
});

Vue.filter('moneyFilter', num => {
  if (!num) {
    return '0';
  }
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
})
Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).utc().format('DD/F/YYYY hh:mm')
  }
});

Vue.use(HighchartsVue);
Highcharts.setOptions({
  global: {
    timezoneOffset: -7 * 60
  }
});

window.Event = new Vue();

new Vue({
  router,
  store,
  render: h => h(MainLayout),
}).$mount('#app')
