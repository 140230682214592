<template lang="pug">
    #static-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                    h2.font-bold {{ editing ? 'Edit' : 'New' }} FAQ
                    .header-cta.flex.items-center
                        router-link(to="/faq").link.font-sx
                            span Back
                .card__body
                    form(action="" method="method" @submit.prevent="onSubmit(0)")
                        section.border-b.border-blue-100.mb-8.pb-8
                            .container-small
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Question
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: Nikmati akrhir tahun di JHL Serpong"
                                                    v-model="$v.form.title.$model"
                                                    :class="{ 'border-red-500': $v.form.title.$error }"
                                                )
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.title.$error")
                                                    span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right
                                            label.block.h-16.py-2 Answer
                                                small.text-red-500(style="padding-left:4px") *
                                        .block(class="w-3/4").ml-8
                                            .relative.w-full.mr-4
                                                vueEditor(
                                                    v-model="$v.form.content.$model"
                                                    :class="{ 'border-red-500': $v.form.content.$error }"
                                                )
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.content.$error")
                                                    span.info.text-xs This field is required
                        section
                            .flex.items-center.justify-end
                                //-button.link(type='submit') Reset
                                button.btn-primary-o.ml-3(type='button' @click="add(2)" v-if="!editing") Draft
                                button.btn-primary.ml-3(type='button' @click="add(1)" v-if="!editing") Publish
                                button.btn-primary-o.ml-3(type='button' @click="update(2)" v-if="editing") Draft
                                button.btn-primary.ml-3(type='button' @click="update(1)" v-if="editing") Update
                            p.typo__p.text-right.mt-3.text-green-500(v-if="submitStatus === 'OK'") Thanks for your submission!
                            p.typo__p.text-right.mt-3.text-red-500(v-if="submitStatus === 'ERROR'") Please fill the form correctly.
                            p.typo__p.text-right.mt-3.text-blue-500(v-if="submitStatus === 'PENDING'") Sending...

</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import { VueEditor } from "vue2-editor";
import Vue from "vue";
import router from "../../router";
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'FAQCreate',
    components: {
        Breadcrumb,
        VueEditor
    },
    data() {
        return {
            content: "<h2>Some initial content</h2>",
            breadcrumb: [
                {
                    name: 'FAQ',
                    url: '/faq',
                },
                {
                    name: 'Create',
                    url: '/faq/create',
                },
            ],
            coverImage: 'https://i.imgur.com/9XwzHPA.png',
            form: {
                title: null,
                // image: 'https://i.imgur.com/9XwzHPA.png',
                content: "<h2>Some initial content</h2>",
                status: 0,
            },
            editing: false,
            submitStatus: null,
        }
    },

    validations: {
        form: {
            title: { required },
            content: { required },
        },
        coverImage: { },
    },

    methods: {
        load(){
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/faq/' + this.$route.params.id)
                .then((response) => {
                    this.form = response.data.data
                    // this.coverImage = response.data.data.image
                    this.breadcrumb = [
                        {
                            name: 'FAQ',
                            url: '/faq',
                        },
                        {
                            name: this.pageData.title,
                            url: '/faq/edit/'+this.pageData.id,
                        },
                    ]
                })
        },
        add (status) {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'

                var data = {
                    'title': this.form.title,
                    'content': this.form.content,
                    // 'image': this.coverImage,
                    // 'comment_status': this.form.comment_status,
                    'publish_at': Vue.moment().format('YYYY-MM-DD'),
                    'status': status
                }

                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/faq', data)
                    .then(response => {
                        router.push({ name: 'FAQDetail', params: { id: response.data.data.id } })

                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success Adding FAQ!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }


        },
        update (status) {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'

                var data = {
                    'title': this.form.title,
                    'content': this.form.content,
                    // 'image': this.coverImage,
                    // 'comment_status': this.form.comment_status,
                    'publish_at': Vue.moment().format('YYYY-MM-DD'),
                    'status': status
                }

                Vue.prototype.$http
                    .patch(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/faq/'+this.$route.params.id, data)
                    .then(response => {
                        router.push({ name: 'FAQDetail', params: { id: response.data.data.id } })

                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success Updating FAQ!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }
        },
    },
    created() {
        if ("id" in this.$route.params) {
            this.load();
            this.editing = true;
        } else {
            // this
        }
    },

}
</script>

<style lang="sass" scoped>
    .input-file
        width: 100%
        height: 100%
        left: 0
        top: 0
        opacity: 0
        position: absolute
        z-index: 1
</style>