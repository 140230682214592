<template lang="pug">
    .tab
        .tab-header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-4
            h2.font-bold Report Vouchers Redeemed
            ExportButton(export-id="voucher-export-id" :export-end-point="exportEndPoint")

        .tab-body
            section.filter-section.flex.items-center.mb-8
                .inline-block.relative.ui-daterange
                    .block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight
                        date-range-picker(
                            ref="picker"
                            opens="center"
                            :locale-data="locale"
                            :maxDate="maxDate"
                            v-model="dateRange"
                            appendToBody=true
                            @update="updateFilter"
                            :ranges="default_ranges"
                        )
                            template(v-slot:input="picker") {{ dateRange.startDate }} - {{ dateRange.endDate }}
                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-5 w-5"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                
                
                button.btn-primary-o.btn-small.ml-3 Filter
            //- end filter

            .grid.grid-cols-5.gap-x-1.mb-4
                .bg-gray-900.text-gray-100.p-4.rounded
                    span.text-xs.opacity-75.uppercase Total Redeemed
                    h2.text-3xl {{ summary.total_exchanged | moneyFilter }}
                    small.text-xs.opacity-75 {{ dateRange.startDate }} - {{ dateRange.endDate }}
                
                .bg-gray-900.text-gray-100.p-4.rounded
                    span.text-xs.opacity-75.uppercase Avg daily Redeemed
                    h2.text-3xl {{ summary.avg_exchanged | moneyFilter }}
                    small.text-xs.opacity-75 {{ dateRange.startDate }} - {{ dateRange.endDate }}

                

            section.mb-4
                .w-full.py-4
                    highcharts(
                        :options="mainchart"
                        :updateArgs="[true, false]"
                        :key="chartKey"
                    )

            section.mb-4
                h3.font-bold Vouchers Redeemed {{ dateRange.startDate }} - {{ dateRange.endDate }}
                .box-table
                    table.table.table-set.w-full
                        thead
                            tr
                                th Date
                                th Redeemed
                        tbody
                        tr(v-for="(item, index) in voucherList" :key="item.id")
                            td {{ item.day }}
                            td {{ item.total_exchanged | moneyFilter }}
                    Pagination( :pagination="pagination" @paginate="load()" )
</template>

<script>
import {Chart} from 'highcharts-vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Vue from "vue";
import Pagination from "../../../components/content/Pagination";
import ExportButton from "@/components/content/ExportButton";


export default {
    name: 'RedeemedActivity',
    components: {
        highcharts: Chart,
        DateRangePicker,
        Pagination,
        ExportButton
    },
    data() {
        return {
            chartKey: 0,
            maxDate: moment().format('MM/DD/YYYY'),
            dateRange: {
                startDate: moment().startOf('month').format('MM/DD/YYYY'),
                endDate: moment().endOf('month').format('MM/DD/YYYY'),
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                // 'This year': [moment().startOf('year'), moment().endOf('year')],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'week').startOf('week').toDate(), moment().subtract(1, 'week').endOf('week').toDate()],
                'This month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
                'Last month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
            },
            locale: {
                direction: 'ltr', //direction of text
                format: 'mm/dd/yyyy',
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
            },
            mainchart: {
                chart: {
                    type: 'line'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: { // don't display the dummy year
                        month: '%e. %b',
                        year: '%b'
                    },
                    title: {
                        text: 'Date'
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                tooltip: {
                    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                    shared: true
                },
                plotOptions: {
                    series: {
                        compare: 'percent',
                        showInNavigator: true,
                        // pointStart: Date.UTC(2020, 0, 30),
                        pointStart: moment('2020/11/01'),
                        pointInterval:  24 * 3600 * 1000,
                    }
                },
                
                series: [
                    {
                        name: 'Redeemed',
                        data: [],
                    },
                ]
            },
            voucherList: [],
            summary: null,
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    filters: {
        date(val) {
            return val ? moment(val).format("DD/MM/YYYY") : "";
        },
        dateURI(val) {
            return val ? moment(val).format("YYYY-MM-DD") : "";
        },
        exportEndPoint(){
            let params = '?start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate);
            return '/api/v1/management/memberships/master/report/voucher/export'+params;
        }
    },

    methods : {
        updateFilter(){
            this.dateRange.startDate = moment(this.dateRange.startDate).format('MM/DD/YYYY')
            this.dateRange.endDate = moment(this.dateRange.endDate).format('MM/DD/YYYY')
            this.pagination.current_page = 1
            this.load()
        },
        load() {
            let params = '?start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate) +'&page='+this.pagination.current_page;
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/voucher/report'+params)
                .then((response) => {
                    this.voucherList = response.data.data
                    this.pagination = response.data.meta
                })
            this.loadChart()
            this.loadSummary()
        },
        loadSummary(){
            let params = '?start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate) +'&per_page=-1';
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/voucher/summary'+params)
                .then((response) => {
                    this.summary = response.data.data
                })
        },
        loadChart(){
            let params = '?start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate) +'&per_page=-1';
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/voucher/chart'+params)
                .then((response) => {
                    let date = parseInt(moment(this.dateRange.startDate).utc().format('x'))
                    let res = []
                    response.data.data.exchanged.forEach(function(v, i){
                        if(i > 0)date += 24 * 3600 * 1000;
                        res.push([date, v])
                    })
                    this.mainchart.series[0].data = res
                    this.chartKey +=1
                })
        },
        exportReport(){
            let exportId = localStorage.getItem('voucher-export-id');
            if (exportId) {
                Vue.prototype.$http
                    .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/check?export_id='+exportId)
                    .then((response) => {
                        if ( response.data.data.name ) {
                            window.open(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/'+response.data.data.name, '_self')
                            localStorage.removeItem('voucher-export-id');
                        } else {
                            alert('Your file is still on progress... come back later to check your file!');
                        }
                    })
            } else {
                let params = '?start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate);
                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/voucher/export'+params)
                    .then((response) => {
                        localStorage.setItem('voucher-export-id', response.data.data.id);
                        alert('Exporting your file... come back later to check your file!');
                    })
            }
        }
    },
    created() {
        this.load()
    },
}
</script>

<style lang="sass" scoped>
.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>