<template lang="pug">
    #transfer-point-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            //- summary
            Summary
            //- list
            .card.card--shadow.bg-white.border-gray-200.p-4.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                    h2.font-bold List Tranfer Point
                    .header-cta.flex.items-center
                        //- button.btn-primary-o.btn-small(type='submit') Export Data
                        router-link(:to="{name:'GiftCardCreate'}").btn-primary.btn-small.ml-2
                            span.mr-1
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                            span Add Transfer Point
                .card__body
                    .filter-content.mb-4
                        SearchInput(:inputData.sync="searchInput" @search="load()")
                        .inline-block.relative.w-64.mr-4
                            select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                v-model="categoryFilter"
                                @change="load()"
                            )
                                option(value="0") All Categories
                                option(v-for="(category, index) in categoryList" :key="category.index" :value="category.id") {{ category.name }}
                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        .inline-block.relative.w-64
                            select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                v-model="typeFilter"
                                @change="load()"
                            )
                                option(value="0") All Level
                                option(v-for="(optionType, index) in membershipType" :key="optionType.index" :value="optionType.id") {{ optionType.name }}

                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    .box-table
                        table.table.table-set.w-full
                            thead
                                tr
                                    th  No.
                                    th  Voucher
                                    th  Category
                                    th  level
                                    th  Start date
                                    th  Expired date
                                    th  total
                                    th  claimed
                                    th  Redeemed
                                    th  Status
                                    th
                            tbody
                                tr(v-for="(list, index) in voucherList" :key="list.index")
                                    td.w-8 {{ index + 1 }}.
                                    td
                                        router-link.link.font-bold.block(:to="{ name: 'GiftCardDetail', params: { id: list.id }}" style="max-width:450px")
                                            .flex.items-center
                                                .block
                                                    figure.avatar.w-16.h-16.overflow-hidden.mr-4
                                                        img.w-full.h-full.object-cover(:src="list.thumbnail ? list.thumbnail : 'https://i.imgur.com/9XwzHPA.png'", v-bind:alt="list.name")
                                                span {{ list.catalogue_name }}
                                    td
                                        .border.bordr-gray-200.bg-gray-100.px-2.rounded.mr-3
                                            .flex.items-center
                                                span {{ list.category ? list.category.name : '-' }}

                                    td
                                        MembershipTypeWrapper(:listMembershipType="list.membership_type")
                                    td
                                        span {{ list.period_start | moment('DD MMMM YYYY') }}
                                    td
                                        span {{ list.period_end | moment('DD MMMM YYYY') }}
                                    td
                                        span {{ list.quantity | moneyFilter }}
                                    td
                                        span {{ list.exchanged_count | moneyFilter }}
                                    td
                                        span {{ list.redeemed_count | moneyFilter }}
                                    td
                                        template(v-if="list.status === 5")
                                            span.px-2.py-1.bg-gray-200.text-gray-700.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Deactived
                                        template(v-if="list.status === 1")
                                            span.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Active
                                    td
                                        router-link(:to="{name:'GiftCardEdit', params: {id: list.id}}").btn-default.btn-icon
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                        button.btn-default.btn-icon(@click="destroy(list.id)")
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>

                    Pagination( :pagination="pagination" @paginate="load()" )
</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import Pagination from './../../components/content/Pagination';
import SearchInput from './../../components/content/SearchInput';
import MembershipTypeLabel from './../../components/content/MembershipTypeLabel';
import Summary from './summary.vue'
import Vue from "vue";
export default {
    name: 'GiftCardPage',
    components: {
        Breadcrumb,
        Summary,
        SearchInput,
        Pagination,
        MembershipTypeLabel,
    },
    methods: {
        load() {
            let params = '?q='+this.searchInput+'&page='+this.pagination.current_page+'&is_gift_card=2';
            if (this.typeFilter != 0) params += '&type='+this.typeFilter;
            if (this.categoryFilter != 0) params += '&category='+this.categoryFilter;
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue'+params)
                .then((response) => {
                    this.voucherList = response.data.data
                    this.pagination = response.data.meta
                })
        },
        loadCategory() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue/category?all=1')
                .then(response => (this.categoryList = response.data.data))
        },
        destroy (id) {
            if (confirm('Are you sure?')) {
                Vue.prototype.$http
                    .delete(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue/' + id)
                    .then(() => {
                        this.load()

                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success Deleting Page!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }

        },
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Transfer Point',
                    url: '/transfer-point',
                },
            ],
            membershipType: null,
            voucherList: null,
            categoryList: null,
            categoryFilter: 0,
            typeFilter: 0,
            searchInput: '',
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    mounted() {
        this.load()
        this.loadCategory()
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/type?type=paid')
            .then(response => (this.membershipType = response.data.data))
    },
    

}
</script>

<style lang="sass" scoped>
small
    line-height: 0
    position: relative
    top: -4px

.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>