<template lang="pug">
    .panel-wrapper(v-if="listMembershipType.length > 3")
        a.show.btn.btn-secondary.btn-small(@click="show = true" v-if="!show") See More
        a.hide.btn.btn-secondary.btn-small(@click="show = false" v-if="show") See Less
        .panel(v-bind:class="show ? 'showed':''")
            MembershipTypeLabel(:membershipType="type.name" v-for="type in listMembershipType")
        .fade(v-bind:class="show ? 'showed':''")
    div(v-else)
        MembershipTypeLabel(:membershipType="type.name" v-for="type in listMembershipType")
</template>

<script>
    import MembershipTypeLabel from "@/components/content/MembershipTypeLabel";

    export default{
        name: 'MembershipTypeWrapper',
        components: {
            MembershipTypeLabel
        },
        props: {
            listMembershipType: {
                type: Array,
            },
        },
        data() {
            return {
                show: false
            }
        },
    }

</script>

<style lang="sass" scoped>

.panel-wrapper
    position: relative
    margin-bottom: 1em;
    .btn
        width: 100%

.show,
.hide
    position: absolute
    bottom: -1em
    z-index: 100
    text-align: center

.hide
    bottom: -2.5em

.hide
    display: none
.show:target
    display: none
.show:target ~ .hide
    display: block
.show:target ~ .panel
    max-height: 2000px

show:target ~ .fade
    margin-top: 0

.panel
    position: relative
    margin: 0em auto 2em auto
    width: 100%
    max-height: 50px
    overflow: hidden
    //transition: max-height .5s ease
    &.showed
        max-height: 2000px
        //transition: max-height .5s ease


.fade
    background: linear-gradient(to bottom, rgba(74,85,104,0) 0%, #fff 75%)
    height: 100px
    margin-top: -100px
    position: relative
    &.showed
        display: none

</style>