<template lang="pug">
    .grid.grid-cols-4.gap-x-1.mb-8
        .bg-gray-900.text-gray-100.p-8.rounded.flex.items-center.justify-between(v-if="this.userData.principal_staff === 1")
            .block 
                .text-xs.opacity-75.mb-1 TOTAL CUSTOMERS
                router-link.link.text-xs(to="/customers") See all customers
            h2.text-3xl {{ this.totalCustomer | moneyFilter }}

        .bg-gray-900.text-gray-100.p-8.rounded.flex.items-center.justify-between(v-if="this.userData.principal_staff === 1")
            .block 
                .text-xs.opacity-75.mb-1 TOTAL PARTNERS
                router-link.link.text-xs(to="/partners") See all partner
            h2.text-3xl {{ this.totalMerchant | moneyFilter }}

        .bg-gray-900.text-gray-100.p-8.rounded.flex.items-center.justify-between
            .block 
                .text-xs.opacity-75.mb-1 TOTAL VOUCHERS
                router-link.link.text-xs(to="/vouchers") See all Vouchers
            h2.text-3xl {{ this.totalVoucher | moneyFilter }}

        //- .bg-gray-900.text-gray-100.p-8.rounded.flex.items-center.justify-between
            .block 
                .text-xs.opacity-75.mb-1 TOTAL SERVICES
                router-link.link.text-xs(to="/services") See all Services
            h2.text-3xl {{ this.totalService | moneyFilter }}

</template>


<script>
    import Vue from "vue";

    export default {
        name: 'HighlightDashboard',

        data() {
            return {
                totalCustomer: 0,
                totalMerchant: 0,
                totalVoucher: 0,
                totalService: 0,
            }
        },
        computed: {
            userData(){
                return JSON.parse(localStorage.getItem('user'));
            },
        },
        methods: {
            load() {
                if (this.userData.principal_staff == 1) {
                    Vue.prototype.$http
                        .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/dashboard/highlight')
                        .then(response => {
                            this.totalCustomer = response.data.data.total_customer;
                            this.totalMerchant = response.data.data.total_merchant;
                            this.totalVoucher = response.data.data.total_catalogue;
                            this.totalService = response.data.data.total_service;
                        })
                } else {
                    Vue.prototype.$http
                        .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/merchant/catalogue')
                        .then((response) => {
                            this.totalVoucher = response.data.meta.total;
                        })
                }

            },
        },

        created() {
            this.load();
        },
    }
</script>