<template lang="pug">
    #static-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .w-600.m-auto
                .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                    header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                        h2.font-bold Detail FAQ
                        .header-cta.flex.items-center
                            router-link(to="/faq").link.font-sx
                                span Back
                    .card__body
                        form(action="" method="method")
                            section.border-b.border-blue-100.mb-8
                                    h2.text-2xl.block.font-bold {{ pageData.title }}
                                    small.mb-6.block Slug: {{ pageData.name }}
                                    article.html-tag(v-html="pageData.content")




</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import Vue from "vue";

export default {
    name: 'FAQDetail',
    components: {
        Breadcrumb,
    },
    data() {
        return {
            title: 'FAQ',
            // cover: 'https://i.imgur.com/9XwzHPA.png',
            breadcrumb: [
                {
                    name: 'FAQ',
                    url: '/faq',
                },
                {
                    name: 'FAQ',
                    url: '/detail',
                },
            ],
            pageData:null,
        }
    },
    methods: {
        load(){
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/faq/' + this.$route.params.id)
                .then((response) => {
                    this.pageData = response.data.data
                    this.breadcrumb = [
                        {
                            name: 'FAQ',
                            url: '/faq',
                        },
                        {
                            name: this.pageData.title,
                            url: '/faq/detail/'+this.pageData.id,
                        },
                    ]
                })
        }
    },
    mounted() {
        this.load()
    },

}
</script>

<style lang="sass" scoped>
    .w-600
        max-width: 600px

    .html-tag
        > *
            padding-bottom: 1em
        ul, ol
            padding-left: 16px
            li
                padding-bottom: 0.25em
        ul
            list-style: disc
        ol
            list-style: decimal
        img, iframe, video
            max-width: 100%
</style>