<template lang="pug">
    #broadcast-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                    h2.font-bold {{ editing ? 'Edit' : 'New' }} Group Broadcast
                    .header-cta.flex.items-center
                        router-link(to="/broadcast").link.font-sx
                            span Back
                .card__body
                    form(action="" method="method")
                        section.border-b.border-blue-100.mb-8.pb-8
                            .container-small
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Group Name
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: Enjoy the end of the year at JHL Serpong"
                                                    v-model="$v.form.name.$model"
                                                    :class="{ 'border-red-500': $v.form.name.$error }"
                                                )
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.name.$error")
                                                    span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Add Users to group
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .info-filter.block.mt-3.mb-4.bg-gray-100.p-4.rounded.border.border-gray-200
                                                .flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                                                    p Found {{ totalAllUser }} Users
                                                    button.btn-primary.btn-small(type='button' @click="addAllCustomer()") Add all {{ totalAllUser }} users to group

                                            SearchInput(:inputData.sync="searchInput" @search="loadCustomer()")
                                            .block-user.mt-4
                                                span.block.text-xs.text-gray-500.mb-4.uppercase.font-bold Select User
                                                .list-user-found.w-full
                                                    ul.w-full
                                                        li.flex.items-center.border-b.border-gray-200.pb-4.mb-4.w-full(v-for="(user, index) in customerList" v-bind:key="user.index")
                                                            .flex.items-center.w-full
                                                                .mr-4
                                                                    figure.w-6.h-6
                                                                        img.rounded-full(:src="user.avatar ? user.avatar : 'https://i.imgur.com/dL0n397.png'", alt="alt")
                                                                .grid.grid-cols-3.w-full
                                                                    .block {{ user.customer_name }}
                                                                    .block {{ user.email }}
                                                                    .block.text-right {{ user.phone }}
                                                                button.btn-primary.btn-small.ml-4(type='button' v-if="" @click="addCustomer(user)") Add


                            .border.bg-gray-100.border-gray-200.rounded.p-4
                                span.block.text-xs.text-gray-500.mb-4.uppercase.font-bold
                                    | Selected User - {{ selectedUser }}
                                .box-table
                                    table.table.table-set.w-full
                                        thead
                                            tr
                                                th  No.
                                                th  Name
                                                th  Email
                                                th  Phone
                                                th
                                        tbody(id="customer-group-list")
                                            tr(v-for="(item, index) in broadcastGroup" :key="item.id")
                                                td.w-8 {{ index + 1}}
                                                td
                                                    router-link.link.font-bold(:to="{ name: 'UserPage', params: { id: item.id }}")
                                                        .avatar.flex.items-center
                                                            figure.avatar.w-8.h-8.rounded-full.overflow-hidden.mr-4
                                                                img.w-full.h-full.object-cover(:src="item.avatar", v-bind:alt="item.customer_name")
                                                            span {{ item.customer_name }}
                                                td  {{ item.email }}
                                                td  {{ item.phone }}
                                                td
                                                    button.btn-icon.btn-icon--40(type='button' @click="removeCustomer(item.id)")
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                                Pagination( :pagination="pagination" @paginate="load()" )

                        section
                            .flex.items-center.justify-end
                                //-button.link(type='button') Reset
                                button.btn-primary-o.ml-3(type='button' @click="add(0)" v-if="!editing" :disabled="submitStatus === 'PENDING'") Draft
                                button.btn-primary.ml-3(type='button' @click="add(1)" v-if="!editing" :disabled="submitStatus === 'PENDING'") Publish
                                button.btn-primary-o.ml-3(type='button' @click="update(0)" v-if="editing" :disabled="submitStatus === 'PENDING'") Draft
                                button.btn-primary.ml-3(type='button' @click="update(1)" v-if="editing" :disabled="submitStatus === 'PENDING'") Publish
                            p.typo__p.text-right.mt-3.text-green-500(v-if="submitStatus === 'OK'") Thanks for your submission!
                            p.typo__p.text-right.mt-3.text-red-500(v-if="submitStatus === 'ERROR'") Please fill the form correctly.
                            p.typo__p.text-right.mt-3.text-blue-500(v-if="submitStatus === 'PENDING'") Sending...


</template>

<script>
import Breadcrumb from './../../../components/content/breadcrumb';
import {required} from "vuelidate/lib/validators";
import Vue from "vue";
import SearchInput from "../../../components/content/SearchInput";
import Pagination from "@/components/content/Pagination";

export default {
    name: 'BroadcastGroupCreate',
    components: {
        Breadcrumb,
        SearchInput,
        Pagination,
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Broadcast',
                    url: '/broadcast',
                },
                {
                    name: 'Create',
                    url: '/broadcast/group/new',
                },
            ],
            broadcastData: [],
            broadcastGroup: [],
            form: {
                name: null,
            },
            editing: false,
            submitStatus: null,
            selectedUser: 0,
            totalAllUser: 0,
            searchInput: '',
            typeFilter: 0,
            customerList: [],
            tempCustomerList:[],
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    validations: {
        form: {
            name: { required },
        },
    },
    methods: {
        loadCustomer(firstLoad = false) {
            var params = '?q='+this.searchInput;
            if (this.typeFilter != 0) params += '&type='+this.typeFilter;
            if (firstLoad) params += '&per_page=0';
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/'+params)
                .then((response) => {
                    if(firstLoad) {
                        this.tempCustomerList = response.data.data
                        this.totalAllUser = response.data.data.length
                    } else {
                        this.customerList = response.data.data
                    }
                })
        },
        addCustomer(customer) {
            this.broadcastGroup.push(customer)
            this.selectedUser = this.broadcastGroup.length
        },
        addAllCustomer() {
            this.broadcastGroup = this.tempCustomerList
            this.broadcastGroup.concat(this.tempCustomerList)
            this.selectedUser = this.broadcastGroup.length
        },
        removeCustomer(id) {
            var filtered = this.broadcastGroup.filter(function(value){
                return value.id != id;
            });
            this.broadcastGroup = filtered;
            this.selectedUser = this.broadcastGroup.length - 1
        },
        load() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/notification/group/' + this.$route.params.id)
                .then((response) => {
                    var data = response.data.data
                    this.form = {
                        name: data.name,
                        description: data.description,

                    }
                    this.breadcrumb = [
                        {
                            name: 'Broadcast',
                            url: '/broadcast',
                        },
                        {
                            name: data.name,
                            url: '/broadcast/group/'+data.id,
                        },
                    ]
                })


            let params = '?page='+this.pagination.current_page;
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/notification/group/' + this.$route.params.id + '/member' + params)
                .then((response) => {
                    var data = response.data.data
                    this.broadcastGroup = data.map(d => {
                        return {
                            id: d.member.id,
                            customer_name: d.member.customer_name,
                            phone: d.member.base_user.phone,
                            email: d.member.base_user.email,
                            avatar: d.member.base_user.avatar,
                        }
                    })
                    this.selectedUser = response.data.meta.total
                    this.pagination = response.data.meta
                })
        },
        add (status) {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'

                var data = {
                    "name": this.form.name,
                    "description": this.form.name,
                    'status': status
                }

                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/notification/group', data)
                    .then((response) => {
                        this.broadcastData = response.data.data
                        let broadcastId = response.data.data.id;
                        var broadcastLength = this.broadcastGroup.length - 1
                        var showNotif = this.$store
                        this.broadcastGroup.forEach(function(item, index){
                            var data = {
                                "group_id": broadcastId,
                                "member_id": item.id
                            }
                            Vue.prototype.$http
                                .post(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/notification/group/'+broadcastId+'/member', data)
                                .then(() => {
                                    if (index == broadcastLength) {
                                        this.submitStatus = null
                                        showNotif
                                            .commit('ADD_NOTIFICATION', {
                                                title: 'Success!',
                                                text: 'Success Adding Broadcast Group!',
                                                type: 'success',
                                                timeout: false,
                                                delay: 0
                                            });
                                    }
                                })
                        })

                    })


            }
        },
        update (status) {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'

                var data = {
                    "name": this.form.name,
                    "description": this.form.name,
                    'status': status
                }

                Vue.prototype.$http
                    .patch(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/notification/group/'+this.$route.params.id, data)
                    .then((response) => {
                        this.broadcastData = response.data.data
                        var broadcastId = this.broadcastData.id
                        var broadcastLength = this.broadcastGroup.length - 1
                        var showNotif = this.$store
                        this.broadcastGroup.forEach(function(item, index){
                            var data = {
                                "group_id": broadcastId,
                                "member_id": item.id
                            }
                            Vue.prototype.$http
                                .post(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/notification/group/'+broadcastId+'/member', data)
                                .then(() => {
                                    if (index == broadcastLength) {
                                        this.submitStatus = null
                                        showNotif
                                            .commit('ADD_NOTIFICATION', {
                                                title: 'Success!',
                                                text: 'Success Updating Broadcast Group!',
                                                type: 'success',
                                                timeout: false,
                                                delay: 0
                                            });
                                    }
                                })
                        })

                    })
            }
        },
    },

    created() {
        this.loadCustomer(true)
        console.log("id" in this.$route.params)
        if ("id" in this.$route.params) {
            this.load();
            this.editing = true;
        } else {
            // this
        }

    },
}
</script>

<style lang="sass" scoped>
    .table-set
        tr
            th,
            td
                padding: 12px 16px
                border-bottom: 1px solid #f1f7fb
                transition: all 0.3s ease-in-out
            &:hover
                td
                    background-color: rgba(0, 0, 0, 0.0250 )
            th
                text-transform: uppercase
                text-align: left
                font-size: 11px
                opacity: 0.7
    .btn-icon
        display: flex
        align-items: center
        justify-content: center
        border-radius: 100%
        &--40
            width: 40px
            height: 40px
            svg
                width: 20px
                height: 20px

    .field-radio
        position: relative
        cursor: pointer
        input
            width: 100%
            height: 100%
            position: absolute
            left: 0
            top: 0
            opacity: 0
            z-index: 3
            &:checked
                +
                    .ui-radio
                        .bullet
                            border: 1px solid brown
                            &::after
                                transform: translate(-50%, -50%) scale(1)
                        span
                            color: brown
        .ui-radio
            display: inline-flex
            align-items: center
            .bullet
                width: 24px
                height: 24px
                border-radius: 100%
                border: 1px solid #dddddd
                position: relative
                transition: all 0.3s
                &::after
                    content: ''
                    width: 14px
                    height: 14px
                    position: absolute
                    top: 50%
                    left: 50%
                    border-radius: 100%
                    transform: translate(-50%, -50%) scale(0)
                    transition: all 0.3s
                    background-color: brown
            span
    
    .field-checkbox
        position: relative
        cursor: pointer
        input
            width: 100%
            height: 100%
            position: absolute
            left: 0
            top: 0
            opacity: 0
            z-index: 3
            &:checked
                +
                    .ui-checkbox
                        .bullet
                            border: 1px solid brown
                            svg
                                opacity: 1
                                stroke: brown
                        span
                            color: brown
        .ui-checkbox
            display: inline-flex
            align-items: center
            .bullet
                width: 24px
                height: 24px
                border-radius: 4px
                border: 1px solid #dddddd
                position: relative
                display: flex
                align-items: center
                justify-content: center
                transition: all 0.3s
                svg
                    opacity: 0
            span
</style>