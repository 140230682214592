<template lang="pug">
    #voucher-page
        //- list
        .card.card--shadow.bg-white.border-gray-200.p-4.rounded
            header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                h2.font-bold Point Activity
                ExportButton(export-id="merchant-point-export-id" :export-end-point="exportEndPoint")
            .card__body
                section.filter-section.flex.items-center.mb-8
                    .inline-block.relative.ui-daterange
                        .block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight
                            date-range-picker(
                                ref="picker"
                                opens="center"
                                :locale-data="locale"
                                :maxDate="maxDate"
                                v-model="dateRange"
                                appendToBody=true,
                                @update="updateFilter"
                            )
                                template(v-slot:input="picker") {{ dateRange.startDate }} - {{ dateRange.endDate }}
                        .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-5 w-5"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                //- end filter

                .grid.grid-cols-5.gap-x-1.mb-4
                    .bg-gray-900.text-gray-100.p-4.rounded
                        span.text-xs.opacity-75.uppercase Point Earn By Customer
                        h2.text-3xl {{ pointSummary.total_earn | moneyFilter }}
                        small.text-xs.opacity-75 {{ dateRange.startDate }} - {{ dateRange.endDate }}

                    .bg-gray-900.text-gray-100.p-4.rounded
                        span.text-xs.opacity-75.uppercase Point Spend By Customer
                        h2.text-3xl {{ pointSummary.total_spent | moneyFilter }}
                        small.text-xs.opacity-75 {{ dateRange.startDate }} - {{ dateRange.endDate }}

                    .bg-gray-900.text-gray-100.p-4.rounded
                        span.text-xs.opacity-75.uppercase buy points (* {{ buy_point }})
                        h2.text-3xl {{ pointSummary.buy_point | moneyFilter }}
                        small.text-xs.opacity-75 {{ dateRange.startDate }} - {{ dateRange.endDate }}

                    .bg-gray-900.text-gray-100.p-4.rounded
                        span.text-xs.opacity-75.uppercase sell points (* {{ sale_point }})
                        h2.text-3xl {{ pointSummary.sale_point | moneyFilter }}
                        small.text-xs.opacity-75 {{ dateRange.startDate }} - {{ dateRange.endDate }}

                    .bg-gray-900.text-gray-100.p-4.rounded
                        span.text-xs.opacity-75.uppercase Accumulation points
                        span.block.text-xs.opacity-75.uppercase (buy - sell)
                        h2.text-3xl {{ pointSummary.accumulation_point | moneyFilter }}
                        small.text-xs.opacity-75 {{ dateRange.startDate }} - {{ dateRange.endDate }}
                .box-table
                    table.table.table-set.w-full
                        thead
                            tr
                                th User
                                th Type
                                th Point
                                th Transaction Amount
                                th Point Convertion
                                th Staff in charge

                        tbody
                            tr(v-for="item in dataPoin" :key="item.id")
                                td {{ item.member.customer_name }}
                                td
                                    .flex.flex-col
                                        template(v-if="(item.transaction_type == 1)")
                                            span.px-2.py-1.bg-green-300.text-green-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase {{ item.transaction_type | pointSubject(item) }}
                                        template(v-else-if="(item.transaction_type != 1)")
                                            span.px-2.py-1.bg-red-300.text-red-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase {{ item.transaction_type | pointSubject(item) }}
                                        span {{ item.created_at | moment('DD MMMM YYYY HH:mm') }}
                                td
                                    strong {{ item.transaction_type == 1 ? '+':'-' }} {{ item.point }}
                                td
                                    strong {{ item.transaction_amount | moneyFilter }}
                                td
                                    strong IDR {{ item.principal_conversion_amount | moneyFilter }}
                                    template(v-if="(item.principal_conversion_type == 'SELL')")
                                        span.px-2.py-1.bg-red-300.text-red-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase {{ item.principal_conversion_type }}
                                    template(v-else-if="(item.principal_conversion_type == 'BUY')")
                                        span.px-2.py-1.bg-green-300.text-green-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase {{ item.principal_conversion_type }}
                                td
                                    span {{ item.staff !== null ? item.staff.officer_name : '-'}}
                                td
                                    button.btn-primary-o.mr-2.btn-small(@click="showAttachment(item)")
                                        .icon.w-6.h-6
                                            <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Document Text</title><path d='M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/><path d='M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/></svg>
                                        span &nbsp;{{ item.transaction_reference }}
                Pagination( :pagination="pagination" @paginate="load()" )
        .overlay-popup.bg-gray-200.bg-opacity-25(v-if="showModal")
            .overlay-popup__container
                .popup.rounded.shadow
                    header.popup__header.border-b.border-gray-200.p-6.flex.justify-between
                        h2.font-bold Detail Transaction Reff - {{ reffID }}
                        button.btn-icon.btn-icon--40(type='button' @click="showModal = false")
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    .popup__body.p-8
                        span.mb-3 Reff : {{ reffID }}
                        template(v-if="attachmentReff")
                            .block.border-b.border-gray-200.pb-4.mt-4
                                img(:src="attachmentReff" )
                        template(v-else)
                            .block
                                .block No Image
</template>

<script>

import Vue from "vue";
import SearchInput from "../../components/content/SearchInput";
import Pagination from "../../components/content/Pagination";
import MembershipTypeLabel from "../../components/content/MembershipTypeLabel";
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import ExportButton from "@/components/content/ExportButton";


export default {
    name: 'MerchantVoucher',
    components: {
        SearchInput,
        Pagination,
        MembershipTypeLabel,
        DateRangePicker,
        ExportButton
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Vouchers',
                    url: '/vouchers',
                },
            ],
            pointSummary: {
                total_earn: 0,
                total_spent: 0,
                buy_point: 0,
                sale_point: 0,
                accumulation_point: 0,
            },
            membershipType: null,
            dataPoin: null,
            categoryList: null,
            categoryFilter: 0,
            typeFilter: 0,
            searchInput: '',
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
            maxDate: moment().format('MM/DD/YYYY'),
            dateRange: {
                startDate: moment().startOf('month').format('MM/DD/YYYY'),
                endDate: moment().endOf('month').format('MM/DD/YYYY'),
            },
            locale: {
                direction: 'ltr', //direction of text
                format: 'mm/dd/yyyy',
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
            },
            checkAttachment: null,
            attachmentReff: '',
            reffID: '',
            showModal: false,
            app_options: {},
            buy_point: 0,
            sale_point: 0,
        }
    },
    computed: {
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
        urlPrefix(){
            return this.userData.principal_staff === 1 ? 'master':'merchant';
        },
        exportEndPoint(){
            let endPoint = '/api/v1/management/memberships/master/point/adjust/export?partner_id='+this.$route.params.id+'&'
            let params = 'start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate);
            if (this.urlPrefix == 'merchant') endPoint = '/api/v1/management/memberships/merchant/report/point/export?';
            return endPoint + params
        }
    },
    filters: {
        date(val) {
            return val ? moment(val).format("DD/MM/YYYY") : "";
        },
        dateURI(val) {
            return val ? moment(val).format("YYYY-MM-DD") : "";
        },
        pointSubject: function(type, item) {
            let subject = '';
            switch (type) {
                case 1:
                    subject = 'Customer Earn'
                    if (item.transaction_reference.includes('/CR-')) {
                        subject = 'Adjustment Increase for Reff ' + (item.transaction_reference.replace('CR-', ''))
                    }
                    break;
                case 2:
                    subject = 'Customer Spent'
                    if (item.transaction_reference.includes('/DB-')) {
                        subject = 'Adjustment Decrease for Reff ' + (item.transaction_reference.replace('DB-', ''))
                    }
                    break;
                default:
                    subject = 'UNKNOWN TYPE';
                    break;
            }
            return subject;
        }
    },
    methods:{
        updateFilter(){
            this.dateRange.startDate = moment(this.dateRange.startDate).format('MM/DD/YYYY')
            this.dateRange.endDate = moment(this.dateRange.endDate).format('MM/DD/YYYY')
            this.load()
            this.loadSummary()
        },
        load() {
            let params = 'start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate)+'&q='+this.searchInput+'&page='+this.pagination.current_page;
            let endPoint = '/api/v1/management/memberships/master/point/adjust'
            if (this.urlPrefix == 'master') endPoint = '/api/v1/management/memberships/master/point/adjust?partner_id='+this.$route.params.id+'&'
            else endPoint = '/api/v1/management/memberships/merchant/report/point?'
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + endPoint +params)
                .then((response) => {
                    this.dataPoin = response.data.data
                    this.pagination = response.data.meta
                })
        },
        loadSummary() {
            let loader = this.$loading.show()
            let params = 'start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate);
            let endPoint = '/api/v1/management/memberships/master/point/adjust/summary?partner_id='+this.$route.params.id+'&'
            if (this.urlPrefix == 'merchant') endPoint = '/api/v1/management/memberships/merchant/report/point/summary?'
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + endPoint +params)
                .then((response) => {
                    this.pointSummary = response.data.data

                    Vue.prototype.$http
                        .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/options/')
                        .then(response => {
                            this.app_options = response.data.data
                            this.buy_point = this.app_options.find(x => x.option_name === 'buy_points').option_value
                            this.sale_point = this.app_options.find(x => x.option_name === 'sale_points').option_value
                            this.pointSummary.buy_point = this.buy_point * this.pointSummary.total_earn
                            this.pointSummary.sale_point = this.sale_point * this.pointSummary.total_spent
                            this.pointSummary.accumulation_point = this.pointSummary.buy_point - this.pointSummary.sale_point
                        }).then(()=>{
                            loader.hide()
                        })
                })
        },
        exportReport(){
            let loader = this.$loading.show();

            let exportId = localStorage.getItem('merchant-point-export-id');
            if (exportId) {
                Vue.prototype.$http
                    .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/check?export_id='+exportId)
                    .then((response) => {
                        loader.hide()
                        if ( response.data.data.name ) {
                            window.open(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/'+response.data.data.name, '_self')
                            localStorage.removeItem('merchant-point-export-id');
                        } else {
                            alert('Your file is still on progress... come back later to check your file!');
                        }
                    })
            } else {
                let endPoint = '/api/v1/management/memberships/master/point/adjust/export?partner_id='+this.$route.params.id+'&'
                let params = 'start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate);
                if (this.urlPrefix == 'merchant') endPoint = '/api/v1/management/memberships/merchant/report/point/export?';
                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + endPoint +params)
                    .then((response) => {
                        loader.hide()
                        localStorage.setItem('merchant-point-export-id', response.data.data.id);
                        alert('Exporting your file... come back later to check your file!');
                    })
            }
        },
        showAttachment(item){
            this.checkAttachment = null
            this.reffID = item.transaction_reference
            if (item.attachment !== null) {
                this.checkAttachment = 'reff'
                this.attachmentReff = item.attachment[0]
            } else {
                this.attachmentReff = null
                this.checkAttachment = null
            }
            this.showModal = !this.showModal;
        }
    },
    mounted() {
        this.load()
        this.loadSummary()

        let typeEndPoint = 'customer/type?type=paid'
        if (this.urlPrefix == 'merchant') {
            typeEndPoint = 'catalogue/usertype?type=paid'
        }
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/'+typeEndPoint)
            .then(response => (this.membershipType = response.data.data))

        // // get summary
        // Vue.prototype.$http
        //     .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue/summary?merchant_id='+ this.$route.params.id)
        //     .then((response) => {
        //         this.voucherSummary = response.data.data
        //     })
    }


}
</script>

<style lang="sass" scoped>
small
    line-height: 0
    position: relative
    top: -4px

.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7

.overlay-popup
    position: fixed
    left: 0
    top: 0
    bottom: 0
    right: 0
    width: 100%
    height: 100%
    overflow: auto
    &__container
        .popup
        background-color: #ffffff
        max-width: 600px
        margin: 2rem auto
        &__header
            display: flex
            align-items: center
            justify-content: space-between
</style>
