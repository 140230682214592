<template lang="pug">
    .settings-box
        .settings-box__header.p-4
            ul.flex.items-center.w-full.settings-menu
                li(v-for="list in settingsMenu" :key="list.id")
                    router-link(:to="list.url")
                        | {{ list.menu }}
        .settings-box__content.p-4
            router-view
</template>

<script>
    export default {
        name: 'SettingsShell',
        data(){
            return {
                settingsMenu: [
                    {
                        url:'/settings/app',
                        menu: 'Applications'
                    },
                    {
                        url:'/settings/lang',
                        menu: 'Language'
                    },
                    {
                        url:'/settings/admin',
                        menu: 'Admin'
                    },
                    {
                        url:'/settings/categories',
                        menu: 'Categories Management'
                    },
                    {
                        url:'/settings/role',
                        menu: 'Admin Role'
                    },
                    // {
                    //     url:'/settings/notification',
                    //     menu: 'Notification'
                    // },
                    {
                        url:'/settings/images',
                        menu: 'Images'
                    },
                ]
            }
        }
    }
</script>

<style lang="sass" scoped>
    .settings-menu
        li
            a
                display: block
                padding: 6px 12px
                border-radius: 100px
                font-weight: bold
                transition: all 0.3s ease-in-out
                opacity: 0.7
                font-size: 13px
                margin-right: 4px
                &.router-link-active
                    opacity: 1
                    background-color: #c4dae8
                &:hover
                    background-color: lighten(#c4dae8, 8% )

</style>