<template lang="pug">
    //- payment activity
    .card
        header.card__header.flex.items-center.justify-between.pb-4
            h2.font-bold Payment Activity
        .card__body
            .mb-6
                .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between.rounded-b-none
                    .block
                        span Total Amount
                        h2.text-3xl Rp {{ poinActivitySummary.total_transaction | moneyFilter }}
                //-.bg-red-100.text-red-700.px-4.py-2.mb-4.rounded.rounded-t-none(v-if="$attrs.membership_type.id == 1")
                    small.text-xs Need Rp 500.000 to become silver's membership

            .block.mb-6.pb-6.border-b.border-gray-200
                .list-item.border-b.border-gray-200.pb-2.mb-2.flex.items-center.justify-between(v-for="list in poinActivityList" :key="list.index" v-if="list.transaction_type == 1 && !list.transaction_reference.includes('/CR-')")
                    .flex-grow
                        .block Belanja di {{ list.merchant ? list.merchant.company_name : '' }}
                        span.text-xs {{ list.created_at | moment('DD MMMM YYYY HH:mm') }}
                    h3.text-sm.text-right
                        small Rp
                        span {{ list.transaction_amount | moneyFilter }}
            Pagination( :pagination="pagination" @paginate="load()" )

    //- end point activity
</template>

<script>
import Pagination from './../../components/content/Pagination';
import Vue from "vue";

export default {
    name: 'PaymentActivity',
    components: {
        Pagination,
    },
    data() {
        return {
            poinActivityList: null,
            poinActivitySummary: null,
            pointExpiredSoon: false,
            pagination: {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    methods: {
        load() {
            let loader = this.$loading.show()
            let params = '?page='+this.pagination.current_page;
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/'+this.$route.params.id+'/point?'+params)
                .then((response) => {
                    this.poinActivityList = response.data.data
                    this.pagination = response.data.meta
                    loader.hide()
                })
        },
    },
    mounted() {
        this.load()
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/'+this.$route.params.id+'/point/summary')
            .then((response) => {
                this.poinActivitySummary = response.data.data

                if (this.poinActivitySummary.total_expired_soon > 0) {
                    this.pointExpiredSoon = true;
                }
            })
    }
}
</script>
