<template lang="pug">
    .box-role
        SubmitStatus(:submitStatus.sync="submitStatus")
        section.border-b.border-gray-200.pb-6.mb-6(v-for="(module,index) in moduleList")
            .flex.items-center
                .block(class="w-2/5")
                    h3.font-bold {{ module.name }}
                .flex-grow.flex.items-center.justify-between
                    .select-role.relative.w-full.ml-4
                        input(type="checkbox" value="CAN_READ" v-model="module.permissions" @click="updateModulePermission(index, $event)")
                        .select-role__style
                            .list-role.flex.items-center.p-1.rounded.shadow
                                .icon.w-8.h-8.flex.items-center.justify-center.mr-2
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                span Read
                    .select-role.relative.w-full.ml-4
                        input(type="checkbox" value="CAN_CREATE" v-model="module.permissions" @click="updateModulePermission(index, $event)")
                        .select-role__style
                            .list-role.flex.items-center.p-1.rounded.shadow
                                .icon.w-8.h-8.flex.items-center.justify-center.mr-2
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                span Create
                    .select-role.relative.w-full.ml-4
                        input(type="checkbox" value="CAN_UPDATE" v-model="module.permissions" @click="updateModulePermission(index, $event)")
                        .select-role__style
                            .list-role.flex.items-center.p-1.rounded.shadow
                                .icon.w-8.h-8.flex.items-center.justify-center.mr-2
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                span Update
                    .select-role.relative.w-full.ml-4
                        input(type="checkbox" value="CAN_DELETE" v-model="module.permissions" @click="updateModulePermission(index, $event)")
                        .select-role__style
                            .list-role.flex.items-center.p-1.rounded.shadow
                                .icon.w-8.h-8.flex.items-center.justify-center.mr-2
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                span Delete

</template>

<script>
import Vue from "vue";
import SubmitStatus from "../../../components/content/SubmitStatus";

export default {
    name: 'RoleDetail',
    components: {
      SubmitStatus
    },
    props: {
        role: Object
    },
    data() {
        return {
            moduleList: [],
            permissionInput: [],
            submitStatus: null,
        }
    },
    methods: {
        load() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/admin/module?with_role='+this.role.id)
                .then(response => {
                    this.moduleList = response.data.data

                    this.moduleList.forEach((v) => {
                        console.log(v)
                        this.permissionInput[v.id] = v.permission
                    })
                })
        },
        updateModulePermission(moduleIndex, event) {
            if (event.target.checked) {
                this.moduleList[moduleIndex].permissions.push(event.target.value)
            } else {
                this.moduleList[moduleIndex].permissions = this.moduleList[moduleIndex].permissions.filter(perm => perm != event.target.value)
            }
            let data = {
                'role_id' : this.role.id,
                'permissions' : this.moduleList[moduleIndex].permissions
            };

            Vue.prototype.$http.interceptors.request.use((config) => {
                this.submitStatus = 'PENDING';
                return config;
            });

            Vue.prototype.$http
                .patch(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/admin/module/'+ this.moduleList[moduleIndex].id +'/permission', data)
                .then(() => {
                    this.submitStatus = 'OK';
                    // this.moduleList = response.data.data
                })
        },
    },
    mounted() {
        this.load()
    }
}
</script>

<style lang="sass" scoped>
    .select-role
        margin-bottom: 16px
        cursor: pointer
        input
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            z-index: 1
            opacity: 0
            cursor: pointer
            &:checked +
                .select-role__style
                    .list-role
                        background-color: #231F20
                        border-color: #231F20
                        color: #FFEFD8
                        .icon
                            background-color: #FFEFD8
                            svg
                                opacity: 1
                                stroke: #231F20


        &__style
            .list-role
                border: 1px solid #cbd5e0
                padding: 8px
                .icon
                    background-color: rgba(0, 0, 0,0.045)
                    border-radius: 100%
                    svg
                        opacity: 0
</style>