<template lang="pug">
    #sidebar.main-sidebar
        .main-sidebar__header
            .logo-header.text-center.h-16.flex.items-center.justify-center.text-gray-100
                strong.text-sm MYJHL DASHBOARD
        .main-sidebar__menus
            .menu
                ul
                    li(v-for="menu in menus" :key="menu.index")
                        router-link.w-full.flex.items-center.p-3(:to="menu.url" v-if="checkPermission(menu.slug)")
                            .icon.w-6.h-6.flex.items-center.justify-center.mr-2.text-white.text-xs.menu-icon
                                template(v-if="menu.name === 'Dashboard'")
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Apps</title><path d='M104 160a56 56 0 1156-56 56.06 56.06 0 01-56 56zM256 160a56 56 0 1156-56 56.06 56.06 0 01-56 56zM408 160a56 56 0 1156-56 56.06 56.06 0 01-56 56zM104 312a56 56 0 1156-56 56.06 56.06 0 01-56 56zM256 312a56 56 0 1156-56 56.06 56.06 0 01-56 56zM408 312a56 56 0 1156-56 56.06 56.06 0 01-56 56zM104 464a56 56 0 1156-56 56.06 56.06 0 01-56 56zM256 464a56 56 0 1156-56 56.06 56.06 0 01-56 56zM408 464a56 56 0 1156-56 56.06 56.06 0 01-56 56z'/></svg>
                                template(v-if="menu.name === 'Reports'")
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Stats Chart</title><path d='M104 496H72a24 24 0 01-24-24V328a24 24 0 0124-24h32a24 24 0 0124 24v144a24 24 0 01-24 24zM328 496h-32a24 24 0 01-24-24V232a24 24 0 0124-24h32a24 24 0 0124 24v240a24 24 0 01-24 24zM440 496h-32a24 24 0 01-24-24V120a24 24 0 0124-24h32a24 24 0 0124 24v352a24 24 0 01-24 24zM216 496h-32a24 24 0 01-24-24V40a24 24 0 0124-24h32a24 24 0 0124 24v432a24 24 0 01-24 24z'/></svg>
                                template(v-if="menu.name === 'Customers'")
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>People</title><path d='M336 256c-20.56 0-40.44-9.18-56-25.84-15.13-16.25-24.37-37.92-26-61-1.74-24.62 5.77-47.26 21.14-63.76S312 80 336 80c23.83 0 45.38 9.06 60.7 25.52 15.47 16.62 23 39.22 21.26 63.63-1.67 23.11-10.9 44.77-26 61C376.44 246.82 356.57 256 336 256zm66-88zM467.83 432H204.18a27.71 27.71 0 01-22-10.67 30.22 30.22 0 01-5.26-25.79c8.42-33.81 29.28-61.85 60.32-81.08C264.79 297.4 299.86 288 336 288c36.85 0 71 9 98.71 26.05 31.11 19.13 52 47.33 60.38 81.55a30.27 30.27 0 01-5.32 25.78A27.68 27.68 0 01467.83 432zM147 260c-35.19 0-66.13-32.72-69-72.93-1.42-20.6 5-39.65 18-53.62 12.86-13.83 31-21.45 51-21.45s38 7.66 50.93 21.57c13.1 14.08 19.5 33.09 18 53.52-2.87 40.2-33.8 72.91-68.93 72.91zM212.66 291.45c-17.59-8.6-40.42-12.9-65.65-12.9-29.46 0-58.07 7.68-80.57 21.62-25.51 15.83-42.67 38.88-49.6 66.71a27.39 27.39 0 004.79 23.36A25.32 25.32 0 0041.72 400h111a8 8 0 007.87-6.57c.11-.63.25-1.26.41-1.88 8.48-34.06 28.35-62.84 57.71-83.82a8 8 0 00-.63-13.39c-1.57-.92-3.37-1.89-5.42-2.89z'/></svg>
                                template(v-if="menu.name === 'Broadcast'")
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Megaphone</title><path d='M48 176v.66a17.38 17.38 0 01-4.2 11.23v.05C38.4 194.32 32 205.74 32 224c0 16.55 5.3 28.23 11.68 35.91A19 19 0 0148 272a32 32 0 0032 32h8a8 8 0 008-8V152a8 8 0 00-8-8h-8a32 32 0 00-32 32zM452.18 186.55l-.93-.17a4 4 0 01-3.25-3.93V62c0-12.64-8.39-24-20.89-28.32-11.92-4.11-24.34-.76-31.68 8.53a431.18 431.18 0 01-51.31 51.69c-23.63 20-46.24 34.25-67 42.31a8 8 0 00-5.15 7.47V299a16 16 0 009.69 14.69c19.34 8.29 40.24 21.83 62 40.28a433.74 433.74 0 0151.68 52.16 26.22 26.22 0 0021.1 9.87 33.07 33.07 0 0010.44-1.74C439.71 410 448 399.05 448 386.4V265.53a4 4 0 013.33-3.94l.85-.14C461.8 258.84 480 247.67 480 224s-18.2-34.84-27.82-37.45zM240 320V152a8 8 0 00-8-8h-96a8 8 0 00-8 8v304a24 24 0 0024 24h52.45a32.66 32.66 0 0025.93-12.45 31.65 31.65 0 005.21-29.05c-1.62-5.18-3.63-11-5.77-17.19-7.91-22.9-18.34-37.07-21.12-69.32A32 32 0 00240 320z'/></svg>
                                template(v-if="menu.name === 'Vouchers' || menu.name === 'Transfer Point'")
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Ticket</title><path d='M490.18 181.4l-44.13-44.13a20 20 0 00-27-1 30.81 30.81 0 01-41.68-1.6 30.81 30.81 0 01-1.6-41.67 20 20 0 00-1-27L330.6 21.82a19.91 19.91 0 00-28.13 0l-70.35 70.34a39.87 39.87 0 00-9.57 15.5 7.71 7.71 0 01-4.83 4.83 39.78 39.78 0 00-15.5 9.58l-180.4 180.4a19.91 19.91 0 000 28.13L66 374.73a20 20 0 0027 1 30.69 30.69 0 0143.28 43.28 20 20 0 001 27l44.13 44.13a19.91 19.91 0 0028.13 0l180.4-180.4a39.82 39.82 0 009.58-15.49 7.69 7.69 0 014.84-4.84 39.84 39.84 0 0015.49-9.57l70.34-70.35a19.91 19.91 0 00-.01-28.09zm-228.37-29.65a16 16 0 01-22.63 0l-11.51-11.51a16 16 0 0122.63-22.62l11.51 11.5a16 16 0 010 22.63zm44 44a16 16 0 01-22.62 0l-11-11a16 16 0 1122.63-22.63l11 11a16 16 0 01.01 22.66zm44 44a16 16 0 01-22.63 0l-11-11a16 16 0 0122.63-22.62l11 11a16 16 0 01.05 22.67zm44.43 44.54a16 16 0 01-22.63 0l-11.44-11.5a16 16 0 1122.68-22.57l11.45 11.49a16 16 0 01-.01 22.63z'/></svg>
                                template(v-if="menu.name === 'Partners'")
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Storefront</title><path d='M480 448h-12a4 4 0 01-4-4V273.51a4 4 0 00-5.24-3.86 104.92 104.92 0 01-28.32 4.78c-1.18 0-2.3.05-3.4.05a108.22 108.22 0 01-52.85-13.64 8.23 8.23 0 00-8 0 108.18 108.18 0 01-52.84 13.64 106.11 106.11 0 01-52.46-13.79 8.21 8.21 0 00-8.09 0 108.14 108.14 0 01-53.16 13.8 106.19 106.19 0 01-52.77-14 8.25 8.25 0 00-8.16 0 106.19 106.19 0 01-52.77 14c-1.09 0-2.19 0-3.37-.05h-.06a104.91 104.91 0 01-29.28-5.09 4 4 0 00-5.23 3.8V444a4 4 0 01-4 4H32.5c-8.64 0-16.1 6.64-16.48 15.28A16 16 0 0032 480h447.5c8.64 0 16.1-6.64 16.48-15.28A16 16 0 00480 448zm-256-68a4 4 0 01-4 4h-88a4 4 0 01-4-4v-64a12 12 0 0112-12h72a12 12 0 0112 12zm156 68h-72a4 4 0 01-4-4V316a12 12 0 0112-12h56a12 12 0 0112 12v128a4 4 0 01-4 4zM492.57 170.28l-42.92-98.49C438.41 47.62 412.74 32 384.25 32H127.7c-28.49 0-54.16 15.62-65.4 39.79l-42.92 98.49c-9 19.41 2.89 39.34 2.9 39.35l.28.45c.49.78 1.36 2 1.89 2.78.05.06.09.13.14.2l5 6.05a7.45 7.45 0 00.6.65l5 4.83.42.36a69.65 69.65 0 009.39 6.78v.05a74 74 0 0036 10.67h2.47a76.08 76.08 0 0051.89-20.31l.33-.31a7.94 7.94 0 0110.89 0l.33.31a77.3 77.3 0 00104.46 0 8 8 0 0110.87 0 77.31 77.31 0 00104.21.23 7.88 7.88 0 0110.71 0 76.81 76.81 0 0052.31 20.08h2.49a71.35 71.35 0 0035-10.7c.95-.57 1.86-1.17 2.78-1.77A71.33 71.33 0 00488 212.17l1.74-2.63q.26-.4.48-.84c1.66-3.38 10.56-20.76 2.35-38.42z'/></svg>
                                template(v-if="menu.name === 'Poin'")
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.6094 15.7317C12.4116 15.7464 12.2119 15.7537 12.0103 15.7537C11.7652 15.7537 11.5228 15.7425 11.2833 15.7214C10.0983 15.6164 8.95004 15.2564 7.91712 14.6663C6.88419 14.0762 5.99101 13.2698 5.29875 12.3023C5.28052 12.2763 5.25598 12.2554 5.22743 12.2415C5.19888 12.2275 5.16726 12.2211 5.13554 12.2227C5.10382 12.2244 5.07303 12.234 5.04607 12.2508C5.01911 12.2676 4.99686 12.291 4.98141 12.3187L1.60641 18.3844C1.47797 18.6187 1.45781 18.9 1.59328 19.1287C1.6638 19.2422 1.76204 19.3358 1.87874 19.4007C1.99544 19.4657 2.12676 19.4998 2.26031 19.5H5.91656C6.04393 19.4953 6.17024 19.5246 6.28247 19.585C6.3947 19.6454 6.48878 19.7347 6.555 19.8436L8.36719 22.875C8.4335 22.9875 8.5276 23.081 8.64046 23.1467C8.75332 23.2124 8.88116 23.2479 9.01172 23.25C9.29016 23.2345 9.57844 23.0536 9.69422 22.8L12.7931 15.9961C12.8065 15.9665 12.812 15.934 12.809 15.9017C12.8061 15.8694 12.7948 15.8384 12.7763 15.8118C12.7578 15.7851 12.7326 15.7637 12.7034 15.7497C12.6741 15.7357 12.6417 15.7295 12.6094 15.7317ZM22.3894 18.3694L19.0364 12.3145C19.0209 12.2871 18.9987 12.2639 18.9719 12.2473C18.9451 12.2307 18.9146 12.2211 18.8831 12.2194C18.8516 12.2177 18.8202 12.224 18.7917 12.2377C18.7633 12.2513 18.7388 12.272 18.7205 12.2977C17.7984 13.5896 16.5241 14.589 15.0497 15.1767C14.8687 15.2481 14.7222 15.3866 14.6409 15.5634L12.9131 19.365C12.8909 19.4135 12.8794 19.4663 12.8794 19.5197C12.8794 19.5731 12.8909 19.6258 12.9131 19.6744L14.332 22.7958C14.4469 23.0494 14.7347 23.2345 15.0127 23.25C15.1433 23.2468 15.271 23.2101 15.3834 23.1434C15.4958 23.0768 15.5893 22.9824 15.6548 22.8694L17.4609 19.8422C17.5964 19.6148 17.842 19.4986 18.1069 19.5H21.7992C22.1119 19.5 22.3486 19.3369 22.4555 19.0312C22.493 18.9227 22.5066 18.8073 22.4952 18.693C22.4838 18.5788 22.4477 18.4683 22.3894 18.3694Z" fill="CurrentColor"/>
                                        <path d="M12.0122 9.75C13.2551 9.75 14.2626 8.74264 14.2626 7.5C14.2626 6.25736 13.2551 5.25 12.0122 5.25C10.7693 5.25 9.76171 6.25736 9.76171 7.5C9.76171 8.74264 10.7693 9.75 12.0122 9.75Z" fill="CurrentColor"/>
                                        <path d="M12.0122 0.75C8.28936 0.75 5.26217 3.77766 5.26217 7.5C5.26217 11.2223 8.29076 14.25 12.0122 14.25C15.7336 14.25 18.7622 11.2219 18.7622 7.5C18.7622 3.77812 15.7345 0.75 12.0122 0.75ZM12.0122 11.25C11.2705 11.25 10.5455 11.0301 9.92878 10.618C9.3121 10.206 8.83145 9.62029 8.54762 8.93506C8.26379 8.24984 8.18953 7.49584 8.33423 6.76841C8.47892 6.04098 8.83607 5.3728 9.36052 4.84835C9.88497 4.3239 10.5532 3.96675 11.2806 3.82206C12.008 3.67736 12.762 3.75162 13.4472 4.03545C14.1325 4.31928 14.7181 4.79993 15.1302 5.41661C15.5422 6.0333 15.7622 6.75832 15.7622 7.5C15.7609 8.49418 15.3654 9.44729 14.6625 10.1503C13.9595 10.8533 13.0064 11.2488 12.0122 11.25Z" fill="CurrentColor"/>
                                    </svg>
                                template(v-if="menu.name === 'Membership'")
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Card</title><path d='M32 376a56 56 0 0056 56h336a56 56 0 0056-56V222H32zm66-76a30 30 0 0130-30h48a30 30 0 0130 30v20a30 30 0 01-30 30h-48a30 30 0 01-30-30zM424 80H88a56 56 0 00-56 56v26h448v-26a56 56 0 00-56-56z'/></svg>
                                template(v-if="menu.url === '/whats-on'")
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Newspaper</title><path d='M439.91 112h-23.82a.09.09 0 00-.09.09V416a32 32 0 0032 32 32 32 0 0032-32V152.09A40.09 40.09 0 00439.91 112z'/><path d='M384 416V72a40 40 0 00-40-40H72a40 40 0 00-40 40v352a56 56 0 0056 56h342.85a1.14 1.14 0 001.15-1.15 1.14 1.14 0 00-.85-1.1A64.11 64.11 0 01384 416zM96 128a16 16 0 0116-16h64a16 16 0 0116 16v64a16 16 0 01-16 16h-64a16 16 0 01-16-16zm208 272H112.45c-8.61 0-16-6.62-16.43-15.23A16 16 0 01112 368h191.55c8.61 0 16 6.62 16.43 15.23A16 16 0 01304 400zm0-64H112.45c-8.61 0-16-6.62-16.43-15.23A16 16 0 01112 304h191.55c8.61 0 16 6.62 16.43 15.23A16 16 0 01304 336zm0-64H112.45c-8.61 0-16-6.62-16.43-15.23A16 16 0 01112 240h191.55c8.61 0 16 6.62 16.43 15.23A16 16 0 01304 272zm0-64h-63.55c-8.61 0-16-6.62-16.43-15.23A16 16 0 01240 176h63.55c8.61 0 16 6.62 16.43 15.23A16 16 0 01304 208zm0-64h-63.55c-8.61 0-16-6.62-16.43-15.23A16 16 0 01240 112h63.55c8.61 0 16 6.62 16.43 15.23A16 16 0 01304 144z'/></svg>
                                template(v-if="menu.name === 'Pages'")
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Share Social</title><path d='M384 336a63.78 63.78 0 00-46.12 19.7l-148-83.27a63.85 63.85 0 000-32.86l148-83.27a63.8 63.8 0 10-15.73-27.87l-148 83.27a64 64 0 100 88.6l148 83.27A64 64 0 10384 336z'/></svg>
                                template(v-if="menu.name === 'FAQ'")
                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Help</title><path d="M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="40"/><circle cx="248" cy="399.99" r="32"/></svg>
                                template(v-if="menu.name === 'Services JHL'")
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Albums</title><path d='M368 96H144a16 16 0 010-32h224a16 16 0 010 32zM400 144H112a16 16 0 010-32h288a16 16 0 010 32zM419.13 448H92.87A44.92 44.92 0 0148 403.13V204.87A44.92 44.92 0 0192.87 160h326.26A44.92 44.92 0 01464 204.87v198.26A44.92 44.92 0 01419.13 448z'/></svg>
                                template(v-if="menu.name === 'Settings'")
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Settings</title><circle cx='256' cy='256' r='48'/><path d='M470.39 300l-.47-.38-31.56-24.75a16.11 16.11 0 01-6.1-13.33v-11.56a16 16 0 016.11-13.22L469.92 212l.47-.38a26.68 26.68 0 005.9-34.06l-42.71-73.9a1.59 1.59 0 01-.13-.22A26.86 26.86 0 00401 92.14l-.35.13-37.1 14.93a15.94 15.94 0 01-14.47-1.29q-4.92-3.1-10-5.86a15.94 15.94 0 01-8.19-11.82l-5.59-39.59-.12-.72A27.22 27.22 0 00298.76 26h-85.52a26.92 26.92 0 00-26.45 22.39l-.09.56-5.57 39.67a16 16 0 01-8.13 11.82 175.21 175.21 0 00-10 5.82 15.92 15.92 0 01-14.43 1.27l-37.13-15-.35-.14a26.87 26.87 0 00-32.48 11.34l-.13.22-42.77 73.95a26.71 26.71 0 005.9 34.1l.47.38 31.56 24.75a16.11 16.11 0 016.1 13.33v11.56a16 16 0 01-6.11 13.22L42.08 300l-.47.38a26.68 26.68 0 00-5.9 34.06l42.71 73.9a1.59 1.59 0 01.13.22 26.86 26.86 0 0032.45 11.3l.35-.13 37.07-14.93a15.94 15.94 0 0114.47 1.29q4.92 3.11 10 5.86a15.94 15.94 0 018.19 11.82l5.56 39.59.12.72A27.22 27.22 0 00213.24 486h85.52a26.92 26.92 0 0026.45-22.39l.09-.56 5.57-39.67a16 16 0 018.18-11.82c3.42-1.84 6.76-3.79 10-5.82a15.92 15.92 0 0114.43-1.27l37.13 14.95.35.14a26.85 26.85 0 0032.48-11.34 2.53 2.53 0 01.13-.22l42.71-73.89a26.7 26.7 0 00-5.89-34.11zm-134.48-40.24a80 80 0 11-83.66-83.67 80.21 80.21 0 0183.66 83.67z'/></svg>
                            span {{ menu.name }}

</template>

<script>
    export default {
        name: 'SidebarShell',
        computed: {
            userPermissions() {
                return JSON.parse(localStorage.getItem('permission'));
            },
            userData() {
                return JSON.parse(localStorage.getItem('user'));
            },
        },
        data(){
            return{
                menus: [
                    {
                        name: 'Dashboard',
                        slug: 'dashboard',
                        url: '/',
                    },
                    {
                        name: 'Dashboard',
                        slug: 'partner-dashboard',
                        url: '/partner-dashboard',
                    },
                    {
                        name: 'Reports',
                        slug: 'report',
                        url: '/reports/customers',
                    },
                    {
                        name: 'Customers',
                        slug: 'customer',
                        url: '/customers',
                    },
                    // {
                    //     name: 'Broadcast',
                    //     slug: 'broadcast',
                    //     url: '/broadcast',
                    // },
                    {
                        name: 'Vouchers',
                        slug: 'voucher',
                        url: '/vouchers',
                    },
                    {
                        name: 'Transfer Point',
                        slug: 'gift-card',
                        url: '/transfer-point',
                    },
                    {
                        name: 'Partners',
                        slug: 'partner',
                        url: '/partners',
                    },
                    {
                        name: 'Poin',
                        slug: 'point',
                        url: '/poin',
                    },
                    {
                        name: 'Membership',
                        slug: 'membership',
                        url: '/membership',
                    },
                    {
                        name: 'What\'s On',
                        slug: 'whats-on',
                        url: '/whats-on',
                    },
                    {
                        name: 'Pages',
                        slug: 'pages',
                        url: '/pages',
                    },
                    {
                        name: 'FAQ',
                        slug: 'pages',
                        url: '/faq',
                    },
                    // {
                    //     name: 'Services JHL',
                    //     slug: 'services',
                    //     url: '/services',
                    // },
                    {
                        name: 'Settings',
                        slug: 'setting',
                        url: '/settings/app',
                    },
                ],
                merchantPermission: [
                    'partner-dashboard',
                    'voucher',
                    'point'
                ]
            }
        },
        methods: {
            checkPermission(needle, can='CAN_READ'){
                if (needle == 'dashboard' && this.userData.principal_staff === 1) {
                    return true;
                } else {
                    // var permissions = JSON.parse(localStorage.getItem('permission'));
                    var search = null;
                    if(this.userData.principal_staff == 1) {
                        search = this.userPermissions.find((perm)=>{
                            if(perm.slug == needle) {
                                return perm.permissions.find((p)=>{
                                    return p == can;
                                })
                            }
                        })
                    } else {
                        search = this.merchantPermission.find((perm)=>{
                            return perm == needle;
                        })
                    }
                    if (search) return true;
                    else return false;
                }

            }
        }
    }
</script>

<style lang="sass" scoped>
    .menu-icon
        svg
            width: 16px
            height: 16px
            fill: #ffffff
    .menu
        a
            color: #ffffff
            opacity: 0.8
            position: relative
            transition: all 0.3s ease
            &:hover
                opacity: 1
            &.router-link-active
                opacity: 1
                &:after
                    content: ''
                    height: 100%
                    width: 3px
                    border-radius: 2px 0px 0px 2px
                    right: 0
                    top: 0
                    position: absolute
                    background-color: #f7fafc
                    transition: all 0.3s ease
        li:first-child
            a
                &:after
                    opacity: 0
                &.is-dashboard
                    &:after
                        opacity: 1
</style>