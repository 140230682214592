<template lang="pug">
    .flex.items-center.justify-between.pt-4
        span {{ pagination.to }} from {{ pagination.total }}
        .pagination
            ul.flex.items-center
                li.ml-2(v-if="pagination.current_page > 1")
                    a.btn-primary.btn-small(
                        href="javascript:void(0)"
                        aria-label="Previous"
                        v-on:click.prevent="changePage(pagination.current_page - 1)"
                    )
                        span(aria-hidden="true") «

                li.ml-2(
                    v-for="pageNum in pagesNumber"
                )
                    a.btn-small(
                        href="javascript:void(0)"
                        v-on:click.prevent="changePage(pageNum)"
                        v-bind:class="pagination.current_page == pageNum ? 'btn-primary':'btn-primary-o'"
                    ) {{ pageNum }}

                li.ml-2(
                    v-if="pagination.current_page < pagination.last_page"
                )
                    a.btn-primary.btn-small(
                        href="javascript:void(0)"
                        aria-label="Next"
                        v-on:click.prevent="changePage(pagination.current_page + 1)"
                    )
                        span(aria-hidden="true") »
</template>

<script>
    export default{
        name: 'Pagination',
        props: {
            pagination: {
                type: Object,
                required: true
            },
            offset: {
                type: Number,
                default: 4
            }
        },
        computed: {
            pagesNumber() {
                if (!this.pagination.to) {
                    return [];
                }
                let from = this.pagination.current_page - this.offset;
                if (from < 1) {
                    from = 1;
                }
                let to = from + (this.offset * 2);
                if (to >= this.pagination.last_page) {
                    to = this.pagination.last_page;
                }
                let pagesArray = [];
                for (let page = from; page <= to; page++) {
                    pagesArray.push(page);
                }
                return pagesArray;
            }
        },
        methods : {
            changePage(page) {
                this.pagination.current_page = page;
                this.$emit('paginate');
            }
        }
    }
</script>