<template lang="pug">
    .report-page
        vue-tabs
            v-tab(title="General")
                GeneralActivity
            v-tab(title="Categories")
                CategoriesActivity

</template>

<script>
import {VueTabs, VTab} from 'vue-nav-tabs'
import GeneralActivity from './GeneralActivity.vue'
import CategoriesActivity from './CategoriesActivity.vue'

export default {
    name: 'ReportsServices',
    components: {
        VueTabs,
        VTab,
        GeneralActivity,
        CategoriesActivity,
    },
}
</script>