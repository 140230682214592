<template lang="pug">
    .px-4.w-full
        .flex.items-center.justify-between.w-full.border-b.border-gray-200.h-16
            //- HeaderTitle( :headerTitle="dynamicTitleHeader()")
            //- HeaderTitle( :headerTitle="title")
            h1.font-bold {{ title }}
            .flex.items-center
                router-link(v-if="userData.principal_staff == 1" to="/ticket-poin").main-avatar.flex.items-center.ml-2.btn-icon.w-10.h-10.rounded-full.relative.flex.items-center.justify-center.relative
                    //.bubble.absolute.w-5.h-5.rounded-full.text-red-100.bg-red-600.flex.items-center.justify-center.text-xs 9
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-inbox"><polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline><path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path></svg>
                //- router-link(to="/notification").main-avatar.flex.items-center.ml-2.btn-icon.w-10.h-10.rounded-full.text-orange-500.relative.flex.items-center.justify-center.relative
                    .bubble.absolute.w-5.h-5.rounded-full.text-red-100.bg-red-600.flex.items-center.justify-center.text-xs 9
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                router-link(to="/profile").main-avatar.flex.items-center.ml-4
                    span {{ userData.name }}
                    figure.avatar.w-10.h-10.rounded-full.overflow-hidden.ml-2
                        <img class="w-full h-full object-cover" :src="userData.avatar ? userData.avatar : 'https://i.imgur.com/9XwzHPA.png'" alt="alt">
</template>

<script>

    // import HeaderTitle from './../../components/content/HeaderTitle.vue'

    export default {
        name: 'HeaderShell',
        computed: {
            userData(){
                return JSON.parse(localStorage.getItem('user'));
            },
            title(){
                let titlePage
                const route_name = this.$route.name
                if (route_name === "DashboardPage" ){
                    titlePage = 'Dashboard';

                    // customers & user
                } else if ( route_name.includes('Customers') ){
                    titlePage = 'Customers';
                } else if ( route_name.includes('User') ) {
                    titlePage = 'User';

                    // profile
                } else if ( route_name.includes('Profile') ) {
                    titlePage = 'Profile';

                    // notification
                } else if ( route_name.includes('Notification') ) {
                    titlePage = 'Notification';

                    // Ticket poin
                } else if ( route_name === 'TicketPoinPage') {
                    titlePage = 'Request Adjustment Poin';

                    // poin
                } else if ( route_name === 'PoinPage') {
                    titlePage = 'Poin';

                    // voucher
                } else if ( route_name.includes('Voucher') ) {
                    titlePage = 'Vouchers';

                    // tranfer point
                } else if ( route_name.includes('GiftCard') ) {
                    titlePage = 'Transfer Point';

                    // Broadcast
                } else if ( route_name.includes('Broadcast') ) {
                    titlePage = 'Broadcast';

                    // Merchant
                } else if ( route_name.includes('Merchant') ){
                    titlePage = 'Partners';

                    // membership
                } else if ( route_name.includes('Membership') ){
                    titlePage = 'Membership';

                    // news
                } else if ( route_name.includes('News') ){
                    titlePage = 'What\'s On';

                    // Pages
                } else if ( route_name.includes('Static') ){
                    titlePage = 'Pages';

                    // Services
                } else if ( route_name.includes('Service') ){
                    titlePage = 'Services';

                    // Reports
                } else if ( route_name.includes('Settings') ){
                    titlePage = 'Settings';
                } else if ( route_name === 'ReportsCustomers'){
                    titlePage = 'Reports';
                } else if ( route_name === 'ReportsVouchers'){
                    titlePage = 'Reports';
                } else if ( route_name === 'ReportsServices'){
                    titlePage = 'Reports';
                } else if ( route_name === 'ReportsMerchants'){
                    titlePage = 'Reports';
                } else if ( route_name === 'ReportsTransactions'){
                    titlePage = 'Reports';

                    // Settings
                } else if ( route_name === 'SettingsPage'){
                    titlePage = 'Settings';

                    // Settings - app
                } else if ( route_name === 'SettingsApp') {
                    titlePage = 'Settings';

                    // Settings - language
                } else if ( route_name === 'SettingsLanguage') {
                    titlePage = 'Settings';

                    // Settings - Categories
                } else if ( route_name === 'SettingsCategories') {
                    titlePage = 'Settings';

                    // Settings - Admin
                } else if ( route_name === 'SettingsAdmin') {
                    titlePage = 'Settings';
                } else if ( route_name === 'SettingsAdminCreate') {
                    titlePage = 'Settings';
                } else if ( route_name === 'SettingsAdminEdit') {
                    titlePage = 'Settings';
                } else if ( route_name === 'SettingsAdminDetail') {
                    titlePage = 'Settings';

                    // Settings - Notification
                } else if ( route_name === 'SettingsNotification') {
                    titlePage = 'Settings';

                    // Settings - Role
                } else if ( route_name === 'SettingsRole')
                    titlePage = 'Settings';
                else if ( route_name.includes('FAQ'))
                    titlePage = 'FAQ';
                else
                    titlePage = 'Blank Page';

                return titlePage
            }
        },
        watch: {
            title(){},
            userData(){},
        },
        methods:{
            onLogout(){
                this.$store.dispatch('logout')
            }
        },
    }
</script>

<style lang="sass" scoped>
    h1
        font-size: 1.25em
    .bubble
        font-size: 9px
        font-weight: bold
        top: -4px
        right: 0px
    .btn-icon
        &:hover
            background-color: rgba(0, 0, 0, 0.0250 )

</style>