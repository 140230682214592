<template lang="pug">
    #settings-box
        .card.card--shadow.p-4.rounded.border.border-gray-200.bg-white
            header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                h2.font-bold {{ $route.params.slug | capitalize }} Categories
                .header-cta.flex.items-center
                    router-link(:to="{name : 'SettingsCategoryCreate', params: { slug: this.$route.params.slug }}").btn-primary.btn-small.ml-2
                        span.mr-1
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                        span Add Category
            .card__body
                .box-table
                    table.table.table-set.w-full
                        thead
                            tr
                                th(v-if="$route.params.slug != 'news'")  Icons
                                th  Category name
                                th  Status
                                th
                        tbody
                            tr(v-for="category in categoryList")
                                td(v-if="$route.params.slug != 'news' && $route.params.slug != 'service'")
                                    .w-10.h-10
                                        img.w-full.h-full.object-cover(:src="category.icon ? category.icon : 'https://i.imgur.com/9XwzHPA.png'" )
                                td {{ $route.params.slug != 'news' || $route.params.slug == 'service' ? category.name : category.title }}
                                td
                                    span.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Active
                                td.action-2
                                    router-link(:to="{name:'SettingsCategoryEdit', params: {id: category.id}}").btn-default.btn-icon
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                    button.btn-default.btn-icon(@click="destroy(category.id)")
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>

</template>

<script>
    import Vue from "vue";

    export default {
        name: 'SettingsCategoriesDetail',
        data() {
            return {
                config: false,
                configEdit: true,
                categoryList: [],
                form: {
                    name: '',
                }
            }
        },
        methods: {
            load(){
                let endpoint = '/api/v1/api-manage/lite-cms/category';
                switch (this.$route.params.slug) {
                    case 'voucher':
                        endpoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue/category';
                        break;
                    case 'partner':
                        endpoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/category';
                        break;
                    case 'article':
                        endpoint = process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/category'
                        break;
                    case 'service':
                        endpoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/service/amenity'
                        break;
                }
                Vue.prototype.$http
                    .get(endpoint)
                    .then(response => {
                        this.categoryList = response.data.data
                    })
            },
            destroy(categoryId){
                if (confirm('Are you sure?')) {
                    let endpoint = '';
                    switch (this.$route.params.slug) {
                        case 'voucher':
                            endpoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue/category/'+categoryId;
                            break;
                        case 'partner':
                            endpoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/category/'+categoryId;
                            break;
                        case 'article':
                            endpoint = process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/category/'+categoryId;
                            break;
                        case 'service':
                            endpoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/service/amenity/'+categoryId;
                            break;
                    }
                    Vue.prototype.$http
                        .delete(endpoint)
                        .then(() => {
                            this.load()
                        })
                }
            },
        },
        created() {
            this.load()
        },
    }
</script>

<style lang="sass" scoped>
    .table-set
        tr
            th,
            td
                padding: 12px 16px
                border-bottom: 1px solid #f1f7fb
                transition: all 0.3s ease-in-out
            &:hover
                td
                    background-color: rgba(0, 0, 0, 0.0250 )
            th
                text-transform: uppercase
                text-align: left
                font-size: 11px
                opacity: 0.7
</style>