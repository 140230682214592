<template lang="pug">
    #voucher-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            //- highlight customers
            .grid.grid-cols-4.gap-x-8.mb-4
                //- voucher info
                .block.col-span-3
                    .card.card--shadow.bg-white.border-gray-200.p-4.rounded.mb-4
                        header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                            .block
                                .flex.items-center
                                    template(v-if="voucherData.status === 99")
                                        span.inline-block.px-2.py-1.bg-red-300.text-red-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Expired
                                    template(v-if="voucherData.status === 5")
                                        span.inline-block.px-2.py-1.bg-gray-200.text-gray-700.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Draft
                                    template(v-if="voucherData.status === 6")
                                        span.inline-block.px-2.py-1.bg-orange-300.text-orange-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Request Approval
                                    template(v-if="voucherData.status === 1")
                                        span.inline-block.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Publish
                                    h2.font-bold.ml-2 {{ voucherData.catalogue_name }}
                                .flex.items-center.mt-3
                                    MembershipTypeLabel(:membershipType="type.name" v-for="type in voucherData.membership_type")

                            .header-cta
                                button.btn-primary-o.btn-small.mr-2(type="button" @click="forceToExpired" v-if="notExpired") Force to Expired
                                router-link.btn-primary-o.btn-small(:to="{ name: 'VoucherEdit', params: { id: voucherData.id } }" v-if="voucherData.memberships_customer_type_id == null") Edit
                        .card__body
                            .flex
                                .block
                                    .w-64.h-64
                                        .rounded.bg-orange-200.overflow-hidden
                                            img(:src="voucherData.thumbnail ? voucherData.thumbnail : 'https://i.imgur.com/9XwzHPA.png'")
                                .block.w-full.pl-4
                                    .block
                                        strong.uppercase.text-black-600.mb-2.block(v-if="voucherData.goods_worth_for") Amount : {{ voucherData.goods_worth_for | moneyFilter }}
                                    hr.mb-3
                                    .block.mb-3(v-if="voucherData.is_upgrade_gift == 1")
                                        p(v-html="'Gifted Voucher for <b>' + voucherData.membership_type[0].name + ' Membership </b>'")
                                    .block.mb-3
                                        strong.text-xs.uppercase.text-gray-600.mb-2.block Category
                                        .border.bordr-gray-200.bg-gray-100.px-2.rounded.mr-3
                                            .flex.items-center
                                                //- .icon.w-8.h-8.flex.items-center.justify-center
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                                span {{ voucherData.category ? voucherData.category.name : '-' }}
                                    .block.mb-3
                                        strong.text-xs.uppercase.text-gray-600.mb-2.cursor-pointer.flex.justify-between(@click="showPartner = !showPartner")
                                            span.mr-3 Partner
                                            <svg data-v-28cadd2c="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current h-5 w-5"><path data-v-28cadd2c="" d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg>
                                    .block.mb-3(:class="showPartner ? '':'hidden'")
                                        .border.bordr-gray-200.bg-gray-100.px-2.rounded.mb-1( v-for="merchant in voucherData.redeemable_at")
                                            .flex.items-center
                                                router-link.link.font-bold(:to="{ name: 'MerchantDetail', params: { id: merchant.merchant_id }}") {{ merchant.brand_name }}
                                    .block
                                        strong.text-xs.uppercase.text-gray-600.mb-2.block Syarat &amp; Ketentuan
                                        p(v-html="voucherData.catalogue_term")
                    .block
                        .alert.alert-warning.flex.items-center.justify-between.bg-blue-500.text-blue-100.p-4.rounded.mb-4(v-if="voucherData.memberships_customer_type_id != null && voucherData.quantity == 0")
                            .flex-grow
                                strong Sending Voucher to all membership may take a while
                                p Sending it around 1 minute to 10 minutes, please wait...
                                p Have a nice day
                    //- voucher transaction history
                    HistoryVoucher
                    //- end voucher transaction history
                //- end voucher info

                //- voucher sidebar
                .block.col-span-1
                    router-link.btn-primary-o.w-full.block.mb-2(:to="{ name: 'PreviewVoucher', params: { id: voucherData.id}}" target="_blank") <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg> Preview Voucher
                    .bg-white.text-gray-600.border.border-gray-300.p-4.rounded.mb-2
                        span.text-xs.block.mb-2.uppercase Published date
                        strong(v-if="([5,6].includes(voucherData.status))") -
                        strong(v-else) {{ voucherData.updated_at | moment('DD MMMM YYYY') }}
                    .bg-white.text-gray-600.border.border-gray-300.p-4.rounded.mb-2
                        span.text-xs.block.mb-2.uppercase Start date
                        strong(v-if="([5,6].includes(voucherData.status))") -
                        strong(v-else) {{ voucherData.period_start | moment('DD MMMM YYYY') }}
                    .bg-red-300.text-red-900.p-4.rounded.mb-2
                        span.text-xs.block.mb-2.uppercase expired date
                        strong {{ voucherData.period_end | moment('DD MMMM YYYY') }}
                    .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between.mb-2
                        .block
                            span.text-xs.opacity-75.uppercase Total Voucher
                            h2.text-3xl {{ voucherData.quantity | moneyFilter }}
                        .block.text-right.text-xs
                            span.block.text-gray-500 Available
                            span.text-red-300 {{ voucherData.quantity | moneyFilter }} left
                    .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between.mb-2
                        .block
                            span.text-xs.opacity-75.uppercase Claimed
                            h2.text-3xl {{ voucherData.redeemed_count | moneyFilter }}
                    .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between.mb-2
                        .block
                            span.text-xs.opacity-75.uppercase Reedemed
                            h2.text-3xl {{ voucherData.exchanged_count | moneyFilter }}
                //- end voucher sidebar

</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import MembershipTypeLabel from './../../components/content/MembershipTypeLabel';
import HistoryVoucher from './HistoryVoucher';
import Vue from "vue";
import router from "@/router";

export default {
    name: 'VoucherDetail',
    components: {
        Breadcrumb,
        MembershipTypeLabel,
        HistoryVoucher
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Vouchers',
                    url: '/vouchers',
                },
                {
                    name: 'Voucher Hotel 20% promo ramdahan Berkah 2020',
                    url: '/detail',
                },
            ],
            voucherData: null,
            membershipColorClass: 'gray',
            showPartner: false
        }
    },
    computed:{
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
        urlPrefix(){
            return this.userData.principal_staff === 1 ? 'master':'merchant';
        }
    },
    methods: {
        forceToExpired(){
            if (confirm('Are you sure?')) {
                Vue.prototype.$http
                    .delete(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/force-to-expired/' + this.$route.params.id)
                    .then(() => {
                        router.push({ name: 'VouchersPage'})

                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success to force voucher to be expired!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }
        },
    },
    created() {
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/' + this.$route.params.id)
            .then((response) => {
                this.voucherData = response.data.data
                let now = new Date().getTime();
                let expiredDate = (new Date(this.voucherData.period_end).getTime())
                this.notExpired = now < expiredDate;
                this.breadcrumb = [
                    {
                        name: 'Vouchers',
                        url: '/vouchers',
                    },
                    {
                        name: this.voucherData.catalogue_name,
                        url: '/vouchers/detail/'+this.voucherData.id,
                    },
                ]
            })
    },
}
</script>

<style lang="sass" scoped>

</style>
