<template lang="pug">
    #dashboard-page
        .content-area.px-4 
            DashboardHighlight

            .grid.grid-cols-7.gap-x-8.mb-4
                HighlightVouchers
                //- HighlightServices

        //-
            .block.mb-4
                h2.text-xl Alert Info
            .block.mb-4
                //- start alert
                .alert.alert-success.flex.items-center.justify-between.bg-green-500.text-green-100.p-4.rounded
                    .flex-grow
                        strong Success!
                        p Description Lorem ipsum dolor sit amet, consectetur adipisicing.
                    button.btn-icon.btn-icon--40(type='submit')
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            
            .block.mb-4
                //- start alert
                .alert.alert-error.flex.items-center.justify-between.bg-red-500.text-red-100.p-4.rounded
                    .flex-grow
                        strong Error!
                        p Description Lorem ipsum dolor sit amet, consectetur adipisicing.
                    button.btn-icon.btn-icon--40(type='submit')
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>

            .block.mb-4
                //- start alert
                .alert.alert-info.flex.items-center.justify-between.bg-blue-500.text-blue-100.p-4.rounded
                    .flex-grow
                        strong Info!
                        p Description Lorem ipsum dolor sit amet, consectetur adipisicing.
                    button.btn-icon.btn-icon--40(type='submit')
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            .block.mb-4
                //- start alert
                .alert.alert-waring.flex.items-center.justify-between.bg-orange-500.text-orange-100.p-4.rounded
                    .flex-grow
                        strong Warning!
                        p Description Lorem ipsum dolor sit amet, consectetur adipisicing.
                    button.btn-icon.btn-icon--40(type='submit')
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>

            .block.mb-4
                //- start alert
                .alert.alert-netral.flex.items-center.justify-between.bg-black.text-white.p-4.rounded
                    .flex-grow
                        strong Netral
                        p Description Lorem ipsum dolor sit amet, consectetur adipisicing.
                    button.btn-icon.btn-icon--40(type='submit')
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>

            .block.mb-4
                h2.text-xl.mb-2 Toast Info
                .block 
                    .inline-block.rounded-full.text-sm.px-4.py-2.bg-white.shadow.text-center.font-bold hello this is toast!
                    .inline-block.rounded-full.text-sm.px-4.py-2.bg-black.text-white.shadow.text-center.font-bold hello this is toast!


</template>

<script>

import DashboardHighlight from './Highlight'
import HighlightVouchers from './HighlightVouchers'
import HighlightServices from './HighlightServices'

export default {
    name: 'DashboardPage',
    components: {
        DashboardHighlight,
        HighlightVouchers,
        HighlightServices
    },
    data() {
        return {
            
        }
    },
    

}
</script>