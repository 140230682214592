<template lang="pug">
    .grid.grid-cols-5.gap-x-1.mb-4
        .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
            .block
                span.text-xs.opacity-75.uppercase All voucher
                h2.text-3xl {{ voucherSummary.total ? voucherSummary.total : voucherSummary.total_voucher }}
        .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
            .block
                span.text-xs.opacity-75.uppercase Claimed
                h2.text-3xl {{ voucherSummary.total_redeemed | moneyFilter }}
        .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
            .block
                span.text-xs.opacity-75.uppercase Reedemed
                h2.text-3xl {{ voucherSummary.total_exchanged | moneyFilter }}
</template>


<script>
    import Vue from "vue";

    export default {
        name: 'VoucherDetail',
        data() {
            return {
                voucherSummary: null,
            }
        },
        computed: {
            userData(){
                return JSON.parse(localStorage.getItem('user'));
            },
            urlPrefix(){
                return this.userData.principal_staff === 1 ? 'master':'merchant';
            }
        },
        mounted() {
            let endPoint = '/api/v1/management/memberships/merchant/report/voucher'
            if (this.userData.principal_staff === 1) {
                endPoint = '/api/v1/management/memberships/master/catalogue/summary?is_gift_card=1'
            }
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + endPoint)
                .then((response) => {
                    this.voucherSummary = response.data.data
                })
        }
    }
</script>
