<template lang="pug">
    .alert.alert-success.flex.items-center.justify-between.bg-green-500.text-green-100.p-4.rounded.fixed.right-0.bottom-0.mr-5.mb-20.z-20(:class="notification.type ? notification.type : 'secondary'")
        .flex-grow
            strong(v-if="notification.title") {{notification.title}}
            p {{notification.text}}
        button.btn-icon.btn-icon--40(type='button' @click="triggerClose(notification)")
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
</template>

<script>
    export default {
        props: ['notification'],
        data() {
            return {
                timer: null,
                colorClass: ''
            }
        },
        mounted() {
            let timeout = this.notification.timeout ? this.notification.timeout : true

            if (timeout) {
                let delay = this.notification.delay || 6000

                this.timer = setTimeout(function () {
                    this.triggerClose(this.notification)
                }.bind(this), delay)
            }
        },
        methods: {
            triggerClose: function (notification) {
                clearTimeout(this.timer)
                this.$emit('close-notification', notification)
            }
        },
    }
</script>