<template lang="pug">
    //- voucher activity
    .card.card--shadow.bg-white.border-gray-200.p-4.rounded.mb-4
        header.card__header.flex.items-center.justify-between.pb-4
            h2.font-bold Voucher History
        .card__body
            .filter-content.mb-4
                SearchInput(:inputData.sync="searchInput" @search="load()")
                .inline-block.relative.w-64
                    select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(v-model="statusFilter" @change="load()")
                        option(value="") All Status
                        option(value="claimed") Claimed
                        option(value="redeemed") Redeemed
                        option(value="expired") Expired
                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                        <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>

            .box-table
                table.table.table-set.w-full
                    thead
                        tr
                            th  Customer
                            th  Type
                            th  Code
                            th  Status
                            th  Claim date
                            th  Redeem date
                            th  Expired date
                    tbody
                        tr(v-for="list in dataList" :key="list.index")
                            td
                                router-link.link.font-bold(:to="{ name: 'UserPage', params: { id: list.customer_detail.id }}")
                                    .avatar.flex.items-center
                                        figure.avatar.w-8.h-8.rounded-full.overflow-hidden.mr-4
                                            img.w-full.h-full.object-cover(:src="list.base_user.avatar ? list.base_user.avatar : 'https://i.imgur.com/dL0n397.png'")
                                        span {{ list.base_user.name }}
                            td
                                MembershipTypeLabel(:membershipType="list.customer_detail.membership_type.name")
                            td
                                span {{ list.voucher_code }}
                            td
                                template(v-if="list.voucher_exchanged_at")
                                    span.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Redeemed
                                template(v-else-if="checkExpired(list.last_allowed_exchange_date)")
                                    span.px-2.py-1.bg-red-300.text-red-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Expired
                                template(v-else)
                                    span.px-2.py-1.bg-orange-300.text-orange-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Claimed
                            td
                                span {{ list.created_at | moment('DD MMMM YYYY')}}
                            td
                                span(v-if="list.voucher_exchanged_at") {{ list.voucher_exchanged_at | moment('DD MMMM YYYY')}}
                                span(v-else) -
                            td
                                span {{ list.last_allowed_exchange_date | moment('DD MMMM YYYY')}}

            Pagination( :pagination="pagination" @paginate="load()" )
    //- end voucher activity
</template>

<script>
import Vue from "vue";
import Pagination from './../../components/content/Pagination';
import SearchInput from './../../components/content/SearchInput';
import MembershipTypeLabel from "@/components/content/MembershipTypeLabel";

export default {
    name: 'HistoryVoucher',
    components: {
        Pagination,
        SearchInput,
        MembershipTypeLabel
    },
    data() {
        return {
            dataList: null,
            searchInput: '',
            statusFilter: "",
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    methods: {
        checkExpired(date) {
            return Vue.moment(date) < Vue.moment()
        },
        checkClosestExpired(date) {
            return Vue.moment(date).fromNow().includes('hari')
        },
        load() {
            let loader = this.$loading.show()
            let params = '';
            if (this.statusFilter != "") params += '?status='+ this.statusFilter;
            if (this.searchInput != "") params += '?q='+ this.searchInput;
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue/' + this.$route.params.id + '/history'+params)
                .then((response) => {
                    this.dataList = response.data.data
                    this.pagination = response.data.meta
                    loader.hide()
                });
        }
    },
    mounted() {
        this.load()
    }
}
</script>


<style lang="sass" scoped>
.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
.menu-dropdown
    margin-top: 30px
    z-index: 999
</style>