<template lang="pug">
    #voucher-page
        .grid.grid-cols-5.gap-x-1.mb-4
            .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
                .block
                    span.text-xs.opacity-75.uppercase All voucher
                    h2.text-3xl {{ voucherSummary.total }}
                //- .block.text-right.text-xs
                    span.block.text-green-200 + 20%
                    small from Last Month
            //- .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
                .block
                    span.text-xs.opacity-75.uppercase Exiperd Soon
                    h2.text-3xl 4.000
                //- .block.text-right.text-xs
                    span.block.text-green-200 + 20%
                    small from Last Month
            .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
                .block
                    span.text-xs.opacity-75.uppercase Claimed
                    h2.text-3xl {{ voucherSummary.total_exchanged }}
                //- .block.text-right.text-xs
                    span.block.text-red-300 - 0.2%
                    small from Last Month
            .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
                .block
                    span.text-xs.opacity-75.uppercase Reedemed
                    h2.text-3xl {{ voucherSummary.total_redeemed }}
                //- .block.text-right.text-xs
                    span.block.text-green-200 + 20%
                    small from Last Month

        //- list
        .card.card--shadow.bg-white.border-gray-200.p-4.rounded
            header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                h2.font-bold List Vouchers
                //-.header-cta.flex.items-center
                    button.btn-primary-o.btn-small(type='submit') Export Data
            .card__body
                .filter-content.mb-4
                    SearchInput(:inputData.sync="searchInput" @search="load()")
                    .inline-block.relative.w-64.mr-4
                        select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                            v-model="categoryFilter"
                            @change="load()"
                        )
                            option(value="0") All Categories
                            option(v-for="(category, index) in categoryList" :key="category.index" :value="category.id") {{ category.name }}
                        .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                            <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    .inline-block.relative.w-64
                        select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                            v-model="typeFilter"
                            @change="load()"
                        )
                            option(value="0") All Level
                            option(v-for="(optionType, index) in membershipType" :key="optionType.index" :value="optionType.id") {{ optionType.name }}

                        .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                            <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                .box-table
                    table.table.table-set.w-full
                        thead
                            tr
                                th  No.
                                th  Voucher
                                th  Category
                                th  level
                                th  Expired date
                                th  total
                                th  claimed
                                th  Redeemed
                                th  Status
                        tbody
                            tr(v-for="(list, index) in voucherList" :key="list.index")
                                td.w-8 {{ index + 1 }}.
                                td
                                    router-link.link.font-bold.block(:to="{ name: 'VoucherDetail', params: { id: list.id }}" style="max-width:450px")
                                        .flex.items-center
                                            .block
                                                figure.avatar.w-16.h-16.overflow-hidden.mr-4
                                                    img.w-full.h-full.object-cover(:src="list.thumbnail ? list.thumbnail : 'https://i.imgur.com/9XwzHPA.png'", v-bind:alt="list.name")
                                            span {{ list.catalogue_name }}
                                td
                                    .border.bordr-gray-200.bg-gray-100.p.rounded
                                        .flex.items-center
                                            span {{ list.category ? list.category.name : '-' }}

                                td
                                    MembershipTypeWrapper(:listMembershipType="list.membership_type")
                                td
                                    span {{ list.period_end | moment('DD MMMM YYYY') }}
                                td
                                    span {{ list.quantity | moneyFilter }}
                                td
                                    span {{ list.exchanged_count | moneyFilter }}
                                td
                                    span {{ list.redeemed_count | moneyFilter }}
                                td
                                    template(v-if="list.status === 5")
                                        span.px-2.py-1.bg-gray-200.text-gray-700.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Deactived
                                    template(v-if="list.status === 1")
                                        span.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Active

                Pagination( :pagination="pagination" @paginate="load()" )
</template>

<script>

import Vue from "vue";
import SearchInput from "../../components/content/SearchInput";
import Pagination from "../../components/content/Pagination";
import MembershipTypeLabel from "../../components/content/MembershipTypeLabel";
import MembershipTypeWrapper from "@/components/content/MembershipTypeWrapper";

export default {
    name: 'MerchantVoucher',
    components: {
        SearchInput,
        Pagination,
        MembershipTypeWrapper,
        MembershipTypeLabel,
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Vouchers',
                    url: '/vouchers',
                },
            ],
            voucherSummary: {},
            membershipType: [],
            voucherList: [],
            categoryList: [],
            categoryFilter: 0,
            typeFilter: 0,
            searchInput: '',
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    computed: {
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
        urlPrefix(){
            return this.userData.principal_staff === 1 ? 'master':'merchant';
        }
    },
    methods:{
        load() {
            let params = '?q='+this.searchInput+'&page='+this.pagination.current_page+'&merchant_id='+this.$route.params.id;
            if (this.typeFilter != 0) params += '&type='+this.typeFilter;
            if (this.categoryFilter != 0) params += '&category='+this.categoryFilter;

            let endPoint = ''
            if (this.userData.principal_staff === 1) endPoint = '/api/v1/management/memberships/master/catalogue'
            else endPoint = '/api/v1/management/memberships/merchant/catalogue'
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + endPoint +params)
                .then((response) => {
                    this.voucherList = response.data.data
                    this.pagination = response.data.meta
                    this.voucherSummary.total = response.data.meta.total
                })
        },
        loadCategory() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/category')
                .then(response => (this.categoryList = response.data.data))
        },
    },
    mounted() {
        this.load()
        this.loadCategory()
        let endPoint = ''
        let categoryEndPoint = ''
        if (this.userData.principal_staff === 1) {
            endPoint = '/api/v1/management/memberships/master/catalogue/summary?merchant_id='+ this.$route.params.id
            categoryEndPoint = '/api/v1/management/memberships/master/customer/type?type=paid'
        }
        else {
            endPoint = '/api/v1/management/memberships/merchant/report/voucher'
            categoryEndPoint = '/api/v1/management/memberships/merchant/catalogue/category'
        }

        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + categoryEndPoint)
            .then(response => (this.membershipType = response.data.data))

        // get summary
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + endPoint)
            .then((response) => {
                this.voucherSummary = response.data.data
                if(response.data.data.total_voucher) this.voucherSummary.total = response.data.data.total_voucher
            })
    }


}
</script>

<style lang="sass" scoped>
small
    line-height: 0
    position: relative
    top: -4px

.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>
