<template lang="pug">
    #settings-box
        .card.card--shadow.p-4.rounded.border.border-gray-200.bg-white
            header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                h2.font-bold Categories Management
                .header-cta.flex.items-center

            .card__body

                .box-table
                    table.table.table-set.w-full
                        thead
                            tr
                                th  No.
                                th  Name
                                th  Options
                                th  Status
                                th
                        tbody
                            tr(v-for="(list, index) in categories" :key="list.index")
                                td.w-8 {{ index + 1 }}.
                                td
                                    router-link.link.font-bold(:to="{name: 'SettingsCategoriesDetail', params:{ slug:list.slug }}")  {{ list.name }}
                                td
                                    span {{ list.options }}
                                td
                                    template(v-if="list.status === 'deactived'")
                                        span.px-2.py-1.bg-gray-200.text-gray-700.rounded.font-bold.text-xs.w-24.block.text-center.uppercase {{ list.status }}
                                    template(v-if="list.status === 'active'")
                                        span.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase {{ list.status }}
                                td.action-2
                                    router-link(:to="{name: 'SettingsCategoriesDetail', params:{ slug:list.slug }}").btn-default.btn-icon
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>


</template>

<script>
import Vue from "vue";

export default {
    name: 'SettingsCategories',
    data() {
        return {
            categories: [
                {
                    name: 'Voucher',
                    slug: 'voucher',
                    options: '0 options',
                    status: 'active',
                    time: '2 minutes ago'
                },
                {
                    name: 'Partners',
                    slug: 'partner',
                    options: '0 options',
                    status: 'active',
                    time: '2 minutes ago'
                },
                {
                    name: 'Article',
                    slug: 'article',
                    options: '0 options',
                    status: 'active',
                    time: '2 minutes ago'
                },
                // {
                //     name: 'Services Amnesty',
                //     slug: 'service',
                //     options: '0 options',
                //     status: 'active',
                //     time: '2 minutes ago'
                // },
            ],
            voucherCategories: null,
            partnerCategories: null,
            newsCategories: null,
            serviceCategories: null,
        }
    },
    methods: {
        loadVoucherCategories() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue/category')
                .then(response => {
                    this.voucherCategories = response.data
                    this.categories[0].options = response.data.meta.total+' options'
                })
        },
        loadPartnerCategories() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/category')
                .then(response => {
                    this.partnerCategories = response.data
                    this.categories[1].options = response.data.meta.total+' options'
                })
        },
        loadNewsCategories() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/category')
                .then(response => {
                    this.newsCategories = response.data;
                    this.categories[2].options = response.data.meta.total+' options'
                })
        },
        loadServiceCategories() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/service/amenity')
                .then(response => {
                    this.serviceCategories = response.data;
                    this.categories[3].options = response.data.meta.total+' options'
                })
        },
    },
    created(){
        this.loadVoucherCategories();
        this.loadPartnerCategories();
        this.loadNewsCategories();
        // this.loadServiceCategories();
    }
}
</script>

<style lang="sass" scoped>
small
    line-height: 0
    position: relative
    top: -4px

.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>