<template lang="pug">
    #settings-box
        vue-tabs
            //-v-tab(title="Banner Action")
                BannerHome
            v-tab(title="On Boarding")
                Onboarding



</template>

<script>
import {VueTabs, VTab} from 'vue-nav-tabs'
import Onboarding from './Onboarding'
import BannerHome from './BannerHome'
export default {
    name: 'SettingsImages',
    components: {
        VueTabs,
        VTab,
        Onboarding,
        BannerHome,
    }
}
</script>