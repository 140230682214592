<template lang="pug">
    .col-span-4.card.card--shadow.bg-white.border-gray-200.p-4.rounded
        header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
            h2.font-bold Weekly Voucher Activity 
            .header-cta
                router-link(to="/reports/Vouchers").btn-default.btn-small.ml-2
                    span Manage Reports
        .card__body
            p <strong> {{ summary.total_redeemed | moneyFilter }} </strong> Total Vouchers claimed {{ dateRange.startDate | moment('DD MMMM YYYY') }} - {{ dateRange.endDate | moment('DD MMMM YYYY') }}
            .py-4
                highcharts(:options="mainchart")
</template>

<script>
import {Chart} from 'highcharts-vue'
import Vue from "vue";
import moment from "moment";
export default {
    name: 'HighlightVouchers',
    components: {
        highcharts: Chart
    },

    data() {
        return {
            dateRange: {
                startDate: moment().startOf('week').format('MM/DD/YYYY'),
                endDate: moment().endOf('week').format('MM/DD/YYYY'),
            },
            mainchart: {
                chart: {
                    type: 'line'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: { // don't display the dummy year
                        month: '%e. %b',
                        year: '%b'
                    },
                    title: {
                        text: 'Date'
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                tooltip: {
                    pointFormat: '<span style="color:{series.color}">Claimed</span>: <b>{point.y}</b><br/>',
                    shared: true
                },
                plotOptions: {
                    series: {
                        compare: 'percent',
                        showInNavigator: true,
                        // pointStart: Date.UTC(2020, 0, 30),
                        pointStart: moment().startOf('week'),
                        pointInterval:  24 * 3600 * 1000,
                    }
                },

                series: [
                    {
                        name: 'claimed',
                        data: [],
                    },
                ]
            },
            summary: null,
        }
    },

    filters: {
        date(val) {
            return val ? moment(val).format("DD/MM/YYYY") : "";
        },
        dateURI(val) {
            return val ? moment(val).format("YYYY-MM-DD") : "";
        }
    },

    methods : {
        loadSummary(){
            let params = '?start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate) +'&per_page=-1';
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/voucher/summary'+params)
                .then((response) => {
                    this.summary = response.data.data
                })
        },
        loadChart(){
            let params = '?start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate) +'&per_page=-1';
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/voucher/chart'+params)
                .then((response) => {
                    this.mainchart.series[0].data = response.data.data.redeemed
                })
        },
    },
    created() {
        this.loadChart()
        this.loadSummary()
    }
}
</script>