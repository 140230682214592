<template lang="pug">
    .inline-block.relative.w-64.mr-4
        input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
            type="text"
            v-model="inputData"
            @keyup="search()"
            placeholder="Search here..."
        )
        .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
</template>

<script>
    export default{
        name: 'SearchInput',
        props: {
            inputData: {
                type: String,
            },
        },
        data() {
          return {
              awaitingSearch: false,
          }
        },
        methods: {
            search(){
                this.$emit('update:inputData', this.inputData)
                if(this.inputData.length > 3 || this.inputData == '') {
                    if (!this.awaitingSearch) {
                        setTimeout(() => {
                            this.$emit('search')
                            this.awaitingSearch = false;
                        }, 300); // 1 sec delay
                    }
                    this.awaitingSearch = true;
                }

            }
        }
    }
</script>