<template lang="pug">
    #voucher-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                    h2.font-bold {{ editing ? 'Edit' : 'New' }} Voucher
                    .header-cta.flex.items-center
                        router-link(to="/vouchers").link.font-sx
                            span Back
                .card__body
                    form(action="" method="method" @submit.prevent="onSubmit(0)")
                        section.border-b.border-blue-100.mb-8.pb-8
                            .container-small
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Title
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: Welcom to MyJHL"
                                                    v-model="$v.form.name.$model"
                                                    :class="{ 'border-red-500': $v.form.name.$error }"
                                                )
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.name.$error")
                                                    span.info.text-xs This field is required
                                                    //- span.info.text-xs 0/125
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Activity
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    v-model="$v.form.activity.$model"
                                                    :class="{ 'border-red-500': $v.form.activity.$error }"
                                                    @change="getTarget()"
                                                )
                                                    option(value="") Select Activity
                                                    option(v-for="activity in activityList" :value="activity.key") {{ activity.title }}
                                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                    <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.activity.$error")
                                                span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Select Activity Target
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                template(v-if="isTargetURL")
                                                    input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                        type="url"
                                                        placeholder="URL Action"
                                                        v-model="$v.form.activityTarget.$model"
                                                        :class="{ 'border-red-500': $v.form.activityTarget.$error }"
                                                    )
                                                template(v-else)
                                                    select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                        v-model="$v.form.activityTarget.$model"
                                                        :class="{ 'border-red-500': $v.form.activityTarget.$error }"
                                                    )
                                                        option(value="") Select Target
                                                        option(v-for="(item, index) in targetList" :key="item.index" :value="item.id") {{ item.title }}
                                                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                        <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.activityTarget.$error")
                                                span.info.text-xs This field is required

                        section.border-b.border-blue-100.mb-8.pb-12.pt-4
                            .container-small
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Banner Image
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .cover-voucher.w-64.h-64.bg-gray-200.rounded.mb-2
                                                img.w-full.h-full.object-cover.rounded(
                                                    v-if="coverImage"
                                                    :src="coverImage", alt="alt"
                                                )

                                            //- span.info.text-xs Please upload image with 400 x 400
                                            .block.mt-4
                                               UploadMedia(:inputMedia.sync="coverImage")
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.coverImage.$error")
                                                span.info.text-xs This field is required


                        section
                            .flex.items-center.justify-end
                                //-button.link(type='button') Reset
                                button.btn-primary-o.ml-3(type='button' @click="add(0)" v-if="!editing" :disabled="submitStatus === 'PENDING'") Draft
                                button.btn-primary.ml-3(type='button' @click="add(1)" v-if="!editing" :disabled="submitStatus === 'PENDING'") Publish
                                button.btn-primary-o.ml-3(type='button' @click="update(0)" v-if="editing" :disabled="submitStatus === 'PENDING'") Draft
                                button.btn-primary.ml-3(type='button' @click="update(1)" v-if="editing" :disabled="submitStatus === 'PENDING'") Publish
                            p.typo__p.text-right.mt-3.text-green-500(v-if="submitStatus === 'OK'") Thanks for your submission!
                            p.typo__p.text-right.mt-3.text-red-500(v-if="submitStatus === 'ERROR'") Please fill the form correctly.
                            p.typo__p.text-right.mt-3.text-blue-500(v-if="submitStatus === 'PENDING'") Sending...

</template>

<script>
import Breadcrumb from './../../../components/content/breadcrumb';
import UploadMedia from './../../../components/content/UploadMedia';
import Vue from "vue";
import router from "../../../router";
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'SettingsImagesCreate',
    components: {
        Breadcrumb,
        UploadMedia,
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Banner',
                    url: '/settings/images',
                },
                {
                    name: 'Create',
                    url: '/banner/create',
                },
            ],
            activityList: [
                {
                    title: 'URL',
                    key: 'url',
                },
                {
                    title: 'Voucher',
                    key: 'voucher',
                },
                {
                    title: 'News',
                    key: 'news',
                },
            ],
            targetList: [],
            form: {
                name: null,
                activity: '',
                activityTarget: '',
            },
            coverImage: 'https://i.imgur.com/9XwzHPA.png',
            editing: false,
            submitStatus: null,
            isTargetURL: false,
        }
    },
    validations: {
        form: {
            name: { required },
            activity: { required },
            activityTarget: { },
        },
        coverImage: { required },
    },

    methods: {
        load() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/banner/' + this.$route.params.id)
                .then((response) => {
                    var data = response.data.data
                    this.form = {
                        name: data.name,
                        activity: data.activity,
                        activityTarget: data.activity_target,
                        status: data.status,
                    }
                    this.coverImage = data.image ? data.image : 'https://i.imgur.com/9XwzHPA.png'
                    this.breadcrumb = [
                        {
                            name: 'Banner',
                            url: '/settings/images',
                        },
                        {
                            name: data.name,
                            url: '/banner/edit/'+data.id,
                        },
                    ]
                    this.getTarget()
                })
        },
        add (status) {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'

                var data = {
                    "group": "HOME",
                    "name": this.form.name,
                    "activity" : this.form.activity,
                    "activity_target": this.form.activityTarget.toString(),
                    "image": this.coverImage,
                    'status': status
                }

                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/banner', data)
                    .then(() => {
                        router.push({ name: 'SettingsImages'})

                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success Adding Banner!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })

            }
        },
        update (status) {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'

                var data = {
                    "name": this.form.name,
                    "activity" : this.form.activity,
                    "activity_target": this.form.activityTarget.toString(),
                    "image": this.coverImage,
                    'status': status
                }

                Vue.prototype.$http
                    .patch(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/banner/'+this.$route.params.id, data)
                    .then(() => {
                        router.push({ name: 'SettingsImages' })

                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success Updating Banner!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }
        },
        getTarget(){
            this.isTargetURL = false;
            switch (this.form.activity) {
                case 'url' :
                    this.isTargetURL = true;
                    break;
                case 'voucher':
                    Vue.prototype.$http
                        .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue?per_page=-1')
                        .then((response) => {
                            this.targetList = response.data.data.map((target) => {
                                target.title = target.catalogue_name
                                return target;
                            })
                        })
                    break;

                case 'news':
                    Vue.prototype.$http
                        .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/post?per_page=-1')
                        .then(response => {
                            this.targetList = response.data.data
                        })
                    break;
            }
        }
    },

    created() {
        if ("id" in this.$route.params) {
            this.load();
            this.editing = true;
        } else {
            // this
        }

    },
    

}
</script>

<style lang="sass" scoped>
    .field-checkbox
        position: relative
        cursor: pointer
        input
            width: 100%
            height: 100%
            position: absolute
            left: 0
            top: 0
            opacity: 0
            z-index: 3
            cursor: pointer
            &:checked
                +
                    .ui-checkbox
                        .bullet
                            border: 1px solid brown
                            svg
                                opacity: 1
                                stroke: brown
                        span
                            color: brown
        .ui-checkbox
            display: inline-flex
            align-items: center
            .bullet
                width: 24px
                height: 24px
                border-radius: 4px
                border: 1px solid #dddddd
                position: relative
                display: flex
                align-items: center
                justify-content: center
                transition: all 0.3s
                svg
                    opacity: 0
            span
</style>