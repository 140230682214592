<template lang="pug">
    //- voucher activity
    .card
        header.card__header.flex.items-center.justify-between.pb-4
            h2.font-bold Group Broadcast
            .header-cta
                router-link.btn-primary(:to="{ name: 'BroadcastGroupCreate' }") New Group
        .card__body
            .filter-content.mb-4
                SearchInput(:inputData.sync="searchInput" @search="load()")
                .inline-block.relative.w-64
                    select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                        v-model="statusFilter"
                        @change="load()"
                    )
                        option(value="") All Status
                        option(value="1") Publish
                        option(value="0") Draft
                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                        <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>

            .block
                .box-table
                    table.table.table-set.w-full
                        thead
                            tr
                                th No.
                                th Name
                                th Users
                                th Updated at
                                th Status
                        tbody
                            tr(v-for="(item, index) in groupBroadcast" :key="item.id")
                                td.w-8 {{ index + 1}}
                                td
                                    router-link.link(:to="{ name: 'BroadcastGroupEdit', params: { id: item.id } }") {{ item.name }}
                                td {{ item.member_count | moneyFilter }}
                                td 
                                    .text-xs {{ item.updated_at | moment('DD MMMM YYYY') }}
                                td
                                    .w-32
                                        template(v-if="item.status == 1")
                                            span.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Publish
                                        template(v-if="item.status == 0")
                                            span.px-2.py-1.bg-orange-300.text-orange-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Draft
                                td.action-2
                                    router-link(:to="{name:'BroadcastGroupEdit', params: {id: item.id}}").btn-default.btn-icon
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                    button.btn-default.btn-icon(@click="destroy(item.id)")
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                Pagination( :pagination="pagination" @paginate="load()" )
    //- end voucher activity
</template>

<script>
import Vue from "vue";
import Pagination from "../../../components/content/Pagination";
import SearchInput from "../../../components/content/SearchInput";

export default {
    name: 'VoucherActivity',
    components: {
        Pagination,
        SearchInput,
    },
    data() {
        return {
            groupBroadcast: [],
            statusFilter: '',
            searchInput: '',
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    methods: {
        load() {
            let params = '?q='+this.searchInput+'&page='+this.pagination.current_page;
            if (this.statusFilter != '') params += '&status='+this.statusFilter;
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/notification/group'+params)
                .then((response) => {
                    this.groupBroadcast = response.data.data
                    this.pagination = response.data.meta
                })
        },
        destroy (id) {
            if (confirm('Are you sure?')) {
                Vue.prototype.$http
                    .delete(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/notification/group/' + id)
                    .then(() => {
                        this.load()

                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success Deleting Broadcast Group!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }

        },
    },
    mounted() {
        this.load();
    },
}
</script>

<style lang="sass" scoped>
    .table-set
        tr
            th,
            td
                padding: 12px 16px
                border-bottom: 1px solid #f1f7fb
                transition: all 0.3s ease-in-out
            &:hover
                td
                    background-color: rgba(0, 0, 0, 0.0250 )
            th
                text-transform: uppercase
                text-align: left
                font-size: 11px
                opacity: 0.7
</style>