<template lang="pug">
    .tab-employee
        .mb-8.flex.items-center.justify-between
            h2.font-bold List Connection
        .box-table
            table.table.table-set.w-full
                thead
                    tr
                        th  No.
                        th  POS Name
                        th  Key
                        th  Secret
                tbody
                    tr(v-for="(list, index) in dataList" :key="list.index")
                        td.w-8 {{ index + 1 }}.
                        td {{ list.pos_vendor }}
                        td {{ list.key }}
                        td {{ list.secret }}

            Pagination( :pagination="pagination" @paginate="load()" )
</template>

<script>
import Pagination from "../../components/content/Pagination";
import Vue from "vue";

export default {
    name: 'IntegrationClient',
    props: {
        merchantData: Object
    },
    watch: {
        merchantData: function() { // watch it
            this.load()
        }
    },
    components: {
        Pagination,
    },
    data(){
        return {
            dataList: null,
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    computed: {
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
        urlPrefix(){
            return this.userData.principal_staff === 1 ? 'master':'merchant';
        }
    },
    methods: {
        load() {
            let endPoint = '/api/v1/management/memberships/master/merchant/'+ this.$route.params.id +'/integration-client';
            let params = '?page=' + this.pagination.current_page;
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + endPoint + params)
                .then(response => {
                    this.dataList = response.data.data
                    this.pagination = response.data.meta
                })
        },
    },
    mounted() {
        this.load();
    },
}
</script>

<style lang="sass" scoped>
small
    line-height: 0
    position: relative
    top: -4px

.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>
