<template lang="pug">
    .tab-employee
        .mb-8.flex.items-center.justify-between
            h2.font-bold List Employee
            router-link.btn-primary.btn-small(:to="{ name: (urlPrefix == 'master' ? 'MerchantEmployeeCreate':'MerchantSelfEmployeeCreate'), params : { merchantId: $route.params.id } }") Add Employee
        .box-table
            table.table.table-set.w-full
                thead
                    tr
                        th  No.
                        th  Name
                        th  Username
                        th  Role
                        th  Manager ID
                        th
                tbody
                    tr(v-for="(list, index) in employeeList" :key="list.index")
                        td.w-8 {{ index + 1 }}.
                        td
                            router-link.link.font-bold(:to="{ name: (urlPrefix == 'master' ? 'MerchantEmployeeDetail': 'MerchantSelfEmployeeDetail'), params: { id: list.id, merchantId: $route.params.id } }")
                                button.link.font-bold
                                    .avatar.flex.items-center
                                        figure.avatar.w-8.h-8.rounded-full.overflow-hidden.mr-4
                                            img.w-full.h-full.object-cover(:src="list.officer_image ? list.officer_image : 'https://i.imgur.com/9XwzHPA.png'")
                                        span {{ list.officer_name }}
                        td {{ list.master_account.username }}
                        td
                            template(v-if="list.master_account.role == 2")
                                span.px-2.py-1.bg-success-200.text-success-700.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Manager
                            template(v-else-if="list.status == 1")
                                span.px-2.py-1.font-bold.text-xs.w-24.block.text-center.uppercase -
                        td
                            template(v-if="list.master_account.role == 2")
                                span.w-24.block.text-center {{ list.officer_id}}
                            template(v-else)
                                span.px-2.py-1.font-bold.text-xs.w-24.block.text-center.uppercase -

                        td.action-2
                            router-link(:to="{name: (urlPrefix == 'master' ? 'MerchantEmployeeEdit':'MerchantSelfEmployeeEdit'), params: {id: list.id, merchantId: $route.params.id}}").btn-default.btn-icon
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                            button.btn-default.btn-icon(@click="destroy(list.id)")
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>

            Pagination( :pagination="pagination" @paginate="load()" )
</template>

<script>
import Pagination from './../../../components/content/Pagination';
import Vue from "vue";

export default {
    name: 'EmployeePage',
    props: {
        merchantData: Object
    },
    watch: {
        merchantData: function() { // watch it
            this.load()
        }
    },
    components: {
        Pagination,
    },
    data(){
        return {
            employeeList: null,
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    computed: {
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
        urlPrefix(){
            return this.userData.principal_staff === 1 ? 'master':'merchant';
        }
    },
    methods: {
        load() {
            if (this.merchantData.primary_outlet.id) {
                let endPoint = '/api/v1/management/memberships/master/merchant/'+ this.$route.params.id +'/outlet/'+ this.merchantData.primary_outlet.id +'/officer'
                let params = '?page=' + this.pagination.current_page;
                if(this.urlPrefix == 'merchant') endPoint = '/api/v1/management/memberships/merchant/outlet/'+this.merchantData.primary_outlet.valueOf('id').id+'/officer'
                // console.log('endpoint', endPoint)
                // console.log('outlet id', this.merchantData.primary_outlet.valueOf('id').id)
                Vue.prototype.$http
                    .get(process.env.VUE_APP_API_HOST + endPoint + params)
                    .then(response => {
                        this.employeeList = response.data.data
                        this.pagination = response.data.meta
                    })
            } else {
                // console.log(this.merchantData)
            }
        },
        destroy(id){
            if (confirm('Are you sure?')) {
                let endPoint = '/api/v1/management/memberships/master/merchant/'+this.$route.params.id+'/outlet/'+ this.merchantData.primary_outlet.valueOf('id').id +'/officer/'+id
                if (this.urlPrefix == 'merchant') endPoint = '/api/v1/management/memberships/merchant/outlet/'+this.merchantData.primary_outlet.valueOf('id').id +'/officer/'+id

                Vue.prototype.$http
                    .delete(process.env.VUE_APP_API_HOST + endPoint)
                    .then(() => {
                        this.load()

                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success Deleting Employee!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }

        }
    },
    mounted() {
        this.load();
    },
}
</script>

<style lang="sass" scoped>
small
    line-height: 0
    position: relative
    top: -4px

.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>
