<template lang="pug">
    #notification-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4           
            //- list
            .card.card--shadow.bg-white.border-gray-200.p-4.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                    h2.font-bold All Notification
                    .header-cta.flex.items-center
                        button.link
                            span Mark as read
                .card__body
                    .mb-6
                        .inline-block.relative.w-64
                            select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight
                                option All Catgories
                                option Promo
                                option Information
                                option Global
                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    .block
                        .box-notif
                            .list-notif(v-for="list in notif" :key="list.id")
                                router-link.flex.w-full.items-center(
                                    :to="list.url"
                                    v-bind:class="{'new-notif' : list.status === 'new' }"
                                    )
                                    .flex-grow
                                        span.block {{ list.title }}
                                        small.text-xs {{ list.date }}
                                    .status(v-if="list.status === 'new'")
                                        .w-3.h-3.rounded-full.bg-red-500
                                    .status(v-else)


</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';

export default {
    name: 'NotificationPage',
    components: {
        Breadcrumb,

    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'notification',
                    url: '/notification',
                },
            ],
            notif: [
                {
                    url: '#',
                    title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                    date: '10 april 2020',
                    status: 'new',
                },
                {
                    url: '#',
                    title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                    date: '10 april 2020',
                    status: 'new',
                },
                {
                    url: '#',
                    title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                    date: '10 april 2020',
                    status: 'new',
                },
                {
                    url: '#',
                    title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                    date: '10 april 2020',
                    status: 'new',
                },
                {
                    url: '#',
                    title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                    date: '10 april 2020',
                    status: 'new',
                },
                {
                    url: '#',
                    title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                    date: '10 april 2020',
                    status: 'new',
                },
                {
                    url: '#',
                    title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                    date: '10 april 2020',
                    status: 'read',
                },
                {
                    url: '#',
                    title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                    date: '10 april 2020',
                    status: 'new',
                },
                {
                    url: '#',
                    title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                    date: '10 april 2020',
                    status: 'read',
                },
            ],
        }
    },
    

}
</script>

<style lang="sass" scoped>
    .list-notif
        a
            padding: 16px 16px
            transition: all 0.3s ease-in-out
            border-radius: 8px
            &:hover
                background-color: rgba(0, 0, 0,  0.024)
            &.new-notif
                span
                    font-weight: bold
</style>