<template lang="pug">
    #settings-box
        vue-tabs
            v-tab(:title="role.name" v-for="role in roleList")
                RoleDetail(:role="role")

</template>

<script>
import {VueTabs, VTab} from 'vue-nav-tabs'
import RoleDetail from './RoleDetail'
import Vue from "vue";

export default {
    name: 'SettingsRole',
    components: {
        VueTabs,
        VTab,
        RoleDetail,
    },
    data() {
        return {
            roleList: [],
        }
    },
    methods: {
        load() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/admin/role')
                .then(response => {
                    this.roleList = response.data.data
                })
        }
    },
    mounted() {
        this.load()
    }
}
</script>