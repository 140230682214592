<template lang="pug">
    .auth-page
        .auth-page__header.mb-8
            h1 Welcome back!
            p Please log into your account to start your activity
        .auth-page__body
            form(action="" method="method" @submit.prevent="onSubmit")
                .field.mb-4
                    label.text-sm.font-bold.mb-3.block(
                        for="username"
                    ) Username
                    input.border-main.border.rounded.w-full.py-2.px-3(
                        type="text"
                        placeholder="Write your email or username here"
                        id="username"
                        v-model="username"
                    )
                .field.mb-4
                    label.text-sm.font-bold.mb-3.block(
                        for="password"
                    ) Password
                    input.border-main.border.rounded.w-full.py-2.px-3(
                        placeholder="Write your password"
                        type="password"
                        id="password"
                        v-model="password"
                    )
                .field.flex.items-center.justify-between
                    button.btn-primary(type='submit').font-bold.uppercase Login


</template>

<script>
export default {
    name:'LoginPage',
    data () {
        return {
            username: '',
            password: ''
        }
    },
    methods: {
        onSubmit () {
            const formData = {
                username: this.username,
                password: this.password,
            }
            this.$store.dispatch('login', {username: formData.username, password: formData.password})

        }
    }
}
</script>

<style lang="sass" scoped>
    .border-main
        border-color: #FFEFD8
        background-color: darken(#231F20,5%)
        &::placeholder
            color: #FFEFD8
            opacity: 0.4

</style>