<template lang="pug">
    .tab
        .tab-header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-4
            h2.font-bold Report Vouchers By Membership
            ExportButton(export-id="voucher-data-export-id" export-end-point="/api/v1/management/memberships/master/report/voucher/data/export")

        .tab-body
            //- section.filter-section.flex.items-center.mb-8
                .inline-block.relative.ui-daterange
                    .block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight
                        date-range-picker(
                            ref="picker"
                            opens="center"
                            :locale-data="locale"
                            :maxDate="maxDate"
                            v-model="dateRange"
                            appendToBody=true,
                            @update="updateFilter"
                        )
                            template(v-slot:input="picker") {{ dateRange.startDate }} - {{ dateRange.endDate }}
                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-5 w-5"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>


                button.btn-primary-o.btn-small.ml-3 Filter
            //- end filter

            .grid.grid-cols-6.gap-x-1.mb-8

                .bg-gray-900.text-gray-100.p-4.rounded
                    span.text-xs.opacity-75.uppercase Total Vouchers
                    .flex.items-center.justify-between.w-full
                        h2.text-3xl {{ voucherData.total_voucher | moneyFilter }}

                .bg-gray-900.text-gray-100.p-4.rounded
                    span.text-xs.opacity-75.uppercase Bronze
                    .flex.items-center.justify-between.w-full
                        h2.text-3xl {{ voucherData.total_bronze_member | moneyFilter }}
                        .text-sm {{ percentage.bronze }}%

                .bg-gray-900.text-gray-100.p-4.rounded
                    span.text-xs.opacity-75.uppercase Silver
                    .flex.items-center.justify-between.w-full
                        h2.text-3xl {{ voucherData.total_silver_member | moneyFilter }}
                        .text-sm {{ percentage.silver }}%

                .bg-gray-900.text-gray-100.p-4.rounded
                    span.text-xs.opacity-75.uppercase Gold
                    .flex.items-center.justify-between.w-full
                        h2.text-3xl {{ voucherData.total_gold_member | moneyFilter }}
                        .text-sm {{ percentage.gold }}%

                .bg-gray-900.text-gray-100.p-4.rounded
                    span.text-xs.opacity-75.uppercase Platinum
                    .flex.items-center.justify-between.w-full
                        h2.text-3xl {{ voucherData.total_platinum_member | moneyFilter }}
                        .text-sm {{ percentage.platinum }}%

                .bg-gray-900.text-gray-100.p-4.rounded
                    span.text-xs.opacity-75.uppercase Uncategorized
                    .flex.items-center.justify-between.w-full
                        h2.text-3xl {{ voucherData.uncategorized_voucher | moneyFilter }}
                        .text-sm {{ percentage.uncategorized }}%

            section.mb-8
                .grid.grid-cols-8.gap-x-4.mb-4
                    .col-span-4
                        highcharts(
                            :options="mainchart"
                        )

                    .col-span-4
                        highcharts(
                            :options="piechart"
                        )
            section.mb-4
                .mb-4
                    h3.font-bold Vouchers By Membership
                .box-table
                    table.table.table-set.w-full
                        thead
                            tr
                                th Categories
                                th Total Vouchers
                        tbody
                            tr
                                td Bronze
                                td {{ voucherData.total_bronze_member | moneyFilter }}
                            tr
                                td Silver
                                td {{ voucherData.total_silver_member | moneyFilter }}
                            tr
                                td Gold
                                td {{ voucherData.total_gold_member | moneyFilter }}
                            tr
                                td Platinum
                                td {{ voucherData.total_platinum_member | moneyFilter }}
                            tr
                                td Uncategorized
                                td {{ voucherData.uncategorized_voucher | moneyFilter }}
</template>

<script>
import {Chart} from 'highcharts-vue'
import Vue from "vue";
import DateRangePicker from "vue2-daterange-picker";
import ExportButton from "@/components/content/ExportButton";

export default {
    name: 'MembershipActivity',
    components: {
        highcharts: Chart,
        DateRangePicker,
        ExportButton
    },
    data() {
        return {
            mainchart: {
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: [
                        'Bronze',
                        'Silver',
                        'Gold',
                        'Platinum',
                        'Uncategorized',
                    ]
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                tooltip: {
                    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    shared: true
                },

                series: [
                    {
                        name: 'Vouchers',
                        data: [],
                    },
                ]
            },
            piechart: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: ''
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                series: [{
                    name: 'Vouchers by Membership',
                    colorByPoint: true,
                    data: [{
                        name: 'Bronze',
                        y: 50,
                    }, {
                        name: 'Silver',
                        y: 25
                    }, {
                        name: 'Gold',
                        y: 12.5
                    }, {
                        name: 'Platinum',
                        y: 12.5
                    }, {
                        name: 'Uncategorized',
                        y: 12.5
                    }]
                }]
            },
            voucherData: null,
            percentage: {
                bronze: 0,
                silver: 0,
                gold: 0,
                platinum: 0,
                uncategorized: 0,
            }
        }
    },
    methods : {
        load() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/voucher/membership')
                .then((response) => {
                    this.voucherData = response.data.data
                    this.piechart.series[0].data[0].y = this.voucherData.total_bronze_member
                    this.piechart.series[0].data[1].y = this.voucherData.total_silver_member
                    this.piechart.series[0].data[2].y = this.voucherData.total_gold_member
                    this.piechart.series[0].data[3].y = this.voucherData.total_platinum_member
                    this.piechart.series[0].data[4].y = this.voucherData.uncategorized_voucher
                    this.mainchart.series[0].data = Object.values(this.voucherData).slice(0, -1)

                    this.percentage.bronze = (this.voucherData.total_bronze_member / this.voucherData.total_voucher * 100).toFixed(1);
                    this.percentage.silver = (this.voucherData.total_silver_member / this.voucherData.total_voucher * 100).toFixed(1);
                    this.percentage.gold = (this.voucherData.total_gold_member / this.voucherData.total_voucher * 100).toFixed(1);
                    this.percentage.platinum = (this.voucherData.total_platinum_member / this.voucherData.total_voucher * 100).toFixed(1);
                    this.percentage.uncategorized = (this.voucherData.uncategorized_voucher / this.voucherData.total_voucher * 100).toFixed(1);
                })
        },
        exportReport(){
            let exportId = localStorage.getItem('voucher-data-export-id');
            if (exportId) {
                Vue.prototype.$http
                    .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/check?export_id='+exportId)
                    .then((response) => {
                        if ( response.data.data.name ) {
                            window.open(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/'+response.data.data.name, '_self')
                            localStorage.removeItem('voucher-data-export-id');
                        } else {
                            alert('Your file is still on progress... come back later to check your file!');
                        }
                    })
            } else {
                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/voucher/data/export')
                    .then((response) => {
                        localStorage.setItem('voucher-data-export-id', response.data.data.id);
                        alert('Exporting your file... come back later to check your file!');
                    })
            }
        }
    },
    created() {
        this.load()
    },

}
</script>

<style lang="sass" scoped>
.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>
