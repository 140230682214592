<template lang="pug">
    #news-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .w-600.m-auto
                .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                    header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                        h2.font-bold Detail What's On
                        .header-cta.flex.items-center
                            router-link(to="/whats-on").link.font-sx
                                span Back
                    .card__body
                        form(action="" method="method")
                            section.border-b.border-blue-100.mb-8
                                    h2.text-2xl.block.mb-2.font-bold {{ newsData.title }}
                                    small.mb-6.block {{ newsData.name }}
                                    .flex.items-center.text-sm.mb-6
                                        span {{ newsData.created_at | moment('DD MMMM YYYY') }}
                                        //- span.inline-flex.px-2 •
                                        //- span Category
                                    img.w-full.mb-6(:src="newsData.image ? newsData.image : ''", alt="alt" v-if="newsData.image")
                                    article.html-tag(v-html="newsData.content")





</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import Vue from "vue";

export default {
    name: 'NewsDetail',
    components: {
        Breadcrumb,
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Whats On',
                    url: '/whats-on',
                },
                {
                    name: 'Mengintip Hotel Episode Serpong yang Sarat Budaya Banten',
                    url: '/create',
                },
            ],
            newsData: null,
        }
    },
    mounted() {
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/post/' + this.$route.params.id)
            .then((response) => {
                this.newsData = response.data.data
                this.breadcrumb = [
                    {
                        name: 'Whats on',
                        url: '/whats-on',
                    },
                    {
                        name: this.newsData.title,
                        url: '/whats-on/detail/'+this.newsData.id,
                    },
                ]
            })
    },

}
</script>

<style lang="sass" scoped>
    .w-600
        max-width: 600px

    .html-tag
        > *
            padding-bottom: 1em
        ul, ol
            padding-left: 16px
            li
                padding-bottom: 0.25em
        ul
            list-style: disc
        ol
            list-style: decimal
        img, iframe, video
            max-width: 100%
</style>