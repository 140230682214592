<template lang="pug">
    #static-page
        .content-area.px-4
            .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                    h2.font-bold {{ editing ? 'Edit' : 'New' }} {{ $route.params.slug | capitalize }} Category
                    .header-cta.flex.items-center
                        router-link(:to="{name : 'SettingsCategoriesDetail', params: { slug: this.$route.params.slug }}").link.font-sx
                            span Back
                .card__body
                    form(action="" method="method" @submit.prevent="onSubmit(0)")
                        section.border-b.border-blue-100.mb-8.pb-8
                            .container-small
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Title
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: Uncategorized"
                                                    v-model="categoryName"
                                                )
                                                //-.flex.items-center.justify-between.mt-2
                                                    span.info.text-xs message
                                                    span.info.text-xs 0/125
                                .field(v-if="this.$route.params.slug != 'article' && this.$route.params.slug != 'service'")
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Cover
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .cover-voucher.w-64.h-64.bg-gray-200.rounded.mb-2
                                                img.w-full.h-full.object-cover.rounded(
                                                    v-if="coverImage"
                                                    :src="coverImage ? coverImage : 'https://i.imgur.com/9XwzHPA.png'", alt="alt"
                                                )
                                            span.info.text-xs Please upload image with 400 x 400

                                            .block.mt-4
                                                UploadMedia(:inputMedia.sync="coverImage")

                        section
                            .flex.items-center.justify-end
                                //-button.link(type='submit') Reset
                                button.btn-primary.ml-3(type='button' @click="add()" v-if="!editing") Publish
                                button.btn-primary.ml-3(type='button' @click="update()" v-if="editing") Update


</template>

<script>
import UploadMedia from './../../../components/content/UploadMedia';
import { VueEditor } from "vue2-editor";
import Vue from "vue";
import router from "../../../router";

export default {
    name: 'SettingsCategoryCreate',
    components: {
        UploadMedia,
        VueEditor
    },
    data() {
        return {
            coverImage: 'https://i.imgur.com/9XwzHPA.png',
            categoryName: '',
            editing: false,
        }
    },
    methods: {
        load(){
            let endpoint = '/api/v1/api-manage/lite-cms/category/'+ this.$route.params.id;
            switch (this.$route.params.slug) {
                case 'voucher':
                    endpoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue/category/'+ this.$route.params.id;
                    break;
                case 'partner':
                    endpoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/category/'+ this.$route.params.id;
                    break;
                case 'article':
                    endpoint = process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/category/'+ this.$route.params.id
                    break;
                case 'service':
                    endpoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/service/amenity/'+ this.$route.params.id
                    break;
            }
            Vue.prototype.$http
                .get(endpoint)
                .then(response => {
                    this.coverImage = response.data.data.icon
                    this.categoryName = response.data.data.name
                })
        },
        add () {
            let data = {
                'name': this.categoryName,
                'description': this.categoryName,
                'title': this.categoryName,
                'icon': this.coverImage,
            };
            let endpoint = '/api/v1/api-manage/lite-cms/category';
            switch (this.$route.params.slug) {
                case 'voucher':
                    endpoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue/category';
                    break;
                case 'partner':
                    endpoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/category';
                    break;
                case 'article':
                    endpoint = process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/category'
                    break;
                case 'service':
                    endpoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/service/amenity'
                    break;
            }

            Vue.prototype.$http
                .post(endpoint, data)
                .then(() => {
                    router.push({ name: 'SettingsCategoriesDetail', params: { slug: this.$route.params.slug} })
                })

        },
        update () {
            let data = {
                'name': this.categoryName,
                'description': this.categoryName,
                'title': this.categoryName,
                'slug': this.slugify(this.categoryName),
                'icon': this.coverImage,
            };

            let endpoint = '/api/v1/api-manage/lite-cms/category';
            switch (this.$route.params.slug) {
                case 'voucher':
                    endpoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue/category/'+ this.$route.params.id;
                    break;
                case 'partner':
                    endpoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/category/'+ this.$route.params.id;
                    break;
                case 'article':
                    endpoint = process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/category/'+ this.$route.params.id
                    break;
                case 'service':
                    endpoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/service/amenity/'+ this.$route.params.id
                    break;
            }

            Vue.prototype.$http
                .patch(endpoint, data)
                .then(() => {
                    router.push({ name: 'SettingsCategoriesDetail', params: { slug: this.$route.params.slug} })
                })

        },
        slugify: function(text){
            return text.toString().toLowerCase()
                .replace(/\s+/g, '-')           // Replace spaces with -
                // .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                // .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                .replace(/^-+/, '')             // Trim - from start of text
                .replace(/-+$/, '');            // Trim - from end of text
        }
    },
    created() {
        if ("id" in this.$route.params) {
            this.load();
            this.editing = true;
        } else {
            // this
        }
    },

}
</script>

<style lang="sass" scoped>
    .input-file
        width: 100%
        height: 100%
        left: 0
        top: 0
        opacity: 0
        position: absolute
        z-index: 1
</style>