<template lang="pug">
    #user-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            //- highlight customers
            .grid.grid-cols-5.gap-x-8.mb-4

                //- user info
                .col-span-3
                    vue-tabs
                        v-tab(title="Voucher")
                            //- voucher activity
                            VoucherActivity(v-bind="customerData")
                            //- end voucher activity
                        v-tab(title="Point")
                            //- point activity
                            PoinActivity(v-bind="customerData")
                            //- end point activity

                        v-tab(title="Login Activity")
                            //- Login activity
                            LoginActivity(v-bind="customerData")
                            //- end Login activity

                        v-tab(title="Membership")
                            //- Membership activity
                            MembershipActivity(v-bind="customerData")
                            //- end Membership activity

                        v-tab(title="Payment")
                            //- Payment activity
                            PaymentActivity(v-bind="customerData")
                            //- end Payment activity

                //- end user info

                //- user sidebar
                .col-span-2
                    .rounded.bg-orange-200.overflow-hidden
                        .banner-membership.w-full.h-40.bg-white
                            img(src="https://i.imgur.com/dL0n397.png")
                        .info-user.flex.flex-col
                            .info-user__avatar.w-32.h-32.rounded-full.overflow-hidden.m-auto
                                img.w-full.h-full.object-cover(:src="customerData.avatar ? customerData.avatar : require('./../../assets/unknown-user.png')", alt="alt")
                            .info-user__account.text-black.text-center.mb-4
                                strong.text-xl.opacity-50.block.mb-2 {{ customerData.customer_name }}
                                .flex.justify-center.mb-2
                                    .px-2.py-1.rounded.font-bold.text-xs.text-center.uppercase.bg-green-500.text-green-200(v-if="customerData.status == 1") Active
                                    .px-2.py-1.rounded.font-bold.text-xs.text-center.uppercase.bg-blue-500.text-blue-200(v-if="customerData.status == 0") Registration Pending
                                    .px-2.py-1.rounded.font-bold.text-xs.text-center.uppercase.bg-red-500.text-red-200(v-if="customerData.status == 2") Suspended
                                    .px-2.py-1.rounded.font-bold.text-xs.text-center.uppercase.bg-red-500.text-red-200(v-if="customerData.status == 3") Inactive
                                MembershipTypeLabel(:membershipType="customerData.membership_type.name + ' MEMBERSHIP'" width="100" display="inline-block")
                                p.text-orange-900.mt-3(v-if="customerData.membership_type.active == false") Rp {{ customerData.transaction_accumulation | moneyFilter }} / Rp {{ membershipDefaultType.upgrade_when_reach | moneyFilter }}
                                .block(v-if="userData.role == 1")
                                    router-link.btn.btn-primary-o.border-500.text-500.btn-small.mt-3(:to="{ name: 'CustomerEdit', params: { id: customerData.id }}") Edit
                            //- intro account
                            .p-8.text-orange-900
                                .flex.items-center.mb-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                    .block {{ customerData.email }}
                                .flex.items-center.mb-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                    .block {{ customerData.phone || '-' }}
                                .flex.items-center.mb-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                    .block {{ customerData.gender || 'not set' }}
                                //- .flex.items-center.mb-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                                    .block Bandung
                                .flex.items-center
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-gift"><polyline points="20 12 20 22 4 22 4 12"></polyline><rect x="2" y="7" width="20" height="5"></rect><line x1="12" y1="22" x2="12" y2="7"></line><path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path><path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path></svg>
                                    .block {{ customerData.date_of_birth | moment('DD MMMM YYYY')  }} ({{ customerData.date_of_birth | moment('from', true) }})
                                .flex.items-start.border-t.border-dashed.pt-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                    .block
                                        p.block.mb-2 Reset PIN Customer
                                        button.btn.btn-danger-o.border-red-500.text-red-500.btn-small(@click="resetPIN()") Reset PIN
                                .flex.items-start.border-t.border-dashed.pt-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                    .block
                                        p.block.mb-2 Force Logout Customer
                                        button.btn.btn-danger-o.border-red-500.text-red-500.btn-small(@click="forceLogout()") Force Logout

</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import MembershipTypeLabel from './../../components/content/MembershipTypeLabel';
import PoinActivity from './PoinActivity'
import VoucherActivity from './VoucherActivity'
import LoginActivity from './LoginActivity'
import MembershipActivity from './MembershipActivity'
import PaymentActivity from './PaymentActivity'
import {VueTabs, VTab} from 'vue-nav-tabs'
import Vue from "vue";

export default{
    name: 'UserPage',
    components: {
        Breadcrumb,
        PoinActivity,
        VoucherActivity,
        LoginActivity,
        MembershipActivity,
        PaymentActivity,
        VueTabs,
        VTab,
        MembershipTypeLabel
    },
    // classObject: function () {
    //     return {
    //         active: this.isActive && !this.error,
    //         'text-danger': this.error && this.error.type === 'fatal'
    //     }
    // },
    filter: {
        membershipColor: function(type) {
            switch (type) {
                case 'SILVER':
                    this.membershipColorClass = 'gray';
                    break;
                case 'GOLD':
                    this.membershipColorClass = 'orange';
                    break;
                case 'PLATINUM':
                    this.membershipColorClass = 'blue';
                    break;
                default:
                    this.membershipColorClass = 'gray';
                    break;
            }
        }
    },
    computed: {
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
    },
    data() {
        return {
            breadcrumb: null,
            customerData: null,
            point: null,
            membershipDefaultType: null,
            membershipColorClass: 'gray',
            showModal: true,
        }
    },
    created() {
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/type/default')
            .then((response) => {
                this.membershipDefaultType = response.data.data
            })
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/' + this.$route.params.id)
            .then((response) => {
                this.customerData = response.data.data
                // if (this.customerData.membership_type.active === false) {
                //     this.customerData.membership_type.name = "Non Active"
                // }
                this.breadcrumb = [
                    {
                        name: 'Customers',
                        url: '/customers',
                    },
                    {
                        name: this.customerData.customer_name,
                        url: '/customers/user/'+this.customerData.id,
                    },
                ]
                this.point = {
                    totalPointActive: this.customerData.point_active,
                    totalPointWillExpired: this.customerData.point_expired,
                    expiredDate: this.customerData.updated_at,
                }
            })
    },
    methods: {
        resetPIN(){
            if(confirm('Are you sure?')) {
                var data = {
                    "customer_id": this.customerData.id
                }
                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/reset/pin', data)
                    .then(() => {
                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success reseting PIN customer!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }
        },
        forceLogout(){
            if(confirm('Are you sure?')) {
                Vue.prototype.$http
                    .delete(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/logout/'+this.customerData.id)
                    .then(() => {
                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success force logout customer!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }
        }
    }
}
</script>

<style lang="sass" scoped>
    .overlay-popup
        position: fixed
        left: 0
        top: 0
        bottom: 0
        right: 0
        width: 100%
        height: 100%
        overflow: auto
        &__container
            .popup
            background-color: #ffffff
            max-width: 600px
            margin: 2rem auto
            &__header
                display: flex
                align-items: center
                justify-content: space-between
    .info-user
        transform: translateY(-24px)
</style>