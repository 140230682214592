<template lang="pug">
    #services-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                    h2.font-bold {{ editing ? 'Edit' : 'New' }} Service
                    .header-cta.flex.items-center
                        router-link(to="/services").link.font-sx
                            span Back
                .card__body
                    form(action="" method="method" @submit.prevent="onSubmit(0)")
                        section.border-b.border-blue-100.mb-8.pb-8
                            .container-small
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Name
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: Nikmati akrhir tahun di JHL Serpong"
                                                    v-model="form.name"
                                                )
                                                //-.flex.items-center.justify-between.mt-2
                                                    span.info.text-xs message
                                                    span.info.text-xs 0/125

                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Services Availability
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .select-services.relative(v-for="optionName in servicesOption")
                                                input(type="checkbox" :value="optionName.id ? optionName.id : optionName" v-model="serviceOptionInput")
                                                .select-services__style
                                                    .list-service.flex.items-center.p-1.rounded.shadow
                                                        .icon.w-8.h-8.flex.items-center.justify-center.mr-2
                                                            img.rounded(src="https://i.imgur.com/9XwzHPA.png")
                                                        span {{optionName.name ? optionName.name : optionName}}

                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Cover
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .cover-voucher.w-64.h-64.bg-gray-200.rounded.mb-2
                                                img.w-full.h-full.object-cover.rounded(
                                                    v-if="coverImage"
                                                    :src="coverImage ? coverImage : 'https://i.imgur.com/9XwzHPA.png'", alt="alt"
                                                )
                                            span.info.text-xs Please upload image with 400 x 400

                                            .block.mt-4
                                                UploadMedia(:inputMedia.sync="coverImage")

                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Address
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: Jl. Gading Serpong Boulevard, Curug Sangereng"
                                                    v-model="form.address"
                                                )
                                            //-.flex.items-center.justify-between.mt-2
                                                span.info.text-xs message

                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Discover Link
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="https://www.jhlcollections.com/en/jsi-resorts/hotel/jsi-resort-megamendung"
                                                    v-model="form.discover_link"
                                                )
                                            //-.flex.items-center.justify-between.mt-2
                                                span.info.text-xs message

                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right
                                            label.block.h-16.py-2 Description
                                                small.text-red-500(style="padding-left:4px") *
                                        .block(class="w-3/4").ml-8
                                            .relative.w-full.mr-4
                                                vueEditor(
                                                    v-model="form.description"
                                                )
                                                //-.flex.items-center.justify-between.mt-2
                                                    span.info.text-xs message

                        section
                            .flex.items-center.justify-end
                                //-button.link(type='button') Reset
                                //-button.btn-primary-o.ml-3(type='button' @click="add(0)" v-if="!editing") Draft
                                button.btn-primary.ml-3(type='button' @click="add()" v-if="!editing") Publish
                                //-button.btn-primary-o.ml-3(type='button' @click="update(0)" v-if="editing") Draft
                                button.btn-primary.ml-3(type='button' @click="update()" v-if="editing") Publish
                            p.typo__p.text-right.mt-3.text-green-500(v-if="submitStatus === 'OK'") Thanks for your submission!
                            p.typo__p.text-right.mt-3.text-red-500(v-if="submitStatus === 'ERROR'") Please fill the form correctly.
                            p.typo__p.text-right.mt-3.text-blue-500(v-if="submitStatus === 'PENDING'") Sending...

</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import UploadMedia from './../../components/content/UploadMedia';
import Vue from "vue";
import router from "../../router";
import {VueEditor} from "vue2-editor";

export default {
    name: 'ServiceCreate',
    components: {
        Breadcrumb,
        UploadMedia,
        VueEditor,
    },
    data() {
        return {
            coverVoucher: 'https://i.imgur.com/9XwzHPA.png',
            breadcrumb: [
                {
                    name: 'Services',
                    url: '/services',
                },
                {
                    name: 'Create',
                    url: '/services/create',
                },
            ],
            form: {
                name: null,
                address: "",
                email: "",
                phone: "",
            },
            coverImage: 'https://i.imgur.com/9XwzHPA.png',
            serviceOptionInput: [],
            originalServiceOption: [],
            editing: false,
            servicesOption: null,
            submitStatus: null,

        }
    },

    methods: {
        load() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/service/partner/' + this.$route.params.id)
                .then((response) => {
                    let data = response.data.data
                    this.form = {
                        name: data.name,
                        address: data.address,
                        description: data.description,
                        discover_link: data.discover_link,
                    }
                    this.coverImage = data.cover_image ? data.cover_image : 'https://i.imgur.com/9XwzHPA.png'
                    this.originalServiceOption = data.service_type;
                    this.serviceOptionInput = data.service_type.map((type) => {
                        if (type.status == 1) {
                            return type.name;
                        }
                    })

                    this.breadcrumb = [
                        {
                            name: 'Services',
                            url: '/services',
                        },
                        {
                            name: data.name,
                            url: '/services/edit/'+data.id,
                        },
                    ]
                })
        },
        add () {
            this.submitStatus = 'PENDING'
            let serviceType = this.originalServiceOption.map((type) => {
                if (this.serviceOptionInput.includes(type.name)) {
                    type.status = 1 ;
                } else {
                    type.status = 3;
                }
                return type
            })
            var data = {
                'name': this.form.name,
                'address': this.form.address,
                'email': this.form.email,
                'phone': this.form.phone,
                'cover_image': this.coverImage,
                'service_type': serviceType,
                'description': this.form.description,
                'discover_link': this.form.discover_link,
            }

            Vue.prototype.$http
                .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/service/partner', data)
                .then((response) => {
                    router.push({ name: 'ServiceDetail', params: { id: response.data.data.id } })

                    this.$store
                        .commit('ADD_NOTIFICATION', {
                            title: 'Success!',
                            text: 'Success Adding Service!',
                            type: 'success',
                            timeout: false,
                            delay: 0
                        });
                })
            // router.push({ name: 'ServiceDetail', params: { id: response.data.data.id } })
        },
        update (serviceId = this.$route.params.id) {
            this.submitStatus = 'PENDING'
            let serviceType = this.originalServiceOption.map((type, key) => {
                if (this.serviceOptionInput.includes(type.name)) {
                    this.originalServiceOption[key].status = 1;
                    type.status = 1 ;
                } else {
                    this.originalServiceOption[key].status = 3;
                    type.status = 3;
                }
                return type
            })

            let data = {
                'name': this.form.name,
                'address': this.form.address,
                'email': this.form.email,
                'phone': this.form.phone,
                'cover_image': this.coverImage,
                'service_type': serviceType,
                'description': this.form.description,
                'discover_link': this.form.discover_link,
            }

            Vue.prototype.$http
                .patch(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/service/partner/'+ serviceId, data)
                .then(response => {
                    router.push({ name: 'ServiceDetail', params: { id: response.data.data.id } })
                    this.$store
                        .commit('ADD_NOTIFICATION', {
                            title: 'Success!',
                            text: 'Success Updating Service!',
                            type: 'success',
                            timeout: false,
                            delay: 0
                        });
                })

        },
    },

    created() {
        if ("id" in this.$route.params) {
            this.load();
            this.editing = true;
        } else {
            // this
        }

        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/service/all-service')
            .then((response) => {
                this.servicesOption = response.data.data
            })
    },
}
</script>

<style lang="sass" scoped>
    .input-file
        width: 100%
        height: 100%
        left: 0
        top: 0
        opacity: 0
        position: absolute
        z-index: 1

    .select-services
        margin-bottom: 16px
        max-width: 200px
        cursor: pointer
        input
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            z-index: 1
            opacity: 0
            cursor: pointer
            &:checked +
                .select-services__style
                    .list-service
                        background-color: #231F20
                        border-color: #231F20
                        color: #FFEFD8


        &__style
            .list-service
                border: 1px solid #cbd5e0
</style>