<template lang="pug">
    component(:is="layout")
        router-view
</template>

<script>
    const applayout = 'app'
export default {
    name: 'MainShell',
    created () {
        this.$store.dispatch('AutoLogin')
    },
    computed: {
        auth () {
            return this.$store.getters.ifAuthenticated
        },
        layout() {
            return(this.$route.meta.layout || applayout ) + '-layout'
        }
    },
}
</script>
