<template lang="pug">
    //- Login activity
    .card
        header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
            h2.font-bold Password

        .card__body
            form(action="" method="method")
                //- section.border-b.border-blue-100.mb-8.pb-4
                    .field
                        .flex.mb-6
                            .block(class="w-1/4").text-right.mr-8
                                label.block.h-16.py-2 Current Password
                                    small.text-red-500(style="padding-left:4px") *
                            .flex-grow
                                .inline-block.relative.w-full.mr-4
                                    input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                        type="password"
                                        placeholder="Write your current password"
                                    )
                                    //-.flex.items-center.justify-between.mt-2
                                        span.info.text-xs message
                                .pt-4
                                    router-link.link.font-bold(to="/forgot") Forgot Password?
                section.border-b.border-blue-100.mb-8.pb-8          
                    .field
                        .flex.mb-6
                            .block(class="w-1/4").text-right.mr-8
                                label.block.h-16.py-2 New Password
                                    small.text-red-500(style="padding-left:4px") *
                            .flex-grow
                                .inline-block.relative.w-full.mr-4
                                    input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                        type="password"
                                        placeholder="Write your new password"
                                        v-model.trim="$v.form.password.$model"
                                        :class="{ 'border-red-500': $v.form.password.$error }"
                                    )
                                    .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.password.$error && !$v.form.password.required")
                                        span.info.text-xs Password is required.
                                    .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.password.$error && !$v.form.password.minLength")
                                        span.info.text-xs Password must have at least {{ $v.form.password.$params.minLength.min }} letters
                    .field
                        .flex.mb-6
                            .block(class="w-1/4").text-right.mr-8
                                label.block.h-16.py-2 Confrim Password
                                    small.text-red-500(style="padding-left:4px") *
                            .flex-grow
                                .inline-block.relative.w-full.mr-4
                                    input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                        type="password"
                                        placeholder="Rewrite your new password"
                                        v-model.trim="$v.form.password_confirmation.$model"
                                        :class="{ 'border-red-500': $v.form.password_confirmation.$error }"
                                    )
                                    .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.password_confirmation.$error && !$v.form.password_confirmation.required")
                                        span.info.text-xs Password is required.
                                    .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.password_confirmation.$error && !$v.form.password_confirmation.sameAsPassword")
                                        span.info.text-xs Passwords must be identical.
                section
                    .flex.items-center.justify-end
                        button.btn-primary.ml-3(type='button' @click="updatePassword()" :disabled="submitStatus === 'PENDING'") Change
                    p.typo__p.text-right.mt-3.text-green-500(v-if="submitStatus === 'OK'") Thanks for your submission!
                        p.typo__p.text-right.mt-3.text-red-500(v-if="submitStatus === 'ERROR'") Please fill the form correctly.
                        p.typo__p.text-right.mt-3.text-blue-500(v-if="submitStatus === 'PENDING'") Sending...
    //- end Login activity
</template>

<script>
import {required, sameAs, minLength} from "vuelidate/lib/validators";
import Vue from "vue";

export default {
    name: 'LoginActivity',
    data() {
        return {
            submitStatus: null,
            form: {
                password: '',
                password_confirmation: '',
            }
        }
    },
    validations: {
        form: {
            password: { required, minLength: minLength(8) },
            password_confirmation: { required, sameAsPassword: sameAs('password') },
        },
    },
    methods: {
        updatePassword () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                this.submitStatus = 'PENDING'
                var data = {
                    'password': this.form.password,
                    'password_confirmation': this.form.password_confirmation,
                }

                Vue.prototype.$http
                    .patch(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/auth/profile/change-password', data)
                    .then(() => {
                        this.submitStatus = null

                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success Updating Password!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }
        },
    }
}
</script>