<template lang="pug">
    .grid.grid-cols-5.gap-x-1.mb-4
        .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
            .block
                span.text-xs.opacity-75.uppercase All Transfer Point
                h2.text-3xl {{ voucherSummary.total | moneyFilter }}
            //- .block.text-right.text-xs
                span.block.text-green-200 + 20%
                small from Last Month
        //- .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
            .block 
                span.text-xs.opacity-75.uppercase Expired Soon
                h2.text-3xl 4.000
            //- .block.text-right.text-xs
                span.block.text-green-200 + 20%
                small from Last Month
        .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
            .block 
                span.text-xs.opacity-75.uppercase Claimed
                h2.text-3xl {{ voucherSummary.total_exchanged | moneyFilter }}
            //- .block.text-right.text-xs
                span.block.text-red-300 - 0.2%
                small from Last Month
        .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between
            .block 
                span.text-xs.opacity-75.uppercase Reedemed
                h2.text-3xl {{ voucherSummary.total_redeemed | moneyFilter }}
            //- .block.text-right.text-xs
                span.block.text-green-200 + 20%
                small from Last Month
</template>


<script>
    import Vue from "vue";

    export default {
        name: 'GiftCardDetail',
        data() {
            return {
                voucherSummary: null,
            }
        },
        mounted() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue/summary?is_gift_card=2')
                .then((response) => {
                    this.voucherSummary = response.data.data
                })
        }
    }
</script>