<template lang="pug">
    #static-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                    h2.font-bold {{ editing ? 'Edit' : 'New' }} broadcast
                    .header-cta.flex.items-center
                        router-link(to="/broadcast").link.font-sx
                            span Back
                .card__body
                    form(action="" method="method")
                        section.border-b.border-blue-100.mb-8.pb-8
                            .container-small
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Type
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    v-model="$v.form.type.$model"
                                                    :class="{ 'border-red-500': $v.form.type.$error }"
                                                )
                                                    option Select Type
                                                    option(selected value="notification") Notification
                                                    //-option Email
                                                    //-option Global
                                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                    <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.type.$error")
                                                span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Title
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            //- .mb-4.border.p-3.bg-gray-100
                                            span.block.text-xs.uppercase.mb-2.text-blue-500.font-bold Indonesia version
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: Nikmati akhir tahun di JHL Serpong"
                                                    v-model="$v.form.title.$model"
                                                    :class="{ 'border-red-500': $v.form.title.$error }"
                                                )
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.title.$error")
                                                    span.info.text-xs This field is required
                                            //- .border.p-3.bg-gray-100
                                                span.block.text-xs.uppercase.mb-2.text-blue-500.font-bold English version
                                                .inline-block.relative.w-full.mr-4
                                                    input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                        type="text"
                                                        placeholder="ex: Enjoy the end of the year at JHL Serpong"
                                                        v-model="$v.form.titleEn.$model"
                                                        :class="{ 'border-red-500': $v.form.titleEn.$error }"
                                                    )
                                                    .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.titleEn.$error")
                                                        span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Broadcast Group
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    v-model="$v.form.groupId.$model"
                                                    :class="{ 'border-red-500': $v.form.groupId.$error }"
                                                )
                                                    option(value="") Select Group
                                                    option(v-for="group in broadcastGroup" :value="group.id") {{ group.name }}
                                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                    <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.groupId.$error")
                                                span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Type of Notification
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    v-model="$v.form.activity.$model"
                                                    :class="{ 'border-red-500': $v.form.activity.$error }"
                                                    @change="getTarget()"
                                                )
                                                    option(value="") Select Activity
                                                    option(v-for="activity in activityList" :value="activity.key") {{ activity.title }}
                                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                    <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.activity.$error")
                                                span.info.text-xs This field is required

                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Choose Activity Target
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    v-model="$v.form.activityTarget.$model"
                                                    :class="{ 'border-red-500': $v.form.activityTarget.$error }"
                                                )
                                                    option(value="") Select Target
                                                    option(v-for="(item, index) in targetList" :key="item.index" :value="item.id") {{ item.title }}
                                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                    <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.activityTarget.$error")
                                                span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Publish date
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                datetime.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.rounded.shadow.leading-tight(
                                                    type="datetime"
                                                    v-model="$v.form.publish_at.$model"
                                                    :class="{ 'border-red-500': $v.form.publish_at.$error }"
                                                )
                                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700.hidden
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>

                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.publish_at.$error")
                                                span.info.text-xs This field is required
                                .field.hidden
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Cover
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .cover-voucher.w-64.h-64.bg-gray-200.rounded.mb-2
                                                img.w-full.h-full.object-cover.rounded(
                                                    v-if="icon"
                                                    :src="icon ? icon : 'https://i.imgur.com/9XwzHPA.png'", alt="alt"
                                                )
                                            span.info.text-xs Please upload image with 400 x 400

                                            .block.mt-4
                                                UploadMedia(:inputMedia.sync="icon")
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.icon.$error")
                                                span.info.text-xs This field is required

                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right
                                            label.block.h-16.py-2 Content
                                                small.text-red-500(style="padding-left:4px") *
                                        .block(class="w-3/4").ml-8
                                            .relative.w-full.mr-4
                                                vueEditor(
                                                    v-model="$v.form.content.$model"
                                                    :class="{ 'border-red-500': $v.form.content.$error }"
                                                )
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.content.$error")
                                                    span.info.text-xs This field is required
                        section
                            .flex.items-center.justify-end
                                //-button.link(type='button') Reset
                                button.btn-primary-o.ml-3(type='button' @click="add(0)" v-if="!editing" :disabled="submitStatus === 'PENDING'") Draft
                                button.btn-primary.ml-3(type='button' @click="add(1)" v-if="!editing" :disabled="submitStatus === 'PENDING'") Publish
                                button.btn-primary-o.ml-3(type='button' @click="update(0)" v-if="editing" :disabled="submitStatus === 'PENDING'") Draft
                                button.btn-primary.ml-3(type='button' @click="update(1)" v-if="editing" :disabled="submitStatus === 'PENDING'") Publish
                            p.typo__p.text-right.mt-3.text-green-500(v-if="submitStatus === 'OK'") Thanks for your submission!
                            p.typo__p.text-right.mt-3.text-red-500(v-if="submitStatus === 'ERROR'") Please fill the form correctly.
                            p.typo__p.text-right.mt-3.text-blue-500(v-if="submitStatus === 'PENDING'") Sending...


</template>

<script>
import Breadcrumb from './../../../components/content/breadcrumb';
import { VueEditor } from "vue2-editor";
import UploadMedia from "../../../components/content/UploadMedia";
import Vue from "vue";
import router from "../../../router";
import {required} from "vuelidate/lib/validators";
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

export default {
    name: 'StaticCreate',
    components: {
        Breadcrumb,
        UploadMedia,
        VueEditor,
        datetime: Datetime
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Broadcast',
                    url: '/broadcast',
                },
                {
                    name: 'Create',
                    url: '/create',
                },
            ],
            activityList: [
                // {
                //     title: 'News List',
                //     key: 'news-page-list',
                // },
                {
                    title: 'What\'s On Page',
                    key: 'news-page',
                },
                // {
                //     title: 'Voucher List',
                //     key: 'voucher-page-list',
                // },
                {
                    title: 'Voucher Page',
                    key: 'voucher-page',
                },
                // {
                //     title: 'My Voucher List',
                //     key: 'my-voucher-page-list',
                // },
                // {
                //     title: 'My Voucher Page ',
                //     key: 'my-voucher-page',
                // },
                // {
                //     title: 'Point List',
                //     key: 'point-list'
                // },

    ],
            targetList: [],
            voucherTargetList: [],
            newsTargetList: [],
            broadcastGroup: [],
            form: {
                type: 'notification',
                title: null,
                titleEn: null,
                content: null,
                groupId: '',
                publish_at: '',
                activity: '',
                activityTarget: '',
            },
            icon: 'https://api.membership.jhlcollections.com/assets/notif/news.svg',
            editing: false,
            submitStatus: null,
        }
    },

    validations: {
        form: {
            type: { required },
            title: { required },
            titleEn: { },
            publish_at: { required },
            content: { required },
            groupId: { required },
            activity: { },
            activityTarget: { },
        },
        icon: { required }
    },

    methods: {
        changeCover(e){
            const file = e.target.files[0];
            this.coverVoucher = URL.createObjectURL(file)
        },
        load() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/notification/group/' + this.$route.params.groupId + '/message/' + this.$route.params.id )
                .then((response) => {
                    var data = response.data.data
                    console.log(data.data.activity_target)
                    this.form = {
                        type: 'notification',
                        title: data.title,
                        titleEn: data.title,
                        content: data.content,
                        groupId: data.group.id,
                        publish_at: data.publish_at,
                        activity: data.activity,
                        activityTarget: data.data.activity_target,

                    }
                    if (data.activity == 'voucher') {
                        this.getVoucherTarget()
                    } else {
                        this.getNewsTarget()
                    }
                    this.breadcrumb = [
                        {
                            name: 'Broadcast',
                            url: '/broadcast',
                        },
                        {
                            name: data.name,
                            url: '#',
                        },
                    ]
                })
        },
        add (status) {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'

                var data = {
                    "group_id": this.form.groupId,
                    "title": this.form.title,
                    "content": this.form.content,
                    "activity": this.form.activity,
                    "data": {
                        'activity_target' : this.form.activityTarget
                    },
                    "icon": this.icon,
                    "publish_at": this.form.publishAt,
                    "status": status
                }

                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/notification/group/'+ this.form.groupId +'/message', data)
                    .then(() => {
                        router.push({ name: 'BroadcastPage' })

                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success Adding Broadcast Message!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })

            }
        },
        update (status) {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'

                var data = {
                    "group_id": this.form.groupId,
                    "title": this.form.title,
                    "content": this.form.content,
                    "activity": this.form.activity,
                    "data": {
                        'activity_target' : this.form.activityTarget
                    },
                    "icon": this.icon,
                    "publish_at": this.form.publishAt,
                    "status": status
                }
                console.log(data)

                Vue.prototype.$http
                    .patch(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/notification/group/'+ this.form.groupId +'/message/'+this.$route.params.id, data)
                    .then(() => {
                        router.push({ name: 'BroadcastPage' })

                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success Updating Broadcast Message!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }
        },
        getVoucherTarget(){
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue?per_page=-1')
                .then((response) => {
                    this.voucherTargetList = response.data.data.map((target) => {
                        target.title = target.catalogue_name
                        return target;
                    })
                    this.targetList = this.voucherTargetList
                })

        },
        getNewsTarget(){
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/post?per_page=-1')
                .then(response => {
                    this.newsTargetList = response.data.data
                    this.targetList = this.newsTargetList
                })

        },
        getTarget(){
            switch (this.form.activity) {
                case 'voucher-page':
                    this.getVoucherTarget()
                    break;

                case 'news-page':
                    this.getNewsTarget()
                    break;
            }
        }
    },

    created() {
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/notification/group')
            .then((response) => {
                this.broadcastGroup = response.data.data
            })
        if ("id" in this.$route.params) {
            this.load();
            this.editing = true;
        } else {
            // this
        }
    },
    

}
</script>

<style lang="sass" scoped>
    .input-file
        width: 100%
        height: 100%
        left: 0
        top: 0
        opacity: 0
        position: absolute
        z-index: 1
</style>