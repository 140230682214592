<template lang="pug">
    .card.card--shadow.bg-white.p-6.rounded.border.border-gray-100
        header.card__header.flex.items-center.border-b.border-gray-200.mb-4.pb-4
            h2.font-bold Inbox
            //- .w-6.h-6.flex.items-center.justify-center.rounded-full.bg-red-500.text-red-100.ml-1 9
        .card__body
            .filter-content.mb-4
                .inline-block.relative.w-64.mr-4
                    input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                        type="text"
                        placeholder="Search News here..."
                    )
                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                .inline-block.relative.w-64.mr-4
                    select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight
                        option All Services
                        option Room
                        option Meeting
                        option Global
                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                        <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>

                .inline-block.relative.w-64.mr-4
                    select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight
                        option All Status
                        option New
                        option replied
                        option Read
                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                        <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>

            .box-table
                table.table.table-set.w-full
                    thead
                        tr
                            th  Sender
                            th  Subject
                            th  Message
                            th  Service
                            th  status
                            th
                    tbody
                        //- tr(v-for="(list, index) in news" :key="list.index")
                            td
                                router-link(to="/services/message")
                                    template(v-if="list.status === 'new'")
                                        .whitespace-no-wrap.mr-4.font-bold {{ list.sender}}
                                    template(v-else)
                                        .whitespace-no-wrap.mr-4 {{ list.sender}}
                            td
                                router-link(to="/services/message")
                                    template(v-if="list.status === 'new'")
                                        .whitespace-no-wrap.mr-4.font-bold {{ list.subject}}
                                    template(v-else)
                                        .whitespace-no-wrap.mr-4 {{ list.subject}}

                            td
                                router-link(to="/services/message")
                                        template(v-if="list.status === 'new'")
                                            .truncate.w-64.font-bold {{ list.message}}
                                        template(v-else)
                                            .truncate.w-64 {{ list.message}}
                                
                            td
                                .border.bordr-gray-200.bg-gray-100.pr-2.rounded.mr-3
                                    .flex.items-center.p-1
                                        .icon.w-8.h-8.flex.items-center.justify-center.mr-2
                                            img.rounded(src="https://i.imgur.com/9XwzHPA.png")
                                        span {{ list.service}}
                            td
                                template(v-if="list.status === 'new'")
                                    .flex.items-center.justify-center.text-red-600.bg-red-200.font-bold.uppercase.text-xs.p-1.rounded
                                        .w-2.h-2.bg-red-600.rounded-full.inline-block.mr-2
                                        span.font-bold {{ list.status}}
                                template(v-if="list.status === 'replied'")
                                    .bg-green-300.text-green-800.rounded.p-1.text-center.font-bold.uppercase.text-xs {{ list.status}}
                                template(v-if="list.status === 'read'")
                                    .bg-orange-300.text-orange-800.rounded.p-1.text-center.font-bold.uppercase.text-xs {{ list.status}}
                            td.text-right
                                small {{ list.date}}
                            
            Pagination( :pagination="pagination" @paginate="load()" )
</template>

<script>
import Pagination from "../../components/content/Pagination";

export default {
    name: 'InboxService',
    components: {
        Pagination
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Whats On',
                    url: '/whats-on',
                },
            ],
            pagination: {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
            news: [
                {
                    sender: 'Devi Krisdiansyah',
                    subject: 'Tidak ada layanin ini',
                    message: 'Marzipan bonbon gingerbread cake candy sweet toffee cake. Cake pie jelly beans tootsie roll lollipop chupa chups croissant macaroon. Wafer cake candy canes sugar plum marshmallow muffin chocolate bar oat cake. Macaroon marzipan carrot cake pastry croissant candy canes.',
                    date: '10 april 2020',
                    service: 'Room',
                    status: 'new',
                },
                {
                    sender: 'Devi Krisdiansyah',
                    subject: 'Tidak ada layanin ini',
                    message: 'Marzipan bonbon gingerbread cake candy sweet toffee cake. Cake pie jelly beans tootsie roll lollipop chupa chups croissant macaroon. Wafer cake candy canes sugar plum marshmallow muffin chocolate bar oat cake. Macaroon marzipan carrot cake pastry croissant candy canes.',
                    date: '10 april 2020',
                    service: 'Room',
                    status: 'new',
                },
                {
                    sender: 'Devi Krisdiansyah',
                    subject: 'Tidak ada layanin ini',
                    message: 'Marzipan bonbon gingerbread cake candy sweet toffee cake. Cake pie jelly beans tootsie roll lollipop chupa chups croissant macaroon. Wafer cake candy canes sugar plum marshmallow muffin chocolate bar oat cake. Macaroon marzipan carrot cake pastry croissant candy canes.',
                    date: '10 april 2020',
                    service: 'Room',
                    status: 'new',
                },
                {
                    sender: 'Devi Krisdiansyah',
                    subject: 'Tidak ada layanin ini',
                    message: 'Marzipan bonbon gingerbread cake candy sweet toffee cake. Cake pie jelly beans tootsie roll lollipop chupa chups croissant macaroon. Wafer cake candy canes sugar plum marshmallow muffin chocolate bar oat cake. Macaroon marzipan carrot cake pastry croissant candy canes.',
                    date: '10 april 2020',
                    service: 'Room',
                    status: 'new',
                },
                {
                    sender: 'Devi Krisdiansyah',
                    subject: 'Tidak ada layanin ini',
                    message: 'Marzipan bonbon gingerbread cake candy sweet toffee cake. Cake pie jelly beans tootsie roll lollipop chupa chups croissant macaroon. Wafer cake candy canes sugar plum marshmallow muffin chocolate bar oat cake. Macaroon marzipan carrot cake pastry croissant candy canes.',
                    date: '10 april 2020',
                    service: 'Room',
                    status: 'new',
                },
                {
                    sender: 'Devi Krisdiansyah',
                    subject: 'Tidak ada layanin ini',
                    message: 'Marzipan bonbon gingerbread cake candy sweet toffee cake. Cake pie jelly beans tootsie roll lollipop chupa chups croissant macaroon. Wafer cake candy canes sugar plum marshmallow muffin chocolate bar oat cake. Macaroon marzipan carrot cake pastry croissant candy canes.',
                    date: '10 april 2020',
                    service: 'Room',
                    status: 'new',
                },
                {
                    sender: 'Devi Krisdiansyah',
                    subject: 'Tidak ada layanin ini',
                    message: 'Marzipan bonbon gingerbread cake candy sweet toffee cake. Cake pie jelly beans tootsie roll lollipop chupa chups croissant macaroon. Wafer cake candy canes sugar plum marshmallow muffin chocolate bar oat cake. Macaroon marzipan carrot cake pastry croissant candy canes.',
                    date: '10 april 2020',
                    service: 'Room',
                    status: 'new',
                },
                {
                    sender: 'Devi Krisdiansyah',
                    subject: 'Tidak ada layanin ini',
                    message: 'Marzipan bonbon gingerbread cake candy sweet toffee cake. Cake pie jelly beans tootsie roll lollipop chupa chups croissant macaroon. Wafer cake candy canes sugar plum marshmallow muffin chocolate bar oat cake. Macaroon marzipan carrot cake pastry croissant candy canes.',
                    date: '10 april 2020',
                    service: 'Room',
                    status: 'new',
                },
                {
                    sender: 'Devi Krisdiansyah',
                    subject: 'Tidak ada layanin ini',
                    message: 'Marzipan bonbon gingerbread cake candy sweet toffee cake. Cake pie jelly beans tootsie roll lollipop chupa chups croissant macaroon. Wafer cake candy canes sugar plum marshmallow muffin chocolate bar oat cake. Macaroon marzipan carrot cake pastry croissant candy canes.',
                    date: '10 april 2020',
                    service: 'Room',
                    status: 'new',
                },
                {
                    sender: 'Devi Krisdiansyah',
                    subject: 'Tidak ada layanin ini',
                    message: 'Marzipan bonbon gingerbread cake candy sweet toffee cake. Cake pie jelly beans tootsie roll lollipop chupa chups croissant macaroon. Wafer cake candy canes sugar plum marshmallow muffin chocolate bar oat cake. Macaroon marzipan carrot cake pastry croissant candy canes.',
                    date: '10 april 2020',
                    service: 'Room',
                    status: 'replied',
                },
                {
                    sender: 'Devi Krisdiansyah',
                    subject: 'Tidak ada layanin ini',
                    message: 'Marzipan bonbon gingerbread cake candy sweet toffee cake. Cake pie jelly beans tootsie roll lollipop chupa chups croissant macaroon. Wafer cake candy canes sugar plum marshmallow muffin chocolate bar oat cake. Macaroon marzipan carrot cake pastry croissant candy canes.',
                    date: '10 april 2020',
                    service: 'Room',
                    status: 'read',
                },
                {
                    sender: 'Devi Krisdiansyah',
                    subject: 'Tidak ada layanin ini',
                    message: 'Marzipan bonbon gingerbread cake candy sweet toffee cake. Cake pie jelly beans tootsie roll lollipop chupa chups croissant macaroon. Wafer cake candy canes sugar plum marshmallow muffin chocolate bar oat cake. Macaroon marzipan carrot cake pastry croissant candy canes.',
                    date: '10 april 2020',
                    service: 'Room',
                    status: 'read',
                },
                {
                    sender: 'Devi Krisdiansyah',
                    subject: 'Tidak ada layanin ini',
                    message: 'Marzipan bonbon gingerbread cake candy sweet toffee cake. Cake pie jelly beans tootsie roll lollipop chupa chups croissant macaroon. Wafer cake candy canes sugar plum marshmallow muffin chocolate bar oat cake. Macaroon marzipan carrot cake pastry croissant candy canes.',
                    date: '10 april 2020',
                    service: 'Room',
                    status: 'read',
                },
            ],
        }
    },

}
</script>

<style lang="sass" scoped>
.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>