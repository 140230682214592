<template lang="pug">
    #settings-box
        .card.card--shadow.p-4.rounded.border.border-gray-200.bg-white
            header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                h2.font-bold Admin
                .header-cta.flex.items-center
                    router-link(to="/settings/admin/create").btn-primary.btn-small.ml-2
                        span Add Admin
            .card__body
                .filter-content.flex.items-center.mb-4
                    SearchInput(:inputData.sync="searchInput" @search="load()")

                .box-table
                    table.table.table-set.w-full
                        thead
                            tr
                                th  No.
                                th  Name
                                th  Email
                                th  Role
                                th  Status
                                th  Latest Activity
                                th
                        tbody
                            tr(v-for="(user, index) in adminList" :key="user.index")
                                td.w-8 {{ index + 1 }}.
                                td
                                    router-link.link.font-bold(:to="{ name: 'SettingsAdminDetail', params: { id: user.id } }")
                                        .avatar.flex.items-center
                                            figure.avatar.w-8.h-8.rounded-full.overflow-hidden.mr-4
                                                img.w-full.h-full.object-cover(:src="user.avatar ? user.avatar : 'https://i.imgur.com/9XwzHPA.png'", v-bind:alt="user.name")
                                            span {{ user.name }}
                                td
                                    span {{ user.email }}
                                td
                                    span {{ user.role ? user.role.name : 'not set' }}
                                td
                                    template(v-if="user.status === 'deactived'")
                                        span.px-2.py-1.bg-gray-200.text-gray-700.rounded.font-bold.text-xs.w-24.block.text-center.uppercase {{ user.status }}
                                    template(v-if="user.status === 'active'")
                                        span.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase {{ user.status }}
                                td
                                    small.opacity-75 ---
                                td.action-2
                                    router-link.btn-default.btn-icon(:to="{ name: 'SettingsAdminEdit', params: { id: user.id } }")
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                    button.btn-default.btn-icon(@click="destroy(user.id)")
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                Pagination( :pagination="pagination" @paginate="load()" )
</template>

<script>
import Pagination from "../../../components/content/Pagination";
import SearchInput from "../../../components/content/SearchInput";
import Vue from "vue";

export default {
    name: 'SettingsAdmin',
    components: {
        SearchInput,
        Pagination,
    },
    data() {
        return {
            adminList: [],
            searchInput: '',
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    methods: {
        load() {
            let params = '?q='+this.searchInput+'&page='+this.pagination.current_page;

            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/admin/user'+params)
                .then(response => {
                    this.adminList = response.data.data
                    this.pagination = response.data.meta
                })
        },
        destroy (id) {
            if (confirm('Are you sure?')) {
                Vue.prototype.$http
                    .delete(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/admin/user/'+ id)
                    .then(() => {
                        this.load()
                    })
            }
        },
    },
    mounted() {
        this.load()
    },
}
</script>

<style lang="sass" scoped>
small
    line-height: 0
    position: relative
    top: -4px

.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>