<template lang="pug">
    #merchant-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                    h2.font-bold {{ editing ? 'Edit' : 'New' }} Partners
                    .header-cta.flex.items-center
                        router-link(:to="{name: 'MerchantPage'}").link.font-sx
                            span Back
                .card__body
                    form(action="" method="method" @submit.prevent="add")
                        section.border-b.border-blue-100.mb-8.pb-8
                            .container-small
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Name
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: hotel jhl gading serpong"
                                                    v-model="$v.form.name.$model"
                                                    :class="{ 'border-red-500': $v.form.name.$error }"
                                                )
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.name.$error")
                                                    span.info.text-xs This field is required

                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Status
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    v-model="$v.form.status.$model"
                                                    :class="{ 'border-red-500': $v.form.status.$error }"
                                                )
                                                    option(value="1") Active
                                                    option(value="3") Inactive
                                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                    <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.status.$error")
                                                span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Display
                                                small.text-red-500(style="padding-left:4px")
                                        .flex-grow
                                            .w-full.flex.items-center.flex-wrap
                                                .field-checkbox.mr-8.mb-4()
                                                    input( type="checkbox" v-model="$v.form.display_partner.$model" value="0")
                                                    .ui-checkbox
                                                        .bullet
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        span.ml-2 Unlisted
                                                .flex.items-center.justify-between.mt-2.text-gray-500
                                                    span.info.text-xs Check this field if you don't want to show this partner
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.display_partner.$error")
                                                    span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Category
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    v-model="$v.form.category.$model"
                                                    :class="{ 'border-red-500': $v.form.category.$error }"
                                                )
                                                    option(value="0") Select Category
                                                    option(v-for="category in categoryList" :value="category.id") {{ category.name }}
                                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                    <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.category.$error")
                                                span.info.text-xs This field is required

                                .field(v-if="userData.principal_staff == 1")
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Order Position
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: 1"
                                                    v-model.lazy="$v.form.order_pos.$model"
                                                    v-money="money"
                                                    :class="{ 'border-red-500': $v.form.order_pos.$error }"
                                                )
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.order_pos.$error")
                                                    span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Contact
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: 081234567890"
                                                    v-model="$v.form.phone.$model"
                                                    :class="{ 'border-red-500': $v.form.phone.$error }"
                                                )
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.phone.$error")
                                                span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Email
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="email"
                                                    placeholder="ex: info@jhlcollections.com"
                                                    v-model="$v.form.email.$model"
                                                    :class="{ 'border-red-500': $v.form.email.$error }"
                                                )
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.email.$error")
                                                span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Discover Link
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="url"
                                                    placeholder="ex: jhlcollections.com"
                                                    v-model="$v.form.discover_link.$model"
                                                    :class="{ 'border-red-500': $v.form.discover_link.$error }"
                                                )
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.discover_link.$error")
                                                span.info.text-xs This field is required

                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Address
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: Jl. Gading Serpong Boulevard, Curug Sangereng"
                                                    v-model="$v.form.address.$model"
                                                    :class="{ 'border-red-500': $v.form.address.$error }"
                                                )
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.address.$error")
                                                span.info.text-xs This field is required
                                .flex.mb-6
                                    .block(class="w-1/4").text-right.mr-8
                                        label.block.h-16.py-2 Description
                                            small.text-red-500(style="padding-left:4px") *
                                    .flex-grow
                                        .inline-block.relative.w-full
                                            textarea.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                type="text"
                                                placeholder="ex: Hotel bintang 5"
                                                v-model="$v.form.description.$model"
                                                :class="{ 'border-red-500': $v.form.description.$error }"
                                            )
                                        .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.description.$error")
                                            span.info.text-xs This field is required

                        section.border-b.border-blue-100.mb-8.pt-4
                            .container-small
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Photo
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .cover-voucher.w-64.h-64.bg-gray-200.rounded-full.mb-2.overfow-hidden
                                                img.w-full.h-full.object-cover.rounded-full(
                                                    v-if="coverImage"
                                                    :src="coverImage ? coverImage : 'https://i.imgur.com/9XwzHPA.png'", alt="alt"
                                                )

                                            .block.mt-4.w-64.text-center
                                                span.info.text-xs.mb-2.block Please upload image with 400 x 400
                                                UploadMedia(:inputMedia.sync="coverImage")
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.coverImage.$error")
                                                span.info.text-xs This field is required


                        section
                            .flex.items-center.justify-end
                                //- button.link(type='button') Reset
                                button.btn-primary.ml-3(type='submit' v-if="!editing" :disabled="submitStatus === 'PENDING'") Publish
                                button.btn-primary.ml-3(type='button' @click="update" v-if="editing" :disabled="submitStatus === 'PENDING'") Update
                            p.typo__p.text-right.mt-3.text-green-500(v-if="submitStatus === 'OK'") Thanks for your submission!
                            p.typo__p.text-right.mt-3.text-red-500(v-if="submitStatus === 'ERROR'") Please fill the form correctly.
                            p.typo__p.text-right.mt-3.text-blue-500(v-if="submitStatus === 'PENDING'") Sending...


</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import UploadMedia from './../../components/content/UploadMedia';
import Vue from "vue";
import router from "../../router";
import {required} from "vuelidate/lib/validators";

export default {
    name: 'MerchantCreate',
    components: {
        Breadcrumb,
        UploadMedia,
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Partners',
                    url: '/partners',
                },
                {
                    name: 'Create',
                    url: '/create',
                },
            ],
            form: {
                name: null,
                address: null,
                description: null,
                category: 0,
                phone: null,
                email: null,
                discover_link: null,
                status: 0,
                display_partner: 0,
                order_pos: 0,
            },
            coverImage: 'https://i.imgur.com/9XwzHPA.png',
            categoryList: [],
            editing: false,
            submitStatus: null,
            merchantData: null,
        }
    },
    computed:{
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
        urlPrefix(){
            return this.userData.principal_staff === 1 ? 'master':'merchant';
        }
    },
    validations: {
        form: {
            name: { required },
            address: { required },
            description: { },
            category: { required },
            phone: { required },
            email: { required },
            status: { required },
            display_partner: { },
            discover_link: { required },
            order_pos: { },
        },
        coverImage: { required },
    },

    methods: {
        load(){
            if (this.urlPrefix == 'merchant') {
                let data = this.userData.merchant
                this.form = {
                    name: data.company_name ? data.company_name : null,
                    discover_link: data.discover_link ? data.discover_link : null,
                    address: data.primary_outlet.outlet_address ? data.primary_outlet.outlet_address : null,
                    description: data.merchant_description ? data.merchant_description : null,
                    category: data.category ? data.category.id : 0,
                    order_pos: data.order_pos ? data.order_pos : 0,
                    phone: data.primary_outlet.outlet_phone ? data.primary_outlet.outlet_phone : null,
                    email: data.primary_outlet.outlet_email ? data.primary_outlet.outlet_email : null,
                    status: data.status ? data.status : null,
                    display_partner: data.display === 1 ? 0 : 1,
                }
                this.coverImage =  data.company_logo ? data.company_logo : 'https://i.imgur.com/9XwzHPA.png'
                this.merchantData = data
                this.breadcrumb = [
                    {
                        name: 'Partners',
                        url: '/partner-dashboard',
                    },
                ]
            } else {
                Vue.prototype.$http
                    .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/merchant/' + this.$route.params.id)
                    .then((response) => {
                        if (response.data.error !== 0){
                            alert(response.data.description || 'Something went wrong')
                        } else {
                            var data = response.data.data;
                            this.form = {
                                name: data.company_name ? data.company_name : null,
                                discover_link: data.discover_link ? data.discover_link : null,
                                address: data.primary_outlet.outlet_address ? data.primary_outlet.outlet_address : null,
                                description: data.merchant_description ? data.merchant_description : null,
                                category: data.category ? data.category.id : 0,
                                phone: data.primary_outlet.outlet_phone ? data.primary_outlet.outlet_phone : null,
                                email: data.primary_outlet.outlet_email ? data.primary_outlet.outlet_email : null,
                                status: data.status ? data.status : null,
                                display_partner: data.display === 1 ? 0 : 1,

                            }
                            this.coverImage =  data.company_logo ? data.company_logo : 'https://i.imgur.com/9XwzHPA.png'
                            this.merchantData = data
                            this.breadcrumb = [
                                {
                                    name: 'Partners',
                                    url: '/partners',
                                },
                                {
                                    name: data.company_name,
                                    url: '/partners/detail/'+data.id,
                                },
                            ]
                        }
                    })
            }

        },

        add() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'

                let data = {
                    'status': this.form.status,
                    'display': this.form.display_partner === true ? 0 : 1,
                    'company_name': this.form.name,
                    'discover_link': this.form.discover_link,
                    'brand_name': this.form.name,
                    'category_id': this.form.category,
                    'content': this.form.content,
                    'company_cover': this.coverImage,
                    'company_logo': this.coverImage,
                    'merchant_description': this.form.description,
                    'merchant_term': '',
                };
                let dataOutlet = {
                    'outlet_name': this.form.name,
                    'outlet_address': this.form.address,
                    'outlet_phone': this.form.phone,
                    'outlet_email': this.form.email,
                };
                if (this.userData.principal_staff == 1){
                    data.order_pos = this.form.order_pos
                }

                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/merchant', data)
                    .then((response) => {
                        if (response.data.error !== 0){
                            this.submitStatus = null
                            alert(response.data.description || 'Something went wrong')
                        } else {
                            Vue.prototype.$http
                                .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/merchant/' + response.data.data.id + '/outlet', dataOutlet)
                                .then((responseOutlet) => {
                                    if (responseOutlet.data.error !== 0){
                                        this.submitStatus = null
                                        alert(responseOutlet.data.description || 'Something went wrong')
                                    } else {
                                        router.push({name: 'MerchantDetail', params: {id: response.data.data.id}})
                                    }
                                })
                        }
                    })
            }
        },
        update() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'
                var data = {
                    'status': this.form.status,
                    'display': this.form.display_partner === true ? 0 : 1,
                    'company_name': this.form.name,
                    'discover_link': this.form.discover_link,
                    'brand_name': this.form.name,
                    'category_id': this.form.category,
                    'content': this.form.content,
                    'company_cover': this.coverImage,
                    'company_logo': this.coverImage,
                    'merchant_description': this.form.description,
                    'merchant_term': '',
                };
                let dataOutlet = {
                    'outlet_name': this.form.name,
                    'outlet_address': this.form.address,
                    'outlet_phone': this.form.phone,
                    'outlet_email': this.form.email,
                };
                if (this.userData.principal_staff == 1){
                    data.order_pos = this.form.order_pos
                }

                let endpoint = 'master/merchant/' + this.$route.params.id;
                let outletEndpoint = 'master/merchant/' + this.$route.params.id + '/outlet/';
                if (this.urlPrefix == 'merchant') {
                    endpoint = 'merchant';
                    outletEndpoint = 'merchant/outlet/';
                }
                Vue.prototype.$http
                    .patch(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+endpoint, data)
                    .then((response) => {
                        if (response.data.error !== 0){
                            this.submitStatus = null
                            alert(response.data.description || 'Something went wrong')
                        } else {
                            Vue.prototype.$http
                                .patch(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/' + outletEndpoint + this.merchantData.primary_outlet.id, dataOutlet)
                                .then((responseOutlet) => {
                                    if (responseOutlet.data.error !== 0){
                                        this.submitStatus = null
                                        alert(responseOutlet.data.description || 'Something went wrong')
                                    } else {
                                        if (this.urlPrefix == 'merchant') {
                                            Vue.prototype.$http
                                                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/merchant')
                                                .then((responseMerchant) => {
                                                    if (responseMerchant.data.error !== 0){
                                                        this.submitStatus = null
                                                        alert(responseMerchant.data.description || 'Something went wrong')
                                                    } else {
                                                        this.userData.merchant = responseMerchant.data.data
                                                        localStorage.setItem('user', JSON.stringify(this.userData))
                                                        router.push({
                                                            name: 'MerchantDetail',
                                                            params: {id: this.$route.params.id}
                                                        })
                                                    }
                                                })
                                        } else {
                                            router.push({name: 'MerchantDetail', params: {id: this.$route.params.id}})
                                        }
                                    }
                                })
                        }
                    })
            }
        }
    },
    created() {
        if ("id" in this.$route.params || this.urlPrefix == 'merchant') {
            this.load();
            this.editing = true;
        } else {
            // this
        }

        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/category?all=1')
            .then((response) => {
                this.categoryList = response.data.data
            })
    },


}
</script>

<style lang="sass" scoped>
    .field-checkbox
        position: relative
        cursor: pointer
        input
            width: 100%
            height: 100%
            position: absolute
            left: 0
            top: 0
            opacity: 0
            z-index: 3
            cursor: pointer
            &:checked
                +
                    .ui-checkbox
                        .bullet
                            border: 1px solid brown
                            svg
                                opacity: 1
                                stroke: brown
                        span
                            color: brown
        .ui-checkbox
            display: inline-flex
            align-items: center
            .bullet
                width: 24px
                height: 24px
                border-radius: 4px
                border: 1px solid #dddddd
                position: relative
                display: flex
                align-items: center
                justify-content: center
                transition: all 0.3s
                svg
                    opacity: 0
            span
</style>