<template lang="pug">
    .main-preview.bg-orange-100.text-gray-700
        .container-preview
            .bg-white
                figure.w-full
                    img(:src="voucherData.thumbnail ? voucherData.thumbnail : 'https://i.imgur.com/9XwzHPA.png'")
                .p-4
                    h1.text-xl.font-bold.text-gray-800 {{ voucherData.catalogue_name }}
                    .flex.items-center.justify-between.border-t.border-dashed.mt-3.pt-4.text-xs
                        span Berlaku Hingga
                        span {{ voucherData.period_end | moment('DD MMMM YYYY') }}
            .w-full.h-2.bg-orange-300.opacity-25
            .p-4
                .html-tag
                    h2.mb-2.text-black Syarat &amp; Ketentuan
                    div(v-html="voucherData.catalogue_term")

        .cta-download
            .cta-download__container
                .flex.items-center.justify-between
                    .flex-grow
                        router-link.flex.items-center(to="")
                            .mr-4
                                figure.w-8.h-8
                                    img.rounded(src="https://i.imgur.com/9XwzHPA.png", alt="alt")
                            span Download Aplikasi MyJHL
                    button.btn-icon(type='button')
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
</template>

<script>
import Vue from "vue";

export default {
    name: 'PreviewVoucher',
    data() {
        return {
            voucherData: null,
        }
    },
    computed:{
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
        urlPrefix(){
            return this.userData.principal_staff === 1 ? 'master':'merchant';
        }
    },
    mounted() {
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/' + this.$route.params.id)
            .then((response) => {
                this.voucherData = response.data.data
            })
    },
}
</script>

<style lang="sass" scoped>
.main-preview
    min-height: 100vh
    padding-bottom: 100px
    .container-preview
        max-width: 560px
        margin: 0 auto
    .html-tag
        > *
            margin-bottom: 1rem
        ul, ol
            padding-left: 1rem
            li
                padding-bottom: 0.5rem
        ul
            list-style-type: disc
        ol
            list-style-type: decimal
.cta-download
    width: 100%
    position: fixed
    bottom: 0
    left: 0
    border-top: 1px solid #dddddd
    background-color: #ffffff
    &__container
        width: 100%
        max-width: 560px
        margin: 0 auto
        padding: 16px
</style>