<template lang="pug">
    #settings-box
        .card.card--shadow.p-4.rounded.border.border-gray-200.bg-white
            header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                h2.font-bold Language
                .header-cta.flex.items-center
                    button.btn-primary.btn-small.ml-2(
                        @click="addGroup = !addGroup"
                    )
                        span Add Page
            .card__body
                .box-configuration(v-bind:class="{'box-configuration--show' : addGroup}")
                    .box-configuration__inner.p-6.bg-gray-200.border.border-gray-400.rounded.mb-6
                        .flex.items-center
                            .field.mr-3.flex-grow
                                label.text-xs.uppercase.font-bold.text-gray-600 Name
                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                    type="text"
                                    placeholder="Page Name"
                                    v-model="groupName"
                                )
                            .block
                                label.block.opacity-0 Value
                                button.btn-primary(@click="createGroup()") Add
                .lang-box.flex
                    .lang-box__sidebar.bg-gray-100.rounded.p-3
                        .p-2.text-xs.font-bold.text-gray-600 PAGES
                        ul
                            li(v-for="(group, groupIndex) in groupList" :key="group.index")
                                button.main-level.flex.items-center.justify-between(@click="loadGroupItem(group.id)" v-bind:class="")
                                    span {{ group.group_name }}

                    .lang-box__content.pl-8.flex-grow
                        .card
                            header.card__header.flex.items-center.justify-between.pb-4
                                h2.font-bold {{ activeGroup ? activeGroup.group_name : '' }}
                                .header-cta.flex.items-center
                                    button.btn-primary.btn-small(
                                        @click="addTranslation = !addTranslation"
                                    ) Add content
                            .card__body
                                .box-configuration(v-bind:class="{'box-configuration--show' : addTranslation}")
                                    .box-configuration__inner.p-6.bg-gray-200.border.border-gray-400.rounded.mb-6
                                        .flex.items-center
                                            .field.mr-3.flex-grow
                                                label.text-xs.uppercase.font-bold.text-gray-600 Content
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="content name"
                                                    v-model="contentName"
                                                )
                                            .field.mr-3.flex-grow
                                                label.text-xs.uppercase.font-bold.text-gray-600 ID
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ID"
                                                    v-model="translationID"
                                                )
                                            .field.mr-3.flex-grow
                                                label.text-xs.uppercase.font-bold.text-gray-600 EN
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="EN"
                                                    v-model="translationEN"
                                                )
                                            .block
                                                label.block.opacity-0 Value
                                                button.btn-primary(@click="createTranslation()") Add
                                .box-table
                                    table.table.table-set.table-lang.w-full
                                        thead
                                            tr
                                                th Content
                                                th ID
                                                th EN
                                                th
                                        tbody
                                            tr(v-for="(translation, translationIndex) in translationList" :key="translation.index")
                                                td
                                                    .monoscape {{ translation.translation }}
                                                td(v-for="(language, languageIndex) in translation.translation_list" :key="languageIndex")
                                                    template(v-if="translation.edit === true")
                                                        .flex.items-center
                                                            .block.mr-3.flex-grow
                                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                                    type="text"
                                                                    v-model="language.translation"
                                                                )
                                                            button.btn-primary-o.btn-small(@click="confirmEdit(translation)" v-if="languageIndex+1 == translation.translation_list.length") save
                                                    template(v-else)
                                                        .block {{ language.translation }}
                                                td.action-2
                                                    button.btn-default.btn-icon(v-if="translation.edit === true" v-on:click="cancelEdit(translation)")
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>
                                                    button.btn-default.btn-icon(v-else="" v-on:click="translation.edit = !translation.edit")
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                    button.btn-default.btn-icon(@click="destroyTranslation(translation.id)")
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                                Pagination( :pagination="pagination" @paginate="loadGroupItem(activeGroup.id)" )

</template>

<script>
import Vue from "vue";
import Pagination from "../../../components/content/Pagination";

export default {
    name: 'SettingsLanguage',
    components: {
        Pagination,
    },
    methods: {
        loadGroup() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/translation/group')
                .then(response => {
                    this.groupList = response.data.data
                    this.activeGroup = response.data.data[0];
                    this.loadGroupItem(response.data.data[0].id)
                })
        },
        loadGroupItem(groupId) {
            let params = '&page='+this.pagination.current_page;
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/translation?group_id='+groupId+params)
                .then(response => {
                    this.translationList = response.data.data.map(v => {
                        this.$set(v, 'edit', false); // https://vuejs.org/v2/guide/reactivity.html
                        v.originalTranslation = v.translation_list; //  will be used when user click the cancel botton
                        return v
                    });
                    this.pagination = response.data.meta;

                    this.activeGroup = this.groupList.find(x => x.id === groupId);
                })
        },
        createGroup() {
            let data = {
                "slug": this.groupName,
                "group_name": this.groupName,
            }

            Vue.prototype.$http
                .post(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/translation/group', data)
                .then(() => {
                    this.loadGroup()
                    this.addGroup = false
                })
        },
        createTranslation() {
            let data = {
                "key": this.contentName,
                "translation": this.contentName,
                "group_id": this.activeGroup.id,
            }
            let dataL1 = {
                "language_id": 1,
                "translation": this.translationID,
                "group_id": this.activeGroup.id,
            }
            let dataL2 = {
                "language_id": 2,
                "translation": this.translationEN,
                "group_id": this.activeGroup.id,
            }

            Vue.prototype.$http
                .post(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/translation', data)
                .then((response) => {
                    Vue.prototype.$http
                        .patch(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/translation/'+response.data.data.id, dataL1)
                        .then(() => {
                            Vue.prototype.$http
                                .patch(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/translation/'+response.data.data.id, dataL2)
                                .then(() => {
                                    this.addTranslation = false
                                    this.loadGroupItem(this.activeGroup.id)
                                })
                        })

                })
        },
        cancelEdit(row) {
            row.translation_list = row.originalTranslation
            row.edit = false
        },
        confirmEdit(row) {
            row.translation_list.forEach((list) => {
                let data = {
                    "translation": list.translation,
                    "language_id": list.language_id,
                    "group_id": this.activeGroup.id
                }

                Vue.prototype.$http
                    .patch(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/translation/'+list.translation_id, data)
                    .then(() => {
                        row.originalTranslation = row.translation_list
                        row.edit = false
                    })
            })
        },
        destroyTranslation (id) {
            if (confirm('Are you sure?')) {
                Vue.prototype.$http
                    .delete(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/translation/'+ id)
                    .then(() => {
                        this.loadGroupItem(this.activeGroup.id)
                    })
            }
        },
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Vouchers',
                    url: '/vouchers',
                },
            ],
            addGroup: false,
            addTranslation: false,
            groupName: '',
            contentName: '',
            translationID: '',
            translationEN: '',
            groupList: null,
            activeGroup: null,
            translationList: null,
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    created() {
        this.loadGroup()
    },
}
</script>

<style lang="sass" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@900&display=swap')

    .box-configuration
        display: none
        &--show
            display: block

    .monoscape
        font-family: 'Inconsolata', monospace

    .lang-box
        &__sidebar
            width: 100%
            max-width: 180px
            font-size: 14px
            ul
                li
                    border-bottom: 1px solid lighten(#e2e8f0, 3%)
                    color: #6D6E71
                    &:last-child
                        border-bottom: 0px
                    .main-level
                        padding: 12px 8px
                        .icon
                            opacity: 0.4
                    &.tree
                        .sub-menu
                            display: none
                            li
                                padding: 6px 6px 6px 16px

                    &.tree--show
                        .icon
                            transform: rotate(180deg)
                        .sub-menu
                            display: block
                            padding-bottom: 4px
                            li
                                border-bottom: 0px
                                a.is-active
                                    font-weight: bold
                                    color: #AC854F
    .table-set
        tr
            th,
            td
                padding: 12px 16px
                border-bottom: 1px solid #f1f7fb
                transition: all 0.3s ease-in-out
            &:hover
                td
                    background-color: rgba(0, 0, 0, 0.0250 )
            th
                text-transform: uppercase
                text-align: left
                font-size: 11px
                opacity: 0.7
    .table-lang
        border: 1px solid #f0f4f6
        td
            font-size: 13px
        tbody
            tr:nth-of-type(even)
                td
                    background-color: #f0f4f6

    .action-2
        min-width: 100px
</style>