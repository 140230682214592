<template lang="pug">
    .reports-box
        .reports-box__header.p-4
            ul.flex.items-center.w-full.reports-menu
                li(v-for="list in reportsMenu" :key="list.id")
                    router-link(:to="list.url")
                        | {{ list.menu }}
        .reports-box__content.p-4
            router-view
</template>

<script>
export default {
    name: 'ReportsShell',
    data(){
        return {
            reportsMenu: [
                {
                    url:'/reports/customers',
                    menu: 'Customers'
                },
                {
                    url:'/reports/vouchers',
                    menu: 'Vouchers'
                },
                {
                    url:'/reports/transactions',
                    menu: 'Transactions'
                },
                {
                    url:'/reports/partners',
                    menu: 'Partners'
                },
                // {
                //     url:'/reports/services',
                //     menu: 'Services'
                // },
            ]
        }
    }
}
</script>

<style lang="sass" scoped>
    .reports-menu
        li
            a
                display: block
                padding: 6px 12px
                border-radius: 100px
                font-weight: bold
                transition: all 0.3s ease-in-out
                opacity: 0.7
                font-size: 13px
                margin-right: 4px
                &.router-link-active
                    opacity: 1
                    background-color: #c4dae8
                &:hover
                    background-color: lighten(#c4dae8, 8% )

</style>