<template lang="pug">
    .report-page
        vue-tabs
            v-tab(title="Claimed")
                ClaimedActivity
            v-tab(title="Redeemed")
                RedeemedActivity
            v-tab(title="Membership")
                MembershipActivity
            v-tab(title="Categories")
                CategoriesActivity

</template>

<script>
import {VueTabs, VTab} from 'vue-nav-tabs'
import ClaimedActivity from './ClaimedActivity.vue'
import RedeemedActivity from './RedeemedActivity.vue'
import MembershipActivity from './MembershipActivity.vue'
import CategoriesActivity from './CategoriesActivity.vue'

export default {
    name: 'ReportsVouchers',
    components: {
        VueTabs,
        VTab,
        ClaimedActivity,
        RedeemedActivity,
        MembershipActivity,
        CategoriesActivity,
    },
}
</script>