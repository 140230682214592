<template lang="pug">
    .tab
        .tab-header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-4
            h2.font-bold Report Vouchers By Categories
            ExportButton(export-id="voucher-data-export-id" export-end-point="/api/v1/management/memberships/master/report/voucher/data/export")

        .tab-body
            .grid.grid-cols-7.gap-x-1.mb-8

                .bg-gray-900.text-gray-100.p-4.mb-1.rounded(v-for="(category) in voucherData")
                    span.text-xs.opacity-75.uppercase {{ category.name }}
                    .flex.items-center.justify-between.w-full
                        h2.text-3xl {{ category.catalogue_count }}
                        //- .text-sm 20%

            section.mb-8
                .grid.grid-cols-8.gap-x-4.mb-4
                    .col-span-4
                        highcharts(
                            :options="mainchart"
                        )

                    .col-span-4
                        highcharts(
                            :options="piechart"
                        )
            section.mb-4
                .mb-4
                    h3.font-bold Vouchers By Categories
                .box-table
                    table.table.table-set.w-full
                        thead
                            tr
                                th Categories
                                th Total Vouchers
                        tbody
                            tr(v-for="(category) in voucherData")
                                td {{ category.name }}
                                td {{ category.catalogue_count }}

</template>

<script>
import {Chart} from 'highcharts-vue'
import Vue from "vue";
import ExportButton from "@/components/content/ExportButton";

export default {
    name: 'CategoriesActivity',
    components: {
        highcharts: Chart,
        ExportButton
    },
    data() {
        return {
            mainchart: {
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: [
                        'Hotel',
                        'Restaurant',
                        'Gym',
                        'Spa',
                        'Ballroom',
                        'Meeting',
                        'Others',

                    ]
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                tooltip: {
                    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                    shared: true
                },

                series: [
                    {
                        name: 'Vouchers',
                        data: [],
                    },
                ]
            },
            piechart: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: ''
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                series: [{
                    name: 'Brands',
                    colorByPoint: true,
                    data: [{
                        name: 'Hotel',
                        y: 20,
                    }, {
                        name: 'Restaurant',
                        y: 20
                    }, {
                        name: 'Spa',
                        y: 10
                    }, {
                        name: 'Gym',
                        y: 15
                    }, {
                        name: 'Ballroom',
                        y: 15
                    }, {
                        name: 'Meeting',
                        y: 10
                    }, {
                        name: 'Others',
                        y: 5
                    }]
                }]
            },
            voucherData: null,
        }
    },
    methods : {
        load() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/voucher/category')
                .then((response) => {
                    this.voucherData = response.data.data
                    let mainChartCategories = [], mainChartData = [];
                    let piechartData = this.voucherData.map((cat) => {
                        mainChartCategories.push(cat.name);
                        mainChartData.push(cat.catalogue_count);
                        return { name: cat.name, y: cat.catalogue_count };
                    })

                    this.piechart.series[0].data = piechartData
                    // this.mainchart.series[0].data = Object.values(this.voucherData).slice(0, -1)
                    // this.mainchart.xAxis.categories = Object.keys(this.voucherData).slice(0, -1)
                    this.mainchart.series[0].data = mainChartData
                    this.mainchart.xAxis.categories = mainChartCategories

                })
        },
        exportReport(){
            let exportId = localStorage.getItem('voucher-data-export-id');
            if (exportId) {
                Vue.prototype.$http
                    .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/check?export_id='+exportId)
                    .then((response) => {
                        if ( response.data.data.name ) {
                            window.open(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/'+response.data.data.name, '_self')
                            localStorage.removeItem('voucher-data-export-id');
                        } else {
                            alert('Your file is still on progress... come back later to check your file!');
                        }
                    })
            } else {
                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/voucher/data/export')
                    .then((response) => {
                        localStorage.setItem('voucher-data-export-id', response.data.data.id);
                        alert('Exporting your file... come back later to check your file!');
                    })
            }
        }
    },
    created() {
        this.load()
    },

}
</script>

<style lang="sass" scoped>
.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>
