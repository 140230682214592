<template lang="pug">
    main#main-layout.main-layout.bg-gray-100.text-gray-700
        Notifications
        header.main-layout__header.flex.items-center.justify-between
            HeaderShell
        aside.main-layout__sidebar.bg-black
            SidebarShell
        section.main-layout__content
            slot
            Footer
</template>

<script>

    import HeaderShell from './../shell/contents/Header';
    import SidebarShell from './../shell/contents/Sidebar';
    import Footer from './../components/layouts/Footer';
    import Notifications from "../components/content/TheNotifications";
    import {mapGetters} from "vuex";
    export default {
        name: 'MainShell',
        components: {
            Notifications,
            HeaderShell,
            SidebarShell,
            Footer
        },
        computed: {
            ...mapGetters({
                notificationsList: 'notificationsList',
            }),
        }
    }
</script>

<style lang="sass">
    .calendars
        display: flex
    .main-layout
        display: grid
        grid-template-areas: "aside header header" "aside content content"
        grid-template-columns: 200px auto
        grid-template-rows: 64px auto
        &__header
            grid-area: header
        &__sidebar
            grid-area: aside
        &__content
            grid-area: content
            height: calc(100vh - 64px)
            overflow: auto
            .main-height
                height: 1000px
        // GOES HERE

        $color-primary: #AC854F

        .link
            &:hover
                text-decoration: underline

        .transition
            transition: all 0.3s ease-in-out

        // button
        .btn
            background-color: transparent
            display: inline-flex
            align-items: center
            justify-content: center
            padding: 0em 1em
            line-height: 40px
            border-width: 1px
            border-style: solid
            border-radius: 4px
            font-size: 1em
            min-width: 40px

        .btn-small
            min-width: 32px
            line-height: 32px
            padding: 0em 0.75em
            font-size: 0.9em


        .btn-primary-o
            @extend .btn
            @extend .transition
            border-color: $color-primary
            color: $color-primary
            &:hover
                background-color: $color-primary
                color: #ffffff

        .btn-primary
            @extend .btn
            @extend .transition
            border-color: $color-primary
            background-color: $color-primary
            color: #ffffff
            &:hover
                background-color: darken($color-primary, 5%)

        .btn-disabled
            cursor: default

        .btn-icon
            border-radius: 100%
            height: 32px
            width: 32px
            @extend .transition
            display: inline-flex
            justify-content: center
            align-items: center
            svg
                width: 18px
            &:hover
                background-color: rgba(0, 0, 0, 0.075%)

        // card

        .card--shadow
            box-shadow: 0 1px 3px 0 rgba(233, 242, 249, 100%), 0 1px 12px 0 rgba(216, 222, 226, 0.26%)

        .list-item
            &:last-child
                border-bottom: 0px
                padding-bottom: 0px
                margin-bottom: 0px

        // form
        .container-small
            max-width: 1000px
            margin: 0 auto

        // Tabs
        .vue-tabs
            background-color: #ffffff
            border-radius: 8px
            border: 1px solid #e8f2ff
            @extend .card--shadow
            .nav-tabs-wrapper
                ul.nav-tabs
                    display: flex
                    align-items: center
                    border-bottom: 1px solid #e8f2ff
                    li
                        a
                            padding: 16px
                            display: block
                            position: relative
                            @extend .transition
                            opacity: 0.7
                            &:after
                                content: ''
                                position: absolute
                                bottom: -2px
                                left: 0
                                width: 100%
                                height: 3px
                                border-radius: 2px
                                background-color: $color-primary
                                @extend .transition
                                opacity: 0
                            &:hover
                                opacity: 1
                                color: $color-primary
                        &.active
                            a
                                color: $color-primary
                                opacity: 1
                                &:after
                                    opacity: 1

            .tab-content
                padding: 24px
                min-height: 400px

        .action-2
            text-align: right


</style>