<template lang="pug">
    //- Login activity
    .card
        header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
            h2.font-bold Login Activity

        .card__body
            .list-item.border-b.border-gray-200.mb-6.pb-6(v-for="device in devicesLogin")
                .flex.items-center.justify-between.mb-4
                    .flex-grow
                        .flex.items-center
                            .flex.items-center.justify-center.w-10.h-10.rounded.bg-gray-200.text-gray-600
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-smartphone"><rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect><line x1="12" y1="18" x2="12.01" y2="18"></line></svg>
                            .pl-4
                                span.block {{ device.device_name }}
                                span.block.opacity-50 {{ device.created_at | moment('from') }}
                span.text-xs.flex.items-center.text-gray-500(v-if="device.location")
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                    span.block.ml-2 {{ device.location }}

    //- end Login activity
</template>

<script>
import Vue from "vue";

export default {
    name: 'LoginActivity',
    data() {
        return {
            devicesLogin: null,
        }
    },
    mounted() {
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/'+this.$route.params.id+'/devices-login-history')
            .then((response) => {
                this.devicesLogin = response.data.data
            })
    },
    methods: {
        forceLogout(){
            Vue.prototype.$http
                .delete(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/logout/'+this.$route.params.id)
                .then(() => {
                    this.$store
                        .commit('ADD_NOTIFICATION', {
                            title: 'Success!',
                            text: 'Logging out user',
                            type: 'success',
                            timeout: false,
                            delay: 0
                        });
                })
        }
    }
}
</script>