<template lang="pug">
    .breadcrumb.p-2.mb-4
        ul.flex.items-center
            li
                router-link(to="/") Dashboard
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
            //- li
            //-     router-link(to="/") page Level 1
            //-     <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
            //- li
            //-     router-link(to="/") page level 2
            //-     <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
            //- li
            //-     span current page
            li(v-for="(list,index) in ListBreadcrumb" :key="index")
                template(v-if="(index + 1) === ListBreadcrumb.length")
                    span.block.overflow-hidden.width-12 {{ list.name}}
                template(v-else)
                    router-link(:to="list.url") {{ list.name}}
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>

</template>

<script>
    export default {
        name: 'breadcrumb',
        props: {
            ListBreadcrumb: Array,
        }
    }
</script>

<style lang="sass" scoped>
    .breadcrumb
        ul
            li
                display: flex
                align-items: center
                &:first
                    a
                        padding-left: 0px
                svg
                span,
                a
                    font-size: 12px
                    padding: 0px 8px
                a
                    opacity: 0.8
                    &:hover
                        text-decoration: underline
                span
                    font-weight: bold
                    max-width: 300px
                    white-space: nowrap
                    text-overflow: ellipsis
</style>