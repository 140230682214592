<template lang="pug">
    #user-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            //- highlight customers
            .grid.grid-cols-5.gap-x-8.mb-4

                //- user info
                .col-span-3
                    vue-tabs
                        //- v-tab(title="Login Activity")
                            //- Login activity
                            ProfileLogin
                            //- end Login activity

                        v-tab(title="Password")
                            //- Password activity
                            ProfilePassword
                            //- end Password activity

                //- end user info

                //- user sidebar
                .col-span-2
                    .card.card--shadow.rounded.bg-white.p-8.relative
                        .absolute.top-0.right-0.p-4
                            router-link.btn-primary-o.btn-small(to="profile/edit") Edit
                        .flex.flex-col
                            .info-user__avatar.w-32.h-32.rounded-full.overflow-hidden.m-auto
                                img.w-full.h-full.object-cover(:src="userData.avatar ? userData.avatar : 'https://i.imgur.com/9XwzHPA.png'", alt="alt")
                            .info-user__account.text-gray-600.text-center.my-4
                                strong.text-xl.block.mb-2 {{ userData.name }}
                                span.inline-block.px-2.py-1.bg-gray-100.border.border-gray-300.rounded.font-bold.text-xs.block.text-center.uppercase(v-if="userData.role") {{ userData.role ? userData.role.name:  'Admin' }}
                            //- intro account
                            .p-8.text-gray-700
                                .flex.items-center.mb-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                    .block {{ userData.email }}
                                .flex.items-center.mb-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                    .block {{ userData.phone }}
                                .flex.items-center
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                    button(@click="onLogout").text-red-500 Logout

</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import {VueTabs, VTab} from 'vue-nav-tabs'
import ProfileLogin from './ProfileLogin'
import ProfilePassword from './ProfilePassword'


export default {
    name: 'UserPage',
    components: {
        Breadcrumb,
        VueTabs,
        VTab,
        ProfileLogin,
        ProfilePassword
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Profile',
                    url: '/profile',
                }
            ],
        }
    },
    computed: {
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
    },
    methods:{
        onLogout(){
            this.$store.dispatch('logout')
        }
    },
    created(){
        this.breadcrumb = [
            {
                name: 'Profile',
                url: '/profile',
            },
            {
                name: this.userData.name,
                url: '/profile',
            },
        ]
    },
}
</script>

<style lang="sass" scoped>
    .info-user
        transform: translateY(-24px)
</style>