<template lang="pug">
    #voucher-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            //- highlight customers
            .grid.grid-cols-4.gap-x-8.mb-4
                //- voucher info
                .block.col-span-3
                    .card.card--shadow.bg-white.border-gray-200.p-4.rounded.mb-8
                        header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                            .flex.items-center
                                MembershipTypeLabel(:membershipType="type.name" v-for="type in voucherData.membership_type")
                                h2.font-bold.ml-2 {{ voucherData.catalogue_name }}
                            .header-cta
                                router-link.btn-primary-o.btn-small(:to="{ name: 'GiftCardEdit', params: { id: voucherData.id } }") Edit
                        .card__body
                            .flex
                                .block
                                    .w-64.h-64
                                        .rounded.bg-orange-200.overflow-hidden
                                            img(:src="voucherData.thumbnail ? voucherData.thumbnail : 'https://i.imgur.com/9XwzHPA.png'")
                                .flex-grow.pl-4
                                    .flex.items-center.mb-6
                                        .border.bordr-gray-200.bg-gray-100.px-2.rounded.mr-3
                                            .flex.items-center
                                                //- .icon.w-8.h-8.flex.items-center.justify-center
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                                span {{ voucherData.category ? voucherData.category.name : '-' }}
                                        router-link.link.font-bold(to="/merchants/detail" v-if="voucherData.merchant")  {{ voucherData.merchant.company_name }}
                                    .block
                                        strong.text-xs.uppercase.text-gray-600.mb-2.block Syarat &amp; Ketentuan
                                        p(v-html="voucherData.catalogue_term")
                    .block
                        router-link.btn-primary-o(:to="{ name: 'PreviewVoucher', params: { id: voucherData.id}}" target="_blank")
                            .mr-2
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>
                            span Preview Transfer Point

                //- end voucher info

                //- voucher sidebar
                .block.col-span-1
                    .bg-white.text-gray-600.border.border-gray-300.p-4.rounded.mb-2
                        span.text-xs.block.mb-2.uppercase Published date
                        strong(v-if="(voucherData.status === 5)") -
                        strong(v-else) {{ voucherData.updated_at | moment('DD MMMM YYYY') }}
                    .bg-white.text-gray-600.border.border-gray-300.p-4.rounded.mb-2
                        span.text-xs.block.mb-2.uppercase Start date
                        strong(v-if="(voucherData.status === 5)") -
                        strong(v-else) {{ voucherData.period_start | moment('DD MMMM YYYY') }}
                    .bg-red-300.text-red-900.p-4.rounded.mb-2
                        span.text-xs.block.mb-2.uppercase expired date
                        strong {{ voucherData.period_end | moment('DD MMMM YYYY') }}
                    .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between.mb-2
                        .block 
                            span.text-xs.opacity-75.uppercase Total Redeemable
                            h2.text-3xl {{ voucherData.quantity | moneyFilter }}
                        .block.text-right.text-xs
                            span.block.text-gray-500 Available
                            span.text-red-300 {{ voucherData.quantity | moneyFilter }} left
                    .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between.mb-2
                        .block 
                            span.text-xs.opacity-75.uppercase Claimed
                            h2.text-3xl {{ voucherData.exchanged_count | moneyFilter }}
                    .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between.mb-2
                        .block 
                            span.text-xs.opacity-75.uppercase Reedemed
                            h2.text-3xl {{ voucherData.redeemed_count | moneyFilter }}
                //- end voucher sidebar
</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import MembershipTypeLabel from './../../components/content/MembershipTypeLabel';
import Vue from "vue";

export default {
    name: 'GiftCardDetail',
    components: {
        Breadcrumb,
        MembershipTypeLabel
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Transfer Point',
                    url: '/transfer-point',
                },
                {
                    name: 'Voucher Hotel 20% promo ramdahan Berkah 2020',
                    url: '#',
                },
            ],
            voucherData: null,
            membershipColorClass: 'gray',
        }
    },
    mounted() {
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue/' + this.$route.params.id)
            .then((response) => {
                this.voucherData = response.data.data
                this.breadcrumb = [
                    {
                        name: 'Transfer Point',
                        url: '/transfer-point',
                    },
                    {
                        name: this.voucherData.catalogue_name,
                        url: '/transfer-point/detail/'+this.voucherData.id,
                    },
                ]
            })
    },
}
</script>

<style lang="sass" scoped>

</style>