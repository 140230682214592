<template lang="pug">
    .tab-employee
        .mb-8.flex.items-center.justify-between
            h2.font-bold List POS Outlet ID
            .header-cta.flex.items-center
                button.btn-primary.btn-small.ml-2(
                    v-on:click="config = !config"
                )
                    template(v-if="config === true")
                        span Hide Configuration
                    template(v-else)
                        span Add Configuration

        .box-configuration(v-bind:class="{'box-configuration--show' : config}")
            .box-configuration__inner.p-6.bg-gray-200.border.border-gray-400.rounded.mb-6
                .flex.items-center
                    .field.mr-3.flex-grow
                        label.text-xs.uppercase.font-bold.text-gray-600 POS Outlet ID
                        input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                            type="text"
                            placeholder="Add POS Outlet ID"
                            v-model="pos_outlet_id"
                        )
                    .block
                        label.block.opacity-0 Value
                        button.btn-primary(@click="create()") Add
        .box-table
            table.table.table-set.w-full
                thead
                    tr
                        th POS Outlet ID
                        th
                tbody
                    tr(v-for="(list, index) in dataList")
                        td
                            template(v-if="list.configEdit === true")
                                .flex.items-center
                                    .block.mr-3.flex-grow
                                        input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                            type="text"
                                            v-model="list.pos_outlet_id"
                                        )
                                    button.btn-primary-o.btn-small(@click="confirmEdit(list)") save
                            template(v-else)
                                .block.py-2.w-full {{ list.pos_outlet_id || '-' }}
                        td.action-2
                            button.btn-default.btn-icon(v-if="list.configEdit === true" v-on:click="cancelEdit(list)")
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>
                            button.btn-default.btn-icon(v-else="" v-on:click="list.configEdit = !list.configEdit")
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>

            Pagination( :pagination="pagination" @paginate="load()" )
</template>

<script>
import Pagination from "../../components/content/Pagination";
import Vue from "vue";

export default {
    name: 'Outlet',
    props: {
        merchantData: Object,
        primaryOutletId: Number,
    },
    watch: {
        merchantData: function() { // watch it
            this.load()
        }
    },
    components: {
        Pagination,
    },
    data(){
        return {
            dataList: null,
            config: false,
            configEdit: true,
            pos_outlet_id: '',
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    computed: {
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
        urlPrefix(){
            return this.userData.principal_staff === 1 ? 'master':'merchant';
        }
    },
    methods: {
        load() {
            let endPoint = '/api/v1/management/memberships/master/merchant/'+ this.$route.params.id +'/outlet/pos/'+this.merchantData.primary_outlet.id;
            let params = '?page=' + this.pagination.current_page;
            if(this.urlPrefix == 'merchant') endPoint = '/api/v1/management/memberships/merchant/outlet/pos/'+this.merchantData.primary_outlet.id
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + endPoint + params)
                .then(response => {
                    this.dataList = response.data.data.map(v => {
                        this.$set(v, 'configEdit', false)
                        v.originalID = v.pos_outlet_id //  will be used when user click the cancel botton
                        return v
                    })
                    this.pagination = response.data.meta
                })
        },
        create() {
            var data = {
                'outlet_name' : this.merchantData.primary_outlet.outlet_name +'-'+ this.pos_outlet_id,
                'outlet_address' : this.merchantData.primary_outlet.outlet_address,
                'pos_outlet_id': this.pos_outlet_id,
            }

            let endPoint = '/api/v1/management/memberships/master/merchant/'+ this.$route.params.id +'/outlet/';
            if(this.urlPrefix == 'merchant') endPoint = '/api/v1/management/memberships/merchant/outlet/'

            Vue.prototype.$http
                .post(process.env.VUE_APP_API_HOST + endPoint, data)
                .then(() => {
                    this.optionName = ''
                    this.optionValue = ''
                    this.load()
                    this.config = false
                })
        },
        cancelEdit(row) {
            row.pos_outlet_id = row.originalID
            row.configEdit = false
        },
        confirmEdit(row) {
            var data = {
                'pos_outlet_id': row.pos_outlet_id,
                'outlet_address' : this.merchantData.primary_outlet.outlet_address,
            }

            let endPoint = '/api/v1/management/memberships/master/merchant/'+ this.$route.params.id +'/outlet/'+row.id;
            if(this.urlPrefix == 'merchant') endPoint = '/api/v1/management/memberships/merchant/outlet/'+row.id;

            Vue.prototype.$http
                .patch(process.env.VUE_APP_API_HOST + endPoint, data)
                .then(() => {
                    row.configEdit = false
                    row.originalID = row.pos_outlet_id
                })
        },
    },
    mounted() {
        // this.load();
    },
}
</script>

<style lang="sass" scoped>

.box-configuration
    display: none
    &--show
        display: block


.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>
