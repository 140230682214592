<template lang="pug">
    #settings-box
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            //- highlight customers
            .grid.grid-cols-5.gap-x-8.mb-4

                //- user info
                .col-span-3
                    vue-tabs
                        v-tab(title="Recent Activity")
                            //- Password activity
                            EmployeeActivity
                            //- end Password activity


                //- end user info

                //- user sidebar
                .col-span-2
                    .card.card--shadow.rounded.bg-white.p-8.relative
                        .absolute.top-0.right-0.p-4
                            router-link.btn-primary-o.btn-small(:to="{ name: (urlPrefix == 'master' ? 'MerchantEmployeeEdit' : 'MerchantSelfEmployeeEdit'), params: {id: employeeData.id} }") Edit
                        .flex.flex-col
                            .info-user__avatar.w-32.h-32.rounded-full.overflow-hidden.m-auto
                                img.w-full.h-full.object-cover(:src="employeeData.officer_image ? employeeData.officer_image : 'https://i.imgur.com/9XwzHPA.png'", alt="alt")
                            .info-user__account.text-gray-600.text-center.my-4
                                strong.text-xl.block.mb-2 {{ employeeData.officer_name }}
                                span.inline-block.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Active
                            //- intro account
                            .p-8.text-gray-700
                                .flex.items-center.mb-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 26 26" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><g xmlns="http://www.w3.org/2000/svg" transform="translate(0 0)"><g transform="translate(1 1)"><path class="a" d="M13.035,14.58A6.808,6.808,0,1,0,8.19,12.6,6.807,6.807,0,0,0,13.035,14.58Z" transform="translate(-1 -1)"/><path class="a" d="M25,24.93A10.365,10.365,0,0,0,14.62,14.58H11.38A10.365,10.365,0,0,0,1,24.93" transform="translate(-1 -0.93)"/></g></g></svg>
                                    .block {{ employeeData.master_account.username }}
                                .flex.items-center.mb-4(v-if="employeeData.master_account.role")
                                    .icon.mr-4.opacity-50.w-6.h-6
                                        <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Apps</title><path d='M104 160a56 56 0 1156-56 56.06 56.06 0 01-56 56zM256 160a56 56 0 1156-56 56.06 56.06 0 01-56 56zM408 160a56 56 0 1156-56 56.06 56.06 0 01-56 56zM104 312a56 56 0 1156-56 56.06 56.06 0 01-56 56zM256 312a56 56 0 1156-56 56.06 56.06 0 01-56 56zM408 312a56 56 0 1156-56 56.06 56.06 0 01-56 56zM104 464a56 56 0 1156-56 56.06 56.06 0 01-56 56zM256 464a56 56 0 1156-56 56.06 56.06 0 01-56 56zM408 464a56 56 0 1156-56 56.06 56.06 0 01-56 56z'/></svg>
                                    .block Manager
                                .flex.items-center.mb-4(v-if="employeeData.master_account.role")
                                    .icon.mr-4.opacity-50.w-6.h-6
                                        <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Apps</title><path d='M104 160a56 56 0 1156-56 56.06 56.06 0 01-56 56zM256 160a56 56 0 1156-56 56.06 56.06 0 01-56 56zM408 160a56 56 0 1156-56 56.06 56.06 0 01-56 56zM104 312a56 56 0 1156-56 56.06 56.06 0 01-56 56zM256 312a56 56 0 1156-56 56.06 56.06 0 01-56 56zM408 312a56 56 0 1156-56 56.06 56.06 0 01-56 56zM104 464a56 56 0 1156-56 56.06 56.06 0 01-56 56zM256 464a56 56 0 1156-56 56.06 56.06 0 01-56 56zM408 464a56 56 0 1156-56 56.06 56.06 0 01-56 56z'/></svg>
                                    .block Manager ID : {{ employeeData.officer_id }}
                                .flex.items-center.mb-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                    .block {{ employeeData.master_account.email }}
                                .flex.items-center.mb-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                    .block {{ employeeData.master_account.phone }}
                                .flex.items-start.border-t.border-dashed.pt-4.mb-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                    .block
                                        button.btn.btn-danger-o.border-red-500.text-red-500.btn-small(@click="sendPassword()" :disabled="submitStatus === 'PENDING'") Send Password to email
                                .flex.items-start.mb-4
                                    .icon.mr-4.opacity-50
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                    .block
                                        button.btn.btn-danger-o.border-red-500.text-red-500.btn-small(@click="sendPIN()" :disabled="submitStatus === 'PENDING'") Send New PIN to email

</template>

<script>
import {VueTabs, VTab} from 'vue-nav-tabs'
import EmployeeLogin from './EmployeeLogin.vue'
import EmployeeActivity from './EmployeeActivity.vue'
import Vue from "vue";
import Breadcrumb from "../../../components/content/breadcrumb";


export default {
    name: 'MerchantEmployeeDetail',
    components: {
        VueTabs,
        VTab,
        EmployeeLogin,
        EmployeeActivity,
        Breadcrumb
    },
    data() {
        return {
            submitStatus: null,
            employeeData: null,
            merchantData: null,
            breadcrumb: [
                {
                    name: 'Partners',
                    url: '/partners',
                },
                {
                    name: '...',
                    url: '/detail',
                },
                {
                    name: 'New Employee',
                    url: '/create',
                },
            ],
        }
    },
    computed: {
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
        urlPrefix(){
            return this.userData.principal_staff === 1 ? 'master':'merchant';
        }
    },
    methods: {
        load(){
            let endPoint = '/api/v1/management/memberships/master/merchant/'+this.$route.params.merchantId+'/outlet/'+this.merchantData.primary_outlet.id+'/officer/' + this.$route.params.id
            if (this.urlPrefix == 'merchant') endPoint = '/api/v1/management/memberships/merchant/outlet/'+ +this.merchantData.primary_outlet.id +'/master-officer/' + this.$route.params.id
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + endPoint)
                .then((response) => {
                    var data = response.data.data;
                    this.employeeData = data
                    if (this.urlPrefix == 'merchant') {
                        this.breadcrumb = [
                            {
                                name: 'Partners',
                                url: '/partner-dashboard',
                            },
                            {
                                name: data.officer_name,
                                url: '/partners/employee/detail/'+data.id,
                            },
                        ]
                    } else {
                        this.breadcrumb = [
                            {
                                name: 'Partners',
                                url: '/partners',
                            },
                            {
                                name: this.merchantData.company_name,
                                url: '/partners/detail/'+this.merchantData.id,
                            },
                            {
                                name: data.officer_name,
                                url: '/partners/'+this.merchantData.id+'/employee/detail/'+data.id,
                            },
                        ]
                    }
                })
        },
        sendPIN() {
            if (confirm('Are you sure?')) {
                this.submitStatus = 'PENDING'
                this.$store
                    .commit('ADD_NOTIFICATION', {
                        title: 'Sending...',
                        text: 'Sending...',
                        type: 'alert-info',
                        timeout: false,
                        delay: 0
                    });
                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/admin/user/' + this.employeeData.master_account.id + '/reset/pin/')
                    .then(() => {
                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Sending PIN!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                        this.submitStatus = null
                    })
            }
        },
        sendPassword() {
            if (confirm('Are you sure?')) {
                this.submitStatus = 'PENDING'
                this.$store
                    .commit('ADD_NOTIFICATION', {
                        title: 'Sending...',
                        text: 'Sending...',
                        type: 'alert-info',
                        timeout: false,
                        delay: 0
                    });
                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/admin/user/' + this.employeeData.master_account.id + '/reset/password/')
                    .then(() => {
                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Sending Password!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                        this.submitStatus = null
                    })
            }
        },
    },
    created() {
        if (this.userData.principal_staff === 1) {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/merchant/' + this.$route.params.merchantId)
                .then((response) => {
                    this.merchantData = response.data.data
                    this.load();
                })
        } else {
            this.merchantData = this.userData.merchant
            this.load();
        }
    },
}
</script>

<style lang="sass" scoped>
    .info-user
        transform: translateY(-24px)
</style>