<template lang="pug">
    span(
        v-if="membershipType.toLowerCase().includes('non')"
        v-bind:class="[defaultClass, displayClass, isFullWidth ? '':widthClass, nonActiveClass]"
    ) {{ membershipType }}
    span(
        v-else-if="membershipType.toLowerCase().includes('bronze')"
        v-bind:class="[defaultClass, displayClass, isFullWidth ? '':widthClass, bronzeClass]"
    ) {{ membershipType }}
    span(
        v-else-if="membershipType.toLowerCase().includes('silver')"
        v-bind:class="[defaultClass, displayClass, isFullWidth ? '':widthClass, silverClass]"
    ) {{ membershipType }}
    span(
        v-else-if="membershipType.toLowerCase().includes('gold')"
        v-bind:class="[defaultClass, displayClass, isFullWidth ? '':widthClass, goldClass]"
    ) {{ membershipType }}
    span(
        v-else-if="membershipType.toLowerCase().includes('platinum')"
        v-bind:class="[defaultClass, displayClass, isFullWidth ? '':widthClass, platinumClass]"
    ) {{ membershipType }}
</template>

<script>
    export default{
        name: 'MembershipTypeLabel',
        props: {
            membershipType: {
                type: String,
            },
            width: {
                type: String,
            },
            display: {
                type: String,
            },
        },
        data() {
            return {
                defaultClass: 'px-2 py-1 rounded font-bold text-xs text-center uppercase',
                nonActiveClass: 'bg-gray-600 text-gray-200',
                bronzeClass: 'bg-yellow-800 text-yellow-500',
                silverClass: 'bg-gray-300 text-gray-900',
                goldClass: 'bg-orange-300 text-orange-900',
                platinumClass: 'bg-blue-300 text-blue-900',
                widthClass: 'w-24',
            }
        },
        computed: {
            isFullWidth: function () {
                return (this.width == '100');
            },
            displayClass: function() {
                return this.display ? this.display : 'block';
            }
        },
    }

</script>