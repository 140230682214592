<template lang="pug">
    #voucher-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                    h2.font-bold {{ editing ? 'Edit' : 'New' }} Voucher
                    .header-cta.flex.items-center
                        router-link(to="/vouchers").link.font-sx
                            span Back
                .card__body
                    form(action="" method="method" @submit.prevent="onSubmit(0)")
                        section.border-b.border-blue-100.mb-8.pb-8
                            .container-small
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Name
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            //- .mb-4.border.p-3.bg-gray-100
                                                span.block.text-xs.uppercase.mb-2.text-blue-500.font-bold Indonesia version
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: Voucher Hotel 25% promo akrhir tahun"
                                                    v-model="$v.form.name.$model"
                                                    :class="{ 'border-red-500': $v.form.name.$error }"
                                                )
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.name.$error")
                                                    span.info.text-xs This field is required
                                                    //- span.info.text-xs 0/125
                                            //- .border.p-3.bg-gray-100
                                                span.block.text-xs.uppercase.mb-2.text-blue-500.font-bold English version
                                                .inline-block.relative.w-full.mr-4
                                                    input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                        type="text"
                                                        placeholder="ex: Voucher Hotel 25% promo akrhir tahun"
                                                        v-model="$v.form.nameEN.$model"
                                                        :class="{ 'border-red-500': $v.form.nameEN.$error }"
                                                    )
                                                    .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.nameEN.$error")
                                                        span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Category
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    v-model="$v.form.categoryId.$model"
                                                    :class="{ 'border-red-500': $v.form.categoryId.$error }"
                                                )
                                                    option Select Category
                                                    option(v-for="category in voucherCategories" :value="category.id") {{ category.name }}
                                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                    <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.categoryId.$error")
                                                span.info.text-xs This field is required

                                .field(v-if="this.userData.principal_staff === 1")
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Upragde Gift Voucher
                                                small.text-red-500(style="padding-left:4px")
                                        .flex-grow
                                            .w-full.flex.items-center.flex-wrap
                                                .field-checkbox.mr-8.mb-4()
                                                    input( type="checkbox" v-model="$v.form.is_upgrade_gift.$model" value="1" @change="updateGiftType($event)")
                                                    .ui-checkbox
                                                        .bullet
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        span.ml-2 Is Upragde Gift Voucher?
                                                .flex.items-center.justify-between.mt-2.text-gray-500
                                                    span.info.text-xs Check this field if it is an free voucher when upgrading membership
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.is_upgrade_gift.$error")
                                                    span.info.text-xs This field is required


                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Level
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow(v-if="form.is_upgrade_gift == 0")
                                            .w-full.flex.items-center.flex-wrap
                                                .field-checkbox.mr-8.mb-4(v-for="(optionType, index) in membershipType" :key="optionType.index")
                                                    input( type="checkbox" v-model="$v.membershipTypeInput.$model" :value="optionType.id")
                                                    .ui-checkbox
                                                        .bullet
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        span.ml-2 {{ optionType.name }}
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.membershipTypeInput.$error")
                                                    span.info.text-xs This field is required
                                        .flex-grow(v-if="form.is_upgrade_gift == 1")
                                            .inline-block.relative.w-full
                                                select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    v-model="$v.membershipTypeInput.$model"
                                                    :class="{ 'border-red-500': $v.membershipTypeInput.$error }"
                                                )
                                                    option Select Membership Type
                                                    option(v-for="optionType in membershipType" :value="optionType.id") {{ optionType.name }}
                                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                    <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.membershipTypeInput.$error")
                                                span.info.text-xs This field is required

                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Select Partners
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                multiselect(
                                                    v-model="$v.form.merchantId.$model",
                                                    :options="merchantList",
                                                    :multiple="true",
                                                    :close-on-select="false",
                                                    :clear-on-select="false",
                                                    :preserve-search="true",
                                                    placeholder="Select Partner"
                                                    label="company_name",
                                                    track-by="id",
                                                    :preselect-first="true"
                                                )
                                                    template(
                                                      slot="selection"
                                                      slot-scope="{ values, search, isOpen }"
                                                    )
                                                        span.multiselect__single(v-if="values.length && !isOpen")
                                                            | {{ values.length }} options selected
                                                //- div
                                                    select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                        v-model="$v.form.merchantId.$model"
                                                        :class="{ 'border-red-500': $v.form.merchantId.$error }"
                                                    )
                                                        option(value="0") Select Partners
                                                        option(v-for="(merchant, index) in merchantList" :key="merchant.index" :value="merchant.id") {{ merchant.company_name }}
                                                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                        <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.merchantId.$error")
                                                span.info.text-xs This field is required

                                .field(v-if="userData.principal_staff == 1")
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Order Position
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: 1"
                                                    v-model.lazy="$v.form.order_pos.$model"
                                                    v-money="money"
                                                    :class="{ 'border-red-500': $v.form.order_pos.$error }"
                                                )
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.order_pos.$error")
                                                    span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Quantity
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: 2.000"
                                                    v-model.lazy="$v.form.quantity.$model"
                                                    v-money="money"
                                                    :class="{ 'border-red-500': $v.form.quantity.$error }"
                                                )
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.quantity.$error")
                                                    span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Poin
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="ex: 1.000"
                                                    v-model.lazy="$v.form.point.$model"
                                                    v-money="money"
                                                    :class="{ 'border-red-500': $v.form.point.$error }"
                                                )
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.point.$error")
                                                    span.info.text-xs This field is required

                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Start date
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.rounded.shadow.leading-tight(
                                                    type="date"
                                                    placeholder="10/20/2020"
                                                    v-model="$v.form.periodStart.$model"
                                                    :class="{ 'border-red-500': $v.form.periodStart.$error }"
                                                )
                                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700.hidden
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.periodEnd.$error")
                                                span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Expired date
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.rounded.shadow.leading-tight(
                                                    type="date"
                                                    placeholder="10/20/2020"
                                                    v-model="$v.form.periodEnd.$model"
                                                    :class="{ 'border-red-500': $v.form.periodEnd.$error }"
                                                )
                                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700.hidden
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>

                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.periodEnd.$error")
                                                span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Cover
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .w-full.bg-gray-200.mb-2.overflow-hidden(style="max-width: 600px")
                                                img.w-full.rounded(
                                                    v-if="coverImage"
                                                    :src="coverImage"
                                                    alt="alt"
                                                )
                                            .pt-4.w-64
                                                span.info.text-xs Please upload image with 1.080 x 1.080
                                                .block.mt-4
                                                   UploadMedia(:inputMedia.sync="coverImage")
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.coverImage.$error")
                                                span.info.text-xs This field is required
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right
                                            label.block.h-16.py-2 Term &amp; Condition
                                                small.text-red-500(style="padding-left:4px") *
                                        .block(class="w-3/4").ml-8
                                            .relative.w-full.mr-4
                                                //- .mb-4.border.p-3.bg-gray-100
                                                    span.block.text-xs.uppercase.text-blue-500.font-bold Indonesia version
                                                .inline-block.relative.w-full.mr-4
                                                    vueEditor(
                                                        v-model="$v.form.term.$model"
                                                        :class="{ 'border-red-500': $v.form.term.$error }"
                                                    )
                                                    .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.term.$error")
                                                        span.info.text-xs This field is required

                                                //- .border.p-3.bg-gray-100
                                                    span.block.text-xs.uppercase.text-blue-500.font-bold English version
                                                    .inline-block.relative.w-full.mr-4
                                                    vueEditor(
                                                        v-model="$v.form.termEN.$model"
                                                        :class="{ 'border-red-500': $v.form.termEN.$error }"
                                                    )
                                                    .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.termEN.$error")
                                                        span.info.text-xs This field is required
                        section
                            .flex.items-center.justify-end
                                //-button.link(type='button') Reset
                                button.btn-primary-o.ml-3(type='button' @click="add(5)" v-if="!editing" :disabled="submitStatus === 'PENDING'") Draft
                                button.btn-primary.ml-3(type='button' @click="add(1)" v-if="!editing && urlPrefix == 'master'" :disabled="submitStatus === 'PENDING'") Publish
                                button.btn-primary.ml-3(type='button' @click="add(6)" v-if="!editing && urlPrefix == 'merchant' && form.status != 1" :disabled="submitStatus === 'PENDING'") Request to Publish
                                button.btn-primary-o.ml-3(type='button' @click="update(5)" v-if="editing" :disabled="submitStatus === 'PENDING'") Draft
                                button.btn-primary.ml-3(type='button' @click="update(1)" v-if="editing && urlPrefix == 'master'" :disabled="submitStatus === 'PENDING'") Publish
                                button.btn-primary.ml-3(type='button' @click="update(6)" v-if="editing && urlPrefix == 'merchant' && ![1,6].includes(form.status)" :disabled="submitStatus === 'PENDING'") Request to Publish
                            p.typo__p.text-right.mt-3.text-green-500(v-if="submitStatus === 'OK'") Thanks for your submission!
                            p.typo__p.text-right.mt-3.text-red-500(v-if="submitStatus === 'ERROR'") Please fill the form correctly.
                            p.typo__p.text-right.mt-3.text-blue-500(v-if="submitStatus === 'PENDING'") Sending...

</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import UploadMedia from './../../components/content/UploadMedia';
import Multiselect from 'vue-multiselect'
import { VueEditor } from "vue2-editor";
import Vue from "vue";
import router from "../../router";
import { required } from 'vuelidate/lib/validators'
import {VMoney} from 'v-money'

export default {
    name: 'VoucherCreate',
    components: {
        Breadcrumb,
        UploadMedia,
        VueEditor,
        Multiselect
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Vouchers',
                    url: '/vouchers',
                },
                {
                    name: 'Create',
                    url: '/create',
                },
            ],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 0,
                masked: false
            },
            merchantList: [],
            membershipType: null,
            voucherCategories: null,
            form: {
                name: null,
                nameEN: "",
                categoryId: null,
                merchantId: [],
                description: "",
                descriptionEN: "",
                term: "",
                termEN: "",
                point: 1,
                order_pos: 0,
                quantity: 1,
                goodsWorthFor: '1',
                periodStart: Vue.moment().format('YYYY-MM-DD'),
                periodEnd: Vue.moment().format('YYYY-MM-DD'),
                status: 0,
                is_upgrade_gift: 0,
                is_gift_card: 1,
            },
            oldMembershipGiftType:0,
            value: [],
            membershipTypeInput : [],
            coverImage: 'https://i.imgur.com/9XwzHPA.png',
            coverImageLandscape: 'https://i.imgur.com/9XwzHPA.png',
            editing: false,
            submitStatus: null,
        }
    },
    directives: {money: VMoney},
    validations: {
        form: {
            name: { required },
            nameEN: {},
            categoryId: { required },
            merchantId: { },
            description: { },
            descriptionEN: { },
            term: { required },
            termEN: { },
            point: { },
            order_pos: { },
            quantity: { required },
            periodStart: { required },
            periodEnd: { required },
            is_upgrade_gift: { },
            is_gift_card: { },
        },
        membershipTypeInput: { required },
        coverImage: { required },
        coverImageLandscape: { required },
    },
    computed:{
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
        urlPrefix(){
            return this.userData.principal_staff === 1 ? 'master':'merchant';
        }
    },
    methods: {
        load() {
            let endPoint = process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/' + this.$route.params.id;
            Vue.prototype.$http
                .get(endPoint)
                .then((response) => {
                    var data = response.data.data
                    this.form = {
                        name: data.catalogue_name,
                        nameEN: data.catalogue_name_en,
                        categoryId: data.category ? data.category.id : 0,
                        // level: data.membership_type ? data.membership_type.id : [],
                        description: data.catalogue_description,
                        descriptionEN: data.catalogue_description_en,
                        term: data.catalogue_term,
                        termEN: data.catalogue_term_en,
                        point: data.point,
                        order_pos: data.order_pos,
                        quantity: data.quantity,
                        goodsWorthFor: data.goods_worth_for,
                        periodStart: data.period_start ? Vue.moment(data.period_start).format('YYYY-MM-DD') : Vue.moment().format('YYYY-MM-DD'),
                        periodEnd: data.period_end ? Vue.moment(data.period_end).format('YYYY-MM-DD') : Vue.moment().format('YYYY-MM-DD'),
                        status: data.status,
                        is_upgrade_gift: data.is_upgrade_gift,
                        is_gift_card: data.is_gift_card,
                    }
                    if (data.is_upgrade_gift == 1) {
                        this.membershipTypeInput = data.membership_type[0] ? data.membership_type[0].id : 0;
                        this.oldMembershipGiftType = data.membership_type[0] ? data.membership_type[0].id : 0;
                    } else {
                        this.membershipTypeInput = data.membership_type.map(function(value) {
                            return value.id;
                        });
                    }

                    this.form.merchantId = data.redeemable_at.map(function(value) {
                        return {
                            'id':value.merchant_id,
                            'company_name':value.brand_name,
                            'outlet_id':value.outlet_id,
                        };
                    });
                    this.coverImage = data.thumbnail ? data.thumbnail : 'https://i.imgur.com/9XwzHPA.png'
                    this.coverImageLandscape = data.catalogue_images[0] ? data.catalogue_images[0] : 'https://i.imgur.com/9XwzHPA.png'
                    this.breadcrumb = [
                        {
                            name: 'Vouchers',
                            url: '/vouchers',
                        },
                        {
                            name: data.catalogue_name,
                            url: '/vouchers/edit/'+data.id,
                        },
                    ]
                })
        },
        add (status) {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'

                var data = {
                    'catalogue_name': this.form.name,
                    'catalogue_name_en': this.form.nameEN,
                    'category_id': this.form.categoryId != 0 ? this.form.categoryId : null,
                    'membership_merchant_id': this.form.merchantId,
                    'catalogue_description': this.form.description,
                    'catalogue_description_en': this.form.descriptionEN,
                    'catalogue_term': this.form.term,
                    'catalogue_term_en': this.form.termEN,
                    'thumbnail': this.coverImage,
                    'catalogue_images': [this.coverImage],
                    'point': this.form.point.replace(/\./g,''),
                    'quantity': this.form.quantity.replace(/\./g,''),
                    'goods_worth_for': this.form.goodsWorthFor.toString().replace(/\./g,''),
                    'period_start': Vue.moment(this.form.periodStart).format('YYYY-MM-DD'),
                    'period_end': Vue.moment(this.form.periodEnd).format('YYYY-MM-DD'),
                    'status': status,
                    'is_upgrade_gift': this.form.is_upgrade_gift ? 1 :0,
                    'is_gift_card': 1,
                }
                if (this.urlPrefix == 'merchant') {
                    data.membership_merchant_id = [
                        {
                            'id' : this.userData.merchant.id,
                            'outlet_id' : this.userData.merchant.primary_outlet.id,
                            'company_name' : this.userData.merchant.company_name,
                        }
                    ]
                } else {
                    data.order_pos = this.form.order_pos
                }

                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue', data)
                    .then(response => {
                        if (this.form.is_upgrade_gift == 1) {
                            Vue.prototype.$http
                                .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue/'+ response.data.data.id +'/usertype', { 'membership_customer_type_id': this.membershipTypeInput })
                                .then((res) => {
                                    router.push({name: 'VoucherDetail', params: {id: res.data.data.id}})

                                    this.$store
                                        .commit('ADD_NOTIFICATION', {
                                            title: 'Success!',
                                            text: 'Success Updating Voucher!',
                                            type: 'success',
                                            timeout: false,
                                            delay: 0
                                        });
                                })
                        } else {
                            if (this.urlPrefix == 'merchant') {
                                this.membershipTypeInput.forEach((membershipId, index) => {
                                    Vue.prototype.$http
                                        .patch(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/'+ response.data.data.id +'/usertype', { 'membership_customer_type_id': membershipId })
                                        .then(() => {
                                            if (index == (this.membershipTypeInput.length - 1)) {
                                                router.push({ name: 'VoucherDetail', params: { id: response.data.data.id } })

                                                this.$store
                                                    .commit('ADD_NOTIFICATION', {
                                                        title: 'Success!',
                                                        text: 'Success Adding Voucher!',
                                                        type: 'success',
                                                        timeout: false,
                                                        delay: 0
                                                    });
                                            }
                                        })
                                })
                            } else {
                                this.membershipTypeInput.forEach((membershipId, index) => {
                                    Vue.prototype.$http
                                        .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/'+ response.data.data.id +'/usertype', { 'membership_customer_type_id': membershipId })
                                        .then(() => {
                                            if (index == (this.membershipTypeInput.length - 1)) {
                                                router.push({ name: 'VoucherDetail', params: { id: response.data.data.id } })

                                                this.$store
                                                    .commit('ADD_NOTIFICATION', {
                                                        title: 'Success!',
                                                        text: 'Success Adding Voucher!',
                                                        type: 'success',
                                                        timeout: false,
                                                        delay: 0
                                                    });
                                            }
                                        })
                                })
                            }
                        }

                    })
            }
        },
        update (status) {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'

                var data = {
                    'catalogue_name': this.form.name,
                    'catalogue_name_en': this.form.nameEN,
                    'category_id': this.form.categoryId != 0 ? this.form.categoryId : null,
                    'membership_merchant_id': this.form.merchantId,
                    'catalogue_description': this.form.description,
                    'catalogue_description_en': this.form.descriptionEN,
                    'catalogue_term': this.form.term,
                    'catalogue_term_en': this.form.termEN,
                    'thumbnail': this.coverImage,
                    'catalogue_images': [this.coverImage],
                    'point': this.form.point.replace(/\./g,''),
                    'quantity': this.form.quantity.replace(/\./g,''),
                    'goods_worth_for': this.form.goodsWorthFor.toString().replace(/\./g,''),
                    'period_start': Vue.moment(this.form.periodStart).format('YYYY-MM-DD'),
                    'period_end': Vue.moment(this.form.periodEnd).format('YYYY-MM-DD'),
                    'status': status,
                    'is_upgrade_gift': this.form.is_upgrade_gift ? 1 :0,
                    'is_gift_card': 1,
                }

                if (this.urlPrefix == 'merchant') {
                    data.membership_merchant_id = [
                        {
                            'id' : this.userData.merchant.id,
                            'outlet_id' : this.userData.merchant.primary_outlet.id,
                            'company_name' : this.userData.merchant.company_name,
                        }
                    ];
                } else {
                    data.order_pos = this.form.order_pos
                }

                Vue.prototype.$http
                    .put(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/'+ this.$route.params.id, {"status": "STATUS_DRAFT"})
                    .then(() => {
                        Vue.prototype.$http
                            .patch(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/'+ this.$route.params.id, data)
                            .then((response) => {
                                this.membershipType.forEach((membership, index) => {
                                    Vue.prototype.$http
                                        .delete(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/'+ this.$route.params.id +'/usertype/'+ membership.id)
                                        .then(() => {
                                            if (index == (this.membershipType.length - 1)) {
                                                if (this.form.is_upgrade_gift == 1) {
                                                    Vue.prototype.$http
                                                        .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/catalogue/'+ this.$route.params.id +'/usertype', { 'membership_customer_type_id': this.membershipTypeInput })
                                                        .then((res) => {
                                                            router.push({name: 'VoucherDetail', params: {id: res.data.data.id}})

                                                            this.$store
                                                                .commit('ADD_NOTIFICATION', {
                                                                    title: 'Success!',
                                                                    text: 'Success Updating Voucher!',
                                                                    type: 'success',
                                                                    timeout: false,
                                                                    delay: 0
                                                                });
                                                        })
                                                } else {
                                                    if (this.urlPrefix == 'merchant') {
                                                        this.membershipTypeInput.forEach((membershipId, index) => {
                                                            Vue.prototype.$http
                                                                .patch(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/'+ this.$route.params.id +'/usertype', { 'membership_customer_type_id': membershipId })
                                                                .then(() => {
                                                                    if (index == (this.membershipTypeInput.length - 1)) {
                                                                        router.push({ name: 'VoucherDetail', params: { id: response.data.data.id } })

                                                                        this.$store
                                                                            .commit('ADD_NOTIFICATION', {
                                                                                title: 'Success!',
                                                                                text: 'Success Updating Voucher!',
                                                                                type: 'success',
                                                                                timeout: false,
                                                                                delay: 0
                                                                            });
                                                                    }
                                                                })
                                                        })
                                                    } else {
                                                        this.membershipTypeInput.forEach((membershipId, index) => {
                                                            Vue.prototype.$http
                                                                .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/'+ this.$route.params.id +'/usertype', { 'membership_customer_type_id': membershipId })
                                                                .then(() => {
                                                                    if (index == (this.membershipTypeInput.length - 1)) {
                                                                        router.push({ name: 'VoucherDetail', params: { id: response.data.data.id } })

                                                                        this.$store
                                                                            .commit('ADD_NOTIFICATION', {
                                                                                title: 'Success!',
                                                                                text: 'Success Updating Voucher!',
                                                                                type: 'success',
                                                                                timeout: false,
                                                                                delay: 0
                                                                            });
                                                                    }
                                                                })
                                                        })
                                                    }
                                                }
                                            }
                                        })

                                })
                            })
                    })
            }

        },
        // updateFilter(membershipId, e) {
            // if (e.target.checked) {
            //     if(this.urlPrefix == 'merchant') {
            //         Vue.prototype.$http
            //             .patch(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/'+ this.$route.params.id +'/usertype', { 'membership_customer_type_id': membershipId })
            //             .then(() => {
            //             })
            //     } else {
            //         Vue.prototype.$http
            //             .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/'+ this.$route.params.id +'/usertype', { 'membership_customer_type_id': membershipId })
            //             .then(() => {
            //             })
            //     }
            //
            // } else {
            //     Vue.prototype.$http
            //         .delete(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/'+ this.$route.params.id +'/usertype/'+ membershipId)
            //         .then(() => {
            //         })
            // }
        // },
        updateGiftType(e) {
            if (!e.target.checked) {
                this.membershipTypeInput = [];
            } else {
                this.membershipTypeInput = null;
            }
        }
    },

    created() {
        if ("id" in this.$route.params) {
            this.load();
            this.editing = true;
        } else {
            // this
        }

        let typeEndPoint = 'customer/type?type=paid'
        if (this.urlPrefix == 'merchant') {
            typeEndPoint = 'catalogue/usertype?type=paid'
        }
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/'+typeEndPoint)
            .then(response => (this.membershipType = response.data.data))
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/'+this.urlPrefix+'/catalogue/category')
            .then(response => (this.voucherCategories = response.data.data))
        if (this.urlPrefix == 'master') {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/merchant?per_page=100')
                .then(response => {
                    this.merchantList = response.data.data.map((merchant) => {
                        return {'id' : merchant.id, 'company_name' : merchant.company_name, 'outlet_id': merchant.primary_outlet.id};
                    })
                })
        }
    },
    

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="sass" scoped>
    .field-checkbox
        position: relative
        cursor: pointer
        input
            width: 100%
            height: 100%
            position: absolute
            left: 0
            top: 0
            opacity: 0
            z-index: 3
            cursor: pointer
            &:checked
                +
                    .ui-checkbox
                        .bullet
                            border: 1px solid brown
                            svg
                                opacity: 1
                                stroke: brown
                        span
                            color: brown
        .ui-checkbox
            display: inline-flex
            align-items: center
            .bullet
                width: 24px
                height: 24px
                border-radius: 4px
                border: 1px solid #dddddd
                position: relative
                display: flex
                align-items: center
                justify-content: center
                transition: all 0.3s
                svg
                    opacity: 0
            span
</style>