<template lang="pug">
    #services-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .content-area.px-4
            //- service info
            .card.card--shadow.bg-white.border-gray-200.p-4.rounded.mb-8
                .card__body
                    .flex
                        .flex.flex-grow
                            .block
                                .w-32.h-32
                                    .rounded.bg-orange-200.overflow-hidden
                                        img(:src="serviceData.cover_image ? serviceData.cover_image : 'https://i.imgur.com/9XwzHPA.png'")
                            .flex-grow.pl-4
                                .flex.items-center.mb-3
                                    h2.font-bold.text-xl  {{ serviceData.name }}
                                    template(v-if="serviceData.status === 1")
                                        span.inline-block.ml-4.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Publish
                                    template(v-if="serviceData.status === 0")
                                        span.inline-block.ml-4.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Draft
                                span.text-xs.block.mb-4 {{ serviceData.address }}
                                .flex.items-center.mb-6
                                    .border.bordr-gray-200.bg-gray-100.pr-2.rounded.mr-3(v-for="option in serviceData.service_type" :key="option.id" v-if="option.status == 1")
                                        .flex.items-center.p-1
                                            .icon.w-8.h-8.flex.items-center.justify-center.mr-2
                                                img.rounded(src="https://i.imgur.com/9XwzHPA.png")
                                            span {{ option.name }}
                        .block
                            router-link.btn-primary-o.btn-small(:to="{ name: 'ServiceEdit', params:{id: serviceData.id}}") Edit

            //- service info
            //- .block
                InboxService

            //- end service info



</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import InboxService from './InboxService';
import Vue from "vue";

export default {
    name: 'ServiceDetail',
    components: {
        Breadcrumb,
        InboxService,
    },
    data() {
        return {
            serviceData: null,
            serviceOptionInput: null,
            breadcrumb: [
                {
                    name: 'Services',
                    url: '/services',
                },
                {
                    name: 'JHL Solitaire Hotel',
                    url: '/detail',
                },
            ],
        }
    },
    mounted() {
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/service/partner/' + this.$route.params.id)
            .then((response) => {
                this.serviceData = response.data.data
                this.serviceOptionInput = response.data.data.service_type.map((type) => {
                    if (type.status == 1) {
                        return type;
                    }
                })
                this.breadcrumb = [
                    {
                        name: 'Services',
                        url: '/services',
                    },
                    {
                        name: this.serviceData.name,
                        url: '/services/detail/'+this.serviceData.id,
                    },
                ]
            })
    },

}
</script>

<style lang="sass" scoped>

</style>