<template lang="pug">
    #merchant-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                    h2.font-bold New Employee
                    .header-cta.flex.items-center
                        router-link(:to="{ name: ( urlPrefix == 'master' ? 'MerchantDetail': 'MerchantDashboardPage'), params: { id: $route.params.merchantId }}").link.font-sx
                            span Back
                .card__body
                    form(action="" method="method")
                        section.border-b.border-blue-100.mb-8.pb-8
                            .container-small
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Name
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="Write a name here..."
                                                    v-model="$v.form.name.$model"
                                                    :class="{ 'border-red-500': $v.form.name.$error }"
                                                )
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.name.$error")
                                                    span.info.text-xs This field is required.field
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="validationErrorsData.name != null")
                                                    span.info.text-xs {{ validationErrorsData.name[0] }}
                                .flex.mb-6
                                    .block(class="w-1/4").text-right.mr-8
                                        label.block.h-16.py-2 Username
                                            small.text-red-500(style="padding-left:4px") *
                                    .flex-grow
                                        .inline-block.relative.w-full.mr-4
                                            input.block.appearance-none.w-full.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                type="text"
                                                placeholder="Write a username here..."
                                                v-model="$v.form.username.$model"
                                                :class="[{ 'border-red-500': $v.form.username.$error, 'opacity-50':editing }]"
                                                :disabled="editing"
                                                @keydown.space.prevent
                                            )
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.username.$error")
                                                span.info.text-xs This field is required
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="validationErrorsData.username != null")
                                                span.info.text-xs {{ validationErrorsData.username[0] }}

                                .flex.mb-6
                                    .block(class="w-1/4").text-right.mr-8
                                        label.block.h-16.py-2 Role
                                            small.text-red-500(style="padding-left:4px") *
                                    .flex-grow
                                        .inline-block.relative.w-full.mr-4
                                            select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                v-model="$v.form.role.$model"
                                                :class="[{ 'border-red-500': $v.form.role.$error }]"
                                            )
                                                option(value="0") Default Employee
                                                option(value="2") Manager
                                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.role.$error")
                                                span.info.text-xs This field is required
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="validationErrorsData.role != null")
                                                span.info.text-xs {{ validationErrorsData.role[0] }}
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Email
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="email"
                                                    placeholder="Write email here..."
                                                    v-model="$v.form.email.$model"
                                                    :class="{ 'border-red-500': $v.form.email.$error }"
                                                )
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.email.$error")
                                                    span.info.text-xs This field is required
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="validationErrorsData.email != null")
                                                    span.info.text-xs {{ validationErrorsData.email[0] }}
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Phone Number
                                                small.text-red-500(style="padding-left:4px")
                                        .flex-grow
                                            .inline-block.relative.w-full.mr-4
                                                input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                    type="text"
                                                    placeholder="Write phone number here..."
                                                    v-model="$v.form.phone.$model"
                                                    :class="{ 'border-red-500': $v.form.phone.$error }"
                                                )
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.phone.$error")
                                                    span.info.text-xs This field is required
                                                .flex.items-center.justify-between.mt-2.text-red-500(v-if="validationErrorsData.phone != null")
                                                    span.info.text-xs {{ validationErrorsData.phone[0] }}
                                //- .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Role
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .inline-block.relative.w-full
                                                select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight
                                                    option Choose Role
                                                    option Super Admin
                                                    option Admin
                                                    option Operator
                                                .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                    <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            //-.flex.items-center.justify-between.mt-2
                                                span.info.text-xs message
                                .field
                                    .flex.mb-6
                                        .block(class="w-1/4").text-right.mr-8
                                            label.block.h-16.py-2 Photo
                                                small.text-red-500(style="padding-left:4px") *
                                        .flex-grow
                                            .cover-voucher.w-64.h-64.bg-gray-200.rounded-full.mb-2
                                                img.w-full.h-full.object-cover.rounded-full(
                                                    v-if="avatar"
                                                    :src="avatar ? avatar : 'https://i.imgur.com/9XwzHPA.png'", alt="alt"
                                                )
                                            .pt-4.text-center.w-64
                                                span.info.text-xs Please upload image with 400 x 400
                                                .block.mt-4
                                                    UploadMedia(:inputMedia.sync="avatar")
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.avatar.$error")
                                                span.info.text-xs This field is required

                        section
                            .flex.items-center.justify-end
                                //-button.link(type='submit') Reset
                                //- button.btn-primary-o.ml-3(type='submit') Deactive
                                button.btn-primary.ml-3(type='button' @click="add()" v-if="!editing" :disabled="submitStatus === 'PENDING'") Submit
                                button.btn-primary.ml-3(type='button' @click="update()" v-if="editing" :disabled="submitStatus === 'PENDING'") Submit
                            p.typo__p.text-right.mt-3.text-green-500(v-if="submitStatus === 'OK'") Thanks for your submission!
                            p.typo__p.text-right.mt-3.text-red-500(v-if="submitStatus === 'ERROR'") There is error. Please check your input
                            p.typo__p.text-right.mt-3.text-blue-500(v-if="submitStatus === 'PENDING'") Sending...

</template>

<script>
import Breadcrumb from './../../../components/content/breadcrumb';
import UploadMedia from "../../../components/content/UploadMedia";
import Vue from "vue";
import router from "../../../router";
import {required} from "vuelidate/lib/validators";

export default {
    name: 'MerchantEmployee',
    components: {
        Breadcrumb,
        UploadMedia,
    },
    data() {
        return {
            avatar: 'https://i.imgur.com/9XwzHPA.png',
            breadcrumb: [
                {
                    name: 'Partners',
                    url: '/partners',
                },
                {
                    name: '...',
                    url: '/detail',
                },
                {
                    name: 'New Employee',
                    url: '/create',
                },
            ],
            form: {
                name: null,
                username: null,
                email: null,
                phone: null,
                role: 0,
            },
            editing: false,
            submitStatus: null,
            validationErrorsCheck: false,
            validationErrorsData: {
                name: null,
                username: null,
                email: null,
                phone: null,
                role: null,
            },
            merchantData: null,
            employeeData: null,
        }
    },
    computed: {
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
        urlPrefix(){
            return this.userData.principal_staff === 1 ? 'master':'merchant';
        }
    },
    validations: {
        form: {
            name: { required },
            username: { required },
            email: { required },
            phone: { required },
            role: { required },
        },
        avatar: {  },
    },

    methods: {
        load(){
            let endPoint = '/api/v1/management/memberships/master/merchant/'+this.$route.params.merchantId+'/outlet/'+this.merchantData.primary_outlet.id+'/officer/' + this.$route.params.id
            if (this.urlPrefix == 'merchant') endPoint = '/api/v1/management/memberships/merchant/outlet/'+ +this.merchantData.primary_outlet.id +'/master-officer/' + this.$route.params.id
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + endPoint)
                .then((response) => {
                    var data = response.data.data;
                    this.form = {
                        name: data.officer_name ? data.officer_name : null,
                        username: data.master_account.username ? data.master_account.username : null,
                        email: data.master_account.email ? data.master_account.email : null,
                        phone: data.master_account.phone ? data.master_account.phone : null,
                        role: data.master_account.role ? data.master_account.role : 0,

                    }
                    this.avatar =  data.officer_image ? data.officer_image : 'https://i.imgur.com/9XwzHPA.png'
                    this.employeeData = data
                    if (this.urlPrefix == 'merchant') {
                        this.breadcrumb = [
                            {
                                name: 'Partners',
                                url: '/partner-dashboard',
                            },
                            {
                                name: data.officer_name,
                                url: '/partners/employee/edit/'+data.id,
                            },
                        ]
                    } else {
                        this.breadcrumb = [
                            {
                                name: 'Partners',
                                url: '/partners',
                            },
                            {
                                name: this.merchantData.company_name,
                                url: '/partners/detail/'+this.merchantData.id,
                            },
                            {
                                name: data.officer_name,
                                url: '/partners/'+this.merchantData.id+'/employee/edit/'+data.id,
                            },
                        ]
                    }
                })
        },

        add() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'

                let data = {
                    'name': this.form.name,
                    'username': this.form.username,
                    'role': this.form.role,
                    'email': this.form.email,
                    'phone': this.form.phone,
                    'avatar': this.avatar,
                };

                let endPoint = '/api/v1/management/memberships/master/merchant/'+this.$route.params.merchantId+'/outlet/'+this.merchantData.primary_outlet.id+'/officer'
                if (this.urlPrefix == 'merchant') endPoint = '/api/v1/management/memberships/merchant/outlet/'+this.merchantData.primary_outlet.id+'/master-officer'

                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + endPoint, data)
                    .then((response) => {
                        if (response.data.error != 0) this.submitStatus = 'ERROR';
                        if (response.data.error === 422) {
                            this.validationErrorsCheck = true
                            this.validationErrorsData = response.data.errors
                        }
                        else {
                            router.push({ name: (this.urlPrefix == 'master' ? 'MerchantEmployeeDetail' : 'MerchantSelfEmployeeDetail'), params: { id: response.data.data.id, merchantId: this.$route.params.merchantId } })

                            this.$store
                                .commit('ADD_NOTIFICATION', {
                                    title: 'Success!',
                                    text: 'Success Adding Employee!',
                                    type: 'success',
                                    timeout: false,
                                    delay: 0
                                });
                        }
                    })
            }
        },
        update() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'

                let data = {
                    'name': this.form.name,
                    'username': this.form.username,
                    'role': this.form.role,
                    'email': this.form.email,
                    'phone': this.form.phone,
                    'avatar': this.avatar,
                };

                let endPoint = '/api/v1/management/memberships/master/merchant/' + this.$route.params.merchantId + '/outlet/' + this.merchantData.primary_outlet.id + '/officer/' + this.$route.params.id
                if (this.urlPrefix == 'merchant') endPoint = '/api/v1/management/memberships/merchant/outlet/'+this.merchantData.primary_outlet.id+'/master-officer/' + this.$route.params.id

                Vue.prototype.$http
                    .patch(process.env.VUE_APP_API_HOST + endPoint, data)
                    .then(() => {
                        router.push({ name: (this.urlPrefix == 'master' ? 'MerchantEmployeeDetail' : 'MerchantSelfEmployeeDetail'), params: { id: this.$route.params.id, merchantId: this.$route.params.merchantId } })

                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success Updating Employee!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }
        }
    },
    created() {
        if (this.urlPrefix == 'master') {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/merchant/' + this.$route.params.merchantId)
                .then((response) => {
                    this.merchantData = response.data.data
                    this.breadcrumb = [
                        {
                            name: 'Partners',
                            url: '/partners',
                        },
                        {
                            name: this.merchantData.company_name,
                            url: '/partners/detail/'+this.merchantData.id,
                        },
                        {
                            name: 'New Employee',
                            url: '/partners/'+this.merchantData.id+'/employee/create',
                        },
                    ]
                    if ("id" in this.$route.params) {
                        this.load();
                        this.editing = true;
                    } else {
                        // this
                    }
                })
        } else {
            this.merchantData = this.userData.merchant
            this.breadcrumb = [
                {
                    name: 'Partner',
                    url: '/partner-dashboard',
                },
                {
                    name: 'New Employee',
                    url: '/partners/employee/create',
                },
            ]
            if ("id" in this.$route.params) {
                this.load();
                this.editing = true;
            } else {
                // this
            }
        }

    },
    

}
</script>

<style lang="sass" scoped>
    .input-file
        width: 100%
        height: 100%
        left: 0
        top: 0
        opacity: 0
        position: absolute
        z-index: 1
</style>