import Vue from 'vue'
import Vuex from 'vuex'
import axios from './HttpDefault'
import notifications from './notifications'
import router from './../router/index.js'
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        notifications: notifications
    },
    state: {
        idToken: null,
        userId: null,
        user: null,
        isLoading: false
    },
    mutations: {
        authUser (state, userData) {
            state.idToken = userData.token
            state.user = userData.user
            state.userId = userData.userId
            state.expiredTokenAt = userData.expiredAt
        },
        clearAuth (state) {
            state.idToken = null
            state.userId = null
            state.expiredTokenAt = null
        },
        loading(state, isLoading) {
            state.isLoading = isLoading;
        }
    },
    actions: {
        login ({commit}, authData) {
            var data = new FormData()
            data.append('username', authData.username)
            data.append('password', authData.password)

            axios.post(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/auth/login', data)
                .then(res => {
                    if (res.data.error === 401) {
                        alert(res.data.description)
                        if(localStorage.getItem("access_token")) {
                            localStorage.removeItem("user");
                            localStorage.removeItem("access_token");
                            localStorage.removeItem("expired_at");
                            router.push("/")
                        }
                        return
                    }

                    localStorage.setItem('access_token', res.data.data.token)
                    localStorage.setItem('expired_at', res.data.data.expired_at)
                    commit('authUser', {
                        token: res.data.data.token,
                        expiredAt: res.data.data.expired_at
                    })

                    axios.get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/auth/profile')
                        .then(resProfile => {
                            let userData = resProfile.data.data;
                            // check user
                            if (userData.principal_staff == 1) {
                                // login by admin
                                localStorage.setItem('user', JSON.stringify(userData))
                                commit('authUser', {
                                    user: userData
                                })
                                axios.get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/admin/module?with_role='+resProfile.data.data.role)
                                    .then(response => {
                                        localStorage.setItem('permission', JSON.stringify(response.data.data))
                                        router.push("/dashboard")
                                    }).catch(error => console.log(error));
                            } else {
                                // login by merchant
                                axios.get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/merchant')
                                    .then(response => {
                                        if (response.data.error === 401 || response.data.error === 501) {
                                            alert(response.data.description)
                                            if(localStorage.getItem("access_token")) {
                                                localStorage.removeItem("user");
                                                localStorage.removeItem("access_token");
                                                localStorage.removeItem("expired_at");
                                                router.push("/")
                                            }
                                            return
                                        }

                                        userData.merchant = response.data.data
                                        localStorage.setItem('user', JSON.stringify(userData))
                                        commit('authUser', {
                                            user: userData
                                        })
                                        router.push("/partner-dashboard")
                                    }).catch(error => console.log(error));
                            }
                        }).catch(error => console.log(error));
                })
                .catch(error => console.log(error))
        },
        logout ({commit}) {
            commit('clearAuth')
            localStorage.removeItem('access_token')
            localStorage.removeItem('user')
            localStorage.removeItem('expired_at')
            localStorage.removeItem('permission')
            router.replace('/login')
        },
        AutoLogin ({commit}) {
            const token = localStorage.getItem('access_token')
            if (typeof token === 'undefined' || token === null || token === 'undefined') {
                return
            }

            axios.get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/auth/profile')
                .then(resProfile => {
                    commit('authUser', {
                        token: token,
                    })
                    let userData = resProfile.data.data;
                    // check user
                    if (userData.principal_staff == 1) {
                        // login by admin
                        localStorage.setItem('user', JSON.stringify(userData))
                        commit('authUser', {
                            user: userData,
                        })
                    } else {
                        // login by merchant
                        axios.get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/merchant')
                            .then(response => {
                                userData.merchant = response.data.data
                                localStorage.setItem('user', JSON.stringify(userData))
                                commit('authUser', {
                                    user: userData
                                })
                            }).catch(error => console.log(error));
                    }
                }).catch(error => console.log(error));
        }
    },
    getters: {
        user (state) {
            return state.user
        },
        ifAuthenticated (state) {
            return state.idToken !== null && state.idToken !== 'undefined'
        },

    }
})
