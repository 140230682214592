<template lang="pug">
    .report-page
        .card.card--shadow.bg-white.border-gray-200.p-4.rounded.mb-8
            header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                h2.font-bold Partner Report
                ExportButton(export-id="merchant-export-id" export-end-point="/api/v1/management/memberships/master/report/merchant/export")
            .card__body
                section.filter-section.flex.items-center.mb-8
                    .inline-block.relative.ui-daterange
                        .block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight
                            date-range-picker(
                                ref="picker"
                                opens="center"
                                :locale-data="locale"
                                :maxDate="maxDate"
                                v-model="dateRange"
                                appendToBody=true
                                @update="updateFilter"
                                :ranges="default_ranges"
                            )
                                template(v-slot:input="picker") {{ dateRange.startDate }} - {{ dateRange.endDate }}
                        .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-5 w-5"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                    
                    
                    button.btn-primary-o.btn-small.ml-3 Filter
                //- end filter

                .grid.grid-cols-4.gap-x-1.mb-4
                    .bg-gray-900.text-gray-100.p-4.rounded
                        span.text-xs.opacity-75.uppercase Total Claimed
                        h2.text-3xl {{ summary.total_redeemed | moneyFilter }}
                        small.text-xs.opacity-75 {{ dateRange.startDate }} - {{ dateRange.endDate }}

                    .bg-gray-900.text-gray-100.p-4.rounded
                        span.text-xs.opacity-75.uppercase Daily avg Claimed
                        h2.text-3xl {{ summary.avg_redeemed | moneyFilter }}
                        small.text-xs.opacity-75 {{ dateRange.startDate }} - {{ dateRange.endDate }}

                    .bg-gray-900.text-gray-100.p-4.rounded
                        span.text-xs.opacity-75.uppercase Total Redeemed
                        h2.text-3xl {{ summary.total_exchanged | moneyFilter }}
                        small.text-xs.opacity-75 {{ dateRange.startDate }} - {{ dateRange.endDate }}

                    .bg-gray-900.text-gray-100.p-4.rounded
                        span.text-xs.opacity-75.uppercase Daily avg Redeemed
                        h2.text-3xl {{ summary.avg_exchanged | moneyFilter }}
                        small.text-xs.opacity-75 {{ dateRange.startDate }} - {{ dateRange.endDate }}

                section.mb-8
                    .w-full.py-4
                        highcharts(
                            :options="mainchart"
                            :updateArgs="[true, false]"
                            :key="chartKey"
                        )

                section
                    .mb-4
                        h3.font-bold Lists Merchants
                    .box-table
                        table.table.table-set.w-full
                            thead
                                tr
                                    th No.
                                    th Merchants
                                    th Redeemed
                                    th Claimed
                                    th Total Voucher
                                    th Total Trans
                                    th Total Point
                                tr
                            tbody
                            tr(v-for="(list, index) in merchants")
                                td.w-8 {{ index + 1 }}
                                td
                                    router-link.link.block(:to="{ name: 'MerchantDetail', params: { id: list.merchant_detail.id }}" style="max-width:450px")
                                        .flex.items-center
                                            .block
                                                figure.avatar.w-8.h-8.overflow-hidden.mr-4
                                                    img.w-full.h-full.object-cover.rounded-full(:src="list.merchant_detail.company_logo ? list.merchant_detail.company_logo : 'https://i.imgur.com/9XwzHPA.png'", v-bind:alt="list.merchant_detail.company_name")
                                            span {{ list.merchant_detail.company_name }}

                                td {{ list.total_exchanged | moneyFilter }}
                                td {{ list.total_redeemed | moneyFilter }}
                                td {{ list.total_voucher | moneyFilter }}
                                td {{ list.total_expired_soon | moneyFilter }}
                                td {{ list.total_expired_soon | moneyFilter }}
                        Pagination( :pagination="pagination" @paginate="load()" )
</template>


<script>
import {Chart} from 'highcharts-vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Pagination from "../../../components/content/Pagination";
import Vue from "vue";
import ExportButton from "@/components/content/ExportButton";

export default {
    name: 'ResgiterActivity',
    components: {
        highcharts: Chart,
        DateRangePicker,
        Pagination,
        ExportButton
    },
    data() {
        return {
            chartKey: 0,
            maxDate: moment().format('MM/DD/YYYY'),
            dateRange: {
                startDate: moment().startOf('month').format('MM/DD/YYYY'),
                endDate: moment().endOf('month').format('MM/DD/YYYY'),
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                // 'This year': [moment().startOf('year'), moment().endOf('year')],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'week').startOf('week').toDate(), moment().subtract(1, 'week').endOf('week').toDate()],
                'This month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
                'Last month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
            },
            locale: {
                direction: 'ltr', //direction of text
                format: 'mm/dd/yyyy',
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
                firstDay: 1 //ISO first day of week - see moment documenations for details
            },
            mainchart: {
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: { // don't display the dummy year
                        month: '%e. %b',
                        year: '%b'
                    },
                    title: {
                        text: 'Date'
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                tooltip: {
                    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                    shared: true
                },
                plotOptions: {
                    series: {
                        compare: 'percent',
                        showInNavigator: true,
                        // pointStart: Date.UTC(2020, 0, 30),
                        pointStart: moment().startOf('month'),
                        pointInterval:  24 * 3600 * 1000,
                    }
                },
                
                series: [
                    {
                        name: 'Redeemed',
                        data: [],
                    },
                    {
                        name: 'Clamined',
                        data: [],
                    },
                ]
            },
            merchants: [],
            summary: null,
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    filters: {
        date(val) {
            return val ? moment(val).format("DD/MM/YYYY") : "";
        },
        dateURI(val) {
            return val ? moment(val).format("YYYY-MM-DD") : "";
        }
    },

    methods : {
        updateFilter(){
            this.dateRange.startDate = moment(this.dateRange.startDate).format('MM/DD/YYYY')
            this.dateRange.endDate = moment(this.dateRange.endDate).format('MM/DD/YYYY')
            this.load()
        },
        load() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/merchant/voucher')
                .then((response) => {
                    this.merchants = response.data.data
                    this.pagination = response.data.meta
                })
            this.loadChart()
            this.loadSummary()
        },
        loadSummary(){
            let params = '?start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate) +'&per_page=-1';
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/voucher/summary'+params)
                .then((response) => {
                    this.summary = response.data.data
                })
        },
        loadChart(){
            let params = '?start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate) +'&per_page=-1';
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/voucher/chart'+params)
                .then((response) => {
                    this.mainchart.series[0].data = response.data.data.claimed
                    this.mainchart.series[1].data = response.data.data.redeemed
                    let date = parseInt(moment(this.dateRange.startDate).utc().format('x'))
                    let res = [], res1 = []
                    response.data.data.claimed.forEach(function(v, i){
                        if(i > 0)date += 24 * 3600 * 1000;
                        res.push([date, v])
                    })
                    this.mainchart.series[0].data = res

                    date = parseInt(moment(this.dateRange.startDate).utc().format('x'))
                    response.data.data.redeemed.forEach(function(v, i){
                        if(i > 0)date += 24 * 3600 * 1000;
                        res1.push([date, v])
                    })
                    this.mainchart.series[0].data = res1

                    this.chartKey +=1
                })
        },
        exportReport(){
            let exportId = localStorage.getItem('merchant-export-id');
            if (exportId) {
                Vue.prototype.$http
                    .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/check?export_id='+exportId)
                    .then((response) => {
                        if ( response.data.data.name ) {
                            window.open(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/'+response.data.data.name, '_self')
                            localStorage.removeItem('merchant-export-id');
                        } else {
                            alert('Your file is still on progress... come back later to check your file!');
                        }
                    })
            } else {
                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/merchant/export')
                    .then((response) => {
                        localStorage.setItem('merchant-export-id', response.data.data.id);
                        alert('Exporting your file... come back later to check your file!');
                    })
            }
        }
    },
    created() {
        this.load()
    },
}
</script>

<style lang="sass" scoped>
.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>