<template lang="pug">
    //- point activity
    .card
        header.card__header.flex.items-center.justify-between.pb-4
            h2.font-bold Point Activity
        .card__body
            .mb-6
                .bg-gray-900.text-gray-100.p-4.rounded.flex.items-end.justify-between.rounded-b-none
                    .block
                        h2.text-3xl {{ poinActivitySummary.total | moneyFilter }}
                .bg-red-100.text-red-700.px-4.py-2.mb-4.rounded.rounded-t-none(v-if="pointExpiredSoon" )
                    small.text-xs {{ poinActivitySummary.total_expired_soon | moneyFilter }} poin will expired&nbsp;
                        span.font-bold {{ poinActivitySummary.expired_at | moment('DD MMMM YYYY') }}

            //- .filter-content.mb-4
                .inline-block.relative.w-64
                    select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight
                        option All Status
                        option() Received
                        option Used
                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                        <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>

            .block.mb-6.pb-6.border-b.border-gray-200
                .list-item.border-b.border-gray-200.pb-2.mb-2.flex.items-center.justify-between(v-for="list in poinActivityList" :key="list.index")
                    .flex-grow.cursor-pointer(@click="showAttachment(list)")
                        .block {{ list.transaction_type | pointSubject(list) }}
                        span.text-xs REFF : {{ list.transaction_reference }} | {{ list.created_at | moment('DD MMMM YYYY HH:mm') }}
                    template(v-if="list.transaction_type === 1")
                        h3.text-xl.text-right
                            small +
                            span {{ list.point | moneyFilter }}
                    template(v-else)
                        h3.text-xl.text-right.text-red-500
                            small -
                            span {{ list.point | moneyFilter }}
            Pagination( :pagination="pagination" @paginate="load()" )

        .overlay-popup.bg-gray-200.bg-opacity-25(v-if="showModal")
            .overlay-popup__container
                .popup.rounded.shadow
                    header.popup__header.border-b.border-gray-200.p-6.flex.justify-between
                        h2.font-bold Detail Transaction Reff - {{ reffID }}
                        button.btn-icon.btn-icon--40(type='button' @click="showModal = false")
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    .popup__body.p-8
                        span.mb-3 Reff : {{ reffID }}
                        template(v-if="attachmentReff")
                            .block.border-b.border-gray-200.pb-4.mt-4
                                img(:src="attachmentReff" )
                        template(v-else)
                            .block
                                .block No Image
    //- end point activity
</template>

<script>
import Vue from "vue";
import Pagination from './../../components/content/Pagination';

export default {
    name: 'PoinActivity',
    components: {
        Pagination,
    },
    data() {
        return {
            poinActivityList: null,
            poinActivitySummary: null,
            pointExpiredSoon: false,
            checkAttachment: null,
            attachmentReff: '',
            reffID: '',
            showModal: false,
            pagination: {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
        }
    },
    filters: {
        pointSubject: function(type, item) {
            let subject = '';
            switch (type) {
                case 1:
                    subject = 'Poin dari '+ (item.merchant ? item.merchant.company_name : '')
                    if (item.transaction_reference.includes('/CR-')) {
                        subject = 'Adjustment Increase for Reff ' + (item.transaction_reference.replace('CR-', ''))
                    }
                    break;
                case 2:
                    subject = 'Tukar Poin'
                    if (item.transaction_reference.includes('/DB-')) {
                        subject = 'Adjustment Decrease for Reff ' + (item.transaction_reference.replace('DB-', ''))
                    }
                    break;
                default:
                    subject = 'UNKNOWN TYPE';
                    break;
            }
            return subject;
        }
    },
    methods: {
        load() {
            let loader = this.$loading.show()
            let params = '?page='+this.pagination.current_page;
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/'+this.$route.params.id+'/point'+params)
                .then((response) => {
                    this.poinActivityList = response.data.data
                    this.pagination = response.data.meta
                    loader.hide()
                })
        },
        showAttachment(item){
            this.checkAttachment = null
            this.reffID = item.transaction_reference
            if (item.attachment !== null) {
                this.checkAttachment = 'reff'
                this.attachmentReff = item.attachment[0]
            } else {
                this.attachmentReff = null
                this.checkAttachment = null
            }
            this.showModal = !this.showModal;
        }
    },
    mounted() {
        this.load()
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/'+this.$route.params.id+'/point/summary')
            .then((response) => {
                this.poinActivitySummary = response.data.data

                if (this.poinActivitySummary.total_expired_soon > 0) {
                    this.pointExpiredSoon = true;
                }
            })
    }
}
</script>

<style lang="sass" scoped>
.overlay-popup
    position: fixed
    left: 0
    top: 0
    bottom: 0
    right: 0
    width: 100%
    height: 100%
    overflow: auto
    &__container
        .popup
        background-color: #ffffff
        max-width: 600px
        margin: 2rem auto
        &__header
            display: flex
            align-items: center
            justify-content: space-between

</style>
