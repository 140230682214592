<template lang="pug">
    footer.p-4.text-xs.text-gray-600
        p &copy; Copyright MyJHL {{ getYear }}. All Right Reserved.
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'Footer',
        data(){
            return {
                year: ''
            }
        },
        computed: {
            getYear(){
                return moment().format('YYYY');
            },
        },
    }
</script>