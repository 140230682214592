<template lang="pug">
    #settings-box
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            //- .grid.grid-cols-2.gap-4.mb-8
                .card.card--shadow.p-4.rounded.border.border-gray-200.bg-white
                    .flex.items-start.justify-between
                        .flex.items-center
                            span.px-2.py-1.bg-gray-700.text-gray-400.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Non Active
                            strong.block.px-4 Rp {{ membershipDefault.upgrade_when_reach | moneyFilter }}
                            span.text-xs total accumulated spending

                        router-link(:to="{name: 'MembershipCreateAccumulation'}").btn-default.btn-icon
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>

            .card.card--shadow.p-4.rounded.border.border-gray-200.bg-white
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                    h2.font-bold Membership Management
                    //- .header-cta.flex.items-center
                        router-link(to="/membership/create").btn-primary.btn-small.ml-2
                            span Add category
                .card__body
                    .filter-content.flex.items-center.mb-4
                        .inline-block.relative.w-64.mr-4
                            input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                type="text"
                                placeholder="Search here..."
                            )
                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>

                    .box-table
                        table.table.table-set.w-full
                            thead
                                tr
                                    th  No.
                                    th  Name
                                    th  Slug
                                    th  Price
                                    th  Accumulation
                                    th  Maintain Fee
                                    th  Expired's periode
                                    //- th  Status
                                    th
                            tbody
                                tr(v-for="(list, index) in listData" :key="list.index")
                                    td.w-8 {{ index + 1 }}.
                                    td
                                        .flex.items-center
                                            .block.mr-4
                                                figure.w-40
                                                    router-link(:to="{ name: 'MembershipDetail', params: {id: list.id} }")
                                                        img.rounded(:src="list.logo ? list.logo : 'https://i.imgur.com/dL0n397.png'", alt="alt")
                                            .block
                                                template(v-if="(list.name.toLowerCase().includes('bronze'))")
                                                    span.px-2.py-1.bg-yellow-800.text-yellow-500.rounded.font-bold.text-xs.w-24.block.text-center.uppercase {{ list.name }}
                                                template(v-if="(list.name === 'SILVER') || (list.name === 'Silver') || (list.name.includes('Silver'))")
                                                    span.px-2.py-1.bg-gray-300.text-gray-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase {{ list.name }}
                                                template(v-if="(list.name === 'GOLD') || (list.name === 'Gold') || (list.name.includes('Gold'))")
                                                    span.px-2.py-1.bg-orange-300.text-orange-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase {{ list.name }}
                                                template(v-if="(list.name === 'PLATINUM') || (list.name === 'Platinum') || (list.name.includes('Platinum'))")
                                                    span.px-2.py-1.bg-blue-300.text-blue-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase {{ list.name }}
                                                router-link.link.text-xs(:to="{ name: 'MembershipDetail', params: {id: list.id}}") Detail membership
                                    td
                                        span {{ list.slug }}
                                    td
                                        span Rp {{ list.membership_fee | moneyFilter }}
                                    td
                                        span Rp {{ list.upgrade_when_reach | moneyFilter }}
                                    td
                                        span Rp {{ list.membership_maintain_fee | moneyFilter }}
                                    td
                                        span {{ list.days_to_expire }} Hari
                                    //- td
                                        template(v-if="list.status === 0")
                                            span.px-2.py-1.bg-gray-200.text-gray-700.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Deactived
                                        template(v-if="list.status === 1")
                                            span.px-2.py-1.bg-blue-200.text-blue-800.rounded.font-bold.text-xs.w-24.block.text-center.uppercase Active
                                    td.action-2
                                        router-link(:to="{name: 'MembershipEdit', params: {id: list.id}}").btn-default.btn-icon
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                        //- button.btn-default.btn-icon(@click="destroy(list.id)")
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                    //- .flex.items-center.justify-between.pt-4
                        span 10 from 9.000
                        .pagination
                            ul.flex.items-center
                                li.ml-2
                                    span.btn-primary.btn-small.btn-disabled 1
                                li.ml-2
                                    a.btn-primary-o.btn-small(href="#") 2
                                li.ml-2
                                    a.btn-primary-o.btn-small(href="#") 3
                                li.ml-2
                                    a.btn-primary-o.btn-small(href="#") 4
                                li.ml-2
                                    a.btn-primary-o.btn-small(href="#") 5
</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import Vue from "vue";

export default {
    name: 'SettingsMembership',
    components: {
        Breadcrumb,

    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Membership',
                    url: '/membership',
                },
            ],
            listData: null,
            listPagination: null,
            membershipDefault: null,
        }
    },
    methods: {
        load(){
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/type?type=paid')
                .then(response => {
                    this.listData = response.data.data;
                    this.listPagination = response.data.meta;
                })
        },
        loadDefault(){
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/type/default')
                .then(response => {
                    this.membershipDefault = response.data.data;
                })
        },
    },
    created() {
        this.load();
        this.loadDefault();
    },
}
</script>

<style lang="sass" scoped>
small
    line-height: 0
    position: relative
    top: -4px

.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>