<template lang="pug">
    #merchant-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .card.card--shadow.bg-white.border-gray-200.p-4.rounded
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                    h2.font-bold List Partners
                    .header-cta.flex.items-center
                        router-link(to="/partners/create").btn-primary.btn-small.ml-2
                            span.mr-1
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                            span Add Partners
                .card__body
                    .filter-content.mb-4
                        SearchInput(:inputData.sync="searchInput" @search="load()")
                        .inline-block.relative.w-64
                            select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                v-model="categoryFilter"
                                @change="load()"
                            )
                                option(value="0") All Categories
                                option(v-for="(category, index) in categoryList" :key="category.index" :value="category.id") {{ category.name }}
                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>

                        .inline-block.relative.w-64.ml-4
                            select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                v-model="statusFilter"
                                @change="load()"
                            )
                                option(value="99") All Status
                                option(value="3") Inactive
                                option(value="1") Active
                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    .box-table
                        table.table.table-set.w-full
                            thead
                                tr
                                    th  No.
                                    th  Name
                                    th(width="150")  Categories
                                    th  Vouchers
                                    th  Address
                                    th  Status
                                    th(v-if="userData.principal_staff == 1")  Order
                                    th
                            tbody
                                tr(v-for="(list, index) in merchantList" :key="list.index")
                                    td.w-8 {{ index + 1 }}.
                                    td
                                        router-link.link.font-bold.block(:to="{ name: 'MerchantDetail', params: { id: list.id }}" style="max-width:450px")
                                            .flex.items-center
                                                .block
                                                    figure.avatar.w-16.h-16.overflow-hidden.mr-4
                                                        img.w-full.h-full.object-cover(:src="list.company_logo ? list.company_logo : 'https://i.imgur.com/9XwzHPA.png'", v-bind:alt="list.name")
                                                span {{ list.company_name }}
                                    td
                                        .border.bordr-gray-200.bg-gray-100.pr-2.rounded.mr-3(v-if="list.category")
                                            template
                                                .flex.items-center
                                                    .icon.w-8.h-8.flex.items-center.justify-center(v-if="list.category.slug === 'hotel'")
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                                    .icon.w-8.h-8.flex.items-center.justify-center(v-if="list.category.slug === 'restaurant'")
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right-circle"><circle cx="12" cy="12" r="10"></circle><polyline points="12 16 16 12 12 8"></polyline><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                                                    .icon.w-8.h-8.flex.items-center.justify-center(v-if="list.category.slug === 'gym'")
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
                                                    .icon.w-8.h-8.flex.items-center.justify-center(v-if="list.category.slug === 'salon'")
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
                                                    span {{ list.category.name ? list.category.name : '-' }}

                                        span(v-else) -

                                    td
                                        span {{ list.vouchers_total }}
                                    td
                                        span.block.w-64.text-xs.truncate {{ list.primary_outlet.outlet_address ? list.primary_outlet.outlet_address : '-' }}
                                    td
                                        span.px-2.py-1.bg-blue-200.text-blue-700.rounded.font-bold.text-xs.w-24.block.text-center.uppercase(v-if="list.status === 1") Active
                                        span.px-2.py-1.bg-orange-300.text-orange-900.rounded.font-bold.text-xs.w-24.block.text-center.uppercase(v-if="list.status === 3") Inactive
                                        span.px-2.py-1.bg-yellow-200.text-yellow-700.rounded.font-bold.text-xs.w-24.block.text-center.uppercase(v-if="list.display === 0") Unlisted
                                    td(v-if="userData.principal_staff == 1")
                                        span {{ list.order_pos}}
                                    td
                                        router-link(:to="{name: 'MerchantEdit', params: {id: list.id}}").btn-default.btn-icon
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                        button.btn-default.btn-icon(@click="destroy(list.id)")
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>

                    Pagination( :pagination="pagination" @paginate="load()" )
</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import Pagination from './../../components/content/Pagination';
import SearchInput from './../../components/content/SearchInput';
import Vue from "vue";
import router from "@/router";

export default {
    name: 'MerchantPage',
    components: {
        Breadcrumb,
        SearchInput,
        Pagination,
    },
    data() {
        return {
            breadcrumb: [
                {
                    name: 'Partners',
                    url: '/partners',
                },
            ],
            merchantList: null,
            categoryList: null,
            categoryFilter: 0,
            statusFilter: 99,
            pagination : {
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
                current_page: 1
            },
            searchInput: '',
        }
    },
    computed: {
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
    },
    methods: {
        load(){
            let params = '?q='+this.searchInput+'&page='+this.pagination.current_page;
            if (this.categoryFilter != 0) params += '&category_id='+this.categoryFilter;
            params += this.statusFilter != 99 ? '&status='+this.statusFilter : '';
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/merchant'+params)
                .then(response => {
                    this.merchantList = response.data.data
                    this.pagination = response.data.meta
                })
        },
        loadCategory() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/category')
                .then(response => (this.categoryList = response.data.data))
        },
        destroy(id){
            if (confirm('Are you sure?')) {
                Vue.prototype.$http
                    .delete(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/merchant/'+id)
                    .then(() => {
                        this.load()
                    })
            }

        }
    },
    mounted() {
        if (this.userData.principal_staff == 1) {
            this.load();
            this.loadCategory();
        } else {
            router.push({name: 'MerchantDashboardPage'})
        }
    },

}
</script>

<style lang="sass" scoped>
small
    line-height: 0
    position: relative
    top: -4px

.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>