<template lang="pug">
    .box-on-boarding
        .flex.items-center.justify-between.mb-4.pb-4
            h2.font-bold Banner On Boarding

        .grid.grid-cols-4.gap-8
            .block
                img.rounded(:src="bannerData[0] ? bannerData[0].image : 'https://i.imgur.com/SuMPYmu.png'", alt="alt")
                .flex.block.w-full.bg-orange-100.rounded.p-2.justify-between.items-center.mt-2
                    span Order 1
                    button.btn-primary-o.btn-small.relative
                        input.input-file(
                            type="file"
                            @change="changeCover(0, $event)"
                        )
                        span Change Image
            .block
                img.rounded(:src="bannerData[1] ? bannerData[1].image : 'https://i.imgur.com/SuMPYmu.png'", alt="alt")
                .flex.block.w-full.bg-orange-100.rounded.p-2.justify-between.items-center.mt-2
                    span Order 2
                    button.btn-primary-o.btn-small.relative
                        input.input-file(
                            type="file"
                            @change="changeCover(1, $event)"
                        )
                        span Change Image
            //.block
                img.rounded(:src="bannerData[2] ? bannerData[2].image : 'https://i.imgur.com/SuMPYmu.png'", alt="alt")
                .flex.block.w-full.bg-orange-100.rounded.p-2.justify-between.items-center.mt-2
                    span Order 3
                    button.btn-primary-o.btn-small.relative
                        input.input-file(
                            type="file"
                            @change="changeCover(2, $event)"
                        )
                        span Change Image
            //.block
                img.rounded(:src="bannerData[3] ? bannerData[3].image : 'https://i.imgur.com/SuMPYmu.png'", alt="alt")
                .flex.block.w-full.bg-orange-100.rounded.p-2.justify-between.items-center.mt-2
                    span Order 4
                    button.btn-primary-o.btn-small.relative
                        input.input-file(
                            type="file"
                            @change="changeCover(3, $event)"
                        )
                        span Change Image

</template>

<script>
import Vue from "vue";
import UploadMedia from "../../../components/content/UploadMedia";

export default {
    name: 'OnBoarding',
    components: {
        UploadMedia
    },
    data() {
        return {
            bannerData: [],
            coverImage: 'https://i.imgur.com/SuMPYmu.png',
        }
    },
    methods: {
        load() {
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/banner?group=ONBOARDING')
                .then((response) => {
                    this.bannerData = response.data.data
                })
        },
        changeBanner(index) {
            let bannerId = this.bannerData[index] ? this.bannerData[index].id : null;
            console.log(this.bannerData[index]);

            let data = {
                'name': 'banner-onboarding-'+bannerId,
                'activity': 'none',
                'group': 'ONBOARDING',
                'image': this.coverImage
            }

            if (bannerId === null) {
                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/banner/', data)
                    .then(() => {
                        this.load()
                    })
            } else {
                Vue.prototype.$http
                    .patch(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/banner/'+bannerId, data)
                    .then(() => {
                        this.load()
                    })
            }

        },
        changeCover(index, e){
            let formData = new FormData();
            formData.append('file', e.target.files[0]);

            Vue.prototype.$http
                .post(process.env.VUE_APP_API_HOST + '/api/v1/lite-media/api-manage/', formData, {headers: { 'Content-Type': 'multipart/form-data' }})
                .then((response) => {
                    if (response.data.error === 422) { alert(response.data.errors.file[0]) }
                    else {
                        this.coverImage = response.data.data.url;
                        this.changeBanner(index)
                    }
                })
        },
    },
    mounted() {
        this.load();
    },
}
</script>

<style lang="sass" scoped>
    .input-file
        width: 100%
        height: 100%
        left: 0
        top: 0
        opacity: 0
        position: absolute
        z-index: 1
</style>