<template lang="pug">
    div.text-right
        button.btn-primary-o.mr-2.btn-small(@click="exportReport")
            .icon.mr-2
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
            span(v-if="exportProgress === 1") Export Data
            span(v-if="exportProgress === 2") Check Export Data
            span(v-if="exportProgress === 3") Download Export Data
        small.flex.justify-center.items-center.mt-1(v-if="exportProgress > 1")
            span(v-if="exportProgress === 2") Export on progress
            span(v-if="exportProgress === 3") Export complete, you can download your file
            .icon.inline-block.cursor-pointer.ml-1(v-if="exportProgress === 2")
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" height="18" viewBox="0 0 512 512"><title>Refresh Circle</title><path d="M288 193s12.18-6-32-6a80 80 0 1080 80" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="28"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="28" d="M256 149l40 40-40 40"/><path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/></svg>
            .icon.inline-block.ml-1.text-green-600(v-if="exportProgress === 3")
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" height="18" viewBox="0 0 512 512"><title>Checkmark Circle</title><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352 176L217.6 336 160 272"/></svg>
</template>

<script>
    import Vue from "vue";

    export default{
        name: 'ExportButton',
        props: {
            exportId: String,
            exportEndPoint: String
        },
        data() {
          return {
              exportProgress: 1,
          }
        },
        methods: {
            doExport(){
                if (!this.awaitingSearch) {
                    setTimeout(() => {
                        this.$emit('export')
                        this.awaitingSearch = false;
                    }, 300); // 1 sec delay
                }
                this.awaitingSearch = true;

            },
            checkExport(){
                let exportId = localStorage.getItem(this.exportId);
                if (exportId) {
                    let loader = this.$loading.show({
                        // Optional parameters
                        container: null,
                        canCancel: false,
                    });
                    Vue.prototype.$http
                        .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/check?export_id='+exportId)
                        .then((response) => {
                            loader.hide()
                            if ( response.data.data.name ) {
                                this.exportProgress = 3;
                                localStorage.setItem(this.exportId+'-url', process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/'+response.data.data.name);
                            } else {
                                this.exportProgress = 2;
                                alert('Your file is still on progress... come back later to check your file!');
                            }
                        })
                } else {
                    this.exportProgress = 1;
                }
            },
            exportReport(){
                if (this.exportProgress === 1) {
                    let loader = this.$loading.show({
                        // Optional parameters
                        container: null,
                        canCancel: false,
                    });
                    // let endPoint = '/api/v1/management/memberships/master/point/adjust/export'
                    // let params = '?start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate);
                    // if (this.urlPrefix == 'merchant') endPoint = '/api/v1/management/memberships/merchant/report/point/export';
                    Vue.prototype.$http
                        .post(process.env.VUE_APP_API_HOST + this.exportEndPoint)
                        .then((response) => {
                            this.exportProgress = 2;
                            loader.hide()
                            localStorage.setItem(this.exportId, response.data.data.id);
                            alert('Exporting your file... come back later to check your file!');
                        })
                } else if (this.exportProgress === 2) {
                    this.checkExport()
                } else if (this.exportProgress === 3) {
                    this.downloadExport()
                }
            },
            downloadExport(){
                let exportURL = localStorage.getItem(this.exportId+'-url');
                window.open(exportURL, '_self')
                localStorage.removeItem(this.exportId);
                localStorage.removeItem(this.exportId+'-url');
                this.exportProgress = 1;
            },
        },
        mounted(){
            this.checkExport()
        }
    }
</script>