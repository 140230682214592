<template lang="pug">
    .tab
        .tab-header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-4
            h2.font-bold Report Ticket Services by Categories
            ExportButton(export-id="services-export-id" export-end-point="/api/v1/management/memberships/master/report/services/export")

        .tab-body
            section.filter-section.flex.items-center.mb-8
                .inline-block.relative.ui-daterange
                    .block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight
                        date-range-picker(
                            ref="picker"
                            opens="center"
                            :locale-data="locale"
                            :maxDate="maxDate"
                            v-model="dateRange"
                            appendToBody=true
                            @update="updateFilter"
                            :ranges="default_ranges"
                        )
                            template(v-slot:input="picker") {{ dateRange.startDate }} - {{ dateRange.endDate }}
                    .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-5 w-5"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                
                
                button.btn-primary-o.btn-small.ml-3 Filter
            //- end filter

            //- .grid.grid-cols-5.gap-x-1.mb-4
                .bg-gray-900.text-gray-100.p-4.rounded
                    span.text-xs.opacity-75.uppercase Ticket Inbox
                    h2.text-3xl 2.250
                    small.text-xs.opacity-75 03/10/2020 - 03/10/2020

                .bg-gray-900.text-gray-100.p-4.rounded
                    span.text-xs.opacity-75.uppercase Ticket Replied
                    h2.text-3xl 2.050
                    small.text-xs.opacity-75 03/10/2020 - 03/10/2020

                

            //- section.mb-4
                .w-full.py-4
                    highcharts(
                        :options="mainchart"
                    )

            section
                //- .mb-4
                    h3.font-bold Lists Ticket Services By Categories
                .box-table
                    table.table.table-set.w-full
                        thead
                            tr
                                th Categories
                                th Inbox
                                th Replied 
                        tbody
                        tr(v-for="(item, index) in poin" :key="item.id")
                            td {{ item.categories }}
                            td {{ '-' }}
                            td {{ '-' }}
                    Pagination( :pagination="pagination" @paginate="load()" )
</template>

<script>
import {Chart} from 'highcharts-vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Vue from "vue";
import Pagination from "../../../components/content/Pagination";
import ExportButton from "@/components/content/ExportButton";


export default {
    name: 'GeneralActivity',
    components: {
        highcharts: Chart,
        DateRangePicker,
        Pagination,
        ExportButton
    },
    data() {
        return {
            maxDate: moment().format('MM/DD/YYYY'),
            dateRange: {
                startDate: moment().format('DD/MM/YYYY'),
                endDate: moment().format('DD/MM/YYYY'),
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                // 'This year': [moment().startOf('year'), moment().endOf('year')],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'week').startOf('week').toDate(), moment().subtract(1, 'week').endOf('week').toDate()],
                'This month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
                'Last month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
            },
            locale: {
                direction: 'ltr', //direction of text
                format: 'mm/dd/yyyy',
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
                firstDay: 1 //ISO first day of week - see moment documenations for details
            },
            mainchart: {
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: [
                        '10 aug',
                        '11 aug',
                        '12 aug',
                        '13 aug',
                        '14 aug',
                        '15 aug',
                        '16 aug',
                        '17 aug',
                        '18 aug',
                        '19 aug',
                        '20 aug',
                        '21 aug',
                        '22 aug',
                        '23 aug',
                        '24 aug',
                        '25 aug',
                        '26 aug',
                        '27 aug',
                        '28 aug',
                        '29 aug',
                        '30 aug',
                        '31 aug',
                        '01 sep',
                        '02 sep',
                        '03 sep',
                        '04 sep',
                        '05 sep',
                        '06 sep',
                        '07 sep',
                        '08 sep',
                        '09 sep',
                        '10 sep',
                        
                    ]
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                tooltip: {
                    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                    shared: true
                },
                
                series: [
                    {
                        name: 'Inbox',
                        data: [121, 121, 212, 123, 432, 412, 212, 531, 112, 231, 123, 223, 131, 113, 113, 134, 134, 412, 422, 312, 341, 212, 314, 121, 431, 214, 133, 134, 234, 234, 242, 341],
                    },
                    {
                        name: 'Replied',
                        data: [121, 111, 210, 123, 402, 410, 210, 501, 112, 231, 113, 213, 130, 103, 111, 124, 132, 402, 412, 302, 321, 202, 304, 111, 421, 204, 103, 134, 234, 234, 242, 341],
                    },
                ]
            },
            poin: [
                {
                    categories: 'Hotel',
                },
                {
                    categories: 'Restaurants',
                },
                {
                    categories: 'Gym',
                },
                {
                    categories: 'Spa',
                },
                {
                    categories: 'Ballroom',
                },
                {
                    categories: 'Meeting',
                },
                {
                    categories: 'Others',
                },
            ]
        }
    },
    filters: {
        date(val) {
            return val ? moment(val).format("DD/MM/YYYY") : "";
        },
        dateURI(val) {
            return val ? moment(val).format("YYYY-MM-DD") : "";
        }
    },

    methods : {
        updateFilter(){
            this.dateRange.startDate = moment(this.dateRange.startDate).format('MM/DD/YYYY')
            this.dateRange.endDate = moment(this.dateRange.endDate).format('MM/DD/YYYY')
            this.load()
        },
        load(isSummary = false) {
            let params = '?start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate) ;
            if (isSummary) params +=  '&per_page=-1';
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/service/category'+params)
                .then((response) => {
                    this.merchants = response.data.data
                    this.pagination = response.data.meta
                })
            this.loadChart()
            this.loadSummary()
        },
        loadSummary(){
            let params = '?start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate) +'&per_page=-1';
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/service/category-summary'+params)
                .then((response) => {
                    this.summary = response.data.data
                })
        },
        loadChart(){
            let params = '?start='+ this.$options.filters.dateURI(this.dateRange.startDate) +'&end='+ this.$options.filters.dateURI(this.dateRange.endDate) +'&per_page=-1';
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/service/category-chart'+params)
                .then((response) => {
                    this.mainchart.series[0].data = response.data.data.claimed
                    this.mainchart.series[1].data = response.data.data.redeemed
                })
        },
        exportReport(){
            let exportId = localStorage.getItem('services-export-id');
            if (exportId) {
                Vue.prototype.$http
                    .get(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/check?export_id='+exportId)
                    .then((response) => {
                        if ( response.data.data.name ) {
                            window.open(process.env.VUE_APP_API_HOST + '/api/v1/api-manage/lite-cms/export/'+response.data.data.name, '_self')
                            localStorage.removeItem('customer-register-export-id');
                        } else {
                            alert('Your file is still on progress... come back later to check your file!');
                        }
                    })
            } else {
                Vue.prototype.$http
                    .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/report/services/export')
                    .then((response) => {
                        localStorage.setItem('customer-register-export-id', response.data.data.id);
                        alert('Exporting your file... come back later to check your file!');
                    })
            }
        }
    },
    created() {
        this.load()
    },
}
</script>

<style lang="sass" scoped>
.table-set
    tr
        th,
        td
            padding: 12px 16px
            border-bottom: 1px solid #f1f7fb
            transition: all 0.3s ease-in-out
        &:hover
            td
                background-color: rgba(0, 0, 0, 0.0250 )
        th
            text-transform: uppercase
            text-align: left
            font-size: 11px
            opacity: 0.7
</style>