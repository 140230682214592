<template lang="pug">
    #services-page
        Breadcrumb( :ListBreadcrumb="breadcrumb")
        .content-area.px-4
            .w-600.m-auto
                .card.card--shadow.bg-white.border-gray-200.p-12.rounded
                    header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                        h2.font-bold Subject ini kayaknya yah
                        .header-cta.flex.items-center
                            router-link(to="/services/detail").link.font-sx
                                span Back
                    .card__body
                        section.border-b.border-blue-100.mb-6
                            h2.text-2xl.block.mb-2.font-bold {{ title }}
                            .flex.items-center.text-xs.mb-2
                                span Devi Krisdiansyah
                                span.inline-flex.px-2 •
                                span Room
                                span.inline-flex.px-2 •
                                span 10 agustus 2020
                            acticle.html-tag
                                p Ice cream tootsie roll chocolate icing pudding chupa chups lemon drops donut toffee. Apple pie sweet chocolate brownie bonbon gummi bears. Danish fruitcake sweet roll dragée candy canes donut sugar plum cake jelly beans.
                        form(action="" method="method")
                            section.pb-8
                                label.font-bold.block.mb-3 Reply
                                vueEditor(
                                    v-model="content"
                                )
                            section
                                .flex.items-center.justify-end
                                    button.btn-primary-o.ml-3(type='submit') Save as Read
                                    button.btn-primary.ml-3(type='submit') Reply



</template>

<script>
import Breadcrumb from './../../components/content/breadcrumb';
import { VueEditor } from "vue2-editor";

export default {
    name: 'ServiceMessage',
    components: {
        Breadcrumb,
        VueEditor
    },
    data() {
        return {
            content: "<p>Please reply here...</p>",
            breadcrumb: [
                {
                    name: 'Services',
                    url: '/services',
                },
                {
                    name: 'JHL Solitaire Hotel',
                    url: '/services/detail',
                },
                {
                    name: 'Subject ini kayaknya yah',
                    url: '/service/message',
                },
            ],
        }
    },

}
</script>

<style lang="sass" scoped>
    .w-600
        max-width: 600px

    .html-tag
        > *
            padding-bottom: 1em
        ul, ol
            padding-left: 16px
            li
                padding-bottom: 0.25em
        ul
            list-style: disc
        ol
            list-style: decimal
        img, iframe, video
            max-width: 100%
</style>