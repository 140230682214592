<template lang="pug">
    #settings-page
        .card.card--shadow.bg-white.border-gray-200.p-12.rounded
            header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-8.pb-8
                h2.font-bold Edit Customer
                .header-cta.flex.items-center
                    router-link(to="/customers").link.font-sx
                        span Back
            .card__body
                form(action="" method="method")
                    section.border-b.border-blue-100.mb-8.pb-8
                        .container-small
                            .flex.mb-6
                                .block(class="w-1/4").text-right.mr-8
                                    label.block.h-16.py-2 Name
                                        small.text-red-500(style="padding-left:4px") *
                                .flex-grow
                                    .inline-block.relative.w-full.mr-4
                                        input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                            type="text"
                                            placeholder="Write a name here..."
                                            v-model="$v.form.name.$model"
                                            :class="{ 'border-red-500': $v.form.name.$error }"
                                        )
                                        .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.name.$error")
                                            span.info.text-xs This field is required
                            .field
                                .flex.mb-6
                                    .block(class="w-1/4").text-right.mr-8
                                        label.block.h-16.py-2 Status
                                            small.text-red-500(style="padding-left:4px") *
                                    .flex-grow
                                        .inline-block.relative.w-full
                                            select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                v-model="$v.form.status.$model"
                                                :class="{ 'border-red-500': $v.form.status.$error }"
                                            )
                                                option(value="0" v-if="form.status == 0") Registration on Pending
                                                option(value="1") Active
                                                option(value="3") Inactive
                                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700
                                                <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.status.$error")
                                            span.info.text-xs This field is required
                            .field
                                .flex.mb-6
                                    .block(class="w-1/4").text-right.mr-8
                                        label.block.h-16.py-2 Email
                                            small.text-red-500(style="padding-left:4px") *
                                    .flex-grow
                                        .inline-block.relative.w-full.mr-4
                                            input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                type="email"
                                                placeholder="Write registerd email here..."
                                                v-model="$v.form.email.$model"
                                                :class="{ 'border-red-500': $v.form.email.$error }"
                                            )
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.email.$error")
                                                span.info.text-xs This field is required
                            .field
                                .flex.mb-6
                                    .block(class="w-1/4").text-right.mr-8
                                        label.block.h-16.py-2 Phone
                                            small.text-red-500(style="padding-left:4px") *
                                    .flex-grow
                                        .inline-block.relative.w-full.mr-4
                                            input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                type="tel"
                                                placeholder="Write registerd email here..."
                                                v-model="$v.form.phone.$model"
                                                :class="{ 'border-red-500': $v.form.phone.$error }"
                                            )
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.phone.$error")
                                                span.info.text-xs This field is required

                            .field
                                .flex.mb-6
                                    .block(class="w-1/4").text-right.mr-8
                                        label.block.h-16.py-2 Gender
                                            small.text-red-500(style="padding-left:4px") *
                                    .flex-grow
                                        .inline-block.relative.w-full.mr-4
                                            select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                                v-model="$v.form.gender_id.$model"
                                                :class="{ 'border-red-500': $v.form.gender_id.$error }"
                                                placeholder="Select Gender"
                                            )
                                                option(value="") Select Gender
                                                option(value=1) Male
                                                option(value=2) Female
                                                option(value=3) Hidden
                                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700.hidden
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>

                                        .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.gender_id.$error")
                                            span.info.text-xs This field is required
                            .field
                                .flex.mb-6
                                    .block(class="w-1/4").text-right.mr-8
                                        label.block.h-16.py-2 Birthdate
                                            small.text-red-500(style="padding-left:4px") *
                                    .flex-grow
                                        .inline-block.relative.w-full.mr-4
                                            input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.rounded.shadow.leading-tight(
                                                type="date"
                                                placeholder="10/20/2020"
                                                v-model="$v.form.date_of_birth.$model"
                                                :class="{ 'border-red-500': $v.form.date_of_birth.$error }"
                                            )
                                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2.text-gray-700.hidden
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>

                                        .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.form.date_of_birth.$error")
                                            span.info.text-xs This field is required
                            .field
                                .flex.mb-6
                                    .block(class="w-1/4").text-right.mr-8
                                        label.block.h-16.py-2 Photo
                                            small.text-red-500(style="padding-left:4px") *
                                    .flex-grow
                                        .cover-voucher.w-64.h-64.bg-gray-200.rounded-full.mb-2
                                            img.w-full.h-full.object-cover.rounded-full(
                                                v-if="avatar"
                                                :src="avatar || avatar != null ? avatar : 'https://i.imgur.com/9XwzHPA.png'", alt=""
                                            )
                                        .pt-4.text-center.w-64
                                            span.info.text-xs Please upload image with 400 x 400
                                            .block.mt-4
                                                UploadMedia(:inputMedia.sync="avatar")
                                            .flex.items-center.justify-between.mt-2.text-red-500(v-if="$v.avatar.$error")
                                                span.info.text-xs This field is required

                    section
                        .flex.items-center.justify-end
                            button.btn-primary.ml-3(type='button' @click="update()" :disabled="submitStatus === 'PENDING'") Update
                        p.typo__p.text-right.mt-3.text-green-500(v-if="submitStatus === 'OK'") Success updating data!
                        p.typo__p.text-right.mt-3.text-red-500(v-if="submitStatus === 'ERROR'") Please fill the form correctly.
                        p.typo__p.text-right.mt-3.text-blue-500(v-if="submitStatus === 'PENDING'") Sending...

</template>

<script>

import UploadMedia from "../../components/content/UploadMedia";
import Vue from "vue";
import router from "../..//router";
import moment from 'moment'
import {required} from "vuelidate/lib/validators";

export default {
    name: 'CustomerEdit',
    components: {
        UploadMedia,
    },
    data() {
        return {
            submitStatus: null,
            avatar: 'https://i.imgur.com/9XwzHPA.png',
            form: {
                name: '',
                status: 99,
                email: '',
                phone: '',
                date_of_birth: '',
                gender_id: 0,
            }
        }
    },
    validations: {
        form: {
            name: { required },
            status: { required },
            email: { required },
            phone: { required },
            date_of_birth: { required },
            gender_id: { required },
        },
        avatar: { },
    },

    methods: {
        load(){
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/' + this.$route.params.id)
                .then((response) => {
                    let data = response.data.data
                    this.form = data
                    this.form.name = data.customer_name
                    this.avatar = data.avatar ? data.avatar : 'https://i.imgur.com/9XwzHPA.png'
                    this.gender = data.gender_id
                    this.form.date_of_birth = moment(data.date_of_birth).format('YYYY-MM-DD')
                })
        },
        update () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                this.submitStatus = 'PENDING'
                var data = {
                    'customer_name': this.form.name,
                    'status': this.form.status,
                    'email': this.form.email,
                    'phone': this.form.phone,
                    'avatar': this.avatar,
                    'date_of_birth': this.form.date_of_birth,
                    'gender': this.form.gender_id ,
                }

                Vue.prototype.$http
                    .patch(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/'+this.$route.params.id+'/update', data)
                    .then(response => {
                        router.push({ name: 'UserPage', params: { id: response.data.data.id } })

                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success Updating Customer!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                    })
            }
        },
    },
    created() {
        this.load();
    },
    

}
</script>

<style lang="sass" scoped>
    .input-file
        width: 100%
        height: 100%
        left: 0
        top: 0
        opacity: 0
        position: absolute
        z-index: 1
</style>