<template lang="pug">
    //- Membership activity
    #membership
        .content-area
            .card
                header.card__header.flex.items-center.justify-between.border-b.border-gray-200.mb-4.pb-4
                    h2.font-bold Membership Activity
                    button.btn-primary.btn-small(@click="showModal = !showModal" v-if="userData.role == 1") Enroll

                .card__body
                    //.border.mb-6.p-6.rounded(v-bind:class="getClass(1)")
                    //    .flex.items-center.justify-between
                    //        .flex-grow
                    //            .flex.items-center
                    //                .flex.items-center.justify-center.w-12.h-12.rounded.bg-red-500.overflow-hidden
                    //                    img(:src="membershipDefaultType.logo ? membershipDefaultType.logo : 'https://i.imgur.com/9XwzHPA.png'", alt="")
                    //                .pl-4
                    //                    strong.block {{ membershipDefaultType.name }}
                    //                    span.block.opacity-75 New member
                    //        .block
                    //            span.block.opacity-75.text-xs {{ $attrs.created_at | moment('DD MMMM YYYY HH:mm') }}
                    .border.mb-6.p-6.rounded(v-for="list in membershipAcivityList" :key="list.index" v-bind:class="getClass(list.type_id)")
                        .flex.items-center.justify-between
                            .flex-grow
                                .flex.items-center
                                    .flex.items-center.justify-center.w-12.h-12.rounded.bg-red-500.overflow-hidden
                                        img(:src="list.logo ? list.logo : 'https://i.imgur.com/9XwzHPA.png'", alt="")
                                    .pl-4
                                        strong.block {{ list.type_name }} Membership
                                        span.block.opacity-50 Activated Membership to {{ list.type_name }}
                            .block
                                span.block.opacity-50.text-xs {{ list.created_at | moment('DD MMMM YYYY HH:mm') }}




        .overlay-popup.bg-gray-200.bg-opacity-75(v-if="showModal")
            .overlay-popup__container
                .popup.rounded.shadow
                    header.popup__header.border-b.border-gray-200.p-6.flex.items-center.justify-between
                        h2.font-bold Detail Enroll Membership
                        button.btn-icon.btn-icon--40(type='button' @click="showModal = false")
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    .popup__body.p-8
                        .block.border-b.border-gray-200.pb-4.mb-4
                            strong.text-xs.text-gray-600.block.pb-2 Select Membership
                            select.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                v-model="formEnroll.membership_type"
                            )
                                option(value="") Select Membership
                                option(v-for="(list, index) in listData" :value="list") {{ list.name }}
                            p.text-xs


                    .popup__footer.border-t.border-gray-200.p-6
                        .flex.items-center.justify-end
                            button.btn-default(type='button' @click="showModal = false") Back
                            button.btn-primary.ml-4(type='button' @click="nextInquiry()") Next
        .overlay-popup.bg-gray-200.bg-opacity-75(v-if="showInquiry")
            .overlay-popup__container
                .popup.rounded.shadow
                    header.popup__header.border-b.border-gray-200.p-6.flex.items-center.justify-between
                        h2.font-bold Detail Enroll Membership
                        button.btn-icon.btn-icon--40(type='button' @click="showInquiry = false")
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    .popup__body.p-8
                        .block.border-b.border-gray-200.pb-4.mb-4
                            strong.text-xs.text-gray-600.block.pb-2 Membership Before
                            p.mb-1 {{ $attrs.membership_type.name }}
                            p.text-xs

                        .block.border-b.border-gray-200.pb-4.mb-4
                            strong.text-xs.text-gray-600.block.pb-2 Membership After
                            p.mb-1 {{ formEnroll.membership_type.name }}
                            p.text-xs

                        .block.border-b.border-gray-200.pb-4.mb-4
                            strong.text-xs.text-gray-600.block.pb-2 Customer name
                            p.mb-1 {{ $attrs.customer_name }}
                            p.text-xs

                        .block.border-b.border-gray-200.pb-4.mb-4
                            strong.text-xs.text-gray-600.block.pb-2 Price
                            span.mr-2.px-2.py-1.bg-red-300.text-red-900.rounded.font-bold.text-xs.w-24.text-center.uppercase 100%
                            span.mb-1.line-through Rp {{ formEnroll.membership_type.membership_fee | moneyFilter }}
                            p.mb-1.mt-1.font-bold Rp 0


                    .popup__footer.border-t.border-gray-200.p-6
                        .flex.items-center.justify-end
                            button.btn-default(type='button' @click="showInquiry = false") Back
                            button.btn-primary.ml-4(type='button' @click="nextEnroll()") Next
        .overlay-popup.bg-gray-200.bg-opacity-75(v-if="showEnroll")
            .overlay-popup__container
                .popup.rounded.shadow
                    header.popup__header.border-b.border-gray-200.p-6.flex.items-center.justify-between
                        h2.font-bold Detail Enroll Membership
                        button.btn-icon.btn-icon--40(type='button' @click="showEnroll = false")
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    .popup__body.p-8
                        .block.border-b.border-gray-200.pb-4.mb-4
                            strong.text-xs.text-gray-600.block.pb-2 Input Password
                            input.block.appearance-none.w-full.bg-white.border.border-gray-400.px-4.py-2.pr-8.rounded.shadow.leading-tight(
                                maxlength="6"
                                type="password"
                                v-model="formEnroll.manager_pin"
                            )

                    .popup__footer.border-t.border-gray-200.p-6
                        .flex.items-center.justify-end
                            button.btn-default(type='button' @click="showEnroll = false") Back
                            button.btn-primary.ml-4(type='button' @click="finnishEnroll()") Enroll
                        p.typo__p.text-right.mt-3.text-blue-500(v-if="submitStatus === 'PENDING'") Sending...
        //- end Login activity
</template>

<script>
import Vue from "vue";
export default {
    name: 'MembershipActivity.vue',
    data() {
        return {
            membershipAcivityList: null,
            membershipDefaultType: null,
            pagination: null,
            showModal: false,
            showInquiry: false,
            showEnroll: false,
            listData: null,
            formEnroll: {
                membership_type: "",
                manager_pin: null,
            },
            submitStatus: null,
        }
    },
    computed: {
        userData(){
            return JSON.parse(localStorage.getItem('user'));
        },
    },
    methods: {
        getClass(membershipType){
            return {
                'bg-gray-600 border-gray-600 text-gray-100': (membershipType == 1),
                'bg-gray-200 border-gray-300 text-gray-900': (membershipType == 2 || membershipType == 6),
                'bg-yellow-200 border-orange-200 text-orange-900': (membershipType == 3 || membershipType == 7),
                'bg-blue-100 border-blue-200 text-blue-900': (membershipType == 4 || membershipType == 8),
                'bg-orange-300 border-orange-400 text-orange-900': (membershipType == 5)
            }
        },
        nextInquiry(){
            this.showModal = false
            this.showInquiry = true
        },
        nextEnroll(){
            this.showInquiry = false
            this.showEnroll = true
        },
        finnishEnroll(){
            this.submitStatus = 'PENDING'
            this.formEnroll.membership_type_id = this.formEnroll.membership_type.id
            this.formEnroll.membership_user_id = this.$route.params.id
            this.formEnroll.manager_id = this.userData.name

            var data = {
                "description": "Enroll membership",
                "membership_user_id": this.$route.params.id,
                "membership_type_id": this.formEnroll.membership_type.id,
                "manager_id": this.userData.name,
                "pin": this.formEnroll.manager_pin
            }

            Vue.prototype.$http
                .post(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/upgrade', data)
                .then((response) => {
                    if (response.data.error == 0) {
                        this.$router.go()
                        this.submitStatus = 'SUCCESS'
                        this.load()
                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Success!',
                                text: 'Success Upgrading Customer Membership!',
                                type: 'success',
                                timeout: false,
                                delay: 0
                            });
                        this.showEnroll = false
                    } else {
                        this.submitStatus = 'ERROR'
                        this.$store
                            .commit('ADD_NOTIFICATION', {
                                title: 'Error!',
                                text: response.data.description,
                                type: 'error',
                                timeout: false,
                                delay: 0
                            });
                    }


                })

        },

        load(){
            Vue.prototype.$http
                .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/'+this.$route.params.id+'/membership-activity')
                .then((response) => {
                    this.membershipAcivityList = response.data.data
                    this.pagination = response.data.meta
                })
        },
    },
    created() {
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/type?type=paid')
            .then(response => {
                this.listData = response.data.data;
            })
        Vue.prototype.$http
            .get(process.env.VUE_APP_API_HOST + '/api/v1/management/memberships/master/customer/type/default')
            .then((response) => {
                this.membershipDefaultType = response.data.data
                this.membershipDefaultType.name = 'Bronze'
            })
        this.load()

    }
}
</script>

<style lang="sass" scoped>
    .overlay-popup
        z-index: 100
        position: fixed
        left: 0
        top: 0
        bottom: 0
        right: 0
        width: 100%
        height: 100%
        overflow: auto
        &__container
            .popup
            background-color: #ffffff
            max-width: 600px
            margin: 2rem auto
            &__header
                display: flex
                align-items: center
                justify-content: space-between
</style>